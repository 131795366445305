import { observer } from "mobx-react";
import { Label } from "office-ui-fabric-react";
import * as React from "react";
import { EasyCheckbox } from "../../Generic";

@observer
export class Knz2FormblattPruefung1 extends React.Component<
    {
        PropertyName: string;
    },
    {}
> {
    public render(): JSX.Element {
        return (
            <>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <Label>Prüfung durch Freigeber</Label>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        Die Anlage ist fertiggestellt gemäß Definition mit folgendem Stand:
                        <EasyCheckbox
                            PropertyName={this.props.PropertyName}
                            CheckboxProps={{ label: "vollständig abgeschlossen" }}
                        />
                        sofern die Anlage nicht vollständig abgeschlossen ist, gilt der darunter vermerkte Stand:
                    </div>
                </div>
            </>
        );
    }
}
