import { observer } from "mobx-react";
import { ITextFieldProps, MaskedTextField } from "office-ui-fabric-react";
import * as React from "react";
import { EasyFormContext } from "./EasyFormContext";

@observer
export class EasyMaskedTextField extends React.Component<
    {
        Object?: object;
        PropertyName: string;
        TextFieldProps: ITextFieldProps;
        OnChange?: () => void;
    },
    {
        value: string;
    }
> {
    private get Object(): object {
        return this.props.Object || this.context.Object;
    }
    public static contextType = EasyFormContext;
    public readonly state = { value: "" };

    public render() {
        return (
            <MaskedTextField
                value={this.Object[this.props.PropertyName] || ""}
                onChange={(e: React.FormEvent<HTMLElement>, value: string) => {
                    this.setState({ value });
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    this.Object[this.props.PropertyName] = this.state.value;
                    if (this.props.OnChange) {
                        this.props.OnChange();
                    }
                }}
                autoComplete="off"
                disabled={this.context.Disabled || this.props.TextFieldProps.disabled}
                required={
                    !this.context.Disabled && !this.props.TextFieldProps.disabled && this.props.TextFieldProps.required
                }
                {...this.props.TextFieldProps}
            />
        );
    }
}
