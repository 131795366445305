import * as React from "react";
import { TaskApproveCheckboxes } from ".";
import { ITaskEditorProps } from "../../Workflow/TaskEditDialogStore";

export class Knz2FormblattOUStep2 extends React.Component<ITaskEditorProps, {}> {
    public render(): JSX.Element {
        return (
            <TaskApproveCheckboxes
                {...this.props}
                Preface="Das Dokument wurde an Hand nachfolgender Prüfungskriterien geprüft:"
                ToCheck={[
                    "Das ID-Prüfprotokoll ist konsistent, vollständig und nachvollziehbar.",
                    "Es bestehen keine Anmerkungen/ Abweichungen zur Prüfung durch den 1.Prüfer (Gesamtprojektsteuerung)."
                ]}
            />
        );
    }
}
