import { observer } from "mobx-react";
import { Label } from "office-ui-fabric-react";
import * as React from "react";
import { EasyCombobox } from "../../Generic";

@observer
export class Knz2FormblattPruefung2 extends React.Component<
    {
        Options: {};
    },
    {}
> {
    public render() {
        return (
            <>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm8">
                        <Label>Die Anlage ist abgeschlossen, es sind...</Label>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    {Object.entries(this.props.Options).map(([key, value]) => (
                        <div className="ms-Grid-col ms-sm5" key={key}>
                            <EasyCombobox
                                PropertyName={key}
                                Options={["abgeschlossen", "offen", "nicht zutreffend"]}
                                ComboBoxProps={{ label: value, required: true }}
                            />
                        </div>
                    ))}
                </div>
            </>
        );
    }
}
