import { observer } from "mobx-react";
import { IColumn, ProgressIndicator, ScrollablePane, ScrollbarVisibility } from "office-ui-fabric-react";
import * as React from "react";
import { allMetadata } from "../../generated/RadRepository";
import { DatenImportListModel } from "../../Model/DatenImport";
import { AbstractDatenImportModel, BaseFile, EnumDatenImportStatus, IEntityRepositoryWithFile } from "../../Rad/DAL";
import { nameof, Utils } from "../../Utils";
import { LargeDetailsList, ObserverSpan, FolderViewInList } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class DatenImportList<T extends AbstractDatenImportModel, F extends BaseFile> extends React.Component<
    {
        Repository: IEntityRepositoryWithFile<T, F>;
        OnEditItem: (knz4DatenImport: AbstractDatenImportModel) => void;
    },
    {
        ListModel: DatenImportListModel<T>;
    }
> {
    private listRef: React.RefObject<LargeDetailsList>;

    private columns: IColumn[] = [
        {
            key: "Feld01",
            name: "Hochgeladen am",
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: T) => (
                <ObserverSpan
                    Object={item}
                    PropertyName={nameof<AbstractDatenImportModel>("created")}
                    FormatFunction={Utils.FormatDateTime}
                />
            )
        },
        {
            key: "Feld02",
            name: "Status",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: T) => (
                <ObserverSpan
                    Object={item}
                    PropertyName={nameof<AbstractDatenImportModel>("k0F61DatenImportStatus")}
                    FormatFunction={(s) =>
                        Utils.ApplyMapping(s, allMetadata.enumMapping["DB.LSK.Model.Enums+EnumDatenImportStatus"])
                    }
                />
            )
        },
        {
            key: "Feld04",
            name: "Verarbeitete Datenzeilen",
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            onRender: (item: T) => (
                <ObserverSpan
                    Object={item}
                    PropertyName={nameof<AbstractDatenImportModel>("k0F63DatenImportItemsImported")}
                />
            )
        },
        {
            key: "Feld03",
            name: "\u2211 Datenzeilen",
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: T) => (
                <ObserverSpan
                    Object={item}
                    PropertyName={nameof<AbstractDatenImportModel>("k0F62DatenImportItemsToImport")}
                />
            )
        },
        {
            key: "Feld05",
            name: "Fortschritt",
            minWidth: 50,
            maxWidth: 350,
            isResizable: true,
            onRender: (item: T) => {
                const totalItemsToImport = item.k0F62DatenImportItemsToImport;
                const totalItemsimported = item.k0F63DatenImportItemsImported;
                switch (item.k0F61DatenImportStatus) {
                    case EnumDatenImportStatus.Begonnen:
                        return <ProgressIndicator description={`Der Datenimport wird gestartet ...`} />;
                    case EnumDatenImportStatus.InBearbeitung:
                        return (
                            <ProgressIndicator
                                description={`${totalItemsimported} von ${totalItemsToImport} Zeilen verarbeitet`}
                                percentComplete={totalItemsimported / totalItemsToImport}
                            />
                        );
                    case EnumDatenImportStatus.Abgeschlossen:
                        return <div>Import abgeschlossen</div>;
                    default:
                        return <div>Unbekannter Status: {item.k0F61DatenImportStatus}</div>;
                }
            }
        },
        {
            key: "Feld06",
            name: "Datei",
            minWidth: 50,
            maxWidth: 350,
            isResizable: true,
            onRender: (item: T) => {
                    return <FolderViewInList
                        Repository={this.props.Repository}
                        ParentObjectId={item.objectId}
                    />
            }
        }
    ];

    private interval: any;
    constructor(props) {
        super(props);
        this.listRef = React.createRef();
        this.state = {
            ListModel: new DatenImportListModel(this.props.Repository),
        };
    }

    public componentDidMount() {
        this.interval = setInterval(() => {
            this.RefreshListItems();
        }, 30000);
    }

    public componentWillUnmount() {
        clearInterval(this.interval);
    }

    public async componentDidUpdate(prevProps): Promise<void> {
        if (this.props.Repository !== prevProps.Repository) {
            this.setState({
                ListModel: new DatenImportListModel(this.props.Repository)
            });
            // await this.RefreshListItems();
        }
    }

    public async RefreshListItems(): Promise<void> {
        await this.listRef.current.RefreshItems();
    }

    public render(): JSX.Element {
        return (
            <div style={{ position: "relative", height: "80vh" }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <LargeDetailsList
                        Columns={this.columns}
                        DataSource={this.state.ListModel}
                        OnItemInvoked={(item) => {
                            this.props.OnEditItem(item);
                        }}
                        ref={this.listRef}
                    />
                </ScrollablePane>
            </div>
        );
    }
}
