import { observer } from "mobx-react";
import { DefaultButton, DialogFooter, MessageBarType, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { EnumBerichtArt, FileParameter } from "../../generated/BackendClient";
import { Bericht, BerichteRepository } from "../../Model/Berichte";
import { nameof } from "../../Utils";
import { DBDialog, EasyCombobox, EasyForm, EasyNumberField, FileUploader, ValidationMessages } from "../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../Generic/Error-Handler/Decorators";

@CatchReactErrors
@observer
export class Knz1NewBerichtDialog extends React.Component<
    {
        Model: BerichteRepository;
        hideDialog: boolean;
        showMonat?: boolean;
        closeDialog: (
            result: boolean,
            newModel: Bericht,
            lspDatei?: FileParameter,
            zentraleDatei?: FileParameter
        ) => void;
    },
    {
        Bericht: Bericht;
        lspDatei: FileParameter;
        zentraleDatei: FileParameter;
        IsFilesSelected: boolean;
    }
> {
    constructor(props) {
        super(props);
        const bericht = new Bericht();
        bericht.berichtJahr = new Date().getFullYear();
        bericht.berichtMonat = Math.min(12, new Date().getMonth() + 1);
        this.state = {
            Bericht: bericht,
            lspDatei: null,
            zentraleDatei: null,
            IsFilesSelected: false
        };
    }

    public render(): JSX.Element {
        let msg = this.validationMessage();
        return (
                <DBDialog
                    onDismiss={() => {
                        this.ExitDialog(false);
                    }}
                    dialogContentProps={{
                        title: "Bericht erstellen"
                    }}
                    minWidth="760px"
                >
                    <EasyForm Object={this.state.Bericht} ShowFooter={true}>
                        <ValidationMessages Messages={this.state.Bericht.NewBerichtValidationmessages} />
                        <ValidationMessages Messages={this.state.Bericht.Knz1ValidationMessages} />
                        {msg && (
                            <ValidationMessages
                                Messages={[
                                    {
                                        Text: msg,
                                        Type: MessageBarType.error
                                    }
                                ]}
                            />
                        )}
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm5">
                                    <EasyCombobox
                                        PropertyName={nameof<Bericht>("berichtArt")}
                                        ComboBoxProps={{ label: "Berichtart" }}
                                        Options={this.props.Model.GetBerichtArt}
                                    />
                                </div>

                                {this.props.showMonat &&
                                    this.state.Bericht.berichtArt !== EnumBerichtArt.Jahresbericht && (
                                        <div className="ms-Grid-col ms-sm3">
                                            <EasyCombobox
                                                PropertyName={nameof<Bericht>("berichtMonat")}
                                                ComboBoxProps={{ label: "Monat" }}
                                                Options={[...Array(12).keys()].map((x) => (x + 1).toString())}
                                            />
                                        </div>
                                    )}
                                <div className="ms-Grid-col ms-sm4">
                                    <EasyCombobox
                                        PropertyName={nameof<Bericht>("berichtJahr")}
                                        ComboBoxProps={{ label: "Jahr" }}
                                        Options={[...Array(10).keys()].map((x) => (x + 2019).toString())}
                                    />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm5">
                                    <EasyNumberField
                                        PropertyName={nameof<Bericht>("berichtKennzahlLSPDateiWert")}
                                        TextFieldProps={{
                                            label: "Knz Lärmsanierungsprogramm Wert",
                                            required: true
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm5">
                                    <EasyNumberField
                                        PropertyName={nameof<Bericht>("berichtKennzahlZentraleDateiWert")}
                                        TextFieldProps={{
                                            label: "Knz Zentrale Wert",
                                            required: this.state.Bericht.berichtArt === EnumBerichtArt.Jahresbericht
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12">
                                    <FileUploader
                                        Label="Knz Lärmsanierungsprogramm Datei"
                                        OnUploaded={(file) => {
                                            this.setState({ lspDatei: file });
                                        }}
                                        Required
                                    />
                                </div>
                                {this.state.Bericht.berichtArt === EnumBerichtArt.Jahresbericht && (
                                    <div className="ms-Grid-col ms-sm12">
                                        <FileUploader
                                            Label="Knz Zentrale Datei"
                                            OnUploaded={(file) => {
                                                this.setState({ zentraleDatei: file });
                                            }}
                                            Required={this.state.Bericht.berichtArt === EnumBerichtArt.Jahresbericht}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <DialogFooter>
                            <PrimaryButton
                                onClick={() => {
                                    this.ExitDialog(true);
                                }}
                                text="Erstellen"
                                disabled={
                                    !this.state.Bericht.IsValid ||
                                    !this.state.Bericht.IsKnz1BerichtValid ||
                                    this.state.Bericht.NewBerichtValidationmessages?.length > 0 ||
                                    this.state.Bericht.Knz1ValidationMessages?.length > 0 ||
                                    !!msg
                                }
                            />
                            <DefaultButton
                                onClick={() => {
                                    this.ExitDialog(false);
                                }}
                                text="Abbrechen"
                            />
                        </DialogFooter>
                    </EasyForm>
                </DBDialog>
        );
    }

    private validationMessage(): string {
        if (this.state.Bericht.berichtArt === EnumBerichtArt.Jahresbericht) {
            if (!this.state.lspDatei || !this.state.zentraleDatei) {
                return "Für Jahresberichte müssen beide Excel-Dateien hochgeladen werden.";
            }

            if (this.state.lspDatei.fileName === this.state.zentraleDatei.fileName) {
                return "Die zwei Dateien für die Knz. 1 Lärmsanierungsprogramm und Zentrale sind identisch.";
            }
            if (!!this.state.lspDatei) {
                return null;
            }
        }
        if (this.state.Bericht.berichtArt === EnumBerichtArt.Zwischenbericht) {
            if (!this.state.lspDatei) {
                return "Für Zwischenberichte bitte die Lärmsanierungsprogramm-Excel hochladen.";
            }

            if (!!this.state.zentraleDatei) {
                return "Für Zwischenberichte darf nur das Lärmsanierungsprogramm-Excel hochgeladen werden.";
            }
        }
        return null;
    }

    @CatchReactErrorsMethod()
    private async ExitDialog(shouldSave: boolean) {
        if (shouldSave) {
            if (!this.state.Bericht.IsValid) {
                return;
            }

            this.props.closeDialog(true, this.state.Bericht, this.state.lspDatei, this.state.zentraleDatei);
        } else {
            this.props.closeDialog(false, undefined);
        }
    }
}
