import { DefaultButton, DialogFooter, Pivot, PivotItem, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { RadEditDialogClose, RadEditDialogLoad, RadEditDialogStateReducer } from ".";
import { DBDialog, PleaseWait } from "../../../tsx/Generic";
import { BaseEntity, IEntityRepository } from "../../DAL";
import { RadVersionHistoryListEntity } from "../Controls";
import { RenderEditField } from "../RadFields/FieldRenderer";

export interface RadEditDialogProps<T extends BaseEntity> {
    Repository: IEntityRepository<T>;
    ObjectId: string;
    OnDialogClosed: (wasSaved: boolean, newModel: T) => void;
    readOnly?: boolean;
}

export function RadEditDialog<T extends BaseEntity>({
    Repository,
    ObjectId,
    readOnly,
    OnDialogClosed
}: React.PropsWithChildren<RadEditDialogProps<T>>) {
    const [state, dispatch] = React.useReducer(RadEditDialogStateReducer<T>(), {
        IsLoading: true,
        Model: null,
        Repository,
        ObjectId,
        OnDialogClosed
    });

    // initial item loading
    React.useEffect(() => {
        RadEditDialogLoad(dispatch, state);
    }, [ObjectId]);

    return (
        <DBDialog
            onDismiss={(): void => {
                RadEditDialogClose(dispatch, state, false);
            }}
            dialogContentProps={{
                title: `${Repository.Metadata.title} ` + (!ObjectId ? "erstellen" : readOnly ? "ansehen" : "bearbeiten")
            }}
        >
            <PleaseWait
                ShowSpinner={state.IsLoading}
                render={(): React.ReactNode => {
                    return (
                        <>
                            <Pivot>
                                <PivotItem headerText="Allgemein">
                                    <div className="ms-Grid" dir="ltr">
                                        {Object.values(Repository.Metadata.fields)
                                            .filter((f) => f.showInEditDialog)
                                            .map((v, i) => {
                                                return (
                                                    <div className="ms-Grid-row" key={i}>
                                                        <div className="ms-Grid-col ms-sm6">
                                                            <RenderEditField
                                                                Metadata={v}
                                                                Value={state.Model[v.fieldName]}
                                                                OnChange={(value) => {
                                                                    dispatch({
                                                                        type: "updateField",
                                                                        fieldName: v.fieldName,
                                                                        value: value
                                                                    });
                                                                }}
                                                                Disabled={readOnly}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </PivotItem>
                                {ObjectId && (
                                    <PivotItem headerText="Historie">
                                        <RadVersionHistoryListEntity ObjectId={ObjectId} Repository={Repository} />
                                    </PivotItem>
                                )}
                            </Pivot>
                            <DialogFooter>
                                {readOnly ? (
                                    <>
                                        <PrimaryButton
                                            onClick={(): void => {
                                                RadEditDialogClose(dispatch, state, false);
                                            }}
                                            text="Schließen"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <PrimaryButton
                                            onClick={() => {
                                                RadEditDialogClose(dispatch, state, true);
                                            }}
                                            text="Speichern"
                                            disabled={readOnly}
                                        />
                                        <DefaultButton
                                            onClick={(): void => {
                                                RadEditDialogClose(dispatch, state, false);
                                            }}
                                            text="Abbrechen"
                                        />
                                    </>
                                )}
                            </DialogFooter>
                        </>
                    );
                }}
            />
        </DBDialog>
    );
}
