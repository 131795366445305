import * as mobx from "mobx";
import { observer } from "mobx-react";
import {
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IDetailsListProps,
    IObjectWithKey,
    Selection,
    SelectionMode,
    ShimmeredDetailsList
} from "office-ui-fabric-react";
import * as React from "react";
import { IBaseEntity } from "../../Rad/DAL";
import { CatchReactErrors } from "./Error-Handler/Decorators";
import { PleaseWait } from "./PleaseWait";

export interface IDetailListDatasource {
    IsLoading: boolean;
    GetAllItems(): Promise<Map<string, IBaseEntity>>;
    Equals(IDetailListDatasource): boolean;
    Sort(items: IBaseEntity[]): void;
}

@observer
@CatchReactErrors
export class LargeDetailsList extends React.Component<
    {
        Columns: IColumn[];
        DataSource: IDetailListDatasource;
        OnItemInvoked?: (item: any) => void;
        OnActiveItemChanged?: (item: any) => void;
        DetailsListProps?: IDetailsListProps | any;
        OnSelectedItems?: (selectedItems: IObjectWithKey[]) => void;
        isShimmerList?: boolean;
        isDataLoaded?: boolean;
    },
    {
        IsLoading: boolean;
        Items: Map<string, IBaseEntity>;
    }
> {
    public readonly state = {
        IsLoading: true,
        Items: undefined
    };

    private selection = new Selection({
        onSelectionChanged: () => {
            this.props.OnSelectedItems && this.props.OnSelectedItems(this.selection.getSelection());
        }
    });

    public async componentDidUpdate(prevProps) {
        if (!this.props.DataSource.Equals(prevProps.DataSource)) {
            this.setState({ IsLoading: true });
            await this.RefreshItems(); // async
            this.setState({ IsLoading: false });
        }
    }

    public async componentDidMount() {
        await this.RefreshItems();
    }

    public async RefreshItems(): Promise<void> {
        const items = await this.props.DataSource.GetAllItems();
        this.setState({
            Items: items,
            IsLoading: false
        });
    }

    public render() {
        let items: any[];
        if (this.state.Items) {
            if (mobx.isObservable(this.state.Items)) {
                items = mobx.values(this.state.Items) as any[];
            } else {
                items = Array.from(this.state.Items.values());
            }
        }

        if (items) {
            this.props.DataSource.Sort(items);
            // must have key
            items = items.filter((i) => i.key);
        }
        return (
            <PleaseWait
                ShowSpinner={this.state.IsLoading || !items}
                render={() =>
                    !this.props.isShimmerList ? (
                        <DetailsList
                            items={items}
                            columns={this.props.Columns}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            compact={true}
                            selectionMode={SelectionMode.multiple}
                            onItemInvoked={(item) => {
                                this.props.OnItemInvoked && this.props.OnItemInvoked(item);
                            }}
                            onActiveItemChanged={(item) => {
                                this.props.OnActiveItemChanged && this.props.OnActiveItemChanged(item);
                            }}
                            selection={this.selection}
                            {...this.props.DetailsListProps}
                        />
                    ) : (
                        <ShimmeredDetailsList
                            items={items}
                            columns={this.props.Columns}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            compact={true}
                            selectionMode={SelectionMode.multiple}
                            onItemInvoked={(item) => {
                                this.props.OnItemInvoked && this.props.OnItemInvoked(item);
                            }}
                            onActiveItemChanged={(item) => {
                                this.props.OnActiveItemChanged && this.props.OnActiveItemChanged(item);
                            }}
                            selection={this.selection}
                            {...this.props.DetailsListProps}
                            enableShimmer={!this.props.isDataLoaded}
                        />
                    )
                }
            />
        );
    }
}
