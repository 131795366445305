import { TasksClient, WorkflowTask, WorkflowTaskResponse } from "../../generated/BackendClient";

export class WorkflowRepository {
    public async GetAllTasks(): Promise<WorkflowTask[]> {
        const dal = new TasksClient();
        return dal.getTasks();
    }

    public async GetTask(taskId: string): Promise<WorkflowTask> {
        const dal = new TasksClient();
        return dal.getTask(taskId);
    }

    public async SetTaskResponse(task: WorkflowTask, response: WorkflowTaskResponse): Promise<void> {
        const dal = new TasksClient(); 
        return dal.setTaskResponse(response, task.id);
    }
}
