import { saveAs } from "file-saver";
import { observer } from "mobx-react";
import { Link, MessageBar, Pivot, PivotItem } from "office-ui-fabric-react";
import * as React from "react";
import { Knz4LVCommandBar, Knz4LVDeletedItemsList, Knz4LVEditDialog, Knz4LVList, Knz4LVSearchBox } from ".";
import { LskRepository } from "../../DAL/LskRepository";
import { EnumApprovalWorkflowStatus } from "../../generated/BackendClient";
import { Knz4LV, Knz4LVListModelFilter, Knz4LVRepository } from "../../Model/Knz4LV";
import { Utils } from "../../Utils";
import { ChoiceDialog, HandleError, LoadingDialog } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz4LVContainer extends React.Component<
    {
        Model: Knz4LVRepository;
    },
    {
        Knz4LVSelectedItem: Knz4LV;
        SelectedItemsToRemove: Knz4LV[];
        SelectedItemsToRestore: Knz4LV[];
        ShowEditDialog: boolean;
        ShowNewDialog: boolean;
        EnableRecycleButton: boolean;
        Filter: Knz4LVListModelFilter;
    }
> {
    public readonly state = {
        Knz4LVSelectedItem: undefined,
        SelectedItemsToRemove: [],
        SelectedItemsToRestore: [],
        ShowEditDialog: false,
        ShowNewDialog: false,
        EnableRecycleButton: false,
        Filter: new Knz4LVListModelFilter("")
    };
    private listRef: React.RefObject<Knz4LVList> = React.createRef();
    private listRefImPapierkorb: React.RefObject<Knz4LVDeletedItemsList> = React.createRef();
    private loadingRef: React.RefObject<LoadingDialog> = React.createRef();
    private yesNoRef: React.RefObject<ChoiceDialog> = React.createRef();

    public render(): JSX.Element {
        return (
            <>
                <MessageBar>
                    Bei Fragen zur Bedienung wenden Sie sich gerne an
                    <Link href="mailto:okhan.obay@deutschebahn.com">Okhan Obay</Link>
                </MessageBar>

                <LoadingDialog ref={this.loadingRef} />
                <ChoiceDialog ref={this.yesNoRef} />

                <HandleError>
                    {this.state.ShowNewDialog && (
                        <Knz4LVEditDialog
                            Model={this.props.Model}
                            InitialModel={this.state.Knz4LVSelectedItem}
                            hideDialog={false}
                            closeDialog={async (shouldSave: boolean, newmodel: Knz4LV): Promise<void> => {
                                if (shouldSave) {
                                    const knz4Item = await this.props.Model.AddKnz4LVItem(newmodel);
                                    await this.listRef?.current?.onItemUpdated(knz4Item);
                                    this.listRef?.current?.RefreshListItems();
                                }

                                this.setState({ ShowNewDialog: false });
                            }}
                        />
                    )}
                    {this.state.ShowEditDialog && (
                        <Knz4LVEditDialog
                            Model={this.props.Model}
                            InitialModel={this.state.Knz4LVSelectedItem}
                            hideDialog={false}
                            closeDialog={async (shouldSave: boolean, newModel: Knz4LV): Promise<void> => {
                                if (shouldSave) {
                                    const knz4Item = await this.props.Model.UpdateKnz4LVItem(newModel);
                                    await this.listRef?.current?.onItemUpdated(knz4Item);
                                    await this.listRefImPapierkorb?.current?.onItemUpdated(knz4Item);
                                    this.listRef?.current?.RefreshListItems();
                                    this.listRefImPapierkorb?.current?.RefreshListItems();
                                    this.UpdateSelecteditem(this.state.SelectedItemsToRemove, knz4Item);
                                    this.UpdateSelecteditem(this.state.SelectedItemsToRestore, knz4Item);
                                }

                                this.setState({ ShowEditDialog: false });
                            }}
                        />
                    )}
                    <Knz4LVCommandBar
                        Model={this.props.Model}
                        OnNewClick={() => {
                            this.props.Model.IsReadOnly = false;
                            let knz4LVItem = new Knz4LV();
                            knz4LVItem.k4LVF24ImPapierkorb = false;
                            this.setState({ Knz4LVSelectedItem: knz4LVItem, ShowNewDialog: true });
                        }}
                        OnRemoveItemsClick={async () => {
                            if (this.state.SelectedItemsToRemove.length >= 5) {
                                const result = await this.yesNoRef.current.ExecuteYesNo(
                                    "Warnung",
                                    <>
                                        Möchsten Sie die {this.state.SelectedItemsToRemove.length} gewählten Einträge
                                        wirklich löschen?
                                    </>,
                                    true
                                );
                                if (!result) {
                                    return;
                                }
                            }

                            await this.loadingRef.current.Execute(async (): Promise<void> => {
                                await this.UpdateItem(this.state.SelectedItemsToRemove, true);
                                await this.listRef.current.RefreshListItems();
                            }, "Die Maßn. Lärmvorsorge wird gelöscht.");
                        }}
                        OnRestoreItemsClick={async () => {
                            await this.loadingRef.current.Execute(async (): Promise<void> => {
                                await this.UpdateItem(this.state.SelectedItemsToRestore, false);
                                await this.listRefImPapierkorb?.current?.RefreshListItems();
                            }, "Die Maßn. Lärmvorsorge wird wiederhergestellt.");
                        }}
                        ExportExcel={async () => {
                            await this.loadingRef.current.Execute(async () => {
                                const fileResponse = await this.props.Model.ExportListToExcel();
                                saveAs(fileResponse.data, fileResponse.fileName);
                            }, "Excel-Export wird erstellt");
                        }}
                        EnableRemoveItemsButton={this.state.EnableRecycleButton}
                        EnableRestoreItemsButton={this.state.SelectedItemsToRestore?.length > 0}
                    />
                    <Pivot>
                        <PivotItem headerText="Maßn. Lärmvorsorge">
                            <br />
                            <Knz4LVSearchBox
                                Model={this.props.Model}
                                OnUpdateFilter={(filter: Knz4LVListModelFilter) => {
                                    this.setState({ Filter: filter });
                                }}
                                FilterText={this.state.Filter.text}
                            />
                            <Knz4LVList
                                ref={this.listRef}
                                Model={this.props.Model}
                                OnEditKnz4LVItem={async (knz4LVItem: Knz4LV) => {
                                    let isBerichtFreigegeben = await this.HasFreigegebeneBerichte(knz4LVItem);

                                    if (isBerichtFreigegeben) {
                                        const result = await this.yesNoRef.current.ExecuteYesNo(
                                            "Warnung",
                                            <>
                                                Die Lärmvorsorgemaßnahme wird bereits in einem Freigabebericht
                                                verwendet.
                                                <br />
                                                Möchten Sie diese Lärmvorsorgemaßnahme bearbeiten? <b>Nein</b> oder{" "}
                                                <b>Ja</b>?
                                            </>,
                                            true
                                        );
                                        if (!result) {
                                            return;
                                        }
                                    }

                                    this.setState({ Knz4LVSelectedItem: knz4LVItem, ShowEditDialog: true });
                                }}
                                OnSelectedItems={async (selectedItems: Knz4LV[]) => {
                                    this.setState({ SelectedItemsToRemove: selectedItems });
                                    if (selectedItems && selectedItems.length > 0) {
                                        this.setState({
                                            EnableRecycleButton: await this.CanItemsBeRemoved(selectedItems)
                                        });
                                    } else {
                                        this.setState({ EnableRecycleButton: false });
                                    }
                                }}
                                Filter={this.state.Filter}
                                LoadItemsFromPapierkorb={false}
                            />
                        </PivotItem>
                        <PivotItem headerText="Maßn. Lärmvorsorge im Papierkorb">
                            <Knz4LVDeletedItemsList
                                ref={this.listRefImPapierkorb}
                                Model={this.props.Model}
                                OnEditKnz4LVItem={async (knz4LVItem: Knz4LV) => {
                                    let isBerichtFreigegeben = await this.HasFreigegebeneBerichte(knz4LVItem);

                                    if (isBerichtFreigegeben) {
                                        const result = await this.yesNoRef.current.ExecuteYesNo(
                                            "Warnung",
                                            <>
                                                Die Lärmvorsorgemaßnahme wird bereits in einem Freigabebericht
                                                verwendet.
                                                <br />
                                                Möchten Sie diese Lärmvorsorgemaßnahme bearbeiten? <b>Nein</b> oder{" "}
                                                <b>Ja</b>?
                                            </>,
                                            true
                                        );
                                        if (!result) {
                                            return;
                                        }
                                    }

                                    this.setState({ Knz4LVSelectedItem: knz4LVItem, ShowEditDialog: true });
                                }}
                                OnSelectedItems={(selectedItems: []) => {
                                    this.setState({ SelectedItemsToRestore: selectedItems });
                                }}
                                Filter={this.state.Filter}
                                LoadItemsFromPapierkorb={true}
                            />
                        </PivotItem>
                    </Pivot>
                </HandleError>
            </>
        );
    }

    private UpdateSelecteditem(selectedItemsCollection: Knz4LV[], knz4LVItem: Knz4LV) {
        const foundIndex = selectedItemsCollection.findIndex((k: Knz4LV) => k.objectId === knz4LVItem.objectId);
        if (foundIndex > -1) {
            selectedItemsCollection.splice(foundIndex, 1);
            selectedItemsCollection.push(knz4LVItem);
        }
    }

    private async UpdateItem(selectedItems: Knz4LV[], removeItem: boolean): Promise<void> {
        for (const item of selectedItems) {
            if (await this.props.Model.CanEditItem(item)) {
                item.k4LVF24ImPapierkorb = removeItem;
                const knz4Item = await this.props.Model.UpdateKnz4LVItem(item);
                await this.listRef?.current?.onItemUpdated(knz4Item);
                await this.listRefImPapierkorb?.current?.onItemUpdated(knz4Item);
            }
        }
    }

    private async HasFreigegebeneBerichte(knz4LVItem: Knz4LV): Promise<boolean> {
        if (knz4LVItem.k4LVF31BerichtRefs?.length > 0) {
            let berichte = await LskRepository.Bericht.getMulti(knz4LVItem.k4LVF31BerichtRefs.map((r) => r.objectId));
            return berichte.some((b) => b.berichtWFStatus === EnumApprovalWorkflowStatus.Freigegeben);
        }
        return false;
    }

    private async CanItemsBeRemoved(items: Knz4LV[]): Promise<boolean> {
        if (items && items.length > 0) {
            let ids = Utils.SelectMany<Knz4LV, string>(items, (r: Knz4LV) =>
                !!r.k4LVF31BerichtRefs ? r.k4LVF31BerichtRefs.map((b) => b.objectId) : []
            );
            let berichte = await LskRepository.Bericht.getMulti([...new Set(ids)]);
            return !berichte.some((b) => b.berichtWFStatus === EnumApprovalWorkflowStatus.Freigegeben);
        }
        return false;
    }
}
