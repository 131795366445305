import * as mobx from "mobx";
import { Knz4LSListModelFilter, Knz4LSObjekt } from ".";
import { IDetailListDatasource } from "../../tsx/Generic/LargeDetailsList";
import { Knz4Repository } from "./Knz4Repository";

export class Knz4LSDetailList implements IDetailListDatasource {
    @mobx.computed public get IsLoading(): boolean {
        return this.RunningRequests > 0;
    }

    @mobx.observable private RunningRequests = 0;
    private Model: Knz4Repository;
    private Filter: Knz4LSListModelFilter;

    public constructor(model: Knz4Repository, filter: Knz4LSListModelFilter) {
        this.Model = model;
        this.Filter = filter;
    }

    public Sort(items: Knz4LSObjekt[]): Knz4LSObjekt[] {
        return items;
    }

    public async GetAllItems(): Promise<Map<string, Knz4LSObjekt>> {
        if (this.Filter.IsSet()) {
            return await this.Model.Search(this.Filter.text);
        } else {
            return new Map<string, Knz4LSObjekt>();
        }
    }

    public Equals(IListDatasource: Knz4LSDetailList): boolean {
        return IListDatasource.Filter.Equals(this.Filter);
    }
}
