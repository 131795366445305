import * as mobx from "mobx";
import { Knz2Altprojekt, Knz2AltprojekteListModelFilter } from ".";
import { LskRepository } from "../../DAL/LskRepository";
import { EntityCache } from "../../Rad/DAL";
import { IDetailListDatasource } from "../../tsx/Generic/LargeDetailsList";
import { Utils } from "../../Utils";
import { Knz2Repository } from "./Knz2Repository";

export class Knz2AltprojekteListModel implements IDetailListDatasource {
    @mobx.computed public get IsLoading(): boolean {
        return this.RunningRequests > 0;
    }
    private AllItems = new EntityCache(LskRepository.Knz2Altprojekt);
    private Filter: Knz2AltprojekteListModelFilter;

    @mobx.observable private RunningRequests = 0;
    private Model: Knz2Repository;

    public constructor(model: Knz2Repository, filter: Knz2AltprojekteListModelFilter) {
        this.Model = model;
        this.Filter = filter;
    }

    public Sort(items: Knz2Altprojekt[]): void {
        items.sort((a, b) => (a.k2F33Kurzprojektnummer < b.k2F33Kurzprojektnummer ? 1 : -1));
    }

    public async GetAllItems(): Promise<Map<string, Knz2Altprojekt>> {
        const allItems = await this.AllItems.GetAllMap();
        if (this.Filter?.IsSet()) {
            // if we have a text filter, apply it
            const needle = this.Filter.text.toLowerCase();
            return Utils.MapFilter(
                allItems,
                (k, p) =>
                    p.k2F33Kurzprojektnummer?.includes(needle) ||
                    p.k2F35ProjektBezeichnungLang?.toLowerCase().includes(needle)
            );
        } else {
            return allItems;
        }
    }

    public Equals(source: Knz2AltprojekteListModel): boolean {
        return this === source;
    }
}
