import { mergeStyleSets } from "@uifabric/styling/lib/MergeStyles";
import { observer } from "mobx-react";
import { ComboBox, IComboBox, IComboBoxOption } from "office-ui-fabric-react";
import * as React from "react";
import { Knz2GK2018Abschnitt } from "../../../Model/Knz2/GK2018/Knz2Abschnitt";
import { Knz2Repository } from "../../../Model/Knz2/GK2018/Knz2Repository";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";
import { EasyFormContext } from "../../Generic/Forms/EasyFormContext";

const classNames = mergeStyleSets({
    ComboBoxRow: {
        minWidth: "700px",
        display: "inline-block"
    },
    ComboBoxSpan1: {
        minWidth: "60px", // 6 Zeichen max
        marginRight: "8px",
        display: "inline-block"
    }
});

@observer
@CatchReactErrors
export class Knz2AbschnitteControl extends React.Component<
    {
        Model: Knz2Repository;
        SelectedId: string;
        OnChange: (newId: string) => void;
        Disabled?: boolean;
        ShowA1: boolean;
    },
    {
        Options: IComboBoxOption[];
        InitialText: string;
    }
> {
    public static contextType = EasyFormContext;

    public readonly state = {
        Options: [],
        InitialText: undefined
    };

    public async componentDidMount(): Promise<void> {
        if (this.state.Options.length > 0) {
            return;
        }

        let items = await this.props.Model.AbschnitteCache.GetAll();

        items = items.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
        let options: IComboBoxOption[] = [];
        for (const obj of items) {
            options.push({
                key: `${obj.objectId}`,
                text: `${obj.f04Nr} - ${obj.title}`,
                data: obj
            });
        }

        // remove entries that are not visible
        if (!this.props.ShowA1) {
            options = options.filter((op) => (op.data as Knz2GK2018Abschnitt).f04Nr !== "A1");
        }

        const initialOption = options.find((value) => {
            return value.key === String(this.props.SelectedId);
        });

        this.setState({
            Options: options,
            InitialText: initialOption?.text
        });
    }

    public render(): JSX.Element {
        const disabled = (this.props.Disabled !== undefined && this.props.Disabled) || this.context.Disabled;

        return (
            <ComboBox
                selectedKey={`${this.props.SelectedId}`}
                label="Zugeordneter Lärmsanierungsabschnitt"
                allowFreeform={true}
                autoComplete="on"
                options={this.state.Options}
                onRenderOption={this._onRenderOption}
                onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
                    this.props.OnChange((option.data as Knz2GK2018Abschnitt)?.objectId);
                }}
                required={true}
                placeholder="Lärmsanierungsabschnitt wählen"
                disabled={disabled}
            />
        );
    }

    private _onRenderOption(item: IComboBoxOption): JSX.Element {
        if (item?.data) {
            return (
                <span className={classNames.ComboBoxRow}>
                    <span className={classNames.ComboBoxSpan1}>{(item.data as Knz2GK2018Abschnitt).f04Nr}</span>
                    <span>{(item.data as Knz2GK2018Abschnitt).title}</span>
                </span>
            );
        }

        return <span />;
    }
}
