import { initializeIcons } from "@uifabric/icons";
import { mergeStyles } from "@uifabric/merge-styles";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as entity from "./generated/BackendClient";
import { MakeNonPlainObjectObservable } from "./generated/InjectMetadata";
import { InitFieldRenderers } from "./Rad/tsx/RadFields/FieldRenderer";
import { App } from "./tsx/App";
import { BerichteTaskEdit } from "./tsx/Berichte/Workflow/BerichteTaskEdit";
import { Knz2FormblattAStep1 } from "./tsx/Knz2/Workflow/Knz2FormblattAStep1";
import { Knz2FormblattAStep2 } from "./tsx/Knz2/Workflow/Knz2FormblattAStep2";
import { Knz2FormblattOUStep1 } from "./tsx/Knz2/Workflow/Knz2FormblattOUStep1";
import { Knz2FormblattOUStep2 } from "./tsx/Knz2/Workflow/Knz2FormblattOUStep2";
import { Knz2FormblattPStep1 } from "./tsx/Knz2/Workflow/Knz2FormblattPStep1";
import { Knz2FormblattPStep2 } from "./tsx/Knz2/Workflow/Knz2FormblattPStep2";
import { TaskEditDialogStore } from "./tsx/Workflow/TaskEditDialogStore";


// Inject some global styles
mergeStyles({
    ":global(body,html,#root)": {
        margin: 0,
        padding: 0,
        height: "100vh"
    }
});

initializeIcons(/* optional base url */);

// set up field types for Rad framework
InitFieldRenderers();

// register workflow task types
TaskEditDialogStore.Register("ApproveDenyWithComment", BerichteTaskEdit);
TaskEditDialogStore.Register("Knz2FormblattAStep1", Knz2FormblattAStep1);
TaskEditDialogStore.Register("Knz2FormblattAStep2", Knz2FormblattAStep2);
TaskEditDialogStore.Register("Knz2FormblattPStep1", Knz2FormblattPStep1);
TaskEditDialogStore.Register("Knz2FormblattPStep2", Knz2FormblattPStep2);
TaskEditDialogStore.Register("Knz2FormblattOUStep1", Knz2FormblattOUStep1);
TaskEditDialogStore.Register("Knz2FormblattOUStep2", Knz2FormblattOUStep2);

// make classes observable by mobx :/
MakeNonPlainObjectObservable(entity.WorkflowTask);
MakeNonPlainObjectObservable(entity.WorkflowTaskResponse);
MakeNonPlainObjectObservable(entity.Knz2FormblattAModelInit);
MakeNonPlainObjectObservable(entity.Knz2FormblattPModelInit);
MakeNonPlainObjectObservable(entity.Knz2FormblattOUModelInit);
MakeNonPlainObjectObservable(entity.KilometerModel);

ReactDOM.render(<App />, document.getElementById("root"));
