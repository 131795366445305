import { observer } from "mobx-react";
import * as React from "react";
import { HashRouter, Route } from "react-router-dom";
import { TaskEditContainer, TaskList } from ".";
import { TaskListModel, WorkflowRepository } from "../../Model/Workflows";
import { HandleError } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class TaskListContainer extends React.Component<
    {
        Repository: WorkflowRepository;
    },
    {
        Model: TaskListModel;
    }
> {
    public readonly state = {
        Model: new TaskListModel(this.props.Repository)
    };

    public async componentDidMount(): Promise<void> {
        return this.state.Model.Refresh();
    }

    public render(): JSX.Element {
        return (
            <HashRouter>
                <HandleError>
                    <Route
                        path="/Tasks/:taskid"
                        render={(routeProps) => (
                            <TaskEditContainer
                                {...routeProps}
                                Repository={this.props.Repository}
                                TaskId={routeProps.match.params.taskid}
                                CloseDialog={async (didSave: boolean) => {
                                    if (didSave) {
                                        await this.state.Model.Refresh();
                                    }
                                }}
                            />
                        )}
                    />
                    <TaskList Model={this.state.Model} />
                </HandleError>
            </HashRouter>
        );
    }
}
