import { observer } from "mobx-react";
import { CommandBar } from "office-ui-fabric-react";
import * as React from "react";
import { Knz2Repository } from "../../../Model/Knz2";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz2DokumenteCommandBar extends React.Component<{
    Model: Knz2Repository;
    OnClickNewProjekt: () => void;
}> {
    public readonly state = {};

    public render() {
        return (
            <CommandBar
                items={[
                    {
                        key: "option1",
                        name: "Neues Projekt",
                        iconProps: {
                            iconName: "Add"
                        },
                        onClick: () => {
                            this.props.OnClickNewProjekt();
                        },
                        disabled: this.props.Model.IsReadOnly
                    }
                ]}
            />
        );
    }
}
