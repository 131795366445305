import { BaseEntity, IEntityRepository, View } from "../../DAL";

export type RadListState<T extends BaseEntity> = {
    IsLoading: boolean;
    SelectedItems: T[];
    Items: T[];
    CurrentView: View;
    Repository: IEntityRepository<T>;
    ProcessingText: string;
};

export type RadListAction<T extends BaseEntity> =
    | { type: "init"; Repository: IEntityRepository<T> }
    | { type: "reload" }
    | { type: "setProcessingText"; ProcessingText: string }
    | { type: "setSelectedItems"; SelectedItems: T[] }
    | { type: "itemsLoaded"; Items: T[] };

export function CreateRadListStateReducer<T extends BaseEntity>() {
    return (state: RadListState<T>, action: RadListAction<T>): RadListState<T> => {
        switch (action.type) {
            case "init":
                return {
                    ...state,
                    IsLoading: true,
                    SelectedItems: [],
                    Items: [],
                    Repository: action.Repository,
                    CurrentView: action.Repository.Metadata.views[0],
                    ProcessingText: null
                };
            case "reload":
                return { ...state, IsLoading: true, SelectedItems: [] };
            case "setProcessingText":
                return { ...state, ProcessingText: action.ProcessingText };
            case "setSelectedItems":
                return { ...state, SelectedItems: action.SelectedItems };
            case "itemsLoaded":
                return { ...state, IsLoading: false, Items: action.Items };
            default:
                throw new Error("Action not supported");
        }
    };
}

export async function RadListReload<T extends BaseEntity>(
    dispatcher: (action: RadListAction<T>) => void,
    state: RadListState<T>
): Promise<void> {
    dispatcher({ type: "reload" });
    const items = await state.Repository.getView([], state.CurrentView.viewIndex, 0);
    for (let i of items) {
        i["key"] = i.objectId; // dirty hack to make react happy
    }
    dispatcher({ type: "itemsLoaded", Items: items });
}

export function RadListUpdateContext<T extends BaseEntity>(
    dispatcher: (action: RadListAction<T>) => void,
    repository: IEntityRepository<T>,
    state: RadListState<T>
): void {
    if (state.Repository !== repository) {
        dispatcher({ type: "init", Repository: repository });
    }
}
