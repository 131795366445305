import { ITextFieldProps, TextField } from "office-ui-fabric-react";
import * as React from "react";
import { Utils } from "../../../../Utils/Utils";
import { IFieldRenderer, IFieldRendererEditProps, IFieldRendererViewProps } from "../FieldRenderer";

export interface RadNumberFieldProps {
    TextFieldProps: ITextFieldProps;
    Value: number;
    OnChange?: (value: number) => void;
    ValidateRule?: (value: number) => string;
    Decimals?: number;
}

function GetValue(input: string, decimals: number): number {
    const temp = input?.replace(/\./g, "").replace(/,/g, ".");
    if (temp == null || temp === "") {
        return null;
    }

    let n = Number(temp);
    if (decimals !== undefined) {
        n = +n.toFixed(decimals);
    }
    return n;
}

function GetString(input: number, decimals: number): string {
    if (!Utils.IsNumber(input)) {
        return "";
    }
    return new Intl.NumberFormat("de-DE", {
        useGrouping: true,
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).format(input);
}

export function RadNumberField({
    TextFieldProps,
    OnChange,
    Value,
    Decimals,
    ValidateRule
}: React.PropsWithChildren<RadNumberFieldProps>) {
    const [stringValue, setStringValue] = React.useState(GetString(Value, Decimals));

    return (
        <TextField
            value={stringValue}
            onChange={(e: React.FormEvent<HTMLElement>, value: string) => {
                setStringValue(value);
            }}
            onGetErrorMessage={(input: string): string => {
                if (input === undefined) {
                    return "";
                }
                const value = GetValue(input, Decimals);
                if (isNaN(value)) {
                    return "Bitte eine Zahl eingeben!";
                }
                if (typeof ValidateRule === "function") {
                    return ValidateRule(value);
                }

                return "";
            }}
            autoComplete="off"
            onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const value = GetValue(stringValue, Decimals);
                setStringValue(GetString(value, Decimals));

                OnChange(value);
            }}
            {...TextFieldProps}
            required={!TextFieldProps.disabled && TextFieldProps.required}
        />
    );
}

export const RadNumberFieldRenderer: IFieldRenderer = {
    EditField: (props: IFieldRendererEditProps) =>
        RadNumberField({
            Value: props.Value,
            TextFieldProps: { label: props.Metadata.title, disabled: props.Disabled || props.Metadata.readOnly },
            OnChange: props.OnChange
        }),
    ShowField: (props: IFieldRendererViewProps) =>
        RadNumberField({ Value: props.Value, TextFieldProps: { label: props.Metadata.title, disabled: true } }),
        ListField: (props: IFieldRendererViewProps) => <>{props.Value}</>
    };
