import { Dialog, DialogFooter, DialogType, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { PleaseWait } from ".";
import { CatchReactErrors } from "./Error-Handler/Decorators";

@CatchReactErrors
export class LoadingDialog extends React.Component<
    {
        closeDialog?: (result: boolean) => void;
    },
    {
        ShowDialog: boolean;
        Error: any;
        Stack: any;
        Title: string;
    }
> {
    public readonly state = { ShowDialog: false, Error: undefined, Title: "Einen Moment bitte", Stack: undefined };

    public async Execute(process: () => Promise<void>, title: string): Promise<void> {
        this.setState({
            Error: undefined,
            ShowDialog: true,
            Title: title
        });
        try {
            await process();
            this.setState({ ShowDialog: false });
            this.props.closeDialog && this.props.closeDialog(true);
        } catch (e) {
            let error: any;
            try {
                error = JSON.parse(e.response);
            } catch (e) {
                //
            }

            if (error?.error) {
                this.setState({ Error: error.error });
            } else {
                if (e.status && e.status === 403) {
                    this.setState({ Error: "Zugriff verweigert" });
                } else {
                    this.setState({ Error: e.message, Stack: e.stack });
                }
            }
        }
    }

    public render(): JSX.Element {
        return (
            <>
                <Dialog
                    hidden={!this.state.ShowDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: this.state.Title
                    }}
                    modalProps={{
                        isBlocking: true
                    }}
                    minWidth="450px"
                >
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12">
                                <PleaseWait
                                    Style={{ paddingTop: "20%", paddingBottom: "20%" }}
                                    ShowSpinner={this.state.Error === undefined}
                                    render={() => (
                                        <>
                                            <div>
                                                <b>Es ist ein Fehler aufgetreten:</b>
                                            </div>
                                            <div>{this.state.Error}</div>
                                            {this.state.Stack && (
                                                <div>
                                                    <br />
                                                    <code className="nl2br">{this.state.Stack}</code>
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {this.state.Error !== undefined && (
                        <DialogFooter>
                            <PrimaryButton
                                onClick={() => {
                                    this.setState({ ShowDialog: false });
                                    this.props.closeDialog && this.props.closeDialog(false);
                                }}
                                text="Ok"
                            />
                        </DialogFooter>
                    )}
                </Dialog>
            </>
        );
    }
}
