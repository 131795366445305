import * as React from "react";

export class Knz2AttachmentControl extends React.Component<
    {
        Counts: { [key: string]: number };
    },
    {}
> {
    public render(): JSX.Element {
        return (
            <span>
                {this.props.Counts["stu"] > 0 ? <b>STU</b> : <>STU</>}{" "}
                {<>{this.props.Counts["ou"] > 0 ? <b>OU</b> : this.props.Counts["ou"] === 0 ? <>OU</> : ""} </>}
                {
                    /*![EnumFormAStatus.nichtförderfähig, EnumFormAStatus.nurpassiv].includes(this.props.StatusA) &&*/ <>
                        {this.props.Counts["a"] > 0 ? <b>A</b> : this.props.Counts["a"] === 0 ? <>A</> : ""}{" "}
                    </>
                }
                {
                    /*![EnumFormPStatus.förderfähigumgesetzt, EnumFormPStatus.nichtFörderfähig].includes(this.props.StatusP) &&*/ <>
                        {this.props.Counts["p"] > 0 ? <b>P</b> : this.props.Counts["p"] === 0 ? <>P</> : ""}{" "}
                    </>
                }
                {this.props.Counts["lv-status"] > 0 ? <b>LV</b> : <>LV</>}
            </span>
        );
    }
}
