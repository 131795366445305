import { Text } from "office-ui-fabric-react/lib/Text";
import * as React from "react";

export class Header extends React.Component<
    {
        Large?: boolean;
    },
    {}
> {
    public render(): JSX.Element {
        return (
            <Text nowrap block variant={this.props.Large ? "xxLarge" : "large"} style={{ marginTop: "20px" }}>
                {this.props.children}
            </Text>
        );
    }
}
