import { observer } from "mobx-react";
import { deepObserve, IDisposer } from "mobx-utils";
import { CompoundButton, Label } from "office-ui-fabric-react";
import * as React from "react";
import { EnumWorkflowTaskReponseType, WorkflowTaskResponse } from "../../../generated/BackendClient";
import { nameof } from "../../../Utils";
import { EasyChoiceGroup, EasyForm, EasyTextField } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";
import { ITaskEditorProps } from "../../Workflow/TaskEditDialogStore";
import { ShowBerichtPreview } from "./ShowBerichtPreview";

@observer
@CatchReactErrors
export class BerichteTaskEdit extends React.Component<ITaskEditorProps, { ShowDocumentUrl: string }> {
    public readonly state = { ShowDocumentUrl: undefined };
    private disposer: IDisposer;

    public componentDidMount(): void {
        // if response is changed, revalidate!
        this.disposer = deepObserve(this.props.Model.TaskResponse, () => this.props.OnValidate(this.Validate()));
    }

    public componentWillUnmount(): void {
        this.disposer();
    }

    public Validate(): boolean {
        if (!this.props.Model.Task) {
            return false;
        }

        switch (this.props.Model.TaskResponse.responseType) {
            case EnumWorkflowTaskReponseType.Approve:
            case EnumWorkflowTaskReponseType.Deny:
                return (this.props.Model.TaskResponse.comment || "").length > 5;
            default:
                return false;
        }
    }

    public render(): JSX.Element {
        return (
            <EasyForm Object={this.props.Model.TaskResponse} ShowFooter={true}>
                {this.state.ShowDocumentUrl && (
                    <ShowBerichtPreview
                        BerichtId={this.state.ShowDocumentUrl}
                        CloseDialog={() => {
                            this.setState({ ShowDocumentUrl: undefined });
                        }}
                    />
                )}
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <Label>Titel</Label>
                            {this.props.Model.Task.title}
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <Label>Beschreibung</Label>
                            {this.props.Model.Task.request.description.map((val, index) => (
                                <div key={index} className="nl2br">
                                    {val}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6">
                            <Label>Freizugebendes Dokument</Label>
                            <CompoundButton
                                secondaryText="Dokument öffnen"
                                primary={true}
                                onClick={() => {
                                    this.setState({
                                        ShowDocumentUrl: this.props.Model.Task.request.documentUrl.objectId
                                    });
                                }}
                            >
                                {this.props.Model.Task.request.documentName}
                            </CompoundButton>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <Label>Entscheidung</Label>
                            <EasyChoiceGroup
                                PropertyName={nameof<WorkflowTaskResponse>("responseType")}
                                Options={[
                                    {
                                        key: EnumWorkflowTaskReponseType.Approve,
                                        text: "Genehmigen"
                                    },
                                    {
                                        key: EnumWorkflowTaskReponseType.Deny,
                                        text: "Ablehnen"
                                    }
                                ]}
                                ChoiceGroupProps={{ required: true }}
                            />
                            <EasyTextField
                                PropertyName={nameof<WorkflowTaskResponse>("comment")}
                                TextFieldProps={{ label: "Kommentar", multiline: true, rows: 4, required: true, placeholder: "Bitte mindestens 6 Zeichen eintragen" }}
                            />
                        </div>
                    </div>
                </div>
            </EasyForm>
        );
    }
}
