import { DefaultButton, Dialog, DialogFooter, DialogType } from "office-ui-fabric-react";
import * as React from "react";

export class MessageDialog extends React.Component<
    {},
    {
        hideDialog: boolean;
        closeDialog: () => void;
        Title: string;
        Message: JSX.Element;
    }
> {
    public readonly state = {
        hideDialog: true,
        closeDialog: null,
        Title: "",
        Message: <></>
    };

    public async Execute(title: string, message: JSX.Element): Promise<void> {
        return new Promise<void>((resolve) => {
            this.setState({
                hideDialog: false,
                Title: title,
                Message: message,
                closeDialog: () => {
                    this.setState({ hideDialog: true });
                    resolve();
                }
            });
        });
    }

    public render(): JSX.Element {
        return (
            <Dialog
                hidden={this.state.hideDialog}
                onDismiss={() => {
                    this.state.closeDialog();
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: this.state.Title
                }}
                modalProps={{
                    isBlocking: true
                }}
                minWidth="450px"
                maxWidth={800}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 nl2br">
                            <>{this.state.Message}</>
                        </div>
                    </div>
                </div>
                <DialogFooter>
                    <DefaultButton
                        onClick={() => {
                            this.state.closeDialog();
                        }}
                        text="Ok"
                    />
                </DialogFooter>
            </Dialog>
        );
    }
}
