import { DefaultButton, DialogFooter, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { RadEditDialogClose, RadEditDialogLoad, RadEditDialogProps, RadEditDialogStateReducer } from "..";
import { DBDialog } from "../../../tsx/Generic";
import { PleaseWait } from "../../../tsx/Generic/PleaseWait";
import { BaseUserModel } from "../../DAL";
import { RadPeoplePicker } from "../Controls";
import { RenderEditField } from "../RadFields/FieldRenderer";

export function NewUserDialog<T extends BaseUserModel>({
    Repository,
    ObjectId,
    readOnly,
    OnDialogClosed
}: React.PropsWithChildren<RadEditDialogProps<T>>) {
    const [state, dispatch] = React.useReducer(RadEditDialogStateReducer<T>(), {
        IsLoading: true,
        Model: null,
        Repository,
        ObjectId,
        OnDialogClosed
    });

    const [currentUser, setCurrentUser] = React.useState<BaseUserModel>(null);

    React.useEffect(() => {
        if (currentUser) {
            dispatch({ type: "setObjectId", objectId: currentUser.objectId });
        }
    }, [currentUser]);

    React.useEffect(() => {
        RadEditDialogLoad(dispatch, state);
    }, [state.ObjectId]);

    return (
        <DBDialog
            onDismiss={(): void => {
                RadEditDialogClose(dispatch, state, false);
            }}
            dialogContentProps={{
                title: "Benutzer bearbeiten"
            }}
        >
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6">
                        <RadPeoplePicker
                            OnChange={(p) => setCurrentUser(p[0])}
                            Value={currentUser ? [currentUser] : []}
                            PeoplePickerProps={{ itemLimit: 1 }}
                            Required={true}
                            LabelText="Benutzer auswählen"
                        />
                    </div>
                </div>
            </div>
            <PleaseWait
                ShowSpinner={state.IsLoading}
                render={(): React.ReactNode => {
                    return (
                        <>
                            <div className="ms-Grid" dir="ltr">
                                {Object.values(Repository.Metadata.fields)
                                    .filter((f) => f.showInEditDialog)
                                    .map((v, i) => {
                                        return (
                                            <div className="ms-Grid-row" key={i}>
                                                <div className="ms-Grid-col ms-sm6">
                                                    <RenderEditField
                                                        Metadata={v}
                                                        Value={state.Model[v.fieldName]}
                                                        OnChange={(value) => {
                                                            dispatch({
                                                                type: "updateField",
                                                                fieldName: v.fieldName,
                                                                value: value
                                                            });
                                                        }}
                                                        Disabled={readOnly}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                            <DialogFooter>
                                {readOnly ? (
                                    <>
                                        <PrimaryButton
                                            onClick={(): void => {
                                                RadEditDialogClose(dispatch, state, false);
                                            }}
                                            text="Schließen"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <PrimaryButton
                                            onClick={() => {
                                                RadEditDialogClose(dispatch, state, true);
                                            }}
                                            text="Speichern"
                                            disabled={readOnly}
                                        />
                                        <DefaultButton
                                            onClick={(): void => {
                                                RadEditDialogClose(dispatch, state, false);
                                            }}
                                            text="Abbrechen"
                                        />
                                    </>
                                )}
                            </DialogFooter>
                        </>
                    );
                }}
            />
        </DBDialog>
    );
}
