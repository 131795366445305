import { observer } from "mobx-react";
import { ITextFieldProps } from "office-ui-fabric-react";
import * as React from "react";
import { NumberField } from "..";
import { CatchReactErrors } from "../Error-Handler/Decorators";
import { EasyFormContext } from "./EasyFormContext";

@observer
@CatchReactErrors
export class EasyNumberField extends React.Component<
    {
        Object?: object;
        PropertyName: string;
        TextFieldProps: ITextFieldProps;
        ValidateRule?: (value: number) => string;
        OnChange?: () => void;
        Decimals?: number;
    },
    {}
> {
    private get Object(): object {
        return this.props.Object || this.context.Object;
    }
    public static contextType = EasyFormContext;
    public readonly state = {};

    public render(): JSX.Element {
        return (
            <NumberField
                InitialValue={this.Object[this.props.PropertyName]}
                OnChange={(value: number) => {
                    if (isNaN(value)) {
                        this.Object[this.props.PropertyName] = null;
                    } else {
                        this.Object[this.props.PropertyName] = value;
                    }

                    if (this.props.OnChange) {
                        this.props.OnChange();
                    }
                }}
                TextFieldProps={{
                    ...this.props.TextFieldProps,
                    disabled: this.context.Disabled || this.props.TextFieldProps.disabled,
                    required:
                        !this.context.Disabled &&
                        !this.props.TextFieldProps.disabled &&
                        this.props.TextFieldProps.required
                }}
                ValidateRule={this.props.ValidateRule}
                Decimals={this.props.Decimals}
            />
        );
    }
}
