import {
    DefaultButton,
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IconButton,
    IDetailsHeaderProps,
    IRenderFunction,
    Label,
    Link,
    MessageBar,
    MessageBarType,
    SelectionMode
} from "office-ui-fabric-react";
import * as React from "react";
import Files from "react-butterfiles";
import { MessageDialog, PleaseWait, ShowUser } from ".";
import { BaseEntity, BaseFile, FileParameter } from "../../generated/BackendClient";
import { IEntityRepositoryWithFile } from "../../Rad/DAL";
import { RadVersionHistoryModal } from "../../Rad/tsx/Controls";
import { nameof, Utils } from "../../Utils";
import { CatchReactErrors } from "./Error-Handler/Decorators";
import { EasyFormContext } from "./Forms/EasyFormContext";

@CatchReactErrors
export class FolderView<T extends BaseEntity, F extends BaseFile> extends React.Component<
    {
        Repository: IEntityRepositoryWithFile<T, F>;
        ParentObjectId: string;
        FolderName?: string;
        Disabled?: boolean;
        Label?: string;
        IsCRUDType?: boolean;
        OnUploaded?: (files: F[]) => void;
        OnDeleteItemClick?: (item: F, files: F[]) => Promise<void>;
        OnFolderLoaded?: (files: F[]) => void;
        Compact?: boolean;
    },
    {
        Files: F[];
        Errors: any[];
        ShowVersionOf: F;
        IsLoading: boolean;
    }
> {
    public static contextType = EasyFormContext;
    public readonly state = { Files: [], Errors: [], List: undefined, ShowVersionOf: undefined, IsLoading: true };
    private msgRef: React.RefObject<MessageDialog> = React.createRef();
    private mounted = false;

    public async componentDidMount(): Promise<void> {
        this.mounted = true;
        await this.RefreshFiles();
    }

    public componentWillUnmount(): void {
        this.mounted = false;
    }

    public async componentDidUpdate(prevProps): Promise<void> {
        if (this.props.ParentObjectId !== prevProps.ParentObjectId || this.props.FolderName !== prevProps.FolderName) {
            await this.RefreshFiles();
        }
    }

    public render(): JSX.Element {
        const disabled = (this.props.Disabled !== undefined && this.props.Disabled) || this.context.Disabled;
        return (
            <PleaseWait
                ShowSpinner={this.state.IsLoading}
                render={() => (
                    <>
                        <MessageDialog ref={this.msgRef}/>
                        {this.state.ShowVersionOf && (
                            <RadVersionHistoryModal
                                Repository={this.props.Repository}
                                FileId={this.state.ShowVersionOf.objectId}
                                OnClose={() => {
                                    this.setState({ ShowVersionOf: undefined });
                                }}
                            />
                        )}
                        {this.props.Label && <Label disabled={disabled}>{this.props.Label}</Label>}
                        {disabled ? (
                            <DetailsList
                                items={this.state.Files}
                                columns={this.GetColumns()}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                compact={true}
                                selectionMode={SelectionMode.none}
                            />
                        ) : (
                            <Files
                                multiple={true}
                                maxSize="100mb"
                                multipleMaxSize="100mb"
                                multipleMaxCount={3}
                                onSuccess={(files: any) => {
                                    this.onDrop(files);
                                }}
                                onError={(errors: any) => console.log(errors)}
                            >
                                {({ browseFiles, getDropZoneProps }) => {
                                    return (
                                        <div>
                                            <div
                                                {...getDropZoneProps({
                                                    style: {
                                                        minHeight: 200,
                                                        outline: "2px lightgray dashed",
                                                        paddingTop: "1px"
                                                    }
                                                })}
                                            >
                                                <DetailsList
                                                    items={this.state.Files}
                                                    columns={this.GetColumns()}
                                                    setKey="set"
                                                    layoutMode={DetailsListLayoutMode.justified}
                                                    compact={true}
                                                    selectionMode={SelectionMode.none}
                                                    onRenderDetailsHeader={(
                                                        detailsHeaderProps: IDetailsHeaderProps,
                                                        defaultRender?: IRenderFunction<IDetailsHeaderProps>
                                                    ): JSX.Element => {
                                                        return (
                                                            <DetailsHeader
                                                                {...detailsHeaderProps}
                                                                styles={{ root: { paddingTop: "1px" } }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                Sie können Dateien in den gestrichelten Bereich ziehen oder den
                                                folgenden Knopf drücken:
                                                <DefaultButton text="Dateien hochladen..." onClick={browseFiles} />
                                            </div>
                                        </div>
                                    );
                                }}
                            </Files>
                        )}
                    </>
                )}
            />
        );
    }

    private GetColumns(): IColumn[] {
        const cols = [
            {
                key: "col1",
                name: "Name",
                fieldName: "Name",
                minWidth: 200,
                isResizable: true,
                onRender: (item: BaseFile) => (
                    <>
                        {item.downloadUrl ? (
                            <Link href={item.downloadUrl} download={true}>
                                {item.name}
                            </Link>
                        ) : (
                            <>{item.name}</>
                        )}
                    </>
                )
            },
            !this.props.Compact && {
                key: "col2",
                name: "Version",
                fieldName: "UIVersionLabel",
                minWidth: 50,
                isResizable: true,
                onRender: (item: F) => (
                    <>
                        {item.version && (
                            <Link
                                href="#"
                                onClick={() => {
                                    this.ShowVersionModal(item);
                                }}
                            >
                                {item.version}
                            </Link>
                        )}
                    </>
                )
            },
            !this.props.Compact && {
                key: "col3",
                name: "Geändert von",
                minWidth: 120,
                isResizable: true,
                onRender: (item: BaseFile) => (
                    <>
                        {(item.lastModifiedBy && (
                            <ShowUser Object={item} PropertyName={nameof<BaseFile>("lastModifiedBy")} />
                        )) ||
                            "Hochladen..."}
                    </>
                )
            },
            {
                key: "col4",
                name: "Geändert am",
                fieldName: "TimeLastModified",
                minWidth: 156,
                isResizable: true,
                onRender: (item: F) => <>{item.lastModified && Utils.FormatDateTime(item.lastModified)}</>
            },
            {
                key: "col5",
                name: "",
                fieldName: "",
                minWidth: 50,
                isResizable: true,
                onRender: (item: F) => (
                    <IconButton
                        onClick={async () => {
                            this.setState({ IsLoading: true });
                            await this.props.OnDeleteItemClick(item, this.state.Files);
                            await this.RefreshFiles();
                            this.setState({ IsLoading: false });
                        }}
                        iconProps={{ iconName: "Delete" }}
                        disabled={this.props.Disabled}
                    />
                )
            }
        ];

        const index = cols.findIndex((col: IColumn) => col.key === (this.props.IsCRUDType ? "col2" : "col5"));
        if (index > -1) {
            cols.splice(index, 1);
        }

        return cols.filter((obj: any) => {
            return obj !== false;
        });
    }

    private ShowVersionModal(item: F): void {
        this.setState({ ShowVersionOf: item });
    }

    private async RefreshFiles(): Promise<void> {
        if (!this.mounted) return;
        this.setState({ IsLoading: true });
        const files = await this.props.Repository.listFiles(this.props.ParentObjectId, this.props.FolderName);
        files.sort((a, b) => (a.created < b.created ? 1 : -1));
        this.setState({ Files: files, IsLoading: false });
        if (this.props.OnFolderLoaded) {
            this.props.OnFolderLoaded(files);
        }
    }

    private SetFileInfo(name: string, message: string) {
        const newList = this.state.Files.map((item) => {
            if (item.Name === name) {
                const updatedItem = {
                    ...item,
                    isComplete: !item.isComplete
                };

                return updatedItem;
            }

            return item;
        });

        this.setState({ Files: newList });
    }

    private async onDrop(accepted: any[]): Promise<void> {
        // show uploading files in details list
        for (const file of accepted) {
            this.state.Files.unshift({ Name: file.src.file.name, Message: "Warten..." });
        }

        // force component refresh
        this.setState({ Files: this.state.Files.slice() });

        for (const file of accepted) {
            let fp: FileParameter = {
                data: file.src.file,
                fileName: file.src.file.name
            };

            this.SetFileInfo(file.src.file.name, "Hochladen...");
            try {
                await this.props.Repository.addFile(fp, this.props.ParentObjectId, this.props.FolderName);
            } catch (e) {
                this.SetFileInfo(file.src.file.name, "Fehler!");

                let error: any;
                try {
                    error = JSON.parse(e.response);
                } catch (e) {
                    //
                }
            
                await this.msgRef.current.Execute(
                    "Fehler beim Upload",
                    <>
                        <MessageBar messageBarType={MessageBarType.error}>
                            Fehler beim Upload der Datei {file.src.file.name}. <br />
                            Fehlermeldung: {error?.error ?? e.message}
                        </MessageBar>
                    </>
                );
            }
            this.SetFileInfo(file.src.file.name, "Fertig");
        }

        await this.RefreshFiles();

        if (this.props.OnUploaded) {
            this.props.OnUploaded(this.state.Files);
        }
    }
}
