import "reflect-metadata";

export function CatchReactErrorsMethod(): MethodDecorator {
    return (target: any, propertyKey: string, descriptor: TypedPropertyDescriptor<any>): void => {
        const properties: string[] = Reflect.getMetadata("CatchReactErrorsFor", target) || [];

        properties.push(propertyKey);

        Reflect.defineMetadata("CatchReactErrorsFor", properties, target);
    };
}

export function CatchErrorFunc<T>(context: any, func: () => any): any {
    try {
        const result = func();
        if (result instanceof Promise) {
            return result.catch((error) => {
                console.log("Caught error in Promise, setting error state");
                if (!context.state.__ReactError) {
                    context.setState({ __ReactError: error });
                }
            });
        }
        return result;
    } catch (error) {
        if (!context.state.__ReactError) {
            context.setState({ __ReactError: error });
        }
    }
}

export function CatchReactErrors<T extends new (...args: any[]) => React.Component>(target: T) {
    return class extends target {
        private static getDerivedStateFromProps(props, state) {
            if (!(target as any).getDerivedStateFromProps) {
                return {};
            }
            try {
                return (target as any).getDerivedStateFromProps(props, state);
            } catch (error) {
                return { __ReactError: error };
            }
        }

        // show wrapped class name in debug messages
        public static get displayName(): string {
            return `CatchReactErrors(${target.name})`;
        }

        constructor(...args: any[]) {
            super(...args);

            // determine methods that we want to wrap
            let methodNames = Reflect.getMetadata("CatchReactErrorsFor", this) || [];
            methodNames = [...methodNames, "componentDidMount", "componentWillUnmount", "componentDidUpdate"];

            // wrap methods
            for (const m of methodNames) {
                let oldFunc = this[m];
                if (!oldFunc) {
                    continue;
                }
                oldFunc = oldFunc.bind(this);

                this[m] = (...margs) => {
                    CatchErrorFunc(this, () => {
                        return oldFunc(...margs);
                    });
                };
            }
        }

        public render(): any {
            if ((this.state as any).__ReactError) {
                throw (this.state as any).__ReactError;
            }
            return super.render();
        }
    };
}
