import { observer } from "mobx-react";
import { Label } from "office-ui-fabric-react";
import * as React from "react";
import { Knz2KilometrierungRow } from ".";
import { KilometerModel } from "../../../Rad/DAL";
import { nameof } from "../../../Utils/Utils";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";
import { EasyFormContext } from "../../Generic/Forms/EasyFormContext";

@observer
@CatchReactErrors
export class Knz2Kilometrierung extends React.Component<
    {
        Object?: object;
        PropertyName: string;
        Disabled?: boolean;
        OnUpdate?: () => void;
    },
    {}
> {
    public static contextType = EasyFormContext;
    public readonly state = {};

    private get Object(): object {
        return this.props.Object || this.context.Object;
    }

    public render() {
        const strecken: KilometerModel[] = (this.Object && this.Object[this.props.PropertyName]) || [];

        if (strecken.length === 0) {
            strecken.push(new KilometerModel({ kmBis: null, kmVon: null, streckeNr: null }));
        }
        const disabled = (this.props.Disabled !== undefined && this.props.Disabled) || this.context.Disabled;

        return (
            <>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm2">
                        <Label>Strecken-Nr.</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm2">
                        <Label>km von</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm2">
                        <Label>km bis</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm2">
                        <Label>Summe km</Label>
                    </div>
                </div>
                {strecken.map((value, index) => (
                    <Knz2KilometrierungRow
                        key={index}
                        Index={index}
                        Object={value}
                        IsReadOnly={disabled}
                        PropKmStreckeNr={nameof<KilometerModel>("streckeNr")}
                        PropKmVon={nameof<KilometerModel>("kmVon")}
                        PropKmBis={nameof<KilometerModel>("kmBis")}
                        AllowRemove={strecken.length > 1 && !disabled}
                        AllowAdd={index === strecken.length - 1 && !disabled}
                        OnChange={() => {
                            this.UpdateObject(strecken);
                        }}
                        OnAdd={(index: number) => {
                            strecken.push(new KilometerModel({ kmBis: null, kmVon: null, streckeNr: null }));
                            this.UpdateObject(strecken);
                        }} 
                        OnRemove={(index: number) => {
                            strecken.splice(index, 1);
                            this.UpdateObject(strecken);
                        }}
                    />
                ))}
            </>
        );
    }

    private UpdateObject(strecken: KilometerModel[]): void {
        this.Object[this.props.PropertyName] = strecken;
        if (this.props.OnUpdate) {
            this.props.OnUpdate();
        }
    }
}
