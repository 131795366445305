import { observer } from "mobx-react";
import * as React from "react";
import { LskRepository } from "../../../DAL/LskRepository";
import { Knz2Altprojekt, Knz2AltprojekteListModelFilter, Knz2Repository } from "../../../Model/Knz2";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";
import { Knz2SimpleFilterControl } from "../Common";
import { Knz2AltprojekteEditDialog } from "./Knz2AltprojekteEditDialog";
import { Knz2AltprojekteList } from "./Knz2AltprojekteList";

@observer
@CatchReactErrors
export class Knz2AltprojekteContainer extends React.Component<
    {
        Model: Knz2Repository;
    },
    {
        CurrentItem: Knz2Altprojekt;
        Filter: Knz2AltprojekteListModelFilter;
    }
> {
    public readonly state = {
        CurrentItem: undefined,
        Filter: new Knz2AltprojekteListModelFilter("")
    };

    public render(): JSX.Element {
        return (
            <>
                <Knz2SimpleFilterControl
                    OnUpdateFilter={(text: string) => {
                        this.setState({ Filter: new Knz2AltprojekteListModelFilter(text) });
                    }}      
                    Text="Durchsuche KPN und Bezeichnung"
                />
                {this.state.CurrentItem && (
                    <Knz2AltprojekteEditDialog
                        Model={this.props.Model}
                        InitialItem={this.state.CurrentItem}
                        closeDialog={async (result, item) => {
                            if (result) {
                                await LskRepository.Knz2Altprojekt.update(item);
                            }
                            this.setState({ CurrentItem: undefined });
                        }}
                    />
                )}
                <Knz2AltprojekteList
                    Model={this.props.Model}
                    OnEditItem={(item: Knz2Altprojekt) => {
                        this.setState({ CurrentItem: item });
                    }}
                    Filter={this.state.Filter}
                />
            </>
        );
    }
}
