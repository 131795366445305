import { observer } from "mobx-react";
import { DefaultButton, DialogFooter, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { Knz2FormblattPruefung1, Knz2FormblattPruefung2, Knz2FormblattPruefung3 } from ".";
import { BaseUserModel, Knz2FormblattAModelInit, UsersClient } from "../../../generated/BackendClient";
import { nameof, Utils } from "../../../Utils";
import { DBDialog, EasyForm, PleaseWait } from "../../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../../Generic/Error-Handler/Decorators";
import { EasyCombobox, EasyTextField } from "../../Generic/Forms";

@observer
@CatchReactErrors
export class Knz2FormblattAStartDialog extends React.Component<
    { CloseDialog: (shouldSave: boolean, model: Knz2FormblattAModelInit) => void },
    { Model: Knz2FormblattAModelInit; IsLoading: boolean; Users: Map<string, BaseUserModel[]> }
> {
    public readonly state = {
        IsLoading: true,
        Users: undefined,
        Model: new Knz2FormblattAModelInit() // this is a mobx.observable!
    };
    private regionen: string[] = ["Nord", "Ost", "Süd", "Südwest", "West"];

    public async componentDidMount(): Promise<void> {
        // load users
        const wf = new UsersClient();
        const output = new Map<string, BaseUserModel[]>();
        for (const region of this.regionen) {
            output.set(region, await wf.getUsersInRole(`Knz2 Freigabe Formblatt A (Region ${region})`));
        }
        this.setState({
            Users: output,
            IsLoading: false
        });
        if (!this.state.Model.rM108a) {
            this.state.Model.rM108a = false;
        }
    }

    public render(): JSX.Element {
        return (
            <DBDialog
                onDismiss={() => {
                    this.ExitDialog(false);
                }}
                dialogContentProps={{
                    title: "Freigabe für Formblatt A starten"
                }}
                minWidth="760px"
            >
                <PleaseWait
                    ShowSpinner={this.state.IsLoading}
                    render={() => (
                        <EasyForm Object={this.state.Model} ShowFooter={true}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm2">
                                        <EasyCombobox
                                            PropertyName={nameof<Knz2FormblattAModelInit>("region")}
                                            ComboBoxProps={{ label: "Region", required: true }}
                                            Options={this.regionen}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm5">
                                        <EasyCombobox
                                            PropertyName={nameof<Knz2FormblattAModelInit>("rM104")}
                                            ComboBoxProps={{ label: "Freigebende Projektleitung", required: true }}
                                            Options={
                                                this.state.Users.get(this.state.Model.region)?.map(
                                                    (u: BaseUserModel) => u.displayName
                                                ) || []
                                            }
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm5">
                                        <EasyTextField
                                            PropertyName={nameof<Knz2FormblattAModelInit>("rM105")}
                                            TextFieldProps={{ label: "Abteilung der PL", required: true }}
                                        />
                                    </div>
                                </div>

                                <Knz2FormblattPruefung1 PropertyName={nameof<Knz2FormblattAModelInit>("rM108a")} />
                                <Knz2FormblattPruefung2
                                    Options={{ rM108b: "Passfelder", rM108c: "Torsionsbalken (mit UIG/ ZIE)" }}
                                />
                                <Knz2FormblattPruefung3 PropertyName={nameof<Knz2FormblattAModelInit>("rM109")} />
                            </div>

                            <DialogFooter>
                                <PrimaryButton
                                    onClick={() => {
                                        this.ExitDialog(true);
                                    }}
                                    text="Freigabe starten"
                                    disabled={!this.IsValid}
                                />
                                <DefaultButton
                                    onClick={() => {
                                        this.ExitDialog(false);
                                    }}
                                    text="Abbrechen"
                                />
                            </DialogFooter>
                        </EasyForm>
                    )}
                />
            </DBDialog>
        );
    }

    private get IsValid(): boolean {
        const model = this.state.Model;
        return (
            model.rM109 &&
            !Utils.IsNullOrEmpty(model.region) &&
            !Utils.IsNullOrEmpty(model.rM104) &&
            !Utils.IsNullOrEmpty(model.rM105) &&
            !Utils.IsNullOrEmpty(model.rM108b) &&
            !Utils.IsNullOrEmpty(model.rM108c)
        );
    }

    @CatchReactErrorsMethod()
    private async ExitDialog(shouldSave: boolean) {
        this.props.CloseDialog(shouldSave, this.state.Model);
    }
}
