import { observer } from "mobx-react";
import { deepObserve, IDisposer } from "mobx-utils";
import { Checkbox, CompoundButton, Label } from "office-ui-fabric-react";
import * as React from "react";
import {
    EnumWorkflowTaskReponseType,
    TypedEntityReference,
    WorkflowTaskResponse
} from "../../../generated/BackendClient";
import { nameof } from "../../../Utils";
import { EasyChoiceGroup, EasyForm, EasyTextField } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";
import { ITaskEditorProps } from "../../Workflow/TaskEditDialogStore";
import { Knz2ShowFormblattPreview } from "./Knz2ShowFormblattPreview";
export interface TaskApproveCheckboxesProps extends ITaskEditorProps {
    Preface: string;
    ToCheck: string[];
}

@observer
@CatchReactErrors
export class TaskApproveCheckboxes extends React.Component<
    TaskApproveCheckboxesProps,
    {
        checkCount: number;
        ShowDocumentUrl: TypedEntityReference;
    }
> {
    public readonly state = { checkCount: 0, ShowDocumentUrl: undefined };
    private disposer: IDisposer;

    public componentDidMount(): void {
        // if response is changed, revalidate!
        this.disposer = deepObserve(this.props.Model.TaskResponse, () => this.revalidate());
    }

    private revalidate(): void {
        this.props.OnValidate(this.Validate());
    }

    public componentWillUnmount(): void {
        this.disposer();
    }
    private Validate(): boolean {
        if (!this.props.Model.Task) {
            return false;
        }

        switch (this.props.Model.TaskResponse.responseType) {
            case EnumWorkflowTaskReponseType.Approve:
                return this.state.checkCount === this.props.ToCheck.length;
            case EnumWorkflowTaskReponseType.Deny:
                return (this.props.Model.TaskResponse.comment || "").length > 5;
            default:
                return false;
        }
    }

    public render() {
        return (
            <EasyForm Object={this.props.Model.TaskResponse} ShowFooter={true}>
                {this.state.ShowDocumentUrl && (
                    <Knz2ShowFormblattPreview
                        DocumentUrl={this.state.ShowDocumentUrl}
                        CloseDialog={() => {
                            this.setState({ ShowDocumentUrl: undefined });
                        }}
                    />
                )}
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <Label>Titel</Label>
                            {this.props.Model.Task.title}
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <Label>Beschreibung</Label>
                            {this.props.Model.Task.request.description.map((val, index) => (
                                <div key={index} className="nl2br">
                                    {val}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6">
                            <Label>Freizugebendes Dokument</Label>
                            <CompoundButton
                                secondaryText="Dokument öffnen"
                                primary={true}
                                onClick={() => {
                                    this.setState({ ShowDocumentUrl: this.props.Model.Task.request.documentUrl });
                                }}
                            >
                                {this.props.Model.Task.request.documentName}
                            </CompoundButton>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">{this.props.Preface}</div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            {this.props.ToCheck.map((v, i) => (
                                <Checkbox
                                    label={v}
                                    key={i}
                                    onChange={(ev, checked) =>
                                        this.setState(
                                            { checkCount: this.state.checkCount + (checked ? 1 : -1) },
                                            () => {
                                                this.revalidate();
                                            }
                                        )
                                    }
                                />
                            ))}
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <Label>Entscheidung</Label>
                            <EasyChoiceGroup
                                PropertyName={nameof<WorkflowTaskResponse>("responseType")}
                                Options={[
                                    {
                                        key: EnumWorkflowTaskReponseType.Approve,
                                        text: "Genehmigen"
                                    },
                                    {
                                        key: EnumWorkflowTaskReponseType.Deny,
                                        text: "Ablehnen (Kommentar erforderlich)"
                                    }
                                ]}
                                ChoiceGroupProps={{ required: true }}
                            />
                            <EasyTextField
                                PropertyName={nameof<WorkflowTaskResponse>("comment")}
                                TextFieldProps={{
                                    label: "Kommentar",
                                    multiline: true,
                                    rows: 4,
                                    placeholder:
                                        this.props.Model.TaskResponse.responseType === EnumWorkflowTaskReponseType.Deny
                                            ? "Bitte mindestens 6 Zeichen eintragen"
                                            : null,
                                    required:
                                        this.props.Model.TaskResponse.responseType == EnumWorkflowTaskReponseType.Deny
                                }}
                            />
                        </div>
                    </div>
                </div>
            </EasyForm>
        );
    }
}
