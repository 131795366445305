import { observer } from "mobx-react";
import { Pivot, PivotItem, PivotLinkSize } from "office-ui-fabric-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Knz2GK2018Abschnitt } from "../../../Model/Knz2/GK2018/Knz2Abschnitt";
import { Knz2GK2018Bereich } from "../../../Model/Knz2/GK2018/Knz2Bereich";
import { Knz2BereicheGroupedByAbschnittList } from "../../../Model/Knz2/GK2018/Knz2BereicheGroupedByAbschnittList";
import { Knz2BereicheGroupedByKPN } from "../../../Model/Knz2/GK2018/Knz2BereicheGroupedByKPN";
import { Knz2BereichListModelFilter } from "../../../Model/Knz2/GK2018/Knz2BereichListModelFilter";
import {
    Knz2BereichStatusAnlage1,
    Knz2BereichStatusAnlage3,
    Knz2Repository
} from "../../../Model/Knz2/GK2018/Knz2Repository";

import { EnumBereichStatus } from "../../../Rad/DAL";
import { Utils } from "../../../Utils";
import { ChoiceDialog, HandleError, IGroupedListDatasource, LoadingDialog, PleaseWait } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";
import { EnumColumnType, Knz2BereicheList } from "../Common";
import { Knz2EditBereichDialog } from "./Knz2EditBereichDialog";
import { Knz2FilterControl } from "./Knz2FilterControl";

enum EnumListModelType {
    Init = "-1",
    Anlage1 = "Anlage1",
    Anlage3 = "Anlage3",
    Ausgeblendet = "Ausgeblendet"
}

@observer
@CatchReactErrors
class Knz2Container extends React.Component<
    RouteComponentProps & {
        Model: Knz2Repository;
    },
    {
        Bereich: Knz2GK2018Bereich;
        ShowEditDialog: boolean;
        Filter: Knz2BereichListModelFilter;
        SelectedBereiche: Knz2GK2018Bereich[];
        CurrentListModel: IGroupedListDatasource;
        CurrentListModelType: EnumListModelType;
    }
> {
    public readonly state = {
        Bereich: undefined,
        ShowEditDialog: false,
        Filter: new Knz2BereichListModelFilter("", null),
        SelectedBereiche: [],
        CurrentListModel: undefined,
        CurrentListModelType: EnumListModelType.Init
    };
    private listRef: React.RefObject<Knz2BereicheList> = React.createRef();
    private yesNoRef: React.RefObject<ChoiceDialog> = React.createRef();
    private loadingRef: React.RefObject<LoadingDialog> = React.createRef();

    public render(): JSX.Element {
        return (
            <>
                <LoadingDialog ref={this.loadingRef} />
                <ChoiceDialog ref={this.yesNoRef} />
                <HandleError>
                    {this.state.ShowEditDialog && (
                        <Knz2EditBereichDialog
                            Model={this.props.Model}
                            InitialModel={this.state.Bereich}
                            closeDialog={async () => {
                                this.setState({ ShowEditDialog: false });
                            }}
                        />
                    )}

                    {this.state.CurrentListModelType !== EnumListModelType.Ausgeblendet && (
                        <Knz2FilterControl
                            Model={this.props.Model}
                            OnUpdateFilter={(filter: Knz2BereichListModelFilter) => {
                                this.setState({ Filter: filter });
                                this.listRef.current?.RefreshGroups();
                            }}
                            StatusValues={
                                this.state.CurrentListModelType === EnumListModelType.Anlage1
                                    ? Knz2BereichStatusAnlage1
                                    : Knz2BereichStatusAnlage3
                            }
                        />
                    )}
                    <Pivot
                        selectedKey={this.state.CurrentListModelType}
                        onLinkClick={async (item: PivotItem) => {
                            await this.setListModel(item.props.itemKey as EnumListModelType);
                        }}
                        headersOnly={true}
                        linkSize={PivotLinkSize.large}
                    >
                        <PivotItem itemKey={EnumListModelType.Anlage1} headerText="Anlage 1" />
                        <PivotItem itemKey={EnumListModelType.Anlage3} headerText="Anlage 3" />
                        <PivotItem itemKey={EnumListModelType.Ausgeblendet} headerText="Ausgeblendet" />
                    </Pivot>
                    <PleaseWait
                        ShowSpinner={!this.state.CurrentListModel}
                        render={() => (
                            <Knz2BereicheList
                                Model={this.props.Model}
                                ref={this.listRef}
                                OnEditBereich={(bereich) => {
                                    this.setState({ Bereich: bereich, ShowEditDialog: true });
                                }}
                                OnSelectedItems={(selectedItems: []) => {
                                    this.setState({ SelectedBereiche: selectedItems });
                                }}
                                ListModel={this.state.CurrentListModel}
                                ColumnType={
                                    this.state.CurrentListModelType === EnumListModelType.Anlage3
                                        ? EnumColumnType.Anlage3
                                        : EnumColumnType.Anlage1
                                }
                            />
                        )}
                    />
                </HandleError>
            </>
        );
    }

    public async componentDidMount(): Promise<void> {
        await this.setListModel(EnumListModelType.Anlage1);
    }

    private async setListModel(type: EnumListModelType) {
        if (this.state.CurrentListModelType !== type) {
            this.setState({
                CurrentListModel: await this.GetListModelFor(type),
                CurrentListModelType: type
            });
            this.listRef.current.RefreshGroups();
        }
    }

    private async GetListModelFor(listmodelType: EnumListModelType): Promise<IGroupedListDatasource> {
        let abschnitteLookup = await this.props.Model.AbschnitteCache.GetAllMap();
        switch (listmodelType) {
            case EnumListModelType.Anlage1:
                return new Knz2BereicheGroupedByKPN(this.props.Model, (b: Knz2GK2018Bereich) => {
                    return this.FilterFunctionAnsicht1(b, abschnitteLookup);
                });
            case EnumListModelType.Anlage3:
                return new Knz2BereicheGroupedByAbschnittList(this.props.Model, (b: Knz2GK2018Bereich) => {
                    return this.FilterFunctionAnsicht3(b, abschnitteLookup);
                });
            case EnumListModelType.Ausgeblendet:
                return new Knz2BereicheGroupedByKPN(this.props.Model, (b: Knz2GK2018Bereich) => {
                    return this.FilterFunctionAusgblendet(b);
                });
        }
    }

    private FilterFunctionAnsicht1(b: Knz2GK2018Bereich, abschnitteLookup: Map<string, Knz2GK2018Abschnitt>): boolean {
        if (!b.knz2AbschnittRef?.objectId) {
            return false;
        }
        if (!Knz2BereichStatusAnlage1.includes(b.f25Status)) {
            return false;
        }
        if (b.f44IstGeteilt) {
            return false;
        }

        if (this.state.Filter.status && this.state.Filter.status !== b.f25Status) {
            return false;
        }

        if (this.state.Filter.text) {
            if (
                Utils.IncludesText(b.f02Bezeichnung, this.state.Filter.text) ||
                Utils.IncludesText(b.f01Streckennummer, this.state.Filter.text) ||
                Utils.IncludesText(b.f27WeitereStrecken, this.state.Filter.text)
            ) {
                return true;
            }

            const abschnitt = abschnitteLookup.get(b.knz2AbschnittRef.objectId);
            if (abschnitt && Utils.IncludesText(abschnitt.f04Nr, this.state.Filter.text)) {
                return true;
            }
            return false;
        }

        return true;
    }

    private FilterFunctionAnsicht3(b: Knz2GK2018Bereich, abschnitteLookup: Map<string, Knz2GK2018Abschnitt>): boolean {
        if (!b.knz2AbschnittRef?.objectId) {
            return false;
        }
        if (!Knz2BereichStatusAnlage3.includes(b.f25Status)) {
            return false;
        }

        if (b.f44IstGeteilt) {
            return false;
        }

        if (this.state.Filter.status && this.state.Filter.status !== b.f25Status) {
            return false;
        }

        if (this.state.Filter.text) {
            if (
                Utils.IncludesText(b.f02Bezeichnung, this.state.Filter.text) ||
                Utils.IncludesText(b.f01Streckennummer, this.state.Filter.text) ||
                Utils.IncludesText(b.f27WeitereStrecken, this.state.Filter.text)
            ) {
                return true;
            }

            const abschnitt = abschnitteLookup.get(b.knz2AbschnittRef.objectId);
            if (abschnitt && Utils.IncludesText(abschnitt.f04Nr, this.state.Filter.text)) {
                return true;
            }
            return false;
        }

        return true;
    }

    private FilterFunctionAusgblendet(b: Knz2GK2018Bereich): boolean {
        if (EnumBereichStatus.Ausgeblendet !== b.f25Status) {
            return false;
        }

        if (b.f44IstGeteilt) {
            return false;
        }

        if (this.state.Filter.status && this.state.Filter.status !== b.f25Status) {
            return false;
        }

        if (this.state.Filter.text) {
            if (
                !Utils.IncludesText(b.f02Bezeichnung, this.state.Filter.text) &&
                !Utils.IncludesText(b.f01Streckennummer, this.state.Filter.text)
            ) {
                return false;
            }
        }

        return true;
    }
}

let wrapped = withRouter(Knz2Container);
export { wrapped as Knz2Container };
