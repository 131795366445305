import { BaseUserModel, UserContextModelOfEnumUserRole, UsersClient } from "../../generated/BackendClient";
import { EntityCache } from "./EntityCache";

export class UserRepository {
    public static GetCurrentUser(): Promise<UserContextModelOfEnumUserRole> {
        if (!this.CurrentUserPromise) {
            const pc = new UsersClient();
            this.CurrentUserPromise = pc.current();
        }

        return this.CurrentUserPromise;
    }

    public static LookupUser(userId: string): Promise<BaseUserModel> {
        this.LazyInit();

        return this.AllUsers.GetById(userId);
    }

    public static async LookupUsers(userIds: string[]): Promise<Map<string, BaseUserModel>> {
        this.LazyInit();

        let result = new Map<string, BaseUserModel>();
        for (let id of userIds) {
            result.set(id, await this.LookupUser(id));
        }

        return result;
    }

    private static LazyInit() {
        if (!this.AllUsers) {
            this.AllUsers = new EntityCache(<any>new UsersClient());
        }
    }

    private static CurrentUserPromise: Promise<UserContextModelOfEnumUserRole> = undefined;
    private static AllUsers: EntityCache<BaseUserModel> = undefined;
}
