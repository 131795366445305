import { Knz2AltprojektModel } from "../../Rad/DAL";
import { Utils } from "../../Utils";

export class Knz2Altprojekt extends Knz2AltprojektModel {
    constructor(parent?: Knz2AltprojektModel) {
        super(parent);
    }
    // Summe aller km der zugeordneten Bereiche
    public get Summe1(): number {
        if (this.k2F42Bereiche?.length > 0) {
            let summe = 0;
            this.k2F42Bereiche.forEach((b) => (summe += Utils.KilometerDifference(b.kmBis, b.kmVon)));
            return summe;
        } else {
            return 0;
        }
    }

    // Summe aller km der zugeordneten netto-on-top Strecken
    public get Summe2(): number {
        if (this.k2F41OnTopStrecken?.length > 0) {
            let summe = 0;
            this.k2F41OnTopStrecken.forEach((b) => (summe += Utils.KilometerDifference(b.kmBis, b.kmVon)));
            return summe;
        } else {
            return 0;
        }
    }

    public get Summe3(): number {
        return this.Summe1 + this.Summe2;
    }
}
