import { observer } from "mobx-react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import * as React from "react";

@observer // removing this will break event propagation
export class PleaseWait extends React.Component<
    {
        ShowSpinner: boolean;
        Style?: React.CSSProperties;
        render: () => React.ReactNode;
    },
    {}
> {
    public render(): JSX.Element {
        if (this.props.ShowSpinner) {
            return (
                <Spinner
                    style={this.props.Style || { paddingTop: "33vh" }}
                    size={SpinnerSize.large}
                    label="Einen Moment bitte..."
                />
            );
        }

        return <>{this.props.render()}</>;
    }
}
