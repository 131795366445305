import * as React from "react";
import { TaskApproveCheckboxes } from ".";
import { ITaskEditorProps } from "../../Workflow/TaskEditDialogStore";

export class Knz2FormblattOUStep1 extends React.Component<ITaskEditorProps, {}> {
    public render(): JSX.Element {
        return (
            <TaskApproveCheckboxes
                {...this.props}
                Preface="Das Dokument wurde an Hand nachfolgender Prüfungskriterien geprüft:"
                ToCheck={[
                    "Das ID-Prüfprotokoll ist konsistent, vollständig und nachvollziehbar.",
                    "Es traten für die Prüfung der NETTO-km keine Abweichungen in km-Anfang und km-Ende sowie Gesamt-km zwischen LärmBerT, STU-Prüf-ID und GK 19 auf."
                ]}
            />
        );
    }
}
