import React from "react";
import { RadEditDialogAction, RadEditDialogState } from ".";
import { BaseEntity } from "../../DAL";

const RadEditDialogContext = React.createContext(null);

type RadEditDialogContextType<T extends BaseEntity> = {
    state: RadEditDialogState<T>;
    dispatch: (action: RadEditDialogAction<T>) => void;
};

export function useRadListContext<T extends BaseEntity>(): RadEditDialogContextType<T> {
    return React.useContext(RadEditDialogContext);
}

export default RadEditDialogContext;
