import { Mutex } from "async-mutex";
import { observer } from "mobx-react";
import {
    ConstrainMode,
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    GroupFooter,
    GroupHeader,
    IColumn,
    IDetailsHeaderProps,
    IDetailsListProps,
    IDetailsRowProps,
    IGroup,
    IGroupDividerProps,
    IGroupHeaderProps,
    ITooltipHostProps,
    ScrollablePane,
    ScrollbarVisibility,
    Selection,
    SelectionMode,
    Sticky,
    StickyPositionType,
    TooltipHost
} from "office-ui-fabric-react";
import * as React from "react";
import { BaseEntity } from "../../Rad/DAL";
import { CatchReactErrors, CatchReactErrorsMethod } from "./Error-Handler/Decorators";
import { PleaseWait } from "./PleaseWait";

export interface IGroupedListDatasource2 {
    GetRootNode(): Promise<IGroupedListGroup2>;
    ExpandGroup(group: IGroupedListGroup2): Promise<void>;
    OnItemUpdated(rootNode: IGroupedListGroup2, item: any): Promise<void>;
}

export interface IGroupedListGroup2 extends IGroup {
    key: string;
    name: string;
    data?: any;
    children?: IGroup[];
    items: any[];
    notLoaded: boolean;
    isLoading: boolean;
    level?: number;
}

@observer
@CatchReactErrors
export class LargeGroupedList2 extends React.Component<
    {
        Columns: IColumn[];
        DataSource: IGroupedListDatasource2;
        OnItemInvoked: (item: any) => void;
        OnSelectedItems: (selectedItems: {}) => void;
        DetailsListProps?: any | IDetailsListProps;
    },
    {
        Items: any[];
        rootNode: IGroupedListGroup2;
        Groups: IGroup[] | IGroupedListGroup2[];
        selectedItems: {};
        IsLoading: boolean;
    }
> {
    public readonly state = {
        IsLoading: true,
        Items: [],
        Groups: undefined,
        rootNode: undefined,
        selectedItems: {}
    };
    private selection = new Selection({
        onSelectionChanged: () => {
            this.props.OnSelectedItems(this.selection.getSelection());
        }
    });
    private mutex = new Mutex();

    public async componentDidUpdate(prevProps): Promise<void> {
        if (prevProps.DataSource !== this.props.DataSource) {
            this.setState({ rootNode: undefined, Items: [] });
            await this.RefreshItems(); // async
            this.setState({ IsLoading: false });
        }
    }

    public async componentDidMount(): Promise<void> {
        await this.RefreshItems();
        this.setState({ IsLoading: false });
    }

    @CatchReactErrorsMethod()
    public async OnItemUpdated(item: BaseEntity): Promise<void> {
        await this.props.DataSource.OnItemUpdated(this.state.rootNode, item);
        await this.RefreshItems();
    }

    @CatchReactErrorsMethod()
    public async RefreshItems(): Promise<void> {
        const release = await this.mutex.acquire();

        try {
            let rootNode: IGroupedListGroup2 = this.state.rootNode;

            // load initial groups if required
            if (this.state.rootNode === undefined) {
                rootNode = await this.props.DataSource.GetRootNode();
                rootNode.isCollapsed = false;
            } else {
                if (rootNode.children) {
                    this.SetLoadingIndicators(rootNode);

                    // refresh groups so that loading indicators will be shown
                    this.setState({
                        Groups: rootNode.children.slice()
                    });
                }
            }

            const items: any[] = [];

            // do the loading
            await this.UpdateTree(rootNode, items);

            // force component refresh
            this.setState({
                rootNode,
                Groups: rootNode.children.slice(),
                Items: items.slice()
            });
        } finally {
            release();
        }
    }

    public render(): JSX.Element {
        return (
            <div style={{ position: "relative", height: "80vh" }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <PleaseWait
                        ShowSpinner={this.state.IsLoading || !this.state.rootNode || !this.state.rootNode.children}
                        render={() => (
                            <DetailsList
                                {...this.props.DetailsListProps}
                                onShouldVirtualize={() => {
                                    return false;
                                }}
                                items={this.state.Items}
                                columns={this.props.Columns}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                compact={true}
                                selectionMode={SelectionMode.multiple}
                                selection={this.selection}
                                groups={this.state.Groups}
                                onRenderRow={this._onRenderRow}
                                indentWidth={64}
                                groupProps={{
                                    onRenderHeader: this._onRenderGroupHeader.bind(this),
                                    onRenderFooter: this._onRenderGroupFooter.bind(this),
                                    showEmptyGroups: true
                                }}
                                // implement custom getGroupHeight() as we sometimes get strange exceptions otherwise
                                getGroupHeight={(group: IGroup) => {
                                    return (
                                        32 +
                                        (!group.isCollapsed && group.count ? group.count * 32 : 0) +
                                        (!group.isCollapsed && group.children ? group.children.length * 40 : 0)
                                    );
                                }}
                                onItemInvoked={(item) => {
                                    this.props.OnItemInvoked(item);
                                }}
                                constrainMode={ConstrainMode.unconstrained} // to make the sticky header work!
                                onRenderDetailsHeader={this._onRenderdetailsHeader}
                            />
                        )}
                    />
                </ScrollablePane>
            </div>
        );
    }

    private SetLoadingIndicators(parent: IGroupedListGroup2): void {
        // only recurse if group is uncollapsed
        if (!parent.isCollapsed) {
            // load more data if required
            if (parent.notLoaded) {
                parent.isLoading = true;
            }

            if (parent.children?.length > 0) {
                for (const child of parent.children as IGroupedListGroup2[]) {
                    this.SetLoadingIndicators(child);
                }
            }
        }
    }

    private async UpdateTree(parent: IGroupedListGroup2, items: any[]): Promise<void> {
        parent.startIndex = items.length;
        // only recurse if group is uncollapsed
        if (!parent.isCollapsed) {
            // load more data if required
            if (parent.notLoaded) {
                await this.props.DataSource.ExpandGroup(parent);
                parent.count = parent.items ? parent.items.length : 0;
            }

            if (parent.children?.length > 0) {
                for (const child of parent.children as IGroupedListGroup2[]) {
                    await this.UpdateTree(child, items);
                }
            }

            if (parent.items) {
                items.push(...parent.items);
            }
        }
    }

    private _onRenderRow(props: IDetailsRowProps): JSX.Element {
        return <DetailsRow {...props} groupNestingDepth={0} />;
    }

    private _onRenderGroupHeader(props: IGroupDividerProps): JSX.Element {
        return (
            <GroupHeader
                {...props}
                isCollapsedGroupSelectVisible={false}
                isGroupLoading={(group: IGroupedListGroup2) => group.isLoading}
                onRenderTitle={(headerProps: IGroupHeaderProps) => {
                    return this._onRenderTitle(headerProps);
                }}
                onToggleCollapse={(group: IGroup) => {
                    this._loadGroup(group as IGroupedListGroup2);
                }}
            />
        );
    }

    private _onRenderTitle(props: IGroupDividerProps): JSX.Element {
        const { group } = props;

        if (!group) {
            return null;
        }

        return <div className="ms-GroupHeader-title ListGroupHeader">{group.name}</div>;
    }

    @CatchReactErrorsMethod()
    private async _loadGroup(group: IGroupedListGroup2): Promise<void> {
        group.isCollapsed = !group.isCollapsed;

        // show loading indicator if we need to load the group
        if (group.notLoaded) {
            group.isLoading = true;
        }

        // force component refresh
        this.setState({
            Groups: this.state.rootNode.children.slice()
        });

        await this.RefreshItems();
    }

    private _onRenderGroupFooter(props: IGroupDividerProps): JSX.Element {
        return <GroupFooter {...props} />;
    }

    // implements a stickyheader
    private _onRenderdetailsHeader(props: IDetailsHeaderProps): JSX.Element {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                <DetailsHeader
                    {...props}
                    groupNestingDepth={0}
                    onRenderColumnHeaderTooltip={(tooltipHostProps: ITooltipHostProps) => (
                        <TooltipHost {...tooltipHostProps} />
                    )}
                />
            </Sticky>
        );
    }
}
