import { observer } from "mobx-react";
import { IColumn, ScrollablePane, ScrollbarVisibility } from "office-ui-fabric-react";
import * as React from "react";
import { Knz4LVBereicheGrouped } from ".";
import { Knz4LV, Knz4LVDetailList, Knz4LVListModelFilter, Knz4LVRepository } from "../../Model/Knz4LV";
import { nameof, Utils } from "../../Utils";
import { LargeDetailsList, LargeGroupedList, ObserverSpan, ShowUser } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz4LVList extends React.Component<
    {
        Model: Knz4LVRepository;
        Filter: Knz4LVListModelFilter;
        OnEditKnz4LVItem: (knz4LVItem: Knz4LV) => void;
        OnSelectedItems?: (selectedItems: []) => void;
        LoadItemsFromPapierkorb?: boolean;
    },
    {
        ListModel: Knz4LVBereicheGrouped;
        ListModelSearch: Knz4LVDetailList;
    }
> {
    private listRef: React.RefObject<LargeGroupedList> = React.createRef();
    private detailslistRef: React.RefObject<LargeDetailsList>;

    public static columns: IColumn[] = [
        {
            key: "Feld01",
            name: "Regionalbereich",
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: Knz4LV) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz4LV>("k4LVF32Regionalbereich")} />
            )
        },
        {
            key: "Feld09",
            name: "Erstellt am",
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: Knz4LV) => (
                <ObserverSpan
                    Object={item}
                    PropertyName={nameof<Knz4LV>("created")}
                    FormatFunction={Utils.FormatDateTimeShort}
                />
            )
        },
        {
            key: "Feld08",
            name: "Ersteller",
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: Knz4LV) => <ShowUser Object={item} PropertyName={nameof<Knz4LV>("createdBy")} />
        },
        {
            key: "Feld02",
            name: "Bundesland",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: Knz4LV) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz4LV>("k4LVF05Bundesland")} />
            )
        },
        {
            key: "Feld03",
            name: "PLZ",
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: Knz4LV) => <ObserverSpan Object={item} PropertyName={nameof<Knz4LV>("k4LVF04PLZ")} />
        },
        {
            key: "Feld04",
            name: "Ort",
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: Knz4LV) => <ObserverSpan Object={item} PropertyName={nameof<Knz4LV>("k4LVF05Ort")} />
        },
        {
            key: "Feld05",
            name: "Straße",
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: Knz4LV) => <ObserverSpan Object={item} PropertyName={nameof<Knz4LV>("k4LVF01Strasse")} />
        },
        {
            key: "Feld06",
            name: "Hausnr",
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: Knz4LV) => <ObserverSpan Object={item} PropertyName={nameof<Knz4LV>("k4LVF02Hausnr")} />
        },
        {
            key: "Feld07",
            name: "Maßnahme",
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: Knz4LV) => <ObserverSpan Object={item} PropertyName={nameof<Knz4LV>("k4LVF12Massnahme")} />
        }
    ];

    constructor(props) {
        super(props);
        this.listRef = React.createRef();
        this.detailslistRef = React.createRef();
        this.state = {
            ListModel: new Knz4LVBereicheGrouped(this.props.Model, this.props.LoadItemsFromPapierkorb),
            ListModelSearch: new Knz4LVDetailList(this.props.Model, this.props.Filter)
        };
    }

    public async RefreshListItems(): Promise<void> {
        await this.listRef?.current?.RefreshGroups();
        await this.detailslistRef?.current?.RefreshItems();
    }

    public async onItemUpdated(item: Knz4LV): Promise<void> {
        await this.state.ListModel.OnItemUpdated(item);
    }

    public async componentDidUpdate(prevProps) {
        if (!this.props.Filter.Equals(prevProps.Filter)) {
            if (this.props.Filter.text) {
                this.setState({
                    ListModelSearch: new Knz4LVDetailList(this.props.Model, this.props.Filter)
                });
            } else {
                this.setState({
                    ListModel: new Knz4LVBereicheGrouped(this.props.Model, this.props.LoadItemsFromPapierkorb)
                });
            }

            if (!this.props.Filter.text) {
                this.props.OnSelectedItems([]);
            }
        }
    }

    public render(): JSX.Element {
        return (
            <div style={{ position: "relative", height: "80vh" }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    {!this.props.Filter.text ? (
                        <LargeGroupedList
                            Columns={Knz4LVList.columns}
                            DataSource={this.state.ListModel}
                            OnItemInvoked={(item: any) => {
                                this.props.OnEditKnz4LVItem(item);
                            }}
                            OnSelectedItems={(selectedItems: []) => {
                                if (this.props.OnSelectedItems) {
                                    this.props.OnSelectedItems(selectedItems);
                                }
                            }}
                            ref={this.listRef}
                        />
                    ) : (
                        <LargeDetailsList
                            Columns={Knz4LVList.columns}
                            DataSource={this.state.ListModelSearch}
                            OnItemInvoked={(item: any) => {
                                this.props.OnEditKnz4LVItem(item);
                            }}
                            OnSelectedItems={(selectedItems: []) => {
                                if (this.props.OnSelectedItems) {
                                    this.props.OnSelectedItems(selectedItems);
                                }
                            }}
                            ref={this.detailslistRef}
                        />
                    )}
                </ScrollablePane>
            </div>
        );
    }
}
