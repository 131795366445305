import { observer } from "mobx-react";
import * as React from "react";
import { EasyCheckbox } from "../../Generic";

@observer
export class Knz2FormblattPruefung3 extends React.Component<
    {
        PropertyName: string;
        HideNettoOnTop?: boolean;
    },
    {}
> {
    public render(): JSX.Element {
        return (
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12">
                    <EasyCheckbox
                        PropertyName={this.props.PropertyName}
                        CheckboxProps={{
                            label: "Der Lärmsanierungsbereich ist in Anhang 1/3 Gesamtkonzept 2022 enthalten"
                        }}
                    />
                    <EasyCheckbox
                        PropertyName={this.props.PropertyName}
                        CheckboxProps={{
                            label: 'Die "Netto-km-Länge" stimmt mit Angabe in STU-Formblatt (STU-Prüf-ID) überein'
                        }}
                    />
                    {!this.props.HideNettoOnTop && (
                        <EasyCheckbox
                            PropertyName={this.props.PropertyName}
                            CheckboxProps={{
                                label: 'Die "Netto-on-top-km" stimmen mit Angabe in STU-Formblatt (STU-Prüf-ID) überein'
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}
