import * as mobx from "mobx";
import { Knz2GK2022AbschnittModel } from "../../Rad/DAL";
import { Knz2GK2022Bereich } from "./Knz2Bereich";

export class Knz2GK2022Abschnitt extends Knz2GK2022AbschnittModel {
    @mobx.observable public Bereiche: Knz2GK2022Bereich[] = undefined;

    constructor(parent?: Knz2GK2022AbschnittModel) {
        super(parent);
    }

    public Clone(): Knz2GK2022Abschnitt {
        return new Knz2GK2022Abschnitt(this);
    }

    @mobx.computed
    public get IsValid(): boolean {
        if (this.title && this.f03PKZ) {
            return true;
        }
        return false;
    }
}
