import {
    BerichtClient,
    EnumKennzahl,
    FileParameter,
    Knz1Client,
    Knz2LSClient,
    Knz2LVClient,
    Knz3LSClient,
    Knz3LVClient,
    Knz3SPClient,
    Knz4LSClient,
    Knz4LVClient
} from "../generated/BackendClient";
import { Bericht } from "../Model/Berichte/Bericht";
import { LskRepository } from "./LskRepository";

export class BerichteDAL {
    private KennzahlType: EnumKennzahl;

    public constructor(berichteKennzahl: EnumKennzahl) {
        this.KennzahlType = berichteKennzahl;
    }

    // Knz2 - Create a Bericht
    public async AddBerichtKnz2LS(bericht: Bericht): Promise<void> {
        const wf = new Knz2LSClient();
        await wf.createBericht(bericht.berichtArt, bericht.berichtJahr);
    }

    // Knz2 - Create a Bericht
    public async AddBerichtKnz2LV(bericht: Bericht): Promise<void> {
        const wf = new Knz2LVClient();
        await wf.createBericht(bericht.berichtArt, bericht.berichtJahr);
    }
    // Knz3LS - Create a Bericht
    public async AddBerichtKnz3LS(bericht: Bericht): Promise<void> {
        const wf = new Knz3LSClient();
        await wf.createBericht(bericht.berichtArt, bericht.berichtMonat, bericht.berichtJahr);
    }
    public async AddBerichtKnz3SP(bericht: Bericht): Promise<void> {
        const wf = new Knz3SPClient();
        await wf.createBericht(bericht.berichtArt, bericht.berichtMonat, bericht.berichtJahr);
    }

    public async AddBerichtKnz3LV(bericht: Bericht): Promise<void> {
        const wf = new Knz3LVClient();
        await wf.createBericht(bericht.berichtArt, bericht.berichtMonat, bericht.berichtJahr);
    }

    // Knz1 - Create a Bericht
    public async AddBerichtKnz1(
        bericht: Bericht,
        zentraleDatei: FileParameter,
        lSPDatei: FileParameter
    ): Promise<void> {
        const wf = new Knz1Client();

        await wf.createBericht(
            bericht.berichtArt,
            bericht.berichtMonat,
            bericht.berichtJahr,
            bericht.berichtKennzahlLSPDateiWert,
            bericht.berichtKennzahlLSPDateiWert,
            zentraleDatei,
            lSPDatei
        );
    }

    // Knz4 - Create a Bericht
    public async AddKnz4Bericht(bericht: Bericht): Promise<void> {
        const wf = new Knz4LSClient();
        await wf.createBericht(bericht.berichtArt, bericht.berichtMonat, bericht.berichtJahr);
    }

    // Knz4LV - Create a Bericht
    public async AddKnz4LVBericht(bericht: Bericht): Promise<void> {
        const wf = new Knz4LVClient();
        await wf.createBericht(bericht.berichtArt, bericht.berichtMonat, bericht.berichtJahr);
    }

    public async StartFreigabeKnz(bericht: Bericht, message: string): Promise<void> {
        let wf = null;
        switch (this.KennzahlType) {
            case EnumKennzahl.Knz4LV:
                wf = new Knz4LVClient();
                break;
            case EnumKennzahl.Knz4LS:
                wf = new Knz4LSClient();
                break;
            case EnumKennzahl.Knz3LS:
                wf = new Knz3LSClient();
                break;
            case EnumKennzahl.Knz3LV:
                wf = new Knz3LVClient();
                break;
            case EnumKennzahl.Knz3SP:
                wf = new Knz3SPClient();
                break;
            case EnumKennzahl.Knz2LS:
                wf = new Knz2LSClient();
                break;
            case EnumKennzahl.Knz2LV:
                wf = new Knz2LVClient();
                break;
            case EnumKennzahl.Knz1:
                wf = new Knz1Client();
                break;
            default:
                throw new Error(`Unbekannte Kennzahl ${this.KennzahlType}`);
        }
        bericht = await LskRepository.Bericht.update(bericht);
        await wf.startFreigabe(bericht.objectId, message);
    }

    public async PublishTm1(bericht: Bericht): Promise<void> {
        const dal = new BerichtClient();
        await dal.submitTm1(bericht.objectId);
    }

    public async RetractBericht(bericht: Bericht): Promise<void> {
        const dal = new BerichtClient();
        await dal.retractBericht(bericht.objectId);
    }
}
