import * as React from "react";
import { RadRepository } from "../../../generated/RadRepository";
import { BaseEntity, BaseUserModel } from "../../DAL";
import { RadListContainer } from "../RadList";
import { NewUserDialog } from "./NewUserDialog";

type ManageUsersProps<T extends BaseEntity> = {};

export function ManageUsers<T extends BaseEntity>({}: React.PropsWithChildren<ManageUsersProps<T>>) {
    return (
        <RadListContainer<BaseUserModel>
            Repository={RadRepository.BaseUserModel}
            NewDialogImplementation={NewUserDialog}
        />
    );
}
