export class KpnValidate {
    private static readonly KpnBundesland = new Map<string, string>([
        ["01", "SH"],
        ["02", "HH"],
        ["03", "NI"],
        ["04", "HB"],
        ["05", "NRW"],
        ["06", "HE"],
        ["07", "RP"],
        ["08", "BW"],
        ["09", "BY"],
        ["10", "SL"],
        ["11", "BE"],
        ["12", "BB"],
        ["13", "MV"],
        ["14", "SN"],
        ["15", "ST"],
        ["16", "TH"]
    ]);

    public static ValidateKpn(kpn: string, bundesland: string): boolean {
        if (!KpnValidate.KpnBundesland.has(kpn)) {
            return false;
        }
        return KpnValidate.KpnBundesland.get(kpn) === bundesland;
    }
}
