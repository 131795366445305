import { observer } from "mobx-react";
import { CommandBar, ICommandBarItemProps, Pivot, PivotItem } from "office-ui-fabric-react";
import * as React from "react";
import { Knz3DatenImportDialog, Knz3DatenImportList } from ".";
import { Knz3DatenImport, Knz3Repository } from "../../../Model/Knz3";
import { LoadingDialog } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz3DatenImportContainer extends React.Component<
    {
        Model: Knz3Repository;
    },
    {
        ShowNewDialog: boolean;
        ShowEditDialog: boolean;
        CurrentItem: Knz3DatenImport;
        SelectedItemsToRecycle: Knz3DatenImport[];
        SelectedItemsToRestore: Knz3DatenImport[];
    }
> {
    private listRef: React.RefObject<Knz3DatenImportList> = React.createRef();
    private restorelistRef: React.RefObject<Knz3DatenImportList> = React.createRef();
    private loadingRef: React.RefObject<LoadingDialog> = React.createRef();

    public readonly state = {
        ShowNewDialog: false,
        ShowEditDialog: false,
        CurrentItem: undefined,
        SelectedItemsToRecycle: [],
        SelectedItemsToRestore: []
    };

    public render(): JSX.Element {
        return (
            <>
                <LoadingDialog ref={this.loadingRef} />
                <CommandBar items={this.getCommandBarItems()} />
                {this.state.ShowNewDialog && (
                    <Knz3DatenImportDialog
                        InitialItem={undefined}
                        readOnly={false}
                        Model={this.props.Model}
                        closeDialog={() => {
                            (async () => {
                                this.setState({ ShowNewDialog: false });
                                this.listRef.current.RefreshListItems();
                            })();
                        }}
                    />
                )}
                {this.state.ShowEditDialog && (
                    <Knz3DatenImportDialog
                        InitialItem={this.state.CurrentItem}
                        readOnly={true}
                        Model={this.props.Model}
                        closeDialog={() => {
                            (async () => {
                                this.setState({ ShowEditDialog: false });
                                this.listRef.current.RefreshListItems();
                            })();
                        }}
                    />
                )}
                <Pivot>
                    <PivotItem headerText="Knz3 Datenimport">
                        <Knz3DatenImportList
                            ref={this.listRef}
                            Model={this.props.Model}
                            ShowDeletedItems={false}
                            OnEditItem={(item: Knz3DatenImport) => {
                                this.setState({
                                    CurrentItem: item,
                                    ShowEditDialog: true
                                });
                            }}
                            OnSelectedItems={(selectedItems: []) => {
                                this.setState({ SelectedItemsToRecycle: selectedItems });
                            }}
                        />
                    </PivotItem>
                    <PivotItem headerText="Knz3 Datenimport im Papierkorb">
                        <Knz3DatenImportList
                            ref={this.restorelistRef}
                            Model={this.props.Model}
                            ShowDeletedItems={true}
                            OnEditItem={(item: Knz3DatenImport) => {
                                this.setState({
                                    CurrentItem: item,
                                    ShowEditDialog: true
                                });
                            }}
                            OnSelectedItems={(selectedItems: []) => {
                                this.setState({ SelectedItemsToRestore: selectedItems });
                            }}
                        />
                    </PivotItem>
                </Pivot>
            </>
        );
    }

    private getCommandBarItems(): ICommandBarItemProps[] {
        const output: ICommandBarItemProps[] = [
            {
                key: "option1",
                name: "Neuer Datenimport",
                iconProps: {
                    iconName: "Add"
                },
                onClick: () => {
                    this.setState({ ShowNewDialog: true });
                },
                disabled: this.props.Model.IsReadOnly
            },
            {
                key: "option2",
                name: "Datenimport löschen",
                iconProps: {
                    iconName: "RecycleBin"
                },
                onClick: () => {
                    this.loadingRef.current.Execute(async (): Promise<void> => {
                        for (const item of this.state.SelectedItemsToRecycle as Knz3DatenImport[]) {
                            item.k3F06Knz3ImPapierkorb = true;
                            await this.props.Model.UpdateData(item);
                        }
                        await this.listRef.current.RefreshListItems();
                    }, "Der Knz3-Datenimport wird gelöscht.");
                },
                disabled: this.state.SelectedItemsToRecycle.length === 0 || this.props.Model.IsReadOnly
            },
            {
                key: "option3",
                name: "Datenimport wiederherstellen",
                iconProps: {
                    iconName: "EmptyRecycleBin"
                },
                onClick: () => {
                    this.loadingRef.current.Execute(async (): Promise<void> => {
                        for (const item of this.state.SelectedItemsToRestore as Knz3DatenImport[]) {
                            item.k3F06Knz3ImPapierkorb = false;
                            await this.props.Model.UpdateData(item);
                        }
                        await this.restorelistRef.current.RefreshListItems();
                    }, "Der Knz3-Datenimport wird wiederhergestellt.");
                },
                disabled: this.state.SelectedItemsToRestore.length === 0 || this.props.Model.IsReadOnly
            }
        ];
        return output;
    }
}
