import * as mobx from "mobx";
import { Knz4LVModel } from "../../generated/BackendClient";


export class Knz4LV extends Knz4LVModel {
    constructor(parent?: Knz4LV) {
        super(parent);
    }

    /**
     * returns true if all required fields are filled
     */
    @mobx.computed
    public get IsValid(): boolean {
        if (this.k4LVF04PLZ && this.k4LVF04PLZ?.length !== 5) {
            return false;
        }
      
        /*1 Straße
2 Hausnummer
3 PLZ
4 Ort
5 Bundesland
6 Nummer Schalltechnische Untersuchung
7 SAP Projektnr
8 SAP Buchungsbelegnr
9 Rechnungsbetrag
10 Rechnungsdatum
11 Maßnahme*/

if (
    !this.k4LVF01Strasse ||
    !this.k4LVF02Hausnr ||
    !this.k4LVF05Ort ||
    !this.k4LVF05Bundesland ||
    !this.k4LVF20Untersuchungsbericht ||
    !this.k4LVF13SAPProjektnummer ||
    !this.k4LVF18Rechnungsbetrag ||
    !this.k4LVF16Rechnungsdatum ||
    !this.k4LVF12Massnahme ||
    !this.k4F06AnzahlWohnungseinheiten ||
    !this.k4LVF32Regionalbereich
) {
    return false;
}
        return true;
    }

    public Clone(): Knz4LV {
        return new Knz4LV(this);
    }

    public CompareTo(other: Knz4LV): number {
        return other.created > this.created ? 1 : -1;
    }
}
