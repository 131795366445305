import * as mobx from "mobx";
import { BerichteDAL } from "../../DAL/BerichteDAL";
import {
    BerichtClient, EnumBerichtArt,
    EnumKennzahl,
    EnumUserRole,
    FileParameter
} from "../../generated/BackendClient";
import { RadRepository } from "../../generated/RadRepository";
import { GenericEntityCache } from "../../Rad/DAL/EntityCache";
import { UserRepository } from "../../Rad/DAL/UserRepository";
import { WrapObjects } from "../../Rad/DAL/WrapperTools";
import { Utils } from "../../Utils";
import { Bericht } from "./Bericht";

export enum EnumBerichtFreigabeStatus {
    freigabebericht = "Freigegeben",
    freigabeFehlerbericht = "Freigegeben trotz Fehlern",
    nichtFreigabebericht = "Nicht freigegeben"
}

export class BerichteRepository {
    private BerichteItemCache: GenericEntityCache<Bericht>;

    @mobx.observable
    public IsReadOnly = true;

    public Kennzahl: EnumKennzahl;
    private static KennzahlBerichtFreigeber = new Map<EnumKennzahl, EnumUserRole>([
        [EnumKennzahl.Knz1, EnumUserRole.BerichtKnz1],
        [EnumKennzahl.Knz2LS, EnumUserRole.BerichtKnz2],
        [EnumKennzahl.Knz2LV, EnumUserRole.BerichtKnz2],
        [EnumKennzahl.Knz3LS, EnumUserRole.BerichtKnz3LS],
        [EnumKennzahl.Knz3LV, EnumUserRole.BerichtKnz3LV],
        [EnumKennzahl.Knz3SP, EnumUserRole.BerichtKnz3LS],
        [EnumKennzahl.Knz4LS, EnumUserRole.BerichtKnz4LS],
        [EnumKennzahl.Knz4LV, EnumUserRole.BerichtKnz4LV]
    ]);
    Dal: BerichteDAL;

    public constructor(kennzahl: EnumKennzahl) {
        this.Dal = new BerichteDAL(kennzahl);
        let client = new BerichtClient();
        this.BerichteItemCache = new GenericEntityCache<Bericht>(
            async (ts) => WrapObjects(await client.getBerichte(kennzahl, ts), <any>Bericht),
            15
        );
        this.Kennzahl = kennzahl;
        this.IsReadOnly = true;
        (async () => {
            let profile = await UserRepository.GetCurrentUser();

            this.IsReadOnly =
                !profile.currentUser.isAdmin &&
                !profile.allowedScopes.some((g) =>
                    [BerichteRepository.KennzahlBerichtFreigeber.get(kennzahl), EnumUserRole.FachlicherAdmin].includes(
                        g
                    )
                );
        })();
    }

    public async AddBericht(bericht: Bericht, file1: FileParameter, file2: FileParameter): Promise<void> {
        try {
            switch (this.Kennzahl) {
                case EnumKennzahl.Knz4LV:
                    return this.Dal.AddKnz4LVBericht(bericht);
                case EnumKennzahl.Knz4LS:
                    return this.Dal.AddKnz4Bericht(bericht);
                case EnumKennzahl.Knz2LS:
                    return this.Dal.AddBerichtKnz2LS(bericht);
                case EnumKennzahl.Knz2LV:
                    return this.Dal.AddBerichtKnz2LV(bericht);
                case EnumKennzahl.Knz3LS:
                    return this.Dal.AddBerichtKnz3LS(bericht);
                case EnumKennzahl.Knz3LV:
                    return this.Dal.AddBerichtKnz3LV(bericht);
                case EnumKennzahl.Knz3SP:
                    return this.Dal.AddBerichtKnz3SP(bericht);
                case EnumKennzahl.Knz1:
                    return this.Dal.AddBerichtKnz1(bericht, file1, file2);
                default:
                    throw new Error(`Unbekannte Kennzahl ${this.Kennzahl}`);
            }
        } finally {
            this.BerichteItemCache.ForceUpdate();
        }
    }

    public async UpdateBericht(bericht: Bericht): Promise<void> {
        let client = RadRepository.BerichtModel;
        let result = await client.update(bericht);
        this.BerichteItemCache.ForceUpdate();
    }

    public async StartFreigabe(bericht: Bericht, message: string): Promise<void> {
        await this.Dal.StartFreigabeKnz(bericht, message);
    }

    public async PublishTm1(bericht: Bericht): Promise<void> {
        await this.Dal.PublishTm1(bericht);
    }

    public async GetBericht(id: string): Promise<Bericht> {
        return await this.BerichteItemCache.GetById(id);
    }

    public async RetractBericht(bericht: Bericht): Promise<void> {
        await this.Dal.RetractBericht(bericht);
    }

    public async GetBerichte(showOnlyRecycleBinItems?: boolean): Promise<Map<string, Bericht>> {
        const items = await this.BerichteItemCache.GetAllMap();

        if (showOnlyRecycleBinItems) {
            return Utils.MapFilter(items, (k, v) => v.berichtKennzahlImPapierkorb === true);
        }

        return Utils.MapFilter(items, (k, v) => v.berichtKennzahlImPapierkorb !== true);
    }

    public get IsKnz2(): boolean {
        return [EnumKennzahl.Knz2LS, EnumKennzahl.Knz2LV].includes(this.Kennzahl);
    }
    public get IsKnz3(): boolean {
        return [EnumKennzahl.Knz3LS, EnumKennzahl.Knz3LV].includes(this.Kennzahl);
    }

    public get DisplayNameKennzahl1(): string {
        if (this.IsKnz2) {
            return "Kennzahl Ergebnis in km";
        }
        return "Kennzahl Ergebnis";
    }

    public get DisplayNameKennzahl2(): string {
        if (this.IsKnz2) {
            return "Kennzahl Ergebnis mit Fehlern in km";
        }
        return null;
    }

    public get ShowMonat(): boolean {
        return !this.IsKnz2;
    }

    public get showAdditionalFilesFolderView(): boolean {
        return this.IsKnz2;
    }

    public get GetBerichtArt(): string[] {
        if (this.IsKnz3) {
            return [EnumBerichtArt.Jahresbericht, EnumBerichtArt.Zwischenbericht, EnumBerichtArt.Gesamtbericht];
        }

        return [EnumBerichtArt.Jahresbericht, EnumBerichtArt.Zwischenbericht];
    }

    public get GetBerichtFreigabeStatus() {
        if (this.IsKnz3) {
            return EnumBerichtFreigabeStatus;
        }
    }

    public get IsBerichtFreigabeStatusZeigen(): boolean {
        return this.IsKnz3;
    }
}
