import * as React from "react";

export interface IKnz2ErstSpeichernProps {
    IstGespeichert: boolean;
}

export function Knz2ErstSpeichern(props: React.PropsWithChildren<IKnz2ErstSpeichernProps>) {
    if (props.IstGespeichert) {
        return <>{props.children}</>;
    }
    return (
        <div>Einige Funktionen stehen erst nach dem Speichern des Projekts zur Verfügung.</div>
    )

}
