import * as mobx from "mobx";
import { WorkflowRepository } from ".";
import { WorkflowTask, WorkflowTaskResponse } from "../../generated/BackendClient";

export class TaskEditModel {
    @mobx.computed public get IsLoading(): boolean {
        return this.RunningRequests > 0;
    }

    @mobx.observable public TaskNotFound = false;
    @mobx.observable public Task: WorkflowTask;
    @mobx.observable public TaskResponse: WorkflowTaskResponse = new WorkflowTaskResponse();

    @mobx.observable private RunningRequests = 0;
    private Repository: WorkflowRepository;

    public constructor(repository: WorkflowRepository) {
        this.Repository = repository;
    }

    public async LoadTask(taskid: string): Promise<void> {
        mobx.runInAction(() => {
            this.RunningRequests++;
        });
        try {
            const task = await this.Repository.GetTask(taskid);
            if (task) {
                mobx.runInAction(() => {
                    this.Task = task;
                    this.TaskNotFound = false;
                });
            }
        } catch (ex) {
            mobx.runInAction(() => {
                this.TaskNotFound = true;
            });
            console.log(ex);
        } finally {
            mobx.runInAction(() => {
                this.RunningRequests--;
            });
        }
    }

    public async SaveTask(): Promise<void> {
        mobx.runInAction(() => {
            this.RunningRequests++;
        });
        try {
            return await this.Repository.SetTaskResponse(this.Task, this.TaskResponse);
        } finally {
            mobx.runInAction(() => {
                this.RunningRequests--;
            });
        }
    }
}
