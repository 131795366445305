import { observer } from "mobx-react";
import * as React from "react";
import {
    Knz2DokumenteCommandBar,
    Knz2DokumenteEditDialog,
    Knz2DokumenteFilterControl,
    Knz2DokumenteProjektList
} from ".";
import { EnumApprovalWorkflowStatus } from "../../../generated/BackendClient";
import { Knz2GK2022Projekt, Knz2ProjekteListModelFilter, Knz2Repository } from "../../../Model/Knz2";
import { ChoiceDialog, HandleError } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz2LSProjekteContainer extends React.Component<
    {
        Model: Knz2Repository;
    },
    {
        Projekt: Knz2GK2022Projekt;
        ShowEditDialog: boolean;
        ShowEditDialogReadonly: boolean;
        ShowNewDialog: boolean;
        Filter: Knz2ProjekteListModelFilter;
    }
> {
    public readonly state = {
        Projekt: undefined,
        ShowEditDialog: false,
        ShowEditDialogReadonly: false,
        ShowNewDialog: false,
        Filter: new Knz2ProjekteListModelFilter("")
    };
    private listRef: React.RefObject<Knz2DokumenteProjektList> = React.createRef();
    private choiceRef: React.RefObject<ChoiceDialog> = React.createRef();

    public render(): JSX.Element {
        return (
            <HandleError>
                <ChoiceDialog ref={this.choiceRef} />
                {this.state.ShowEditDialog && (
                    <Knz2DokumenteEditDialog
                        Model={this.props.Model}
                        InitialModel={this.state.Projekt}
                        hideDialog={false}
                        closeDialog={() => {
                            this.setState({ ShowEditDialog: false });
                            this.listRef.current.RefreshListItems();
                        }}
                        readonly={this.state.ShowEditDialogReadonly}
                    />
                )}
                {this.state.ShowNewDialog && (
                    <Knz2DokumenteEditDialog
                        Model={this.props.Model}
                        InitialModel={this.state.Projekt}
                        hideDialog={false}
                        closeDialog={() => {
                            this.setState({ ShowNewDialog: false });
                            this.listRef.current.RefreshListItems();
                        }}
                    />
                )}
                <Knz2DokumenteCommandBar
                    Model={this.props.Model}
                    OnClickNewProjekt={() => {
                        this.setState({ ShowNewDialog: true, Projekt: new Knz2GK2022Projekt() });
                    }}
                />
                <Knz2DokumenteFilterControl
                    Model={this.props.Model}
                    OnUpdateFilter={(filter: Knz2ProjekteListModelFilter) => {
                        this.setState({ Filter: filter });
                    }}
                />
                <Knz2DokumenteProjektList
                    ref={this.listRef}
                    Model={this.props.Model}
                    OnEditProjekt={async (projekt) => {
                        const isBerichtFreigegeben = projekt.k2F41BerichtRefs?.some(
                            (bericht: any) => bericht.BerichtWFStatus === EnumApprovalWorkflowStatus.Freigegeben
                        );
                        if (isBerichtFreigegeben) {
                            const result = await this.choiceRef.current.Execute(
                                "Warnung",
                                <>
                                    Das Projekt wird bereits in einem Freigabebericht verwendet.
                                    <br />
                                    Möchten Sie dieses Projekt trotzdem öffnen?
                                </>,
                                [
                                    ["Ja (schreibgeschützt)", true],
                                    ["Ja (bearbeitbar)", false],
                                    ["Nein", false]
                                ]
                            );
                            if (result == 2) {
                                return;
                            }
                            this.setState({
                                Projekt: projekt,
                                ShowEditDialog: true,
                                ShowEditDialogReadonly: result === 0
                            });
                        } else {
                            this.setState({
                                Projekt: projekt,
                                ShowEditDialog: true,
                                ShowEditDialogReadonly: false
                            });
                        }
                    }}
                    Filter={this.state.Filter}
                />
            </HandleError>
        );
    }
}
