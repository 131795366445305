import { IPeoplePickerProps, Persona, PersonaSize, Shimmer } from "office-ui-fabric-react";
import * as React from "react";
import { BaseUserModel, EntityReferenceOfBaseUserModel, UserRepository } from "../../../DAL";
import { RadPeoplePicker } from "../../Controls/RadPeoplePicker";
import { IFieldRenderer, IFieldRendererEditProps, IFieldRendererViewProps } from "../FieldRenderer";

export interface RadTextFieldProps {
    PeoplePickerProps?: Partial<IPeoplePickerProps>;
    Value: EntityReferenceOfBaseUserModel[];
    OnChange?: (value: EntityReferenceOfBaseUserModel[]) => void;
    Disabled?: boolean;
    LabelText?: string;
}

export function RadUserField({
    PeoplePickerProps,
    OnChange,
    Value,
    Disabled,
    LabelText
}: React.PropsWithChildren<RadTextFieldProps>) {
    const [resolvedUsers, setResolvedUsers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    // get users from user references
    React.useEffect(() => {
        (async () => {
            setIsLoading(true);
            let resolved = await UserRepository.LookupUsers(Value.map((v) => v.objectId));

            setResolvedUsers(Array.from(resolved.values()));
            setIsLoading(false);
        })();
    }, [Value]);

    if (isLoading) {
        return <Shimmer isDataLoaded={!isLoading} />
    }
    return (
        <RadPeoplePicker
            Value={resolvedUsers}
            OnChange={(v) =>
                OnChange && OnChange(v.map((i) => new EntityReferenceOfBaseUserModel({ legacyId: undefined, objectId: i.objectId })))
            }
            PeoplePickerProps={PeoplePickerProps}
            Disabled={Disabled}
            LabelText={LabelText}
        />
    );
}

export interface RadShowUserProps {
    Value: EntityReferenceOfBaseUserModel[];
}
export function RadShowUser({ Value }: React.PropsWithChildren<RadShowUserProps>) {
    const [resolvedUsers, setResolvedUsers] = React.useState<BaseUserModel[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    // get users from user references
    React.useEffect(() => {
        (async () => {
            setIsLoading(true);
            let resolved = await UserRepository.LookupUsers(Value.map((v) => v.objectId));

            setResolvedUsers(Array.from(resolved.values()).filter(u => !!u));
            setIsLoading(false);
        })();
    }, [Value]);

    if (isLoading) {
        return <Shimmer isDataLoaded={!isLoading} />
    } else {
        return (
            <>
                {resolvedUsers.map((r, idx) => (
                    <Persona key={idx} size={PersonaSize.size8} text={r.displayName} />
                ))}
            </>
        );
    }
}

export const RadUserFieldRendererMulti: IFieldRenderer = {
    EditField: (props: IFieldRendererEditProps) =>
        RadUserField({
            Value: props.Value,
            PeoplePickerProps: { disabled: props.Disabled || props.Metadata.readOnly },
            OnChange: props.OnChange,
            LabelText: props.Metadata.title
        }),
    ShowField: (props: IFieldRendererViewProps) =>
        RadUserField({ Value: props.Value, PeoplePickerProps: { disabled: true }, LabelText: props.Metadata.title }),
    ListField: (props: IFieldRendererViewProps) => <RadShowUser Value={props.Value} />
};

export const RadUserFieldRenderer: IFieldRenderer = {
    EditField: (props: IFieldRendererEditProps) =>
        RadUserField({
            Value: props.Value ? [props.Value] : [],
            PeoplePickerProps: { disabled: props.Disabled || props.Metadata.readOnly },
            OnChange: (v) => props.OnChange(v?.length > 0 ? v[0] : null),
            LabelText: props.Metadata.title
        }),
    ShowField: (props: IFieldRendererViewProps) =>
        RadUserField({
            Value: props.Value ? [props.Value] : [],
            PeoplePickerProps: { disabled: true },
            LabelText: props.Metadata.title
        }),
    ListField: (props: IFieldRendererViewProps) => <RadShowUser Value={props.Value ? [props.Value] : []} />
};
