import { observer } from "mobx-react";
import { MessageBar, MessageBarType } from "office-ui-fabric-react/lib/MessageBar";
import * as React from "react";

export interface IValidationMessage {
    Type: MessageBarType;
    Text: string;
}

@observer
export class ValidationMessages extends React.Component<
    {
        Messages: IValidationMessage[];
    },
    {}
> {
    public render(): JSX.Element {
        return (
            <>
                {this.props.Messages && (
                    <div className="ValidationMessages">
                        {this.props.Messages.map((message, index) => (
                            <MessageBar messageBarType={message.Type} key={index} className="nl2br">
                                {message.Text}
                            </MessageBar>
                        ))}
                    </div>
                )}
            </>
        );
    }
}
