import { observer } from "mobx-react";
import { CommandBar } from "office-ui-fabric-react";
import * as React from "react";
import { Knz4Repository } from "../../Model/Knz4LS";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz4LSCommandBar extends React.Component<{
    Model: Knz4Repository;
    OnNewClick: () => void;
    ExportExcel: () => void;
    OnRemoveItemsClick: () => void;
    OnRestoreItemsClick: () => void;
    ExportDocument: () => void;
    ShowAbsenderDialog: () => void;
    EnableDocumentExport: boolean;
    EnableRemoveItemsButton: boolean;
    EnableRestoreItemsButton: boolean;
}> {
    public readonly state = {};

    public render(): JSX.Element {
        return (
            <CommandBar
                items={[
                    {
                        key: "option1",
                        name: "Neue Maßnahme",
                        iconProps: {
                            iconName: "Add"
                        },
                        onClick: () => {
                            this.props.OnNewClick();
                        },
                        disabled: this.props.Model.IsReadOnly
                    },
                    {
                        key: "option2",
                        name: "Maßn. Lärmsanierung löschen",
                        iconProps: {
                            iconName: "RecycleBin"
                        },
                        onClick: () => {
                            this.props.OnRemoveItemsClick();
                        },
                        disabled: !this.props.EnableRemoveItemsButton
                    },
                    {
                        key: "option3",
                        name: "Maßn. Lärmsanierung wiederherstellen",
                        iconProps: {
                            iconName: "EmptyRecycleBin"
                        },
                        onClick: () => {
                            this.props.OnRestoreItemsClick();
                        },
                        disabled: !this.props.EnableRestoreItemsButton
                    },
                    {
                        key: "option4",
                        name: "Excel Export",
                        iconProps: {
                            iconName: "ExcelDocument"
                        },
                        onClick: () => {
                            this.props.ExportExcel();
                        }
                    },
                    {
                        key: "option5",
                        name: "Dokument erstellen",
                        iconProps: {
                            iconName: "WordDocument"
                        },
                        disabled: !this.props.EnableDocumentExport,
                        subMenuProps: {
                            items: [
                                {
                                    key: "option6",
                                    name: "Prüfprotokoll",
                                    onClick: () => {
                                        this.props.ExportDocument();
                                    }
                                },
                                {
                                    key: "option7",
                                    name: "Rücksendung",
                                    onClick: () => {
                                        this.props.ShowAbsenderDialog();
                                    }
                                }
                            ]
                        }
                    }
                ]}
            />
        );
    }
}
