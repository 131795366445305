export class TupleMap<S, T> {
    public get size() {
        return this.map.size;
    }
    private map = new Map<string, T>();

    public constructor(entries?: ReadonlyArray<[S, T]>) {
        if (entries) {
            for (const val of entries) {
                this.set(val[0] as S, val[1] as T);
            }
        }
    }

    public set(key: S, value: T): this {
        this.map.set(this.getKey(key), value);
        return this;
    }

    public get(key: S): T {
        return this.map.get(this.getKey(key));
    }

    public clear() {
        this.map.clear();
    }

    public delete(key: S): boolean {
        return this.map.delete(this.getKey(key));
    }

    public has(key: S): boolean {
        return this.map.has(this.getKey(key));
    }

    public forEach(callbackfn: (value: number, key: S, map: Map<S, T>) => void, thisArg?: any): void {
        this.map.forEach((value, key) => {
            callbackfn.call(thisArg, value, JSON.parse(key), this);
        });
    }

    private getKey(key: any): string {
        return JSON.stringify(key);
    }
}
