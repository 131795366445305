import { DatePicker, DayOfWeek, IDatePickerProps, IDatePickerStrings } from "office-ui-fabric-react";
import * as React from "react";
import { IFieldRenderer, IFieldRendererEditProps, IFieldRendererViewProps } from "../FieldRenderer";

const DayPickerStrings: IDatePickerStrings = {
    months: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
    ],
    shortMonths: ["Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    shortDays: ["S", "M", "D", "M", "D", "F", "S"],
    goToToday: "Heute",
    prevMonthAriaLabel: "Nächster Monat",
    nextMonthAriaLabel: "Vorheriger Monat",
    prevYearAriaLabel: "Nächstes Jahr",
    nextYearAriaLabel: "Vorheriges Jahr",
    closeButtonAriaLabel: "Schließen"
};

function FormatDate(date: Date): string {
    if (!date) {
        return "";
    }

    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("de-DE", options);
}

function FormatDateTime(date: Date): string {
    if (!date) {
        return "";
    }
    // configure timestamp formatting
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    };
    return date.toLocaleDateString("de-DE", options);
}
function ParseDateFromString(originalDate: Date, value: string): Date {
    const values = (value || "").trim().split(".");
    const day = values.length > 0 ? Math.max(1, Math.min(31, parseInt(values[0], 10))) : originalDate.getDate();
    const month = values.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : originalDate.getMonth();
    let year = values.length > 2 ? parseInt(values[2], 10) : originalDate.getFullYear();
    if (typeof year !== "number" || isNaN(year)) {
        year = originalDate.getFullYear();
    }
    if (year < 100) {
        year += originalDate.getFullYear() - (originalDate.getFullYear() % 100);
    }
    return new Date(year, month, day);
}

export interface RadDateFieldProps {
    DatePickerProps: Partial<IDatePickerProps>;
    Value: Date;
    OnChange?: (value: Date) => void;
}

export function RadDateField({ DatePickerProps, OnChange, Value }: React.PropsWithChildren<RadDateFieldProps>) {
    return (
        <DatePicker
            firstDayOfWeek={DayOfWeek.Monday}
            strings={DayPickerStrings}
            formatDate={FormatDate}
            showWeekNumbers={true}
            firstWeekOfYear={1}
            showMonthPickerAsOverlay={true}
            placeholder="Bitte ein Datum wählen"
            ariaLabel="Bitte ein Datum wählen"
            value={Value}
            onSelectDate={(value: Date) => {
                OnChange && OnChange(value);
            }}
            allowTextInput={true}
            parseDateFromString={(value) => ParseDateFromString(Value, value)}
            {...DatePickerProps}
        />
    );
}

export const RadDateFieldRenderer: IFieldRenderer = {
    EditField: (props: IFieldRendererEditProps) =>
        RadDateField({
            Value: props.Value,
            DatePickerProps: { label: props.Metadata.title, disabled: props.Disabled || props.Metadata.readOnly },
            OnChange: props.OnChange
        }),
    ShowField: (props: IFieldRendererViewProps) =>
        RadDateField({ Value: props.Value, DatePickerProps: { label: props.Metadata.title, disabled: true } }),
    ListField: (props: IFieldRendererViewProps) => <>{FormatDate(props.Value)}</>
};

export const RadDateTimeFieldRenderer: IFieldRenderer = {
    EditField: (props: IFieldRendererEditProps) => <>{FormatDateTime(props.Value)}</>,
    ShowField: (props: IFieldRendererViewProps) => <>{FormatDateTime(props.Value)}</>,
    ListField: (props: IFieldRendererViewProps) => <>{FormatDateTime(props.Value)}</>
};
