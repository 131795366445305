import * as mobx from "mobx";
import { Knz2GK2022Projekt, Knz2ProjekteListModelFilter, Knz2Repository } from ".";
import { IDetailListDatasource } from "../../tsx/Generic/LargeDetailsList";

export class Knz2DetailList implements IDetailListDatasource {
    @mobx.computed public get IsLoading(): boolean {
        return this.RunningRequests > 0;
    }

    @mobx.observable private RunningRequests = 0;
    private Model: Knz2Repository;
    private Filter: Knz2ProjekteListModelFilter;

    public constructor(model: Knz2Repository, filter: Knz2ProjekteListModelFilter) {
        this.Model = model;
        this.Filter = filter;
    }

    public Sort(items: Knz2GK2022Projekt[]): Knz2GK2022Projekt[] {
        return items;
    }

    public async GetAllItems(): Promise<Map<string, Knz2GK2022Projekt>> {
        let items: Knz2GK2022Projekt[];

        if (this.Filter?.IsSet()) {
            items = await this.SearchFor(this.Filter);

            const output = new Map<string, Knz2GK2022Projekt>();
            for (const elem of items) {
                output.set(elem.objectId, elem);
            }
            return output;
        } else {
            return await this.Model.ProjekteCache.GetAllMap();
        }
    }

    public Equals(IListDatasource: Knz2DetailList): boolean {
        return IListDatasource.Filter.Equals(this.Filter);
    }

    private async SearchFor(filter: Knz2ProjekteListModelFilter): Promise<Knz2GK2022Projekt[]> {
        // create filter string
        let filteredItems = await this.Model.ProjekteCache.GetAll();

        // if we have a text filter, apply it
        if (filter.text) {
            const needle = filter.text.toLowerCase();
            filteredItems = filteredItems.filter(
                (p) =>
                    p.k2F33Kurzprojektnummer?.includes(needle) ||
                    p.k2F35ProjektBezeichnung?.toLowerCase().includes(needle) ||
                    p.k2F14StuId?.toLowerCase().includes(needle)
            );
        }
        return filteredItems;
    }
}
