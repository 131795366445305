import * as React from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { RadCommandBar, RadList } from ".";
import { Header } from "../../../tsx/Generic/Header";
import { PleaseWaitProcessing } from "../../../tsx/Generic/PleaseWaitProcessing";
import { BaseEntity, EnumRadPermission, IEntityRepository } from "../../DAL";
import { RadEditDialog, RadEditDialogProps } from "../RadEditDialog";
import RadListContext from "./RadListContext";
import { CreateRadListStateReducer, RadListReload, RadListUpdateContext } from "./RadListState";

type RadListContainerProps<T extends BaseEntity> = {
    Repository: IEntityRepository<T>;
    NewDialogImplementation?: React.FunctionComponent<RadEditDialogProps<T>>;
    EditDialogImplementation?: React.FunctionComponent<RadEditDialogProps<T>>;
};

export function RadListContainer<T extends BaseEntity>({
    Repository,
    EditDialogImplementation,
    NewDialogImplementation
}: React.PropsWithChildren<RadListContainerProps<T>>) {
    let history = useHistory();
    let { url } = useRouteMatch();

    const [state, dispatch] = React.useReducer(CreateRadListStateReducer<T>(), {
        IsLoading: true,
        SelectedItems: [],
        Items: [],
        Repository: Repository,
        CurrentView: Repository.Metadata.views[0],
        ProcessingText: null
    });
    EditDialogImplementation ??= RadEditDialog;
    NewDialogImplementation ??= RadEditDialog;

    RadListUpdateContext(dispatch, Repository, state);

    // initial item loading
    React.useEffect(() => {
        RadListReload(dispatch, state);
    }, [Repository]);

    return (
        <RadListContext.Provider value={{ state, dispatch }}>
            <PleaseWaitProcessing ShowSpinner={!!state.ProcessingText} TextToShow={state.ProcessingText} />
            <Header Large>{state.CurrentView.viewTitle}</Header>
            <Route
                path={`${url}/edit/:id`}
                render={(p) => {
                    return (
                        <EditDialogImplementation
                            Repository={Repository}
                            ObjectId={p.match.params.id}
                            OnDialogClosed={async (saved, newModel) => {
                                history.push(`${url}`);
                                if (saved) {
                                    await RadListReload(dispatch, state);
                                }
                            }}
                        />
                    );
                }}
            />
            <Route
                path={`${url}/new`}
                render={(p) => {
                    return (
                        <NewDialogImplementation
                            Repository={Repository}
                            ObjectId={undefined}
                            OnDialogClosed={async (saved, newModel) => {
                                history.push(`${url}`);
                                if (saved) {
                                    await RadListReload(dispatch, state);
                                }
                            }}
                        />
                    );
                }}
            />
            <RadCommandBar<T>
                Repository={Repository}
                SelectedObjects={state.SelectedItems}
                Actions={[
                    {
                        Props: {
                            name: "Neu",
                            iconProps: {
                                iconName: "Add"
                            }
                        },
                        OnExecute: (Permissions, SelectedObjects) => {
                            history.push(`${url}/new`);
                        },
                        IsEnabled: (Permissions, SelectedObjects) => {
                            return Permissions.includes(EnumRadPermission.Create);
                        }
                    },
                    {
                        Props: {
                            name: "Löschen",
                            iconProps: {
                                iconName: "RecycleBin"
                            }
                        },
                        OnExecute: async (permissions, selectedObjects) => {
                            for (let i = 0; i < selectedObjects.length; i++) {
                                dispatch({
                                    type: "setProcessingText",
                                    ProcessingText: `Lösche Element ${i + 1} von ${selectedObjects.length}`
                                });
                                await Repository.delete(selectedObjects[i].objectId);
                            }

                            await RadListReload(dispatch, state);

                            dispatch({
                                type: "setProcessingText",
                                ProcessingText: null
                            });
                        },
                        IsEnabled: (permissions, selectedObjects) => {
                            return permissions.includes(EnumRadPermission.Delete);
                        }
                    }
                ]}
            />
            <RadList<T>
                Metadata={Repository.Metadata}
                OnItemInvoked={(item) => {
                    history.push(`${url}/edit/${item.objectId}`);
                }}
            />
        </RadListContext.Provider>
    );
}
