import { Icon, MessageBar, MessageBarType } from "office-ui-fabric-react";
import * as React from "react";

export class HandleError extends React.Component<{}, {}> {
    public state = { error: null, errorInfo: null };

    public componentDidCatch(error, errorInfo): void {
        this.setState({
            error,
            errorInfo
        });
    }

    public render() {
        if (this.state.errorInfo) {
            return (
                <MessageBar messageBarType={MessageBarType.error}>
                    <b>
                        Da hat etwas nicht geklappt <Icon iconName="ConstructionCone" />
                    </b>
                    <br />
                    Das tut uns leid! Bitte laden Sie die Seite neu und versuchen es noch einmal.
                    <br />
                    <br />
                    Bitte geben Sie die folgenden Daten an den Support weiter:
                    <br />
                    {this.state.error.message}
                    {this.state.error.stack && (
                        <div>
                            <br />
                            <code className="nl2br">{this.state.error.stack}</code>
                        </div>
                    )}
                    {this.state.errorInfo && this.state.errorInfo.componentStack && (
                        <div>
                            <br />
                            <b>Komponenten Stack:</b>
                            <code className="nl2br">{this.state.errorInfo.componentStack}</code>
                        </div>
                    )}
                    <div className="ms-fontSize-xs db-verticalGap16">
                        <br />
                        Version: {VERSION}
                    </div>
                </MessageBar>
            );
        }

        return this.props.children;
    }
}
