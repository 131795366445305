import * as mobx from "mobx";
import { Knz2GK2018AbschnittModel } from "../../../generated/BackendClient";
import { Knz2GK2018Bereich } from "./Knz2Bereich";

export class Knz2GK2018Abschnitt extends Knz2GK2018AbschnittModel {
    @mobx.observable public Bereiche: Knz2GK2018Bereich[] = undefined;

    constructor(parent?: Knz2GK2018AbschnittModel) {
        super(parent);
    }

    public Clone(): Knz2GK2018Abschnitt {
        return new Knz2GK2018Abschnitt(this);
    }

    @mobx.computed
    public get IsValid(): boolean {
        if (this.title && this.f03PKZ) {
            return true;
        }
        return false;
    }
}
