import { createTheme, Customizer } from "office-ui-fabric-react";
import * as React from "react";

const DBTheme = createTheme({
    palette: {
        themePrimary: "#f01414",
        themeLighterAlt: "#fef5f5",
        themeLighter: "#fdd7d7",
        themeLight: "#fab5b5",
        themeTertiary: "#f66e6e",
        themeSecondary: "#f22e2e",
        themeDarkAlt: "#d81111",
        themeDark: "#b60f0f",
        themeDarker: "#860b0b",
        neutralLighterAlt: "#f8f8f8",
        neutralLighter: "#f4f4f4",
        neutralLight: "#eaeaea",
        neutralQuaternaryAlt: "#dadada",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c8c8",
        neutralTertiary: "#444", // disabled labels are too light otherwise
        neutralSecondary: "#858585",
        neutralPrimaryAlt: "#4b4b4b",
        neutralPrimary: "#333",
        neutralDark: "#272727",
        black: "#333",
        white: "#fff"
    },
    /*  effects: {
    roundedCorner2: "2px",
    elevation4: Depths.depth4,
    elevation8: Depths.depth8,
    elevation16: Depths.depth16,
    elevation64: Depths.depth64
  },*/
    semanticColors: {
        disabledBodyText: "#444" // disabled labels are too light otherwise
    }
});

export class DBFabric extends React.Component<{}, {}> {
    public render(): JSX.Element {
        return (
                <Customizer
                    settings={{
                        theme: DBTheme
                    }}
                >
                    {this.props.children}
                </Customizer>
        );
    }
}
