import { observer } from "mobx-react";
import {
    DetailsList,
    DetailsListLayoutMode,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode
} from "office-ui-fabric-react";
import * as React from "react";
import { Knz2GK2018Bereich } from "../../../Model/Knz2/GK2018/Knz2Bereich";
import { Knz2BereicheGroupedByAbschnittList } from "../../../Model/Knz2/GK2018/Knz2BereicheGroupedByAbschnittList";
import { Knz2BereichListModelFilter } from "../../../Model/Knz2/GK2018/Knz2BereichListModelFilter";
import { Knz2GK2018Projekt } from "../../../Model/Knz2/GK2018/Knz2Projekt";
import { Knz2Repository } from "../../../Model/Knz2/GK2018/Knz2Repository";

import { EnumBereichStatus } from "../../../Rad/DAL";
import { Utils } from "../../../Utils";
import { Header, PleaseWait } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";
import { Knz2EditBereichDialog } from "../Bereiche";
import { Knz2BereicheList } from "../Common";

@observer
@CatchReactErrors
export class Knz2DokumenteVerknüpfen extends React.Component<
    {
        Model: Knz2Repository;
        Projekt: Knz2GK2018Projekt;
        Disabled?: boolean;
    },
    {
        Filter: Knz2BereichListModelFilter;
        StuBereiche: Knz2GK2018Bereich[];
        IsLoading: boolean;
        ListModel: Knz2BereicheGroupedByAbschnittList;
        SelectedBereich: Knz2GK2018Bereich;
        ShowEditDialog: boolean;
    }
> {
    constructor(props) {
        super(props);
        this.state = {
            Filter: new Knz2BereichListModelFilter("", null),
            StuBereiche: [],
            IsLoading: true,
            ListModel: new Knz2BereicheGroupedByAbschnittList(this.props.Model, (b: Knz2GK2018Bereich) => {
                return this.BereichFilterFunction(b);
            }),
            SelectedBereich: undefined,
            ShowEditDialog: false
        };
    }

    public async componentDidMount() {
        await this.Refresh();
        this.setState({ IsLoading: false });
    }

    public render(): JSX.Element {
        return (
            <>
                {this.state.ShowEditDialog && (
                    <Knz2EditBereichDialog
                        Model={this.props.Model}
                        InitialModel={this.state.SelectedBereich}
                        closeDialog={async () => {
                            this.setState({ ShowEditDialog: false });
                        }}
                    />
                )}

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <Header>
                                Verknüpfte Sanierungsbereiche (Netto km:{" "}
                                {Utils.FormatKilometer(this.props.Projekt.F15Summe1)}
                                {" km)"}
                            </Header>

                            <PleaseWait
                                ShowSpinner={this.state.IsLoading}
                                render={() => (
                                    <div style={{ position: "relative", height: "80vh" }}>
                                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                                            <DetailsList
                                                items={this.state.StuBereiche}
                                                columns={Knz2BereicheList.ColumnsAnlage1}
                                                setKey="set"
                                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                                compact={true}
                                                selectionMode={SelectionMode.multiple}
                                                onItemInvoked={(item: Knz2GK2018Bereich) => {
                                                    this.setState({ SelectedBereich: item, ShowEditDialog: true });
                                                }}
                                            />
                                        </ScrollablePane>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    private async Refresh() {
        await this.props.Model.LoadBereicheByStuIdId(this.props.Projekt);
        this.setState({ StuBereiche: this.props.Projekt.Bereiche.filter((b) => !b.f44IstGeteilt) });
    }

    private BereichFilterFunction(b: Knz2GK2018Bereich): boolean {
        if (b.knz2ProjektRef) {
            return false;
        }
        if (b.f44IstGeteilt) {
            return false;
        }

        if (b.f25Status !== EnumBereichStatus.InBearbeitung && b.f25Status !== EnumBereichStatus.ErstsanierungErfolgt) {
            return false;
        }
        if (this.state.Filter.status && this.state.Filter.status !== b.f25Status) {
            return false;
        }

        if (this.state.Filter.text) {
            if (
                Utils.IncludesText(b.f02Bezeichnung, this.state.Filter.text) ||
                Utils.IncludesText(b.f01Streckennummer, this.state.Filter.text) ||
                Utils.IncludesText(b.f27WeitereStrecken, this.state.Filter.text)
            ) {
                return true;
            }

            return false;
        }

        return true;
    }
}
