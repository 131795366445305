import * as mobx from "mobx";
import { MessageBarType } from "office-ui-fabric-react";
import { BerichtModel, EnumApprovalWorkflowStatus, EnumBerichtArt } from "../../generated/BackendClient";
import { IValidationMessage } from "../../tsx/Generic";

export class Bericht extends BerichtModel {
    constructor(parent?: BerichtModel) {
        super(parent);
    }

    /**
     * returns true if all required fields are filled
     */
    @mobx.computed
    public get IsValid(): boolean {
        if (!this.berichtArt) return false;
        switch (this.berichtArt) {
            case EnumBerichtArt.Zwischenbericht:
                return this.berichtJahr > 0 && this.berichtMonat > 0;
            case EnumBerichtArt.Jahresbericht:
                return this.berichtJahr > 0 && this.berichtJahr < new Date().getFullYear();
            default:
                return true;
        }
    }

    /**
     * returns true if Bericht can be submitted to TM1
     */
    @mobx.computed
    public get IsReadyForTm1(): boolean {
        if (this.berichtTm1TransferDate) {
            return false;
        }

        switch (this.berichtArt) {
            case EnumBerichtArt.Zwischenbericht:
                return (
                    [EnumApprovalWorkflowStatus.Freigegeben, EnumApprovalWorkflowStatus.Bereit].includes(
                        this.berichtWFStatus
                    ) && Number(this.berichtMonat) < 12
                );
            case EnumBerichtArt.Jahresbericht:
                return this.berichtWFStatus === EnumApprovalWorkflowStatus.Freigegeben;
            default:
                return false;
        }
    }

    /**
     * returns true if Knz1 fields are filled
     */
    @mobx.computed
    public get IsKnz1BerichtValid(): boolean {
        if (this.berichtArt === EnumBerichtArt.Jahresbericht) {
            if (!this.berichtKennzahlLSPDateiWert || !this.berichtKennzahlZentraleDateiWert) {
                return false;
            }
        } else {
            if (!this.berichtKennzahlLSPDateiWert) {
                return false;
            }
        }

        return true;
    }

    @mobx.computed
    public get NewBerichtValidationmessages(): IValidationMessage[] {
        if (this.berichtArt === EnumBerichtArt.Jahresbericht && Number(this.berichtJahr) >= new Date().getFullYear()) {
            return [
                {
                    Text: "Jahresberichte können erst nach Abschluss eines Jahres erstellt werden.",
                    Type: MessageBarType.error
                }
            ];
        }
    }

    @mobx.computed
    public get Knz1ValidationMessages(): IValidationMessage[] {
        if (this.berichtKennzahlZentraleDateiWert && this.berichtKennzahlLSPDateiWert) {
            if (this.berichtKennzahlZentraleDateiWert !== this.berichtKennzahlLSPDateiWert) {
                return [
                    {
                        Text: "Die zwei Werte für die Knz. 1 Lärmvorsorgeprogramm und Zentrale sind nicht identisch.",
                        Type: MessageBarType.error
                    }
                ];
            }
        }

        return [];
    }

    @mobx.computed
    public get FreigabeValidationMessages(): IValidationMessage[] {
        if (
            [
                EnumApprovalWorkflowStatus.Initial,
                EnumApprovalWorkflowStatus.Vorbereiten,
                EnumApprovalWorkflowStatus.InFreigabe,
                EnumApprovalWorkflowStatus.Fehler,
                EnumApprovalWorkflowStatus.Zurueckgezogen
            ].includes(this.berichtWFStatus)
        ) {
            return [
                {
                    Text: `Freigabeprozesse können nur im Status "Bereit" oder "Abgelehnt" gestartet werden. Der aktuelle Status ist "${this.berichtWFStatus}".`,
                    Type: MessageBarType.warning
                }
            ];
        }
        return [];
    }

    public Clone(): Bericht {
        return new Bericht(this);
    }

    public get CanBeDeleted(): boolean {
        return [
            EnumApprovalWorkflowStatus.Bereit,
            EnumApprovalWorkflowStatus.Abgelehnt,
            EnumApprovalWorkflowStatus.Bearbeitbar,
            EnumApprovalWorkflowStatus.Fehler,
            EnumApprovalWorkflowStatus.Zurueckgezogen
        ].includes(this.berichtWFStatus);
    }

    public Compare(other: Bericht): number {
        const aKey = Number(this.berichtJahr) * 12 + Number(this.berichtMonat);
        const bKey = Number(other.berichtJahr) * 12 + Number(other.berichtMonat);
        return aKey < bKey ? 1 : -1;
    }
}
