import * as mobx from "mobx";
import { observer } from "mobx-react";
import { DefaultButton, DialogFooter, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { EnumBerichtArt } from "../../generated/BackendClient";
import { Bericht, BerichteRepository } from "../../Model/Berichte";
import { nameof } from "../../Utils";
import { DBDialog, EasyCombobox, EasyForm, ValidationMessages } from "../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../Generic/Error-Handler/Decorators";

@CatchReactErrors
@observer
export class NewBerichtDialog extends React.Component<
    {
        Model: BerichteRepository;
        hideDialog: boolean;
        showMonat?: boolean;
        closeDialog: (result: boolean, newModel: Bericht) => void;
    },
    {}
> {
    public Bericht: Bericht;
    constructor(props) {
        super(props);
        this.state = {};
        this.Bericht = new Bericht();
        this.Bericht.berichtJahr = new Date().getFullYear();
        this.Bericht.berichtMonat = Math.min(12, new Date().getMonth() + 1);
    }

    public render(): JSX.Element {
        return (
            <DBDialog
                onDismiss={() => {
                    this.ExitDialog(false);
                }}
                dialogContentProps={{
                    title: "Bericht erstellen"
                }}
                minWidth="400px"
            >
                <ValidationMessages Messages={this.Bericht.NewBerichtValidationmessages} />

                <EasyForm Object={this.Bericht}>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12">
                                <EasyCombobox
                                    PropertyName={nameof<Bericht>("berichtArt")}
                                    ComboBoxProps={{ label: "Berichtart" }}
                                    Options={this.props.Model.GetBerichtArt}
                                    OnChange={(v1, v2) => {
                                        if (v2 === EnumBerichtArt.Gesamtbericht) {
                                            mobx.runInAction(() => {
                                                this.Bericht.berichtJahr = new Date().getFullYear();
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {this.props.showMonat && this.Bericht.berichtArt !== EnumBerichtArt.Jahresbericht && (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6">
                                    <EasyCombobox
                                        PropertyName={nameof<Bericht>("berichtMonat")}
                                        ComboBoxProps={{ label: "Monat" }}
                                        Options={[...Array(12).keys()].map((x) => x + 1)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6">
                                <EasyCombobox
                                    PropertyName={nameof<Bericht>("berichtJahr")}
                                    ComboBoxProps={{
                                        label: "Jahr",
                                        disabled: this.Bericht.berichtArt === EnumBerichtArt.Gesamtbericht
                                    }}
                                    Options={[...Array(10).keys()].map((x) => x + 2018)}
                                />
                            </div>
                        </div>
                    </div>
                    <DialogFooter>
                        <PrimaryButton
                            onClick={() => {
                                this.ExitDialog(true);
                            }}
                            text="Erstellen"
                            disabled={!this.Bericht.IsValid}
                        />
                        <DefaultButton
                            onClick={() => {
                                this.ExitDialog(false);
                            }}
                            text="Abbrechen"
                        />
                    </DialogFooter>
                </EasyForm>
            </DBDialog>
        );
    }

    @CatchReactErrorsMethod()
    private async ExitDialog(shouldSave: boolean) {
        if (shouldSave) {
            if (!this.Bericht.IsValid) {
                return;
            }

            this.props.closeDialog(true, this.Bericht);
        } else {
            this.props.closeDialog(false, undefined);
        }
    }
}
