import { EnumBereichStatus } from "../../Rad/DAL";
import { IGroupedListFilter } from "../../tsx/Generic/LargeGroupedList";

export class Knz2BereichListModelFilter implements IGroupedListFilter {
    public text: string;
    public status: EnumBereichStatus;
    constructor(text: string, status: EnumBereichStatus) {
        this.text = text?.length >= 3 ? text : "";
        this.status = status;
    }
    public Equals(other: Knz2BereichListModelFilter): boolean {
        if (!other) {
            return false;
        }
        return this.text === other.text && this.status === other.status;
    }
    public IsSet(): boolean {
        return !!this.text || !!this.status;
    }
}
