import { observer } from "mobx-react";
import { IColumn, Icon, ScrollablePane, ScrollbarVisibility } from "office-ui-fabric-react";
import * as React from "react";
import { Knz2DetailList, Knz2GK2022Projekt, Knz2ProjekteListModelFilter, Knz2Repository } from "../../../Model/Knz2";
import { nameof, Utils } from "../../../Utils";
import { LargeDetailsList, ObserverSpan } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";
import { Knz2AttachmentControl } from "../Common";

@observer
@CatchReactErrors
export class Knz2DokumenteProjektList extends React.Component<
    {
        Model: Knz2Repository;
        OnEditProjekt: (projekt: Knz2GK2022Projekt) => void;
        Filter: Knz2ProjekteListModelFilter;
    },
    {
        ListModel: Knz2DetailList;
    }
> {
    private listRef: React.RefObject<LargeDetailsList>;

    private columns: IColumn[] = [
        {
            key: "Feld02",
            name: "KPN",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2022Projekt>("k2F33Kurzprojektnummer")} />
            )
        },
        {
            key: "Feld01",
            name: "Bezeichnung",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2022Projekt>("k2F35ProjektBezeichnung")} />
            )
        },
        {
            key: "Feld03a",
            name: "Strecken Nr.",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => {
                const len = item.k2F40WeitereStrecken && item.k2F40WeitereStrecken.length;
                if (!len) {
                    return null;
                }

                return (
                    <span>
                        {item.k2F40WeitereStrecken[0].streckeNr}
                        {len > 1 && <Icon iconName={"Warning"} style={{ color: "#f00", marginLeft: "10px" }} />}
                    </span>
                );
            }
        },
        {
            key: "Feld03",
            name: "km von",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => {
                const len = item.k2F40WeitereStrecken?.length;
                if (!len) {
                    return null;
                }

                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {Utils.FormatKilometer(item.k2F40WeitereStrecken[0].kmVon)}
                    </span>
                );
            }
        },
        {
            key: "Feld04",
            name: "km bis",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => {
                const len = item.k2F40WeitereStrecken?.length;
                if (!len) {
                    return null;
                }
                return (
                    <span
                        style={{
                            display: "block",
                            textAlign: "right"
                        }}
                    >
                        {Utils.FormatKilometer(item.k2F40WeitereStrecken[0].kmBis)}
                    </span>
                );
            }
        },
        {
            key: "Feld05",
            name: "Vorschaujahr",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2022Projekt>("k2F38ProjektVorschau")} />
            )
        },
        {
            key: "Feld06",
            name: "Anhänge",
            minWidth: 50,
            maxWidth: 65,
            isResizable: false,
            ariaLabel: "Anhänge",
            onRender: (item: Knz2GK2022Projekt) => {
                const attCount = this.props.Model.AppendLVIntoAttCounts(item);
                return <Knz2AttachmentControl Counts={attCount} />;
            }
        },
        {
            key: "Feld07",
            name: "STU-ID",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2022Projekt>("k2F14StuId")} />
            ),
            ariaLabel: "STU-ID"
        },
        {
            key: "Feld10",
            name: "STU-ID Anmerkungen",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2022Projekt>("k2F17StuAnmerkungen")} />
            ),
            ariaLabel: "STU-ID Anmerkungen"
        },
        {
            key: "Feld11",
            name: "Formblatt A ID",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2022Projekt>("k2F38FormAID")} />
            ),
            ariaLabel: "Formblatt A ID"
        },
        {
            key: "Feld12",
            name: "Aktive Maßnahmen Status",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2022Projekt>("k2F19FormAStatus")} />
            ),
            ariaLabel: "Aktive Maßnahmen Status"
        },
        {
            key: "Feld13",
            name: "Aktive Maßnahmen Fertigstellungsdatum",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan
                    Object={item}
                    PropertyName={nameof<Knz2GK2022Projekt>("k2F20FormADate")}
                    FormatFunction={Utils.FormatDate}
                />
            ),
            ariaLabel: "Aktive Maßnahmen Fertigstellungsdatum"
        },
        {
            key: "Feld14",
            name: "Formblatt P ID",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2022Projekt>("k2F38FormPID")} />
            ),
            ariaLabel: "Formblatt P ID"
        },
        {
            key: "Feld15",
            name: "Passive Maßnahmen Status",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2022Projekt>("k2F22FormPStatus")} />
            ),
            ariaLabel: "Passive Maßnahmen Status"
        },
        {
            key: "Feld16",
            name: "Passive Maßnahmen Fertigstellungsdatum",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan
                    Object={item}
                    PropertyName={nameof<Knz2GK2022Projekt>("k2F23FormPDate")}
                    FormatFunction={Utils.FormatDate}
                />
            ),
            ariaLabel: "Passive Maßnahmen Fertigstellungsdatum"
        },
        {
            key: "Feld17",
            name: "Formblatt OU ID",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2022Projekt>("k2F38FormOUID")} />
            ),
            ariaLabel: "Formblatt A ID"
        },
        {
            key: "Feld18",
            name: "OU Fertigstellungsdatum",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan
                    Object={item}
                    PropertyName={nameof<Knz2GK2022Projekt>("k2F20FormOUDate")}
                    FormatFunction={Utils.FormatDate}
                />
            ),
            ariaLabel: "OU Fertigstellungsdatum"
        },
        {
            key: "Feld19",
            name: "Datenerhebung LV Status",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: Knz2GK2022Projekt) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2022Projekt>("k2F24AbsStatus")} />
            ),
            ariaLabel: "Datenerhebung LV Status"
        }
    ];

    constructor(props) {
        super(props);
        this.listRef = React.createRef();
        this.state = {
            ListModel: new Knz2DetailList(this.props.Model, this.props.Filter)
        };
    }

    public async componentDidUpdate(prevProps): Promise<void> {
        if (!this.props.Filter.Equals(prevProps.Filter)) {
            this.setState({ ListModel: new Knz2DetailList(this.props.Model, this.props.Filter) });
        }
    }

    public async RefreshListItems(): Promise<void> {
        await this.listRef.current.RefreshItems();
    }

    public render(): JSX.Element {
        return (
            <div style={{ position: "relative", height: "80vh" }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <LargeDetailsList
                        Columns={this.columns}
                        DataSource={this.state.ListModel}
                        OnItemInvoked={(item) => {
                            this.props.OnEditProjekt(item);
                        }}
                        ref={this.listRef}
                    />
                </ScrollablePane>
            </div>
        );
    }
}
