import { saveAs } from "file-saver";
import { Pivot, PivotItem } from "office-ui-fabric-react";
import * as React from "react";
import {
    Knz4AbsenderDialog,
    Knz4LSCommandBar,
    Knz4LSDocCreateDialog,
    Knz4LSEditDialog,
    Knz4LSList,
    Knz4LSListGeneric,
    Knz4LSSearchBox
} from ".";
import { LskRepository } from "../../DAL/LskRepository";
import {
    EnumApprovalWorkflowStatus,
    EnumBerichtArt,
    EnumDocumentCreationType,
    EnumKnz4LSFreigabeStatus,
    Knz4LSIngBueroModel
} from "../../generated/BackendClient";
import { Bericht, BerichteRepository } from "../../Model/Berichte";
import {
    Knz4LSListModelFilter,
    Knz4LSObjekt,
    Knz4LSObjektListModelDeleted,
    Knz4LSObjektListModelOhneFreigabe,
    Knz4Repository
} from "../../Model/Knz4LS";
import { ChoiceDialog, HandleError, LoadingDialog } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@CatchReactErrors
export class Knz4LSContainer extends React.Component<
    {
        Model: Knz4Repository;
        BerichtModel: BerichteRepository;
    },
    {
        ItemInEditor: Knz4LSObjekt;
        SelectedItems: Knz4LSObjekt[];
        SelectedItemsToRestore: Knz4LSObjekt[];
        ShowEditDialog: boolean;
        ShowNewDialog: boolean;
        ShowAbsenderDialog: Knz4LSObjekt;
        ShowDocumentCreateDialog: Knz4LSObjekt;
        Filter: Knz4LSListModelFilter;
    }
> {
    public readonly state = {
        ItemInEditor: undefined,
        ShowEditDialog: false,
        ShowNewDialog: false,
        SelectedItems: [],
        SelectedItemsToRestore: [],
        ShowAbsenderDialog: undefined,
        ShowDocumentCreateDialog: undefined,
        Filter: new Knz4LSListModelFilter("")
    };
    private repository = LskRepository.Knz4LSObjekt;
    private listRef: React.RefObject<Knz4LSList> = React.createRef();
    private listRefUnapproved: React.RefObject<Knz4LSListGeneric> = React.createRef();
    private listRefDeletedItems: React.RefObject<Knz4LSListGeneric> = React.createRef();
    private loadingRef: React.RefObject<LoadingDialog> = React.createRef();
    private yesNoRef: React.RefObject<ChoiceDialog> = React.createRef();
    private berichteCollection: Map<string, Bericht> = null;
    
    public async componentDidMount(): Promise<void> {
        this.berichteCollection = await this.props.BerichtModel.GetBerichte(false);
    }

    private async CreateDocumentPruefprotokoll(item: Knz4LSObjekt): Promise<void> {
        await this.loadingRef.current.Execute(async () => {
            const fileResponse = await this.props.Model.ExportDocument(item, EnumDocumentCreationType.Pruefprotokoll);
            saveAs(fileResponse.data, fileResponse.fileName);
        }, "Word-Dokument wird erstellt");
    }

    public render(): JSX.Element {
        return (
            <>
                <LoadingDialog ref={this.loadingRef} />
                <ChoiceDialog ref={this.yesNoRef} />

                <HandleError>
                    {this.state.ShowNewDialog && (
                        <Knz4LSEditDialog
                            Model={this.props.Model}
                            InitialModel={this.state.ItemInEditor}
                            hideDialog={false}
                            closeDialog={async (result: boolean, newModel: Knz4LSObjekt): Promise<void> => {
                                if (result) {
                                    newModel.k4F39ImPapierkorb=false;
                                    newModel.k4F35HideDsvgo=false;
                                    
                                    const newItem = await this.repository.add(newModel);
                                    await this.listRef.current?.OnItemUpdated(newItem);
                                    await this.listRef.current?.RefreshListItems();
                                    await this.listRefUnapproved.current?.RefreshListItems();
                                    this.setState({ ShowDocumentCreateDialog: newItem });
                                }
                                this.setState({ ShowNewDialog: false });
                            }}
                        />
                    )}
                    {this.state.ShowEditDialog && (
                        <Knz4LSEditDialog
                            Model={this.props.Model}
                            InitialModel={this.state.ItemInEditor}
                            hideDialog={false}
                            closeDialog={async (result: boolean, newModel: Knz4LSObjekt) => {
                                if (result) {
                                    newModel = await this.repository.update(newModel);
                                    await this.listRef.current?.OnItemUpdated(newModel);
                                    await this.listRef.current?.RefreshListItems();
                                    await this.listRefUnapproved.current?.RefreshListItems();
                                }
                                this.setState({ ShowEditDialog: false });
                            }}
                        />
                    )}
                    {this.state.ShowAbsenderDialog && (
                        <Knz4AbsenderDialog
                            Model={this.props.Model}
                            SelectedItem={this.state.ShowAbsenderDialog}
                            CloseDialog={() => {
                                this.setState({ ShowAbsenderDialog: undefined });
                            }}
                        />
                    )}
                    {this.state.ShowDocumentCreateDialog && (
                        <Knz4LSDocCreateDialog
                            CloseDialog={() => {
                                this.setState({ ShowDocumentCreateDialog: undefined });
                            }}
                            ExportDocument={() => {
                                this.CreateDocumentPruefprotokoll(this.state.ShowDocumentCreateDialog);
                            }}
                            ExportRuecksendung={() => {
                                this.setState({ ShowAbsenderDialog: this.state.ShowDocumentCreateDialog });
                            }}
                        />
                    )}
                    <Knz4LSCommandBar
                        Model={this.props.Model}
                        OnNewClick={async () => {
                            await this.loadingRef.current.Execute(async () => {
                                const obj = new Knz4LSObjekt();
                                obj.k4F03Kennnummer = await this.props.Model.GetUniqueId();
                                obj.k4F38FreigabeStatus = EnumKnz4LSFreigabeStatus.Neu;
                                this.setState({ ItemInEditor: obj, ShowNewDialog: true });
                            }, "Einen Moment bitte...");
                        }}
                        ExportExcel={async () => {
                            await this.loadingRef.current.Execute(async () => {
                                const fileResponse = await this.props.Model.ExportListToExcel();
                                saveAs(fileResponse.data, fileResponse.fileName);
                            }, "Excel-Export wird erstellt");
                        }}
                        OnRemoveItemsClick={async () => {
                            await this.loadingRef.current.Execute(async (): Promise<void> => {
                                await this.UpdateItem(this.state.SelectedItems, true);
                                await this.listRef.current.RefreshListItems();
                            }, "Die Maßn. Lärmsanierung wird gelöscht.");
                        }}
                        OnRestoreItemsClick={async () => {
                            await this.loadingRef.current.Execute(async (): Promise<void> => {
                                await this.UpdateItem(this.state.SelectedItemsToRestore, false);
                                await this.listRefDeletedItems?.current?.RefreshListItems();
                            }, "Die Maßn. Lärmsanierung wird wiederhergestellt.");
                        }}
                        ExportDocument={() => {
                            this.CreateDocumentPruefprotokoll(this.state.SelectedItems[0]);
                        }}
                        EnableDocumentExport={this.state.SelectedItems?.length === 1}
                        EnableRemoveItemsButton={this.CanItemsBeRemoved()}
                        EnableRestoreItemsButton={this.state.SelectedItemsToRestore?.length > 0}
                        ShowAbsenderDialog={() => {
                            this.setState({ ShowAbsenderDialog: this.state.SelectedItems[0] });
                        }}
                    />
                    <Pivot>
                        <PivotItem headerText="Maßn. Lärmsanierung">
                            <br />
                            <Knz4LSSearchBox
                                Model={this.props.Model}
                                OnUpdateFilter={(filter: Knz4LSListModelFilter) => {
                                    this.setState({ Filter: filter });
                                }}
                                FilterText={this.state.Filter.text}
                            />
                            <Knz4LSList
                                ref={this.listRef}
                                Model={this.props.Model}
                                OnEditItem={(item) => {
                                    this.setState({ ItemInEditor: item, ShowEditDialog: true });
                                }}
                                OnSelectedItems={(items: any[]) => {
                                    this.setState({ SelectedItems: items });
                                }}
                                Filter={this.state.Filter}
                            />
                        </PivotItem>
                        <PivotItem headerText="Im Papierkorb">
                            <Knz4LSListGeneric
                                ref={this.listRefDeletedItems}
                                Model={this.props.Model}
                                ListModelGenerator={() => new Knz4LSObjektListModelDeleted(this.props.Model)}
                                OnSelectedItems={(items: any[]) => {
                                    this.setState({ SelectedItemsToRestore: items });
                                }}
                            />
                        </PivotItem>
                        <PivotItem headerText="Maßnahmen ohne Freigabe">
                            <Knz4LSListGeneric
                                ref={this.listRefUnapproved}
                                Model={this.props.Model}
                                ListModelGenerator={() => new Knz4LSObjektListModelOhneFreigabe(this.props.Model)}
                                OnEditItem={(item) => {
                                    this.setState({ ItemInEditor: item, ShowEditDialog: true });
                                }}
                                OnSelectedItems={(items: any[]) => {
                                    this.setState({ SelectedItems: items });
                                }}
                            />
                        </PivotItem>
                    </Pivot>
                </HandleError>
            </>
        );
    }

    private async UpdateItem(selectedItems: Knz4LSObjekt[], removeItem: boolean): Promise<void> {
        for (const item of selectedItems) {
            const knz4lSObjekt = await this.repository.get(item.objectId);
            knz4lSObjekt.k4F39ImPapierkorb = removeItem;
            const newModel = await this.repository.update(knz4lSObjekt);
            await this.listRef?.current?.OnItemUpdated(newModel);
        }
    }

    private CanItemsBeRemoved(): boolean {
        if (this.state.SelectedItems?.length > 0) {
            const itemsInReports = this.state.SelectedItems.filter(
                (li: Knz4LSObjekt) => li.k4F07BerichtRefs?.length > 0
            );
            const itemsFoundInPublishedReports = itemsInReports.filter((li: Knz4LSObjekt) => {
                const refIds = li.k4F07BerichtRefs
                    .filter((refId: any) => this.berichteCollection.has(refId))
                    .map((b) => b.objectId);
                return refIds.find(
                    (berichtId: string) =>
                        this.berichteCollection.get(berichtId).berichtWFStatus ===
                            EnumApprovalWorkflowStatus.Freigegeben &&
                        this.berichteCollection.get(berichtId).berichtArt === EnumBerichtArt.Jahresbericht
                );
            });
            const result = itemsFoundInPublishedReports.length === 0;
            return result;
        }
        return false;
    }
}
