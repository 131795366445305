import * as mobx from "mobx";
import { observer } from "mobx-react";
import { ComboBox, IComboBox, IComboBoxOption } from "office-ui-fabric-react";
import * as React from "react";
import { CatchReactErrors } from "../Error-Handler/Decorators";
import { EasyFormContext } from "./EasyFormContext";

@observer
@CatchReactErrors
export class EasyCombobox extends React.Component<
    {
        Object?: object;
        PropertyName: string;
        ComboBoxProps?: any; // IComboBoxProps,
        Options: string[] | number[] | IComboBoxOption[];
        MultiSelect?: boolean;
        AddEmptyOption?: boolean;
        OnChange?: (oldValue: string | string[] | number, newValue: string | string[] | number) => void;
    },
    {}
> {
    private get Object(): object {
        return this.props.Object || this.context.Object;
    }
    public static contextType = EasyFormContext;

    private static getText(options: IComboBoxOption[]): string {
        const selected: string[] = options
            .filter((opt) => {
                return opt.selected;
            })
            .map((opt) => {
                return opt.text;
            });
        return selected.join(", ");
    }

    public readonly state = {};
    public GetOptions(): IComboBoxOption[] {
        // convert string array to work with ComboBox control
        const options: IComboBoxOption[] = [];
        if (this.props.AddEmptyOption === true) {
            options.push({
                key: "",
                text: "keine Auswahl"
            });
        }

        for (const option of this.props.Options) {
            if (typeof option === "object") {
                options.push(option);
            } else {
                options.push({
                    key: option,
                    text: String(option)
                });
            }
        }

        // preselect options
        if (this.Object[this.props.PropertyName]) {
            if (this.props.MultiSelect) {
                for (const key of this.Object[this.props.PropertyName]) {
                    options
                        .filter((opt) => {
                            return opt.key === key;
                        })
                        .forEach((opt) => {
                            opt.selected = true;
                        });
                }
            } else {
                options
                    .filter((opt) => {
                        return opt.key === this.Object[this.props.PropertyName];
                    })
                    .forEach((opt) => {
                        opt.selected = true;
                    });
            }
        }

        return options;
    }

    public componentDidUpdate(prevProps): void {
        if (prevProps.Options !== this.props.Options) {
            // remove selected options that are no longer available
            let selected = this.Object[this.props.PropertyName];
            if (selected) {
                const validKeys = this.GetOptions().map((o) => o.key);
                if (Array.isArray(selected)) {
                    selected = selected.filter((o) => validKeys.includes(o));
                } else {
                    if (!validKeys.includes(selected)) {
                        selected = null;
                    }
                }

                this.UpdateValue(selected);
            }
        }
    }
    public render(): JSX.Element {
        const options = this.GetOptions();
        return (
            <ComboBox
                multiSelect={this.props.MultiSelect}
                selectedKey={this.Object[this.props.PropertyName]}
                allowFreeform={false}
                autoComplete="on"
                options={options}
                onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
                    this._onChange(option);
                }}
                text={EasyCombobox.getText(options)}
                disabled={this.context.Disabled}
                {...this.props.ComboBoxProps}
            />
        );
    }

    private _onChange(option?: IComboBoxOption): void {
        if (this.props.MultiSelect === true) {
            if (!option) {
                return;
            }
            let selected: (string|number)[] = this.Object[this.props.PropertyName] || [];

            if (option.selected) {
                selected.push(option.key);
            } else {
                selected = selected.filter((str) => {
                    return str !== option.key;
                });
            }
            selected.sort();

            this.UpdateValue(selected);
        } else {
            this.UpdateValue(option.key);
        }
    }

    private UpdateValue(newValue: any): void {
        const oldValue = this.Object[this.props.PropertyName];
        if (oldValue === newValue) {
            return;
        }
        mobx.runInAction(() => {
            this.Object[this.props.PropertyName] = newValue;
        });
        if (this.props.OnChange) {
            this.props.OnChange(oldValue, this.Object[this.props.PropertyName]);
        }
    }
}
