import { DefaultButton, PrimaryButton, Spinner, Stack } from "office-ui-fabric-react";
import * as React from "react";
import { EntityReferenceOfBaseUserModel, UsersClient } from "../../generated/BackendClient";
import { allMetadata } from "../../generated/RadRepository";
import { RadUserField } from "../../Rad/tsx/RadFields/Renderers/RadUserField";
import { Header } from "../Generic/Header";

type RoleMembersProps = {
    RoleName: string;
    OnClose: () => void;
};

export function RoleMembers({ RoleName, OnClose }: React.PropsWithChildren<RoleMembersProps>) {
    let [usersInRole, setUsersinRole] = React.useState<EntityReferenceOfBaseUserModel[]>([]);
    let [isLoading, setIsLoading] = React.useState(true);
    if (!allMetadata.entities.BaseUserModel.fields.roles.choices.includes(RoleName)) {
        throw new Error(`Ungültiger Rollenname: ${RoleName}`);
    }

    // initial item loading
    React.useEffect(() => {
        (async () => {
            setIsLoading(true);
            let wf = new UsersClient();
            let users = await wf.getUsersInRole(RoleName);
            setUsersinRole(
                users.map((u) => new EntityReferenceOfBaseUserModel({ objectId: u.objectId, legacyId: null }))
            );
            setIsLoading(false);
        })();
    }, [RoleName]);

    return (
        <>
            {isLoading ? (
                <Spinner
                    label="Einen Moment bitte..."
                    style={{ verticalAlign: "middle", height: "100%", marginTop: "100px" }}
                />
            ) : (
                <>
                    <Header Large>Benutzer in Rolle "{RoleName === "DB Netz Umweltschutz" ? "DB InfraGO AG, Umweltschutzmanagement": RoleName}"</Header>

                    <RadUserField Value={usersInRole} OnChange={(v) => setUsersinRole(v)} />
                    <Stack horizontal tokens={{ childrenGap: 20 }} style={{ marginTop: 40 }}>
                        <PrimaryButton
                            text="Speichern"
                            onClick={async () => {
                                setIsLoading(true);
                                let wf = new UsersClient();
                                await wf.setUsersInRole(usersInRole, RoleName);
                                OnClose();
                            }}
                        />
                        <DefaultButton text="Zurück" onClick={OnClose} />
                    </Stack>
                </>
            )}
        </>
    );
}
