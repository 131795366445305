import { observer } from "mobx-react";
import { ScrollablePane, ScrollbarVisibility, IColumn } from "office-ui-fabric-react";
import * as React from "react";
import { Knz4LSListGeneric } from ".";
import {
    Knz4LSDetailList,
    Knz4LSListModelFilter,
    Knz4LSObjekt,
    Knz4LSObjektListModel,
    Knz4Repository
} from "../../Model/Knz4LS";
import { LargeDetailsList } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";
import { LargeGroupedList2 } from "../Generic/LargeGroupedList2";
import { Knz4LSIngBueroModel } from "../../generated/BackendClient";

@observer
@CatchReactErrors
export class Knz4LSList extends React.Component<
    {
        Model: Knz4Repository;
        OnEditItem: (item: Knz4LSObjekt) => void;
        OnSelectedItems: (selectedItems: []) => void;
        Filter: Knz4LSListModelFilter;
    },
    {
        ListModel: Knz4LSObjektListModel;
        ListModelSearch: Knz4LSDetailList;
    }
> {
    private listRef: React.RefObject<LargeGroupedList2> = React.createRef();
    private detailslistRef: React.RefObject<LargeDetailsList> = React.createRef();
    private allIngBueros: Map<string, Knz4LSIngBueroModel> = null;

    public async componentDidMount(): Promise<void> {
        this.allIngBueros = await this.props.Model.GetFirmen();
    }
    
    public readonly state = {
        ListModel: new Knz4LSObjektListModel(this.props.Model),
        ListModelSearch: new Knz4LSDetailList(this.props.Model, this.props.Filter),
    };

    public async RefreshListItems(): Promise<void> {
        await this.listRef?.current?.RefreshItems();
        await this.detailslistRef?.current?.RefreshItems();
    }

    public async componentDidUpdate(prevProps): Promise<void> {
        if (!this.props.Filter.Equals(prevProps.Filter)) {
            if (this.props.Filter.text) {
                this.setState({ ListModelSearch: new Knz4LSDetailList(this.props.Model, this.props.Filter) });
            } else {
                this.setState({ ListModel: new Knz4LSObjektListModel(this.props.Model) });
            }
        }
    }

    public async OnItemUpdated(item: Knz4LSObjekt): Promise<void> {
        await this.listRef?.current?.OnItemUpdated(item);
    }
    
    public columns: IColumn[] = [...Knz4LSListGeneric.columns, ...[
        {
            key: "Feld08",
            name: "Ingenieurbüro",
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: Knz4LSObjekt) => {
                return (
                    item.k4F29IngRef !== null && this.allIngBueros.has(item.k4F29IngRef.objectId) && (
                        <div>
                            {this.allIngBueros.get(item.k4F29IngRef.objectId).k4F30IngName}
                        </div>
                    )
                );
            }
        },
    ]];

    public render(): JSX.Element {
        return (
            <div style={{ position: "relative", height: "80vh" }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    {!this.props.Filter.IsSet() ? (
                        <LargeGroupedList2
                            Columns={this.columns}
                            DataSource={this.state.ListModel}
                            OnItemInvoked={this.props.OnEditItem}
                            OnSelectedItems={this.props.OnSelectedItems}
                            ref={this.listRef}
                        />
                    ) : (
                        <LargeDetailsList
                            Columns={this.columns}
                            DataSource={this.state.ListModelSearch}
                            OnItemInvoked={this.props.OnEditItem}
                            OnSelectedItems={this.props.OnSelectedItems}
                            ref={this.detailslistRef}
                        />
                    )}
                </ScrollablePane>
            </div>
        );
    }
}
