import * as mobx from "mobx";
import { LskRepository } from "../../DAL/LskRepository";
import { EnumRegionalbereich, FileResponse, Knz4LVClient } from "../../generated/BackendClient";
import { EntityCache } from "../../Rad/DAL/EntityCache";
import { ArrayAsMap, WrapObjects } from "../../Rad/DAL/WrapperTools";
import { Utils } from "../../Utils";
import { Knz4LV } from "./";

export class Knz4LVRepository {
    public Dal = new Knz4LVClient();
    private Knz4LVCache: EntityCache<Knz4LV>;

    @mobx.observable
    public IsReadOnly = false;

    public constructor() {
        this.Knz4LVCache = new EntityCache(LskRepository.Knz4LVModel);
    }

    public async ExportListToExcel(): Promise<FileResponse> {
        return await this.Dal.exportExcel();
    }

    public async GetKnz4LVItems(showOnlyRecycleBinItems?: boolean): Promise<Map<string, Knz4LV>> {
        const allItems = await this.Knz4LVCache.GetAllMap();

        return Utils.MapFilter(allItems, (k, v) => v.k4LVF24ImPapierkorb === showOnlyRecycleBinItems);
    }

    public async Search(query: string): Promise<Map<string, Knz4LV>> {
        const result = await this.Dal.search(query);
        return ArrayAsMap(WrapObjects(result, Knz4LV));
    }

    /**
     * Get list of Massnahme by analyzing the choice field of the list
     */
    public async GetMassnahme(): Promise<string[]> {
        return ["Fenster", "Schalldämmlüfter", "Dachsanierung", "Verschiedenes / Sonstiges"];
    }

    public async CanWriteRegionalBereich(regionalBereichId: EnumRegionalbereich): Promise<boolean> {
        return await this.Dal.canWriteRegionalBereich(regionalBereichId);
    }

    public async UpdateKnz4LVItem(knz4LVItem: Knz4LV): Promise<Knz4LV> {
        let result = await this.Knz4LVCache.update(knz4LVItem);
        return result;
    }

    public async AddKnz4LVItem(knz4LVItem: Knz4LV): Promise<Knz4LV> {
        let result = await this.Knz4LVCache.add(knz4LVItem);
        return result;
    }

    public async CanEditItem(knz4LVItem: Knz4LV): Promise<boolean> {
        return this.Dal.canEditItem(knz4LVItem.objectId);
    }
}
