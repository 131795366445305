import * as mobx from "mobx";
import { WorkflowRepository } from ".";
import { WorkflowTask } from "../../generated/BackendClient";

export class TaskListModel {
    @mobx.computed public get IsLoading(): boolean {
        return this.RunningRequests > 0;
    }

    @mobx.observable public SelectedTask: WorkflowTask;
    @mobx.observable public AllTasks: WorkflowTask[];

    @mobx.observable private RunningRequests = 0;
    private Repository: WorkflowRepository;

    public constructor(repository: WorkflowRepository) {
        this.Repository = repository;
    }

    public async Refresh(): Promise<void> {
        mobx.runInAction(() => {
            this.RunningRequests++;
        });
        const tasks = await this.Repository.GetAllTasks();

        mobx.runInAction(() => {
            this.AllTasks = tasks;
            this.RunningRequests--;
        });
    }
}
