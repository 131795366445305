import * as mobx from "mobx";
import { Knz3DatenImport } from ".";
import { LskRepository } from "../../DAL/LskRepository";
import { EnumUserRole, FileParameter, Knz3Client, UserRepository } from "../../Rad/DAL";
import { EntityCache } from "../../Rad/DAL/EntityCache";
import { Utils } from "../../Utils";

export class Knz3Repository {
    @mobx.observable
    public IsReadOnly = true;
    public ItemCache: EntityCache<Knz3DatenImport>;

    public constructor() {
        this.ItemCache = new EntityCache(LskRepository.Knz3DatenImport);
        (async () => {
            const user = await UserRepository.GetCurrentUser();
            mobx.runInAction(() => {
                this.IsReadOnly =
                    !user.currentUser.isAdmin &&
                    !user.allowedScopes.some((g) =>
                        [EnumUserRole.Knz3Benutzer, EnumUserRole.FachlicherAdmin].includes(g)
                    );
            });
        })();
    }

    public async GetAllItems(showRecycleBinItems = false): Promise<Map<string, Knz3DatenImport>> {
        const items = await this.ItemCache.GetAllMap();

        return Utils.MapFilter(items, (k, v) => v.k3F06Knz3ImPapierkorb === showRecycleBinItems);
    }

    public async ValidateExcelFile(
        pspListe: string,
        pspListeLssp: string,
        excelFile: FileParameter
    ): Promise<string[]> {
        const wf = new Knz3Client();
        return await wf.validateExcelFile(pspListe, pspListeLssp, excelFile);
    }

    public async ImportData(knz3DatenImport: Knz3DatenImport, excelFile: FileParameter): Promise<void> {
        const wf = new Knz3Client();
        await wf.importSAPFile(knz3DatenImport.k3F01PSPList, knz3DatenImport.k3F02PSPListLSSP, excelFile);
        this.ItemCache.ForceUpdate();
    }

    public async UpdateData(knz3DatenImport: Knz3DatenImport): Promise<void> {
        await LskRepository.Knz3DatenImport.update(knz3DatenImport);

        this.ItemCache.ForceUpdate();
    }

    public async GetLastCreatedItem(): Promise<Knz3DatenImport> {
        const allItems = await this.GetAllItems();
        const sorted = [...allItems.values()].sort((a, b) => (a.created > b.created ? -1 : 1));
        if (sorted.length > 0) {
            return sorted[0];
        }
        return null;
    }
}
