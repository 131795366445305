import { IGroupedListFilter } from "../../../tsx/Generic";

export class Knz2ProjekteListModelFilter implements IGroupedListFilter {
    public text: string;
    constructor(text: string) {
        this.text = text?.length >= 3 ? text : "";
    }
    public Equals(other: Knz2ProjekteListModelFilter): boolean {
        if (!other) {
            return false;
        }
        return this.text === other.text;
    }
    public IsSet(): boolean {
        return !!this.text;
    }
}
