import { isObservableArray } from "mobx";
import * as React from "react";
import { BaseUserModel, EntityReferenceOfBaseUserModel } from "../../generated/BackendClient";
import { UserRepository } from "../../Rad/DAL/UserRepository";
import { EasyFormContext } from "./Forms/EasyFormContext";

export interface ShowUserProps {
    Object?: object;
    PropertyName: string;
    Style?: React.CSSProperties;
}

export function ShowUser({ Object, PropertyName, Style }: React.PropsWithChildren<ShowUserProps>) {
    const [resolvedUsers, setResolvedUsers] = React.useState<BaseUserModel[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    
    let context = React.useContext(EasyFormContext);
    let obj = Object || context.Object;
    let rawValue = obj[PropertyName];

    if (!rawValue) {
        return <>-</>;
    }

    let value: EntityReferenceOfBaseUserModel[] = [];
    if (!isObservableArray(rawValue) && !Array.isArray(rawValue)) {
        value = [rawValue];
    } else {
        value = rawValue;
    }

    // get users from user references
    React.useEffect(() => {
        let isCanceled = false;
        (async () => {
            // do not update state if component is no longer mounted
            if (isCanceled) return;

            setIsLoading(true);
            let resolved = await UserRepository.LookupUsers(value.map((u) => u.objectId));

            // do not update state if component is no longer mounted
            if (isCanceled) return;

            setResolvedUsers(Array.from(resolved.values()).filter((u) => !!u));
            setIsLoading(false);
        })();
        return () => {
            // this will be called when to components gets destroyed
            isCanceled = true;
        };
    }, []);

    if (isLoading) {
        return <>...</>;
    } else {
        return <span style={Style}>{resolvedUsers.map((u) => u.displayName).join()}</span>;
    }
}
