import * as mobx from "mobx";
import { Knz4LV, Knz4LVRepository } from "../../Model/Knz4LV";
import { IGroupedListDatasource, IGroupedListGroup } from "../../tsx/Generic";
import { Utils } from "../../Utils/Utils";

export class Knz4LVBereicheGrouped implements IGroupedListDatasource {
    @mobx.computed public get IsLoading(): boolean {
        return this.RunningRequests > 0;
    }

    @mobx.observable protected RunningRequests = 0;
    protected Model: Knz4LVRepository;

    protected CacheBereiche: Map<string, Knz4LV>;
    private includeRecycleBinItems: boolean;
    public constructor(model: Knz4LVRepository, includeRecycleBinItems?: boolean) {
        this.Model = model;
        this.includeRecycleBinItems = includeRecycleBinItems;
    }

    public OnItemUpdated(item: Knz4LV): Promise<void> {
        if (!this.CacheBereiche) {
            return;
        }

        this.CacheBereiche.set(item.objectId, item);
    }

    public async GetAllGroups(): Promise<IGroupedListGroup[]> {
        // make sure that cache is initialized
        if (!this.CacheBereiche) {
            try {
                mobx.runInAction(() => {
                    this.RunningRequests++;
                });

                this.CacheBereiche = await this.Model.GetKnz4LVItems(this.includeRecycleBinItems);
            } finally {
                mobx.runInAction(() => {
                    this.RunningRequests--;
                });
            }
        } else {
            const itemKeys = [];
            for (const [key, value] of this.CacheBereiche) {
                if (value.k4LVF24ImPapierkorb !== this.includeRecycleBinItems) {
                    itemKeys.push(key);
                }
            }
            itemKeys.forEach((itemKey: string) => {
                this.CacheBereiche.delete(itemKey);
            });
        }

        return this.FilteredGroups();
    }

    public async ExpandGroup(group: IGroupedListGroup): Promise<any[]> {
        return group.items || [];
    }

    protected async FilteredGroups(): Promise<IGroupedListGroup[]> {
        const allItems = Array.from(this.CacheBereiche.values());
        // group by Regionalbereich
        const groupedItems: Map<string, Knz4LV[]> = Utils.GroupBy(
            allItems,
            (row: Knz4LV) => row?.k4LVF32Regionalbereich
        );
        const groups: IGroupedListGroup[] = [];
        for (const key of Array.from(groupedItems.keys()).sort(Utils.SortAscending)) {
            groups.push({
                key: `group${key}`,
                name: key,
                data: key,
                items: groupedItems.get(key).sort((a, b) => a.CompareTo(b)),
                isExpanded: true
            });
        }

        return groups;
    }
}
