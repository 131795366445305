import * as mobx from "mobx";
import { AbstractDatenImportModel } from "../../generated/BackendClient";

export class DatenImport extends AbstractDatenImportModel {

    constructor(parent?: AbstractDatenImportModel) {
        super(parent);
    }

    /**
     * returns true if all required fields are filled
     */
    @mobx.computed
    public get IsValid(): boolean {
        return true;
    }

    public Clone(): DatenImport {
        return new DatenImport(this);
    }
}
