import { observer } from "mobx-react";
import { DatePicker, DayOfWeek, IDatePickerProps, IDatePickerStrings } from "office-ui-fabric-react";
import * as React from "react";
import { EasyFormContext } from "./EasyFormContext";

const DayPickerStrings: IDatePickerStrings = {
    months: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
    ],
    shortMonths: ["Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    shortDays: ["S", "M", "D", "M", "D", "F", "S"],
    goToToday: "Heute",
    prevMonthAriaLabel: "Nächster Monat",
    nextMonthAriaLabel: "Vorheriger Monat",
    prevYearAriaLabel: "Nächstes Jahr",
    nextYearAriaLabel: "Vorheriges Jahr",
    closeButtonAriaLabel: "Schließen"
};

@observer
export class EasyDatePicker extends React.Component<
    {
        Object?: object;
        PropertyName: string;
        DatePickerProps: IDatePickerProps;
        OnChange?: () => void;
    },
    {}
> {
    public static contextType = EasyFormContext;
    private get Object(): object {
        return this.props.Object || this.context.Object;
    }

    private static FormatDate(date: Date): string {
        if (!date) {
            return "";
        }

        // configure timestamp formatting
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
        return date.toLocaleDateString("de-DE", options);
    }

    public render(): JSX.Element {
        return (
            <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                strings={DayPickerStrings}
                formatDate={EasyDatePicker.FormatDate}
                showWeekNumbers={true}
                firstWeekOfYear={1}
                showMonthPickerAsOverlay={true}
                placeholder="Bitte ein Datum wählen"
                ariaLabel="Bitte ein Datum wählen"
                value={this.Object[this.props.PropertyName] || undefined}
                onSelectDate={(value: Date) => {
                    this.Object[this.props.PropertyName] = value;
                    if (this.props.OnChange) {
                        this.props.OnChange();
                    }
                }}
                allowTextInput={true}
                parseDateFromString={(value) => this._onParseDateFromString(value)}
                disabled={this.context.Disabled}
                {...this.props.DatePickerProps}
            />
        );
    }

    private _onParseDateFromString(value: string): Date {
        const date = (this.Object && this.Object[this.props.PropertyName]) || new Date();
        const values = (value || "").trim().split(".");
        const day = values.length > 0 ? Math.max(1, Math.min(31, parseInt(values[0], 10))) : date.getDate();
        const month = values.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : date.getMonth();
        let year = values.length > 2 ? parseInt(values[2], 10) : date.getFullYear();
        if (typeof year !== "number" || isNaN(year)) {
            year = date.getFullYear();
        }
        if (year < 100) {
            year += date.getFullYear() - (date.getFullYear() % 100);
        }
        return new Date(year, month, day);
    }
}
