import { Image, Stack, Text } from "office-ui-fabric-react";
import * as React from "react";
export interface WelcomeProps {}

export function Welcome({}: React.PropsWithChildren<WelcomeProps>) {
    return (
        <>
           
            <Stack>
                <Stack.Item align="center">
                    <Stack horizontal>
                        <Stack.Item align="center">
                            <Image src="/logo.svg" width={80} height={80} />
                        </Stack.Item>
                        <Stack.Item align="center">
                            <span style={{ fontFamily: "Zilla Slab", fontWeight: 400, fontSize: 80 }}>Flex</span>
                            <span style={{ fontFamily: "Zilla Slab", fontWeight: 700, fontSize: 80 }}>Point</span>
                        </Stack.Item>
                    </Stack>
                    <Stack.Item align="center">
                        <Text variant="small">Version: {VERSION}</Text>
                    </Stack.Item>
                </Stack.Item>
            </Stack>
        </>
    );
}
