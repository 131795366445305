import { IGroupedListFilter } from "../../tsx/Generic/LargeGroupedList";

export class Knz4LSListModelFilter implements IGroupedListFilter {
    public text: string;
    constructor(text: string) {
        this.text = text?? "";
    }
    public Equals(other: Knz4LSListModelFilter): boolean {
        if (!other) {
            return false;
        }
        return this.text === other.text;
    }
    public IsSet(): boolean {
        return !!this.text && this.text.length > 0;
    }
}
