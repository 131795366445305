import { observer } from "mobx-react";
import { IconButton } from "office-ui-fabric-react";
import * as React from "react";
import { EasyCombobox, EasyDatePicker, EasyNumberField } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz4LSBelegeRow extends React.Component<
    {
        Object: object;
        IsReadOnly: boolean;
        PropDatum: string;
        PropBetrag: string;
        PropMassnahme: string;
        OnChange: (index: number) => void;
        OnAdd: (index: number) => void;
        OnRemove: (index: number) => void;
        Index: number;
        AllowRemove: boolean;
        AllowAdd: boolean;
    },
    {}
> {
    public readonly state = {};

    public render() {
        return (
            <div className="ms-Grid-row" style={{ marginBottom: "3px" }}>
                <div className="ms-Grid-col ms-sm3">
                    <EasyDatePicker
                        Object={this.props.Object}
                        PropertyName={this.props.PropDatum}
                        DatePickerProps={{ disabled: this.props.IsReadOnly }}
                        OnChange={() => {
                            this.props.OnChange(this.props.Index);
                        }}
                    />
                </div>
                <div className="ms-Grid-col ms-sm3">
                    <EasyCombobox
                        Object={this.props.Object}
                        PropertyName={this.props.PropMassnahme}
                        ComboBoxProps={{ disabled: this.props.IsReadOnly }}
                        Options={["Fenster", "Schalldämmlüfter", "Dachsanierung", "Verschiedenes / Sonstiges"]}
                        OnChange={() => {
                            this.props.OnChange(this.props.Index);
                        }}
                    />
                </div>
                <div className="ms-Grid-col ms-sm3">
                    <EasyNumberField
                        Object={this.props.Object}
                        PropertyName={this.props.PropBetrag}
                        Decimals={2}
                        TextFieldProps={{ suffix: "€", disabled: this.props.IsReadOnly }}
                        OnChange={() => {
                            this.props.OnChange(this.props.Index);
                        }}
                    />
                </div>

                <div className="ms-Grid-col ms-sm1">
                    {this.props.AllowRemove && (
                        <IconButton
                            iconProps={{ iconName: "Remove" }}
                            title="Zeile löschen"
                            onClick={() => {
                                this.props.OnRemove(this.props.Index);
                            }}
                        />
                    )}
                </div>
                <div className="ms-Grid-col ms-sm1">
                    {this.props.AllowAdd && (
                        <IconButton
                            iconProps={{ iconName: "Add" }}
                            title="Zeile ergänzen"
                            onClick={() => {
                                this.props.OnAdd(this.props.Index);
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}
