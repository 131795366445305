import * as mobx from "mobx";
import { MessageBarType } from "office-ui-fabric-react";
import { Knz4LSMassnahme, Knz4LSModel } from "../../generated/BackendClient";
import { IValidationMessage } from "../../tsx/Generic";
import { KpnValidate } from "../../Utils";

export enum EnumBearbeitungsstatus {
    verzichtet = "verzichtet",
    inUmsetzung = "in Umsetzung",
    fertiggestellt = "fertiggestellt"
}

export class Knz4LSObjekt extends Knz4LSModel {
    constructor(parent?: Knz4LSObjekt) {
        super(parent);
    }

    @mobx.computed
    public get IsValid(): boolean {
        if (
            this.k4F01Kurzprojektnummer &&
            this.k4F03Kennnummer &&
            this.k4F06AnzahlWohnungseinheiten &&
            this.k4F13ObjBundesland &&
            this.k4F29IngRef &&
            this.k4F14EigNachname
        ) {
            return true;
        }
        return false;
    }

    @mobx.computed
    public get ValidationMessages(): IValidationMessage[] {
        if (this.k4F01Kurzprojektnummer && !this.k4F01Kurzprojektnummer.match(/^\d\d\.\d\d\d$/)) {
            return [
                {
                    Text: 'Die KPN muss im Format "99.999" angegeben werden.',
                    Type: MessageBarType.error
                }
            ];
        }

        if (this.k4F05Bearbeitungsstatus && this.k4F03KontierungsbelegDate) {
            if (
                [EnumBearbeitungsstatus.verzichtet, EnumBearbeitungsstatus.inUmsetzung].includes(
                    this.k4F05Bearbeitungsstatus as EnumBearbeitungsstatus
                )
            ) {
                return [
                    {
                        Text:
                            'Die Statuskombination von "Datum Kontierungsbeleg" und "Bearbeitungsstatus" ist ungültig',
                        Type: MessageBarType.error
                    }
                ];
            }
        }

        if (this.k4F01Kurzprojektnummer && this.k4F13ObjBundesland) {
            if (!KpnValidate.ValidateKpn(this.k4F01Kurzprojektnummer.substr(0, 2), this.k4F13ObjBundesland)) {
                return [
                    {
                        Text: "Die Kombination aus Bundesland und KPN ist ungültig",
                        Type: MessageBarType.error
                    }
                ];
            }
        }

        return [];
    };

    /**
     * returns true if all required fields are filled
     */

    public CopyObjektToEigentuemer(): void {
        this.k4F16EigStrasse = this.k4F07ObjStrasse;
        this.k4F17EigNr = this.k4F09ObjNr;
        this.k4F18EigErg = this.k4F10ObjErg;
        this.k4F19EigPlz = this.k4F11ObjPlz;
        this.k4F20EigOrt = this.k4F12ObjOrt;
    }

    public Clone(): Knz4LSObjekt {
        return new Knz4LSObjekt(this);
    }

    public SortMassnahmen(): void {
        const zeilen: Knz4LSMassnahme[] = this.k4F25Massnahmen?.slice() || [];

        // add rows to start of array
        zeilen.unshift(new Knz4LSMassnahme({
            datum: this.k4F03KontierungsbelegDate,
            betrag: this.k4F25Foerderbetrag,
            massnahme: this.k4F25Massnahme
        }));

        zeilen.sort((a, b) => {
            if (a === null || a === undefined) {
                return -1;
            }
            return a > b ? 1 : -1;
        });

        mobx.runInAction(() => {
            this.k4F03KontierungsbelegDate = zeilen[0].datum;
            this.k4F25Foerderbetrag = zeilen[0].betrag;
            this.k4F25Massnahme = zeilen[0].massnahme;

            this.k4F25Massnahmen = zeilen.slice(1);
        });
    }
}
