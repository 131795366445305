import { observer } from "mobx-react";
import { MessageBar, MessageBarType } from "office-ui-fabric-react/lib/MessageBar";
import * as React from "react";

@observer
export class EasyContainer extends React.Component<
    {
        HideFooter?: boolean;
    },
    {}
> {
    public render() {
        return (
            <>
                {this.props.children}
                {!this.props.HideFooter && (
                    <>
                        <br />
                        <MessageBar messageBarType={MessageBarType.info}>
                            <span>
                                <b>* Pflichtfeld</b>
                            </span>
                        </MessageBar>
                    </>
                )}
            </>
        );
    }
}
