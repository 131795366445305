import { observer } from "mobx-react";
import { DefaultButton, DialogFooter, Label, MessageBarType, PrimaryButton, Text } from "office-ui-fabric-react";
import * as React from "react";
import { FileParameter } from "../../../generated/BackendClient";
import { RadRepository } from "../../../generated/RadRepository";
import { Knz3DatenImport, Knz3Repository } from "../../../Model/Knz3";
import { nameof } from "../../../Utils";
import {
    DBDialog,
    EasyForm,
    EasyTextField,
    FileUploader,
    FolderView,
    IValidationMessage,
    LoadingDialog,
    ValidationMessages
} from "../../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../../Generic/Error-Handler/Decorators";

@CatchReactErrors
@observer
export class Knz3DatenImportDialog extends React.Component<
    {
        Model: Knz3Repository;
        closeDialog: () => void;
        readOnly: boolean;
        InitialItem: Knz3DatenImport;
    },
    {
        CurrentItem: Knz3DatenImport;
        ColumnsNotValidation: string[];
        SelectedFile: FileParameter;
        IsFileSelected: boolean;
        IsFileValidated: boolean;
    }
> {
    private loadingRef: React.RefObject<LoadingDialog> = React.createRef();
    public readonly state = {
        ColumnsNotValidation: [],
        IsFileSelected: false,
        IsFileValidated: false,
        SelectedFile: undefined,
        CurrentItem: undefined
    };

    public async componentDidMount() {
        const currentItem = this.props.InitialItem || new Knz3DatenImport();
        if (!currentItem.k3F01PSPList) {
            const lastItem = await this.props.Model.GetLastCreatedItem();
            currentItem.k3F01PSPList = lastItem?.k3F01PSPList;
            currentItem.k3F02PSPListLSSP = lastItem?.k3F02PSPListLSSP;
        }

        this.setState({ CurrentItem: currentItem });
    }

    public render(): JSX.Element {
        if (!this.state.CurrentItem) return null;
        return (
            <DBDialog
                onDismiss={() => {
                    this.ExitDialog(false);
                }}
                dialogContentProps={{
                    title: "Knz3 Datenimport Tool"
                }}
                minWidth="760px"
            >
                <LoadingDialog ref={this.loadingRef} />
                <EasyForm Object={this.state.CurrentItem} Disabled={this.props.readOnly} ShowFooter={true}>
                    <ValidationMessages Messages={this.showFileValidationMsg()} />
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6">
                                <EasyTextField
                                    PropertyName={nameof<Knz3DatenImport>("k3F01PSPList")}
                                    TextFieldProps={{
                                        label: "PSP-Liste (Konventionell LS)",
                                        required: true,
                                        multiline: true,
                                        rows: 10
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm6">
                                <Label>Beschreibung</Label>
                                <Text>
                                    Geben Sie eine Liste von PSP-Nummern ein, die der Lärmsanierung zugeordnet sind:
                                    beginnt ein Eintrag der Spalte &quot;projektnr&quot; mit einer der hinterlegten
                                    PSPs, gilt die Maßnahme als Lärmsanierung.
                                    <br />
                                    Trennen Sie die PSP-Nummern mit Zeilenumbrüchen (eine PSP pro Zeile).
                                </Text>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6">
                                <EasyTextField
                                    PropertyName={nameof<Knz3DatenImport>("k3F02PSPListLSSP")}
                                    TextFieldProps={{
                                        label: "PSP-Liste Sonderprogramme",
                                        required: true,
                                        multiline: true,
                                        rows: 10
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm6">
                                <Label>Beschreibung</Label>
                                <Text>
                                Geben Sie eine Liste von PSP-Nummern ein, die den Sonderprogrammen zugeordnet sind: beginnt ein Eintrag der Spalte "Projektnr" mit einer der hinterlegten PSPs, gilt die Maßnahme als Sonderprogramme.
                                    <br />
                                    Trennen Sie die PSP-Nummern mit Zeilenumbrüchen (eine PSP pro Zeile).
                                </Text>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12">
                                {this.props.readOnly ? (
                                    <FolderView
                                        Repository={RadRepository.Knz3DatenImportModel}
                                        ParentObjectId={this.state.CurrentItem.objectId as string}
                                        Disabled={true}
                                        Label="Importierte Datei"
                                        Compact={false}
                                    />
                                ) : (
                                    <FileUploader
                                        Label="Import Knz3 Datei"
                                        OnUploaded={(newFile) => {
                                            this.setState({
                                                SelectedFile: newFile,
                                                IsFileValidated: false,
                                                ColumnsNotValidation: []
                                            });
                                        }}
                                        FileObject={this.state.SelectedFile}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <DialogFooter>
                        {this.props.readOnly ? (
                            <>
                                <PrimaryButton
                                    onClick={() => {
                                        this.ExitDialog(false);
                                    }}
                                    text="Schließen"
                                />
                            </>
                        ) : (
                            <>
                                <PrimaryButton
                                    onClick={() => {
                                        this.StartValidatingFile();
                                    }}
                                    text="Validierung starten"
                                    disabled={
                                        !this.state.CurrentItem.IsValid ||
                                        this.state.SelectedFile === undefined ||
                                        this.state.IsFileValidated
                                    }
                                />
                                <PrimaryButton
                                    onClick={() => {
                                        this.ExitDialog(true);
                                    }}
                                    text="Datei hochladen"
                                    disabled={!this.state.IsFileValidated}
                                />
                                <DefaultButton
                                    onClick={() => {
                                        this.ExitDialog(false);
                                    }}
                                    text="Abbrechen"
                                />
                            </>
                        )}
                    </DialogFooter>
                </EasyForm>
            </DBDialog>
        );
    }

    @CatchReactErrorsMethod()
    private async ExitDialog(shouldSave: boolean) {
        if (shouldSave) {
            if (!this.state.CurrentItem.IsValid) {
                return;
            }
            await this.loadingRef.current.Execute(async (): Promise<void> => {
                await this.props.Model.ImportData(this.state.CurrentItem, this.state.SelectedFile);
            }, "Der Knz3-Datenimport wird durchgeführt.");
        }
        this.props.closeDialog();
    }

    private showFileValidationMsg(): IValidationMessage[] {
        if (this.state.SelectedFile && this.state.ColumnsNotValidation.length > 0) {
            return [
                {
                    Text: "Die Datei kann nicht validiert werden:\n" + this.state.ColumnsNotValidation.join("\n"),
                    Type: MessageBarType.error
                }
            ];
        } else if (
            this.state.IsFileValidated &&
            this.state.SelectedFile &&
            this.state.ColumnsNotValidation.length === 0
        ) {
            return [
                {
                    Text: "Die Datei wurde erfolgreich validiert.",
                    Type: MessageBarType.success
                }
            ];
        }
        return [];
    }

    private async StartValidatingFile() {
        this.loadingRef.current.Execute(async (): Promise<void> => {
            const validationResults = await this.props.Model.ValidateExcelFile(
                this.state.CurrentItem.k3F01PSPList,
                this.state.CurrentItem.k3F02PSPListLSSP,
                this.state.SelectedFile
            );
            this.setState({
                ColumnsNotValidation: validationResults,
                IsFileValidated: validationResults.length === 0
            });
        }, "Der Knz3-Datenimport wird validiert.");
    }
}
