import { observer } from "mobx-react";
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from "office-ui-fabric-react";
import * as React from "react";
import { Link } from "react-router-dom";
import { TaskListModel } from "../../Model/Workflows";
import { WorkflowTask } from "../../generated/BackendClient";
import { nameof, Utils } from "../../Utils";
import { PleaseWait, ShowUser } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class TaskList extends React.Component<
    {
        Model: TaskListModel;
    },
    {}
> {
    public readonly state = {};

    private columns: IColumn[] = [
        {
            key: "col1",
            name: "Name",
            fieldName: "Name",
            minWidth: 400,
            isResizable: true,
            onRender: (item: WorkflowTask) => <Link to={`/Tasks/${item.id}`}>{item.title}</Link>
        },
        {
            key: "col2",
            name: "Erstellt am",
            minWidth: 160,
            isResizable: true,
            onRender: (item: WorkflowTask) => <>{Utils.FormatDateTime(item.created)}</>
        },
        {
            key: "col3",
            name: "Zugewiesen an",
            minWidth: 200,
            isResizable: true,
            onRender: (item: WorkflowTask) => <ShowUser Object={item}  PropertyName={nameof<WorkflowTask>("assignedTo")}/>
        }
    ];

    public render() {
        return (
            <PleaseWait
                ShowSpinner={this.props.Model.IsLoading || !this.props.Model.AllTasks}
                render={() => (
                    <DetailsList
                        items={this.props.Model.AllTasks}
                        columns={this.columns}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        compact={true}
                        selectionMode={SelectionMode.none}
                    />
                )}
            />
        );
    }
}
