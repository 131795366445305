import { mergeStyleSets } from "@uifabric/merge-styles";
import { memoizeFunction } from "@uifabric/utilities";

export interface IEasyCheckboxClassNames {
    label: string;
    required: string;
}

export const getClassNames = memoizeFunction(
    (): IEasyCheckboxClassNames => {
        return mergeStyleSets({
            label: {
                color: "rgb(51, 51, 51)",
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "4px"
            },
            required: {
                color: "rgb(164, 38, 44)"
            }
        });
    }
);
