import { observer } from "mobx-react";
import { DefaultButton, Label, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { allMetadata, RadRepository } from "../../../generated/RadRepository";
import { Knz2GK2022Projekt } from "../../../Model/Knz2";
import {
    EntityReferenceOfKnz2GK2022ProjektModel,
    EnumApprovalWorkflowStatus,
    EnumFormblattArt,
    Knz2GK2022FormblattModel
} from "../../../Rad/DAL";
import { nameof, Utils } from "../../../Utils";
import { EasyForm, FolderView, Header, LoadingDialog, ObserverSpan, PleaseWait, ShowUser } from "../../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../../Generic/Error-Handler/Decorators";
import { EasyFormContext } from "../../Generic/Forms/EasyFormContext";
import { WorkflowHistoryModal, WorkflowStatus } from "../../Generic/Workflows";
import { Knz2FormblattAStartDialog, Knz2FormblattOUStartDialog, Knz2FormblattPStartDialog } from "../Workflow";

@observer
@CatchReactErrors
export class Knz2FormblattStatus extends React.Component<
    {
        Projekt: Knz2GK2022Projekt;
        Type: EnumFormblattArt;
        Disabled?: boolean;
        OnFreigabeStart: (model: any) => Promise<void>;
        FormblattDatum: Date;
    },
    { IsLoading: boolean; Formblatt: Knz2GK2022FormblattModel; showHistory: boolean; showStartDialog: boolean }
> {
    public static contextType = EasyFormContext;
    public readonly state = { IsLoading: true, Formblatt: undefined, showHistory: false, showStartDialog: false };
    private loadingRef: React.RefObject<LoadingDialog> = React.createRef();

    public async componentDidMount(): Promise<void> {
        await this.Refresh();
        this.setState({ IsLoading: false });
    }

    @CatchReactErrorsMethod()
    private async OnStartFreigabe(shouldSave, model): Promise<void> {
        this.setState({ showStartDialog: false });
        if (shouldSave) {
            this.loadingRef.current.Execute(async () => {
                await this.props.OnFreigabeStart(model);
                await this.Refresh();
            }, "Freigabe starten");
        }
    }

    public render(): JSX.Element {
        const disabled = (this.props.Disabled !== undefined && this.props.Disabled) || this.context.Disabled;

        return (
            <>
                <LoadingDialog ref={this.loadingRef} />
                {this.state.showStartDialog && this.props.Type === EnumFormblattArt.Aktiv && (
                    <Knz2FormblattAStartDialog
                        CloseDialog={(shouldSave, model) => this.OnStartFreigabe(shouldSave, model)}
                    />
                )}
                {this.state.showStartDialog && this.props.Type === EnumFormblattArt.Passiv && (
                    <Knz2FormblattPStartDialog
                        CloseDialog={(shouldSave, model) => this.OnStartFreigabe(shouldSave, model)}
                    />
                )}
                {this.state.showStartDialog && this.props.Type === EnumFormblattArt.OhneUmsetzung && (
                    <Knz2FormblattOUStartDialog
                        CloseDialog={(shouldSave, model) => this.OnStartFreigabe(shouldSave, model)}
                    />
                )}
                {this.state.showHistory && (
                    <WorkflowHistoryModal
                        Repository={RadRepository.Knz2GK2022FormblattModel}
                        ParentObject={this.state.Formblatt}
                        OnClose={() => {
                            this.setState({ showHistory: false });
                        }}
                    />
                )}
                <PleaseWait
                    ShowSpinner={this.state.IsLoading}
                    render={() => (
                        <>
                            <EasyForm Object={this.state.Formblatt} Disabled={true}>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12">
                                        <Header>Freigabe</Header>
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm2">
                                        <Label>Status</Label>
                                        <ObserverSpan
                                            PropertyName={nameof<Knz2GK2022FormblattModel>("knz2FormblattWFStatus")}
                                            FormatFunction={(i) =>
                                                Utils.ApplyMapping(
                                                    i,
                                                    allMetadata.enumMapping[
                                                        "DB.LSK.Model.Enums+EnumApprovalWorkflowStatus"
                                                    ]
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm3">
                                        <Label>Freigegeben durch</Label>
                                        <ShowUser
                                            PropertyName={nameof<Knz2GK2022FormblattModel>(
                                                "knz2FormblattWFFreigegebenUser"
                                            )}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm3">
                                        <Label>Freigegeben am</Label>
                                        <ObserverSpan
                                            PropertyName={nameof<Knz2GK2022FormblattModel>(
                                                "knz2FormblattWFFreigegebenAm"
                                            )}
                                            FormatFunction={Utils.FormatDateTime}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm4">
                                        <Label>Workflow-Status</Label>
                                        <WorkflowStatus Item={this.state.Formblatt} />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12">
                                        <FolderView
                                            Repository={RadRepository.Knz2GK2022ProjektModel}
                                            FolderName={(() => {
                                                switch (this.props.Type) {
                                                    case EnumFormblattArt.Passiv:
                                                        return "P";
                                                    case EnumFormblattArt.Aktiv:
                                                        return "A";
                                                    case EnumFormblattArt.OhneUmsetzung:
                                                        return "OU";
                                                    default:
                                                        throw new Error(`Unbekannter Formblatt-Typ ${this.props.Type}`);
                                                }
                                            })()}
                                            ParentObjectId={this.props.Projekt.objectId}
                                            Disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm5">
                                        {this.IsApproved ? (
                                            <DefaultButton
                                                onClick={() => {
                                                    this.setState({ showStartDialog: true });
                                                }}
                                                text="Formblatt neu erzeugen und freigeben"
                                                disabled={!this.CanStartWorkflow || disabled}
                                            />
                                        ) : (
                                            <PrimaryButton
                                                onClick={() => {
                                                    this.setState({ showStartDialog: true });
                                                }}
                                                text="Formblatt erzeugen und freigeben"
                                                disabled={!this.CanStartWorkflow || disabled}
                                            />
                                        )}
                                    </div>
                                    <div className="ms-Grid-col ms-sm4">
                                        <DefaultButton
                                            onClick={() => {
                                                this.setState({ showHistory: true });
                                            }}
                                            text="Freigabehistorie"
                                        />
                                    </div>
                                </div>
                            </EasyForm>
                        </>
                    )}
                />
            </>
        );
    }

    private get IsApproved(): boolean {
        return this.state.Formblatt?.knz2FormblattWFStatus === EnumApprovalWorkflowStatus.Freigegeben;
    }

    private get CanStartWorkflow(): boolean {
        if (!this.props.FormblattDatum) {
            return false;
        }
        const fb: Knz2GK2022FormblattModel = this.state.Formblatt;
        if (
            !fb.knz2FormblattWFStatus ||
            [
                EnumApprovalWorkflowStatus.Bereit,
                EnumApprovalWorkflowStatus.Abgelehnt,
                EnumApprovalWorkflowStatus.Freigegeben
            ].includes(fb.knz2FormblattWFStatus)
        ) {
            return true;
        }
        return false;
    }

    private async Refresh(): Promise<void> {
        let allFbs = await RadRepository.Knz2GK2022FormblattModel.getView([this.props.Projekt.objectId], 0, 0);
        let fb = allFbs.filter((f) => f.formblattArt === this.props.Type)[0];
        if (!fb) {
            fb = new Knz2GK2022FormblattModel();
            fb.formblattArt = this.props.Type;
            fb.refProjekt = new EntityReferenceOfKnz2GK2022ProjektModel({
                objectId: this.props.Projekt.objectId,
                legacyId: undefined
            });
        }
        this.setState({ Formblatt: fb, IsLoading: false });
    }
}
