import { observer } from "mobx-react";
import * as React from "react";
import { EasyFormContext } from "./Forms/EasyFormContext";

@observer
export class ObserverSpan extends React.Component<
    {
        Object?: object;
        PropertyName: string;
        FormatFunction?: (object) => string;
        Style?: React.CSSProperties;
        Suffix?: string;
    },
    {}
> {
    public static contextType = EasyFormContext;
    private get Object(): object {
        return this.props.Object || this.context.Object;
    }

    public render(): JSX.Element {
        let value = this.Object[this.props.PropertyName];

        if (this.props.FormatFunction) {
            value = this.props.FormatFunction(value);
        }

        if (value !== null && value !== undefined && value != "" && this.props.Suffix) {
            value += this.props.Suffix;
        }

        return <span style={this.props.Style}>{value}</span>;
    }
}
