import { observer } from "mobx-react";
import { Icon } from "office-ui-fabric-react";
import * as React from "react";
import { ItemWithWorkflow } from "../../../Model/Workflows/ItemWithWorkflow";
import { EnumWorkflowStatusStype } from "../../../generated/BackendClient";
import { getClassNames } from "./WorkflowStatus.classNames";

@observer
export class WorkflowStatus extends React.Component<
    {
        Item: ItemWithWorkflow;
    },
    {}
> {
    private static mapping = new Map<EnumWorkflowStatusStype, [string, string]>([
        [EnumWorkflowStatusStype.Initializing, ["Clock", "#999"]],
        [EnumWorkflowStatusStype.Running, ["CaretSolidRight", "#438"]],
        [EnumWorkflowStatusStype.Waiting, ["UserPause", "#438"]],
        [EnumWorkflowStatusStype.Error, ["Error", "#b22"]],
        [EnumWorkflowStatusStype.Canceled, ["Error", "#b22"]],
        [EnumWorkflowStatusStype.Finished, ["StopSolid", "#666"]]
    ]);

    public render(): JSX.Element {
        const { bubble } = getClassNames();
        if (!this.props.Item.workflowInfo) {
            return null;
        }
        const style =
            WorkflowStatus.mapping.get(this.props.Item.workflowInfo.statusType) ||
            WorkflowStatus.mapping.get(EnumWorkflowStatusStype.Error);
        return (
            <div className={bubble} style={{ backgroundColor: style[1] }}>
                <Icon iconName={style[0]} /> {this.props.Item.workflowInfo.text}
            </div>
        );
    }
}
