import { saveAs } from "file-saver";
import { observer } from "mobx-react";
import { Pivot, PivotItem, PivotLinkSize } from "office-ui-fabric-react";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
    Knz2CommandBar,
    Knz2DivideBereichDialog,
    Knz2EditBereichDialog,
    Knz2FilterControl,
    Knz2UpdateStatusDialog
} from ".";
import {
    Knz2BereicheGroupedByAbschnittList,
    Knz2BereicheGroupedByKPN,
    Knz2BereichListModelFilter,
    Knz2BereichStatusAnlage1,
    Knz2BereichStatusAnlage3,
    Knz2GK2022Abschnitt,
    Knz2GK2022Bereich,
    Knz2Repository
} from "../../../Model/Knz2";
import { EnumBereichStatus } from "../../../Rad/DAL";
import { Utils } from "../../../Utils";
import { ChoiceDialog, HandleError, IGroupedListDatasource, LoadingDialog, PleaseWait } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";
import { EnumColumnType, Knz2BereicheList } from "../Common";

enum EnumListModelType {
    Init = "-1",
    Anlage1 = "Anlage1",
    Anlage3 = "Anlage3",
    Ausgeblendet = "Ausgeblendet"
}

@observer
@CatchReactErrors
class Knz2Container extends React.Component<
    RouteComponentProps & {
        Model: Knz2Repository;
    },
    {
        Bereich: Knz2GK2022Bereich;
        ShowEditDialog: boolean;
        ShowNewDialog: boolean;
        ShowStatusUpdateDialog: boolean;
        ShowDivideBereichDialog: boolean;
        Filter: Knz2BereichListModelFilter;
        SelectedBereiche: Knz2GK2022Bereich[];
        CurrentListModel: IGroupedListDatasource;
        CurrentListModelType: EnumListModelType;
    }
> {
    public readonly state = {
        Bereich: undefined,
        ShowEditDialog: false,
        ShowNewDialog: false,
        ShowStatusUpdateDialog: false,
        ShowDivideBereichDialog: false,
        Filter: new Knz2BereichListModelFilter("", null),
        SelectedBereiche: [],
        CurrentListModel: undefined,
        CurrentListModelType: EnumListModelType.Init
    };
    private listRef: React.RefObject<Knz2BereicheList> = React.createRef();
    private yesNoRef: React.RefObject<ChoiceDialog> = React.createRef();
    private loadingRef: React.RefObject<LoadingDialog> = React.createRef();

    public render(): JSX.Element {
        return (
            <>
                <LoadingDialog ref={this.loadingRef} />
                <ChoiceDialog ref={this.yesNoRef} />
                <HandleError>
                    {this.state.ShowEditDialog && (
                        <Knz2EditBereichDialog
                            Model={this.props.Model}
                            InitialModel={this.state.Bereich}
                            closeDialog={async (shouldSave: boolean, newModel: Knz2GK2022Bereich) => {
                                if (shouldSave) {
                                    newModel = await this.props.Model.SaveBereich(newModel);
                                    this.state.CurrentListModel.OnItemUpdated(newModel);
                                }
                                this.setState({ ShowEditDialog: false });
                                this.listRef.current.RefreshGroups();
                            }}
                        />
                    )}
                    {this.state.ShowNewDialog && (
                        <Knz2EditBereichDialog
                            Model={this.props.Model}
                            InitialModel={this.props.Model.GetInitialBereich()}
                            closeDialog={async (shouldSave: boolean, newModel: Knz2GK2022Bereich) => {
                                if (shouldSave) {
                                    newModel = await this.props.Model.AddBereich(newModel);
                                    this.state.CurrentListModel.OnItemUpdated(newModel);
                                }
                                this.setState({ ShowNewDialog: false });
                                this.listRef.current.RefreshGroups();
                            }}
                        />
                    )}
                    {this.state.ShowStatusUpdateDialog && (
                        <Knz2UpdateStatusDialog
                            Model={this.props.Model}
                            Items={this.state.SelectedBereiche}
                            CloseDialog={() => {
                                this.setState({ ShowStatusUpdateDialog: false });
                                this.listRef.current.RefreshGroups();
                            }}
                        />
                    )}
                    {this.state.ShowDivideBereichDialog && (
                        <Knz2DivideBereichDialog
                            Model={this.props.Model}
                            Item={this.state.SelectedBereiche[0]}
                            CloseDialog={(
                                result: boolean,
                                newModel1: Knz2GK2022Bereich,
                                newModel2: Knz2GK2022Bereich
                            ) => {
                                this.setState({ ShowDivideBereichDialog: false });
                                if (result) {
                                    this.state.CurrentListModel.OnItemUpdated(this.state.SelectedBereiche[0]);
                                    this.state.CurrentListModel.OnItemUpdated(newModel1);
                                    this.state.CurrentListModel.OnItemUpdated(newModel2);
                                    this.listRef.current.RefreshGroups();
                                }
                            }}
                        />
                    )}
                    <Knz2CommandBar
                        Model={this.props.Model}
                        OnClickNewBereich={async () => {
                            const result = await this.yesNoRef.current.ExecuteYesNo(
                                "Warnung",
                                <>
                                    Mit diesem Schritt wird ein neuer Lärmsanierungsbereich in Anlage 3 des GK angelegt.
                                    <br />
                                    Ist das mit dem BMVI <b>abgestimmt</b> und <b>genehmigt?</b>
                                </>,
                                true
                            );
                            if (result) {
                                this.setState({ ShowNewDialog: true });
                            }
                        }}
                        OnClickManageAbschnitte={async () => {
                            const result = await this.yesNoRef.current.ExecuteYesNo(
                                "Warnung",
                                <>
                                    Mit diesem Schritt können neue Abschnitte in Anlage 3 des GK angelegt werden.
                                    <br />
                                    Ist das mit dem BMVI <b>abgestimmt</b> und <b>genehmigt?</b>
                                </>,
                                true
                            );

                            if (result) {
                                this.props.history.push("/Knz2Abschnitte");
                            }
                        }}
                        UpdateStatusAvailable={this.state.SelectedBereiche && this.state.SelectedBereiche.length > 0}
                        DivideBereichAvailable={
                            this.state.SelectedBereiche &&
                            this.state.SelectedBereiche.length === 1 &&
                            [EnumBereichStatus.InBearbeitung, ...Knz2BereichStatusAnlage3].includes(
                                (this.state.SelectedBereiche[0] as Knz2GK2022Bereich).f25Status
                            )
                        }
                        UpdateStatusClick={() => {
                            this.setState({ ShowStatusUpdateDialog: true });
                        }}
                        DivideBereichClick={() => {
                            this.setState({ ShowDivideBereichDialog: true });
                        }}
                        ExportBereichClick={async () => {
                            await this.loadingRef.current.Execute(async () => {
                                const fileResponse = await this.props.Model.ExportListToExcel();
                                saveAs(fileResponse.data, fileResponse.fileName);
                            }, "Excel-Export wird erstellt");
                        }}
                        AbschnitteDatenExportClick={async () => {
                            await this.loadingRef.current.Execute(async () => {
                                const fileResponse = await this.props.Model.AbschnitteDatenExport();
                                saveAs(fileResponse.data, fileResponse.fileName);
                            }, "Excel-Export wird erstellt");
                        }}
                        ValidateBereichClick={async () => {
                            await this.loadingRef.current.Execute(async () => {
                                const fileResponse = await this.props.Model.ValidateBereiche();
                                saveAs(fileResponse.data, fileResponse.fileName);
                            }, "Bereiche validieren");
                        }}
                    />
                    {this.state.CurrentListModelType !== EnumListModelType.Ausgeblendet && (
                        <Knz2FilterControl
                            Model={this.props.Model}
                            OnUpdateFilter={(filter: Knz2BereichListModelFilter) => {
                                this.setState({ Filter: filter });
                                this.listRef.current?.RefreshGroups();
                            }}
                            StatusValues={
                                this.state.CurrentListModelType === EnumListModelType.Anlage1
                                    ? Knz2BereichStatusAnlage1
                                    : Knz2BereichStatusAnlage3
                            }
                        />
                    )}
                    <Pivot
                        selectedKey={this.state.CurrentListModelType}
                        onLinkClick={async (item: PivotItem) => {
                            await this.setListModel(item.props.itemKey as EnumListModelType);
                        }}
                        headersOnly={true}
                        linkSize={PivotLinkSize.large}
                    >
                        <PivotItem itemKey={EnumListModelType.Anlage1} headerText="Anlage 1" />
                        <PivotItem itemKey={EnumListModelType.Anlage3} headerText="Anlage 3" />
                        <PivotItem itemKey={EnumListModelType.Ausgeblendet} headerText="Ausgeblendet" />
                    </Pivot>
                    <PleaseWait
                        ShowSpinner={!this.state.CurrentListModel}
                        render={() => (
                            <Knz2BereicheList
                                Model={this.props.Model}
                                ref={this.listRef}
                                OnEditBereich={(bereich) => {
                                    this.setState({ Bereich: bereich, ShowEditDialog: true });
                                }}
                                OnSelectedItems={(selectedItems: []) => {
                                    this.setState({ SelectedBereiche: selectedItems });
                                }}
                                ListModel={this.state.CurrentListModel}
                                ColumnType={
                                    this.state.CurrentListModelType === EnumListModelType.Anlage3
                                        ? EnumColumnType.Anlage3
                                        : EnumColumnType.Anlage1
                                }
                            />
                        )}
                    />
                </HandleError>
            </>
        );
    }

    public async componentDidMount(): Promise<void> {
        await this.setListModel(EnumListModelType.Anlage1);
    }

    private async setListModel(type: EnumListModelType) {
        if (this.state.CurrentListModelType !== type) {
            this.setState({
                CurrentListModel: await this.GetListModelFor(type),
                CurrentListModelType: type
            });
            this.listRef.current.RefreshGroups();
        }
    }

    private async GetListModelFor(listmodelType: EnumListModelType): Promise<IGroupedListDatasource> {
        let abschnitteLookup = await this.props.Model.AbschnitteCache.GetAllMap();
        switch (listmodelType) {
            case EnumListModelType.Anlage1:
                return new Knz2BereicheGroupedByKPN(this.props.Model, (b: Knz2GK2022Bereich) => {
                    return this.FilterFunctionAnsicht1(b, abschnitteLookup);
                });
            case EnumListModelType.Anlage3:
                return new Knz2BereicheGroupedByAbschnittList(this.props.Model, (b: Knz2GK2022Bereich) => {
                    return this.FilterFunctionAnsicht3(b, abschnitteLookup);
                });
            case EnumListModelType.Ausgeblendet:
                return new Knz2BereicheGroupedByKPN(this.props.Model, (b: Knz2GK2022Bereich) => {
                    return this.FilterFunctionAusgblendet(b);
                });
        }
    }

    private FilterFunctionAnsicht1(b: Knz2GK2022Bereich, abschnitteLookup: Map<string, Knz2GK2022Abschnitt>): boolean {
        if (!b.knz2AbschnittRef?.objectId) {
            return false;
        }
        if (!Knz2BereichStatusAnlage1.includes(b.f25Status)) {
            return false;
        }
        if (b.f44IstGeteilt) {
            return false;
        }

        if (this.state.Filter.status && this.state.Filter.status !== b.f25Status) {
            return false;
        }

        if (this.state.Filter.text) {
            if (
                Utils.IncludesText(b.f02Bezeichnung, this.state.Filter.text) ||
                Utils.IncludesText(b.f01Streckennummer, this.state.Filter.text) ||
                Utils.IncludesText(b.f27WeitereStrecken, this.state.Filter.text)
            ) {
                return true;
            }

            const abschnitt = abschnitteLookup.get(b.knz2AbschnittRef.objectId);
            if (abschnitt && Utils.IncludesText(abschnitt.f04Nr, this.state.Filter.text)) {
                return true;
            }
            return false;
        }

        return true;
    }

    private FilterFunctionAnsicht3(b: Knz2GK2022Bereich, abschnitteLookup: Map<string, Knz2GK2022Abschnitt>): boolean {
        if (!b.knz2AbschnittRef?.objectId) {
            return false;
        }
        if (!Knz2BereichStatusAnlage3.includes(b.f25Status)) {
            return false;
        }

        if (b.f44IstGeteilt) {
            return false;
        }

        if (this.state.Filter.status && this.state.Filter.status !== b.f25Status) {
            return false;
        }

        if (this.state.Filter.text) {
            if (
                Utils.IncludesText(b.f02Bezeichnung, this.state.Filter.text) ||
                Utils.IncludesText(b.f01Streckennummer, this.state.Filter.text) ||
                Utils.IncludesText(b.f27WeitereStrecken, this.state.Filter.text)
            ) {
                return true;
            }

            const abschnitt = abschnitteLookup.get(b.knz2AbschnittRef.objectId);
            if (abschnitt && Utils.IncludesText(abschnitt.f04Nr, this.state.Filter.text)) {
                return true;
            }
            return false;
        }

        return true;
    }

    private FilterFunctionAusgblendet(b: Knz2GK2022Bereich): boolean {
        if (EnumBereichStatus.Ausgeblendet !== b.f25Status) {
            return false;
        }

        if (b.f44IstGeteilt) {
            return false;
        }

        if (this.state.Filter.status && this.state.Filter.status !== b.f25Status) {
            return false;
        }

        if (this.state.Filter.text) {
            if (
                !Utils.IncludesText(b.f02Bezeichnung, this.state.Filter.text) &&
                !Utils.IncludesText(b.f01Streckennummer, this.state.Filter.text)
            ) {
                return false;
            }
        }

        return true;
    }
}

let wrapped = withRouter(Knz2Container);
export { wrapped as Knz2Container };

