import { DefaultButton, Stack } from "office-ui-fabric-react";
import * as React from "react";
import { HealthClient } from "../generated/BackendClient";
export interface Health {}

export function Health({}: React.PropsWithChildren<Health>) {
    return (
        <>
            <Stack>
                <Stack.Item align="center">
                    <DefaultButton
                        text="Teste TM1"
                        onClick={async () => {
                            let h = new HealthClient();
                            let result = await h.checkTm1();
                        }}
                    />
                    <DefaultButton
                        text="Teste SMTP"
                        onClick={async () => {
                            let h = new HealthClient();
                            await h.testSmtp();
                        }}
                    />
                </Stack.Item>
            </Stack>
        </>
    );
}
