import * as mobx from "mobx";
import { Knz4LV, Knz4LVListModelFilter, Knz4LVRepository } from ".";
import { IDetailListDatasource } from "../../tsx/Generic";

export class Knz4LVDetailList implements IDetailListDatasource {
    @mobx.computed public get IsLoading(): boolean {
        return this.RunningRequests > 0;
    }

    @mobx.observable private RunningRequests = 0;
    private Model: Knz4LVRepository;
    private Filter: Knz4LVListModelFilter;

    public constructor(model: Knz4LVRepository, filter: Knz4LVListModelFilter) {
        this.Model = model;
        this.Filter = filter;
    }
    public Sort(items: Knz4LV[]): Knz4LV[] {
        return items;
    }

    public async GetAllItems(): Promise<Map<string, Knz4LV>> {
        return await this.Model.Search(this.Filter.text);
    }

    public Equals(IListDatasource: Knz4LVDetailList): boolean {
        return IListDatasource.Filter.Equals(this.Filter);
    }
}
