import * as mobx from "mobx";
import { Knz2GK2022Bereich, Knz2GroupedListGroup, Knz2Repository } from ".";
import { IGroupedListDatasource, IGroupedListGroup } from "../../tsx/Generic";
import { Utils } from "../../Utils";

export class Knz2BereicheGroupedByAbschnittList implements IGroupedListDatasource {
    @mobx.computed public get IsLoading(): boolean {
        return this.RunningRequests > 0;
    }

    @mobx.observable protected RunningRequests = 0;
    protected Model: Knz2Repository;
    protected Filter: (b: Knz2GK2022Bereich) => boolean;

    public constructor(model: Knz2Repository, filter: (b: Knz2GK2022Bereich) => boolean) {
        this.Model = model;
        this.Filter = filter;
    }

    public OnItemUpdated(item: any): Promise<void> {
        return Promise.resolve();
    }

    public async GetAllGroups(): Promise<IGroupedListGroup[]> {
        try {
            if (!this.Model.BereicheCache.isInitialized) {
                mobx.runInAction(() => {
                    this.RunningRequests++;
                });
            }
            let result = await this.FilteredGroups(this.Filter);
            return result;
        } finally {
            if (this.RunningRequests > 0) {
                mobx.runInAction(() => {
                    this.RunningRequests--;
                });
            }
        }
    }

    public async ExpandGroup(group: Knz2GroupedListGroup): Promise<any[]> {
        return group.items || [];
    }

    protected async FilteredGroups(filter: (b: Knz2GK2022Bereich) => boolean): Promise<Knz2GroupedListGroup[]> {
        let allBereiche = await this.Model.BereicheCache.GetAllMap();
        const filteredItems = Utils.MapFilterToArray(allBereiche, (k, v) => filter(v));

        // group by Abschnitt
        const groupedItems: Map<string, Knz2GK2022Bereich[]> = Utils.GroupBy(
            filteredItems,
            (row: Knz2GK2022Bereich) => row.knz2AbschnittRef?.objectId
        );

        const groups: Knz2GroupedListGroup[] = [];
        const abschnitte = (await this.Model.AbschnitteCache.GetAll()).sort((a, b) => (a.f04Nr > b.f04Nr ? 1 : -1));

        // sort using Abschnitt order
        for (const a of abschnitte) {
            a.Bereiche = groupedItems.get(a.objectId)?.filter((b) => !b.f44IstGeteilt);
            if (!a.Bereiche) {
                continue;
            }
            groups.push(new Knz2GroupedListGroup(a, a.Bereiche));
        }

        return groups;
    }
}
