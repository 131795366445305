import { observer } from "mobx-react";
import { CommandBar, ICommandBarItemProps } from "office-ui-fabric-react";
import * as React from "react";
import { DatenImportList, DatenImportNewDialog, DatenImportViewDialog } from ".";
import { AbstractDatenImportModel, BaseFile, ExcelRowResult, FileParameter } from "../../generated/BackendClient";
import { DatenImport } from "../../Model/DatenImport/DatenImport";
import { IEntityRepositoryWithFile } from "../../Rad/DAL/EntityRepository";
import { HandleError, LoadingDialog } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class DatenImportContainer<T extends AbstractDatenImportModel, F extends BaseFile> extends React.Component<
    {
        Repository: IEntityRepositoryWithFile<T, F>;
        ValidateExcel: (file: FileParameter) => Promise<ExcelRowResult[]>;
        ImportExcel: (file: FileParameter) => Promise<void>;
    },
    {
        ShowNewDialog: boolean;
        ShowEditDialog: boolean;
        CurrentItem: AbstractDatenImportModel;
    }
> {
    private listRef: React.RefObject<DatenImportList<T, F>> = React.createRef();
    private loadingRef: React.RefObject<LoadingDialog> = React.createRef();
    public readonly state = {
        ShowNewDialog: false,
        ShowEditDialog: false,
        CurrentItem: undefined
    };

    public render(): JSX.Element {
        return (
            <HandleError>
                <LoadingDialog ref={this.loadingRef} />
                <CommandBar items={this.getCommandBarItems()} />
                {this.state.ShowNewDialog && (
                    <DatenImportNewDialog
                        CloseDialog={() => {
                            (async () => {
                                this.setState({ ShowNewDialog: false });
                                this.listRef.current.RefreshListItems();
                            })();
                        }}
                        ValidateExcel={async (file) => {
                            let result;
                            await this.loadingRef.current.Execute(async () => {
                                result = await this.props.ValidateExcel(file);
                            }, "Excel validieren");
                            return result;
                        }}
                        ImportExcel={async (file) => {
                            this.loadingRef.current.Execute(async () => {
                                await this.props.ImportExcel(file);
                                this.listRef.current.RefreshListItems();
                            }, "Import starten");
                        }}
                    />
                )}
                {this.state.ShowEditDialog && (
                    <DatenImportViewDialog
                        Repository={this.props.Repository}
                        InitialItem={this.state.CurrentItem}
                        CloseDialog={() => {
                            this.setState({ CurrentItem: undefined, ShowEditDialog: false });
                        }}
                    />
                )}
                <DatenImportList
                    ref={this.listRef}
                    Repository={this.props.Repository}
                    OnEditItem={(item: DatenImport) => {
                        this.setState({
                            CurrentItem: item,
                            ShowEditDialog: true
                        });
                    }}
                />
            </HandleError>
        );
    }

    private getCommandBarItems(): ICommandBarItemProps[] {
        const output: ICommandBarItemProps[] = [
            {
                key: "option1",
                name: "Neuer Datenimport",
                iconProps: {
                    iconName: "Add"
                },
                onClick: () => {
                    this.setState({ ShowNewDialog: true });
                },
                disabled: false
            }
        ];
        return output;
    }
}
