import { CommandBar, ICommandBarItemProps } from "office-ui-fabric-react";
import * as React from "react";
import { EnumRadPermission, IEntityRepository } from "../../../Rad/DAL";
import { BaseEntity } from "../../DAL";

type RadCommandBarAction<T extends BaseEntity> = {
    Props: Partial<ICommandBarItemProps>;
    IsEnabled?: (Permissions: EnumRadPermission[], SelectedObjects: Array<T>) => boolean;
    OnExecute(Permissions: EnumRadPermission[], SelectedObjects: Array<T>): void;
};

type RadCommandBarProps<T extends BaseEntity> = {
    Repository: IEntityRepository<T>;
    SelectedObjects: Array<T>;
    Actions: RadCommandBarAction<T>[];
};

function CalculateOptions<T extends BaseEntity>(
    Actions: RadCommandBarAction<T>[],
    SelectedObjects: Array<T>,
    Permissions: EnumRadPermission[]
) {
    return Actions.map((v, i) => ({
        key: String(i),
        disabled: v.IsEnabled ? !v.IsEnabled(Permissions, SelectedObjects) : false,
        onClick: () => {
            v.OnExecute(Permissions, SelectedObjects);
        },
        ...v.Props
    }));
}

async function CalculatePermissions<T extends BaseEntity>(
    SelectedObjects: Array<T>,
    Repository: IEntityRepository<T>
): Promise<EnumRadPermission[]> {
    let allowedPerms: Array<EnumRadPermission> = [];

    // get permissions for objects
    if (SelectedObjects) {
        let allPerm = new Map<EnumRadPermission, number>();
        for (let obj of SelectedObjects) {
            let perms = await Repository.getPermissions(obj);
            for (let p of perms) {
                allPerm.set(p, (allPerm.get(p) ?? 0) + 1);
            }
        }
        // only allow actions that may be applied to all selected objects
        allowedPerms = Array.from(allPerm.keys()).filter((k) => allPerm.get(k) >= SelectedObjects.length);
    }

    // add general permission for entity (e.g. "new")
    let perms = await Repository.getPermissions();
    for (let p of perms) {
        if (!allowedPerms.includes(p)) {
            allowedPerms.push(p);
        }
    }

    return allowedPerms;
}

export function RadCommandBar<T extends BaseEntity>({
    Repository,
    SelectedObjects,
    Actions
}: React.PropsWithChildren<RadCommandBarProps<T>>) {
    const [permissions, setPermissions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [items, setItems] = React.useState(CalculateOptions(Actions, SelectedObjects, []));

    // update permissions whenever objects change
    React.useEffect(() => {
        (async () => {
            setIsLoading(true);
            let perms = await CalculatePermissions(SelectedObjects, Repository);
            setPermissions(perms);
            setIsLoading(false);
        })();
    }, [Repository, SelectedObjects]);

    // update commands whenever objects or permissions change
    React.useEffect(() => {
        let i = CalculateOptions(Actions, SelectedObjects, permissions);
        setItems(i);
    }, [permissions, SelectedObjects]);

    return <CommandBar items={items} />;
}
