import { observer } from "mobx-react";
import { CommandBar, IColumn, ICommandBarItemProps, Pivot, PivotItem } from "office-ui-fabric-react";
import * as React from "react";
import { BerichteDetailsDialog, Knz1NewBerichtDialog, NewBerichtDialog } from ".";
import { EnumBerichtArt, EnumKennzahl, FileParameter, Knz2LSClient } from "../../generated/BackendClient";
import { allMetadata } from "../../generated/RadRepository";
import { Bericht, BerichteDetailListModel, BerichteRepository } from "../../Model/Berichte";
import { nameof, Utils } from "../../Utils";
import { IDetailListDatasource, LargeDetailsList, LoadingDialog, ObserverSpan } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";
import { WorkflowStatus } from "../Generic/Workflows";
import { StartFreigabeDialog } from "./Workflow";

@observer
@CatchReactErrors
export class BerichteContainer extends React.Component<
    {
        Model: BerichteRepository;
    },
    {
        ListModel: IDetailListDatasource;
        RecycleBinListModel: IDetailListDatasource;
        SelectedItemsToRecycle: Bericht[];
        SelectedItemsToRestore: Bericht[];
        ShowNewDialog: boolean;
        ShowStartDialog: boolean;
        CurrentItem: Bericht;
    }
> {
    private columns: IColumn[] = [];

    private interval: any;
    private listRef: React.RefObject<LargeDetailsList> = React.createRef();
    private loadingRef: React.RefObject<LoadingDialog> = React.createRef();

    public readonly state = {
        ListModel: new BerichteDetailListModel(this.props.Model, false),
        RecycleBinListModel: new BerichteDetailListModel(this.props.Model, true),
        SelectedItemsToRecycle: [],
        SelectedItemsToRestore: [],
        ShowNewDialog: false,
        CurrentItem: undefined,
        ShowStartDialog: false
    };

    public async componentDidUpdate(prevProps): Promise<void> {
        if (this.props.Model !== prevProps.Model) {
            this.setState({
                ListModel: new BerichteDetailListModel(this.props.Model, false),
                RecycleBinListModel: new BerichteDetailListModel(this.props.Model, true),
                SelectedItemsToRecycle: [],
                SelectedItemsToRestore: [],
                ShowNewDialog: false,
                CurrentItem: undefined,
                ShowStartDialog: false
            });
            this.updateColumns();
            await this.RefreshList();
        }
    }

    public async componentDidMount(): Promise<void> {
        this.updateColumns();
        await this.RefreshList();
        this.interval = setInterval(() => {
            this.RefreshList();
        }, 30000);
    }

    public componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    public render(): JSX.Element {
        return (
            <>
                <CommandBar items={this.getCommandBarItems()} />
                <LoadingDialog ref={this.loadingRef} />
                {this.state.ShowStartDialog && (
                    <StartFreigabeDialog
                        CloseDialog={() => {
                            this.setState({ ShowStartDialog: false });
                        }}
                        StartWorkflow={(message: string) => {
                            this.setState({ ShowStartDialog: false, CurrentItem: undefined });
                            this.loadingRef.current.Execute(async () => {
                                await this.props.Model.StartFreigabe(this.state.CurrentItem, message);
                                await this.RefreshList();
                            }, "Freigabe starten");
                        }}
                    />
                )}
                {this.state.ShowNewDialog && this.props.Model.Kennzahl === EnumKennzahl.Knz1 && (
                    <Knz1NewBerichtDialog
                        Model={this.props.Model}
                        hideDialog={false}
                        showMonat={this.props.Model.ShowMonat}
                        closeDialog={(
                            result: boolean,
                            newModel: Bericht,
                            lspDatei?: FileParameter,
                            zentraleDatei?: FileParameter
                        ) => {
                            (async () => {
                                this.setState({ ShowNewDialog: false });
                                if (result) {
                                    await this.loadingRef.current.Execute(
                                        () => this.props.Model.AddBericht(newModel, lspDatei, zentraleDatei),
                                        "Berichterstellung starten"
                                    );
                                    await this.RefreshList();
                                }
                            })();
                        }}
                    />
                )}
                {this.state.ShowNewDialog && this.props.Model.Kennzahl !== EnumKennzahl.Knz1 && (
                    <NewBerichtDialog
                        Model={this.props.Model}
                        hideDialog={false}
                        showMonat={this.props.Model.ShowMonat}
                        closeDialog={(result: boolean, newModel: Bericht) => {
                            (async () => {
                                this.setState({ ShowNewDialog: false });
                                if (result) {
                                    if (newModel.berichtArt === EnumBerichtArt.Gesamtbericht) {
                                        newModel.berichtJahr = new Date().getFullYear();
                                        newModel.berichtMonat = 12;
                                    }
                                    await this.loadingRef.current.Execute(
                                        () => this.props.Model.AddBericht(newModel, null, null),
                                        "Berichterstellung starten"
                                    );
                                    await this.RefreshList();
                                }
                            })();
                        }}
                    />
                )}
                {this.state.CurrentItem && (
                    <BerichteDetailsDialog
                        Item={this.state.CurrentItem}
                        Model={this.props.Model}
                        ReadOnly={false}
                        StartFreigabeProcess={(currentBericht: Bericht) => {
                            this.setState({ ShowStartDialog: true, CurrentItem: currentBericht });
                        }}
                        RetractBericht={async (currentBericht: Bericht) => {
                            await this.props.Model.RetractBericht(currentBericht);
                            await this.RefreshList();
                        }}
                        CloseDialog={(shouldUpdate: boolean, updatedModel: Bericht) => {
                            (async () => {
                                this.setState({ CurrentItem: undefined, ShowNewDialog: false });
                                if (shouldUpdate) {
                                    await this.loadingRef.current.Execute(
                                        () => this.props.Model.UpdateBericht(updatedModel),
                                        "Aktualisierung des Berichts"
                                    );
                                    await this.RefreshList();
                                }
                            })();
                        }}
                        // PublishTm1={(bericht: Bericht) => {
                        //     this.setState({ CurrentItem: undefined, ShowNewDialog: false });
                        //     (async () => {
                        //         await this.loadingRef.current.Execute(
                        //             () => this.props.Model.PublishTm1(bericht),
                        //             "Kennzahl an TM1 übertragen"
                        //         );
                        //         await this.RefreshList();
                        //     })();
                        // }}
                    />
                )}
                <Pivot>
                    <PivotItem headerText="Berichte">
                        <LargeDetailsList
                            Columns={this.columns}
                            DataSource={this.state.ListModel}
                            OnItemInvoked={(item: Bericht) => {
                                this.setState({
                                    CurrentItem: item
                                });
                            }}
                            OnSelectedItems={(selectedItems: []) => {
                                this.setState({ SelectedItemsToRecycle: selectedItems });
                            }}
                            ref={this.listRef}
                        />
                    </PivotItem>
                    <PivotItem headerText="Berichte im Papierkorb">
                        <LargeDetailsList
                            Columns={this.columns}
                            DataSource={this.state.RecycleBinListModel}
                            OnItemInvoked={(item: Bericht) => {
                                this.setState({
                                    CurrentItem: item
                                });
                            }}
                            OnSelectedItems={(selectedItems: []) => {
                                this.setState({ SelectedItemsToRestore: selectedItems });
                            }}
                            ref={this.listRef}
                        />
                    </PivotItem>
                </Pivot>
            </>
        );
    }

    private getCommandBarItems(): ICommandBarItemProps[] {
        const output: ICommandBarItemProps[] = [
            {
                key: "option1",
                name: "Neuer Bericht",
                iconProps: {
                    iconName: "Add"
                },
                onClick: () => {
                    this.setState({ ShowNewDialog: true });
                },
                disabled: this.props.Model.IsReadOnly
            },
            {
                key: "option2",
                name: "Berichte löschen",
                iconProps: {
                    iconName: "RecycleBin"
                },
                onClick: () => {
                    this.loadingRef.current.Execute(async (): Promise<void> => {
                        for (const item of this.state.SelectedItemsToRecycle as Bericht[]) {
                            if (item.CanBeDeleted) {
                                item.berichtKennzahlImPapierkorb = true;
                                await this.props.Model.UpdateBericht(item);
                            }
                        }
                        await this.RefreshList();
                    }, "Die Berichte werden gelöscht.");
                },
                disabled:
                    this.state.SelectedItemsToRecycle.length === 0 ||
                    this.props.Model.IsReadOnly ||
                    (this.state.SelectedItemsToRecycle as Bericht[]).filter((i) => !i.CanBeDeleted).length > 0
            },
            {
                key: "option3",
                name: "Berichte wiederherstellen",
                iconProps: {
                    iconName: "EmptyRecycleBin"
                },
                onClick: () => {
                    this.loadingRef.current.Execute(async (): Promise<void> => {
                        for (const item of this.state.SelectedItemsToRestore as Bericht[]) {
                            item.berichtKennzahlImPapierkorb = false;
                            await this.props.Model.UpdateBericht(item);
                        }
                        await this.RefreshList();
                    }, "Die Berichte werden wiederhergestellt.");
                },
                disabled: this.state.SelectedItemsToRestore.length === 0 || this.props.Model.IsReadOnly
            }
        ];

        if (this.props.Model.Kennzahl == EnumKennzahl.Knz2LS) {
            output.push({
                key: "option4",
                name: "Gesamtkonzept historisieren",
                iconProps: {
                    iconName: "ProductList"
                },
                onClick: () => {
                    this.loadingRef.current.Execute(async (): Promise<void> => {
                        const wf = new Knz2LSClient();
                        await wf.createGesamtkonzept();
                        await this.RefreshList();
                    }, "Erzeugung wird angestoßen.");
                }
            });
        }

        return output;
    }

    private updateColumns(): void {
        this.columns = [
            {
                key: "Feld01",
                name: "Art",
                minWidth: 100,
                isResizable: false,
                onRender: (item: Bericht) => <ObserverSpan Object={item} PropertyName={nameof<Bericht>("berichtArt")} />
            },
            {
                key: "Feld02",
                name: "Monat",
                minWidth: 50,
                isResizable: false,
                onRender: (item: Bericht) => (
                    <ObserverSpan Object={item} PropertyName={nameof<Bericht>("berichtMonat")} />
                )
            },
            {
                key: "Feld02b",
                name: "Jahr",
                minWidth: 50,
                isResizable: false,
                onRender: (item: Bericht) => (
                    <ObserverSpan Object={item} PropertyName={nameof<Bericht>("berichtJahr")} />
                )
            },
            {
                key: "Feld04",
                name: this.props.Model.DisplayNameKennzahl1,
                minWidth: 120,
                isResizable: true,
                onRender: (item: Bericht) => {
                    switch (this.props.Model.Kennzahl) {
                        case EnumKennzahl.Knz1:
                            return (
                                <ObserverSpan
                                    Object={item}
                                    PropertyName={nameof<Bericht>("berichtKennzahlWert")}
                                    FormatFunction={Utils.FormatNumberToEuro}
                                />
                            );

                        case EnumKennzahl.Knz3LV:
                        case EnumKennzahl.Knz3SP:
                        case EnumKennzahl.Knz3LS:
                            return (
                                <ObserverSpan
                                    Object={item}
                                    PropertyName={nameof<Bericht>("berichtKennzahlWert")}
                                    FormatFunction={Utils.FormatNumber}
                                    Suffix=" m"
                                />
                            );
                        default:
                            return (
                                <ObserverSpan
                                    Object={item}
                                    PropertyName={nameof<Bericht>("berichtKennzahlWert")}
                                    FormatFunction={Utils.FormatNumber}
                                />
                            );
                    }
                },
                ariaLabel: this.props.Model.DisplayNameKennzahl1
            },
            {
                key: "Feld04b",
                name: this.props.Model.DisplayNameKennzahl2,
                minWidth: 120,
                isResizable: true,
                onRender: (item: Bericht) => (
                    <ObserverSpan
                        Object={item}
                        PropertyName={nameof<Bericht>("berichtKennzahlWert2")}
                        FormatFunction={Utils.FormatNumber}
                    />
                ),
                ariaLabel: this.props.Model.DisplayNameKennzahl2
            },
            {
                key: "Feld05",
                name: "Geändert am",
                minWidth: 160,
                isResizable: true,
                onRender: (item: Bericht) => <>{Utils.FormatDateTime(item.lastModified)}</>
            },
            {
                key: "Feld06",
                name: "Freigabestatus",
                minWidth: 100,
                isResizable: true,
                onRender: (item: Bericht) => (
                    <ObserverSpan
                        Object={item}
                        PropertyName={nameof<Bericht>("berichtWFStatus")}
                        FormatFunction={(i) =>
                            Utils.ApplyMapping(
                                i,
                                allMetadata.enumMapping["DB.LSK.Model.Enums+EnumApprovalWorkflowStatus"]
                            )
                        }
                    />
                )
            },
            {
                key: "Feld07",
                name: "Workflowstatus",
                minWidth: 300,
                isResizable: true,
                onRender: (item: Bericht) => <WorkflowStatus Item={item} />
            }
        ];

        // hide alternate value if not required;
        if (!this.props.Model.DisplayNameKennzahl2) {
            this.columns = this.columns.filter((c) => c.key !== "Feld04b");
        }
        if (!this.props.Model.ShowMonat) {
            this.columns = this.columns.filter((c) => c.key !== "Feld02");
        }
    }

    private async RefreshList(): Promise<void> {
        if (this.listRef.current && !this.state.ListModel.IsLoading) {
            await this.listRef.current.RefreshItems();
            this.setState({ ListModel: new BerichteDetailListModel(this.props.Model, false) });
        }
    }
}
