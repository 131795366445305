import * as mobx from "mobx";

import { IGroupedListGroup } from "../../tsx/Generic/LargeGroupedList";
import { Utils } from "../../Utils";
import { Knz2GK2022Abschnitt } from "./Knz2Abschnitt";
import { Knz2GK2022Bereich } from "./Knz2Bereich";

export class Knz2GroupedListGroup implements IGroupedListGroup {
    public key: string;
    @mobx.observable public name: string;
    public data?: Knz2GK2022Abschnitt;
    public items: Knz2GK2022Bereich[];
    @mobx.observable public isExpanded = false;

    public constructor(abschnitt: Knz2GK2022Abschnitt, items?: Knz2GK2022Bereich[]) {
        this.data = abschnitt;
        this.items = items;
        this.Refresh();
    }

    @mobx.action
    public Refresh(): void {
        this.key = "group" + this.data.objectId;
        this.isExpanded = this.items !== undefined;
        this.name = `${this.data.f04Nr} - ${this.data.title}`;

        if (this.items) {
            this.items = this.items.sort((a, b) => a.CompareTo(b));
            let summe = 0;
            for (const b of this.items) {
                summe += b.StreckenLaenge;
            }
            this.name += ` (${Utils.FormatKilometer(summe)} km)`;
        }
    }
}
