import { observer } from "mobx-react";
import { IColumn, ScrollablePane, ScrollbarVisibility } from "office-ui-fabric-react";
import * as React from "react";
import { Knz3DatenImport, Knz3DetailListModel, Knz3Repository } from "../../../Model/Knz3";
import { nameof, Utils } from "../../../Utils";
import { LargeDetailsList, ObserverSpan } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz3DatenImportList extends React.Component<
    {
        Model: Knz3Repository;
        ShowDeletedItems: boolean;
        OnEditItem: (knz3DatenImport: Knz3DatenImport) => void;
        OnSelectedItems: (knz3DatenImport: []) => void;
    },
    {
        ListModel: Knz3DetailListModel;
    }
> {
    private listRef: React.RefObject<LargeDetailsList>;

    private columns: IColumn[] = [
        {
            key: "Feld01",
            name: "Hochgeladen am",
            minWidth: 50,
            maxWidth: 500,
            isResizable: true,
            onRender: (item: Knz3DatenImport) => (
                <ObserverSpan Object={item} PropertyName={nameof<Knz3DatenImport>("created")} FormatFunction={Utils.FormatDateTime} />
            )
        }
        // {
        //   key: "Feld02",
        //   name: "Hochgeladen von",
        //   minWidth: 50,
        //   maxWidth: 500,
        //   isResizable: true,
        //   onRender: (item: Knz3DatenImport) => <ShowUser Object={item} PropertyName={nameof<Knz4LV>("createdBy")} />
        // }
    ];

    constructor(props) {
        super(props);
        this.listRef = React.createRef();
        this.state = {
            ListModel: new Knz3DetailListModel(this.props.Model, this.props.ShowDeletedItems)
        };
    }

    public async RefreshListItems(): Promise<void> {
        await this.listRef.current.RefreshItems();
    }

    public render() {
        return (
            <div style={{ position: "relative", height: "80vh" }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <LargeDetailsList
                        Columns={this.columns}
                        DataSource={this.state.ListModel}
                        OnItemInvoked={(item) => {
                            this.props.OnEditItem(item);
                        }}
                        OnSelectedItems={this.props.OnSelectedItems}
                        ref={this.listRef}
                    />
                </ScrollablePane>
            </div>
        );
    }
}
