import { PrimaryButton, TextField } from "office-ui-fabric-react";
import * as React from "react";
import { RadRepository } from "../../generated/RadRepository";
import { Knz4Repository } from "../../Model/Knz4LS";
import { Header, PleaseWait } from "../Generic";

type Knz4LSSettingsProps = {
    Repository: Knz4Repository;
};

/* @{"FZ.Spalte1"   = "DB Netz AG`nSitz Frankfurt am Main`nRegistergericht`nFrankfurt am Main`nHRB 50 879`nUSt-ldNr.:DE199861757";
            "FZ.Spalte2" = "Vorsitzender des Aufsichtsrates`nRonald Pofalla";
            "FZ.Spalte3" = "Vorstand:`nFrank Sennhenn`nVorsitzender";
            "FZ.Spalte4" = "Jens Bergmann`nDr. Roland Bosch`nUte Plambeck`nProf. Dr. Dirk Rompf`nDr. Thomas Schaffer";
            "FZ.Spalte5" = "";
            "K4LS.Abt"   = "I.NI-W-L-W"*/
export function Knz4LSSettings({ Repository }: React.PropsWithChildren<Knz4LSSettingsProps>): JSX.Element {
    const [isLoading, setIsLoading] = React.useState(true);
    const [settings, setSettings] = React.useState<Map<string, string>>(null);

    function updateValue(k: string, v: string) {
        setSettings(new Map(settings.set(k, v)));
    }

    async function saveSettings(): Promise<void> {
        setIsLoading(true);
        const current = await RadRepository.Knz4LSSettingModel.getAll(0, "");
        for (let item of current.results) {
            if (settings.get(item.key) !== item.value) {
                item.value = settings.get(item.key);
                await RadRepository.Knz4LSSettingModel.update(item);
            }
        }
        await loadSettings();
    }

    async function loadSettings(): Promise<void> {
        setIsLoading(true);
        const settings = await RadRepository.Knz4LSSettingModel.getAll(0, "");
        setSettings(new Map(settings.results.map((m) => [m.key, m.value])));
        setIsLoading(false);
    }

    React.useEffect(() => {
        loadSettings();
    }, []);

    return (
        <PleaseWait
            ShowSpinner={isLoading}
            render={() => (
                <>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12">
                                <Header>Fußzeile</Header>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6">
                            <TextField
                                    value={settings.get("FZ.Spalte1") || ""}
                                    onChange={(e: React.FormEvent<HTMLElement>, value: string) => {
                                        updateValue("FZ.Spalte1", value);
                                    }}
                                    autoComplete="off"
                                    multiline
                                    label="Zeile 1"
                                    rows={3}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6">
                            <TextField
                                    value={settings.get("FZ.Spalte2") || ""}
                                    onChange={(e: React.FormEvent<HTMLElement>, value: string) => {
                                        updateValue("FZ.Spalte2", value);
                                    }}
                                    autoComplete="off"
                                    multiline
                                    label="Zeile 2"
                                    rows={3}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6">
                            <TextField
                                    value={settings.get("FZ.Spalte3") || ""}
                                    onChange={(e: React.FormEvent<HTMLElement>, value: string) => {
                                        updateValue("FZ.Spalte3", value);
                                    }}
                                    autoComplete="off"
                                    multiline
                                    label="Zeile 3"
                                    rows={3}
                                />
                            </div>
                        </div>

                            {/* <div className="ms-Grid-col ms-sm3">
                                <TextField
                                    value={settings.get("FZ.Spalte4") || ""}
                                    onChange={(e: React.FormEvent<HTMLElement>, value: string) => {
                                        updateValue("FZ.Spalte4", value);
                                    }}
                                    autoComplete="off"
                                    multiline
                                    label="Spalte 4"
                                    rows={7}
                                />
                            </div> */}
                            {/*<div className="ms-Grid-col ms-sm2">
                                    <TextField
                                        value={settings.get("FZ.Spalte5") || ""}
                                        onChange={(e: React.FormEvent<HTMLElement>, value: string) => {
                                            updateValue("FZ.Spalte5", value);
                                        }}
                                        autoComplete="off"
                                        multiline
                                        label="Spalte 5"
                                        rows={7}
                                    />
                                </div>*/}
                        
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm2">
                                <Header>Organisationseinheit</Header>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4">
                                <TextField
                                    value={settings.get("K4LS.Abt") || ""}
                                    onChange={(e: React.FormEvent<HTMLElement>, value: string) => {
                                        updateValue("K4LS.Abt", value);
                                    }}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4">
                                <PrimaryButton
                                    onClick={() => {
                                        saveSettings();
                                    }}
                                    text="Speichern"
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        />
    );
}
