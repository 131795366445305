import { IPeoplePickerProps, IPersonaProps, Label, NormalPeoplePicker } from "office-ui-fabric-react";
import * as React from "react";
import { BaseUserModel, UsersClient } from "../../DAL";

const ResolvedUserCache = new Map<string, BaseUserModel>();

export interface RadPeoplePickerProps {
    Disabled?: boolean;
    PeoplePickerProps?: Partial<IPeoplePickerProps>;
    Value: BaseUserModel[];
    OnChange?: (value: BaseUserModel[]) => void;
    Required?: boolean;
    LabelText?: string;
}

export function RadPeoplePicker({
    PeoplePickerProps,
    OnChange,
    Value,
    Disabled,
    Required,
    LabelText
}: React.PropsWithChildren<RadPeoplePickerProps>) {
    const usersClient = new UsersClient();
    let currentSelectedItems: IPersonaProps[] = [];

    for (let r of Value) {
        ResolvedUserCache.set(r.objectId, r);
        currentSelectedItems.push({ text: r.displayName, secondaryText: r.email, id: r.objectId });
    }

    return (
        <>
            {LabelText && <Label required={Required}>{LabelText}</Label>}
            <NormalPeoplePicker
                onResolveSuggestions={async (
                    filterText: string,
                    currentPersonas: IPersonaProps[],
                    limitResults?: number
                ): Promise<IPersonaProps[]> => {
                    if (filterText) {
                        let foundUsers = await usersClient.search(filterText);

                        for (let r of foundUsers) {
                            ResolvedUserCache.set(r.objectId, r);
                        }
                        
                        // remove duplicates
                        foundUsers = foundUsers.filter((u) => !currentPersonas.some((cu) => cu.id == u.objectId));

                        if (limitResults) {
                            foundUsers = foundUsers.slice(0, limitResults);
                        }

                        return foundUsers.map((u) => ({ text: u.displayName, secondaryText: u.email, id: u.objectId }));
                    } else {
                        return [];
                    }
                }}
                pickerSuggestionsProps={{
                    suggestionsHeaderText: "Empfohlene Personen",
                    mostRecentlyUsedHeaderText: "Zuletzt verwendet",
                    noResultsFoundText: "Keine Ergebnisse gefunden",
                    loadingText: "Laden...",
                    showRemoveButtons: !Disabled,
                    suggestionsAvailableAlertText: "Empfehlungen verfügbar",
                    suggestionsContainerAriaLabel: "Empfohlene Personen"
                }}
                removeButtonAriaLabel={"Entfernen"}
                selectedItems={currentSelectedItems}
                onChange={async (users) => {
                    let output = [];
                    for (let u of users) {
                        let id = u.id;
                        if (!ResolvedUserCache.has(id)) {
                            throw Error("Interner Fehler!")
                        }
                        output.push(ResolvedUserCache.get(id));
                    }
                    OnChange(output);
                }}
                resolveDelay={300}
                disabled={Disabled}
                {...PeoplePickerProps}
            />
        </>
    );
}
