import * as mobx from "mobx";
import { DatenImport } from ".";
import { AbstractDatenImportModel, EntityCache, IEntityRepository } from "../../Rad/DAL";
import { IDetailListDatasource } from "../../tsx/Generic";

export class DatenImportListModel<T extends AbstractDatenImportModel> implements IDetailListDatasource {
    @mobx.computed public get IsLoading(): boolean {
        return this.RunningRequests > 0;
    }

    @mobx.observable private RunningRequests = 0;
    private ItemCache:EntityCache<T>;

    public constructor(repository: IEntityRepository<T>) {
        this.ItemCache = new EntityCache(repository);
    }

    public Sort(items: DatenImport[]): void {
        items.sort((a, b) => (a.created < b.created ? 1 : -1));
    }

    public async GetAllItems(): Promise<Map<string, T>> {
        return await this.ItemCache.GetAllMap();
    }

    public Equals(source: DatenImportListModel<T>): boolean {
        return this === source;
    }
}
