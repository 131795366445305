import { Link } from "office-ui-fabric-react";
import * as React from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { Header } from "../Generic/Header";
import { RoleMembers } from "./RoleMembers";

type RoleOverviewProps = {};

let groupedRoles = {
    Übergreifend: [
        "DB Netz Umweltschutz",
        "DB AG - DB Umwelt",
        "DB Leitung Lärmsanierung",
        "Übergreifende Leserechte",
        "Fachlicher Administrator"
    ],
    "Kennzahl 1": ["Knz1 Berichterstatter"],
    "Kennzahl 2": [
        "Knz2 Benutzer",
        "Knz2 Benutzer (nur lesen)",
        "Knz2 Berichterstatter",
        "Knz2 Freigabe Formblatt P",
        "Knz2 Freigabe Formblatt A (Region Nord)",
        "Knz2 Freigabe Formblatt A (Region Südwest)",
        "Knz2 Freigabe Formblatt A (Region West)",
        "Knz2 Freigabe Formblatt A (Region Süd)",
        "Knz2 Freigabe Formblatt A (Region Ost)",
        "Knz2 Leitung Gesamtprojektsteuerung"
    ],
    "Kennzahl 3 (LS/LV)": ["Knz3DatenImport Benutzer", "Knz3 Berichterstatter LS", "Knz3 Berichterstatter LV"],
    "Kennzahl 4 (LS)": ["Knz4LS Benutzer", "Knz4LS Benutzer (nur lesen)", "Knz4 Berichterstatter LS"],
    "Kennzahl 4 (LV)": [
        "Knz4LV Benutzer",
        "Knz4LV Benutzer (nur lesen)",
        "Knz4LV Benutzer (RB Mitte)",
        "Knz4LV Benutzer (RB Nord)",
        "Knz4LV Benutzer (RB Südost)",
        "Knz4LV Benutzer (RB Südwest)",
        "Knz4LV Benutzer (RB West)",
        "Knz4LV Benutzer (RB Süd)",
        "Knz4LV Benutzer (RB Ost)",
        "Knz4 Berichterstatter LV",
        "Knz4LV freigebende Leiter"
    ]
};

export function RoleOverview({}: React.PropsWithChildren<RoleOverviewProps>) {
    let history = useHistory();
    let { url } = useRouteMatch();

    return (
        <>
            <Switch>
                <Route
                    path={`${url}/:role`}
                    render={(p) => {
                        return (
                            <RoleMembers
                                RoleName={p.match.params.role}
                                OnClose={async () => {
                                    history.push(`${url}`);
                                }}
                            />
                        );
                    }}
                />
                <Route path="*">
                    <Header Large>Rollenübersicht</Header>
                    {Object.keys(groupedRoles).map((groupName) => (
                        <>
                            <Header>{groupName}</Header>
                            {groupedRoles[groupName].map((r) => (
                                <div>
                                    <Link
                                        onClick={() => {
                                            history.push(`${url}/${r}`);
                                        }}
                                    >
                                    {r==="DB Netz Umweltschutz" ? "DB InfraGO AG, Umweltschutzmanagement": r}
                                    </Link>
                                </div>
                            ))}
                        </>
                    ))}
                </Route>
            </Switch>
        </>
    );
}
