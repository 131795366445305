import * as mobx from "mobx";
import { observer } from "mobx-react";
import { ITextFieldProps, TextField } from "office-ui-fabric-react";
import * as React from "react";
import { EasyFormContext } from "./EasyFormContext";

@observer
export class EasyTextField extends React.Component<
    {
        Object?: object;
        PropertyName: string;
        TextFieldProps: ITextFieldProps;
        OnChange?: () => void;
        OnBlur?: (value: string) => void;
    },
    {}
> {
    public static contextType = EasyFormContext;
    private get Object(): object {
        return this.props.Object || this.context.Object;
    }

    public render(): JSX.Element {
        return (
            <TextField
                value={this.Object[this.props.PropertyName] || ""}
                onChange={(e: React.FormEvent<HTMLElement>, value: string) => {
                    mobx.runInAction(() => {
                        this.Object[this.props.PropertyName] = value;
                    });
                    if (this.props.OnChange) {
                        this.props.OnChange();
                    }
                }}
                autoComplete="off"
                disabled={this.context.Disabled || this.props.TextFieldProps.disabled}
                required={
                    !this.context.Disabled && !this.props.TextFieldProps.disabled && this.props.TextFieldProps.required
                }
                onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    if (this.props.OnBlur) {
                        this.props.OnBlur(e.target.value);
                    }
                }}
                {...this.props.TextFieldProps}
            />
        );
    }
}
