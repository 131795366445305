import { toJS } from "mobx";
import { BaseEntity, BaseFile, Entity, FileParameter, FileResponse, WorkflowHistory } from ".";
import { WrapObject } from "./WrapperTools";

// abstraction for the controller provided by the backend
export interface IBaseController<T extends BaseEntity> {
    getAll(laterThanTimestamp: number, continuationToken?: string): Promise<IRadFeedResponse<T>>;
    add(item: T): Promise<T>;
    update(item: T): Promise<T>;
    get(id: string): Promise<T>;
    getMulti(ids: string[]): Promise<T[]>;
    getView(parameters: any[], viewNr?: number | undefined, laterThanTimestamp?: number | undefined): Promise<T[]>;
    delete(id: string): Promise<void>;
    getVersions(id: string): Promise<T[]>;
    getHistory(id: string): Promise<WorkflowHistory[]>;
}

export interface IRadFeedResponse<T extends BaseEntity> {
    continuationToken: string;
    results: T[];
}

// abstraction for the controller provided by the backend
export interface IBaseControllerWithFile<T extends BaseEntity, F extends BaseFile> extends IBaseController<T> {
    listFiles(parentId: string, folder?: string | undefined): Promise<F[]>;
    downloadFile(id: string, version: number | null): Promise<FileResponse>;
    getFile(id: string): Promise<F>;
    deleteFile(id: string): Promise<void>;
    addFile(
        file?: FileParameter | undefined,
        parentObjectId?: string | undefined,
        folderName?: string | undefined
    ): Promise<F>;
    updateFile(file?: FileParameter | undefined, objectId?: string | undefined): Promise<F>;
    getFileVersions(id: string): Promise<F[]>;
}

export interface IEntityRepository<T extends BaseEntity> extends IBaseController<T> {
    empty(): Promise<T>;
    getPermissions(object?: T): Promise<EnumRadPermission[]>;
    Metadata: Entity;
}

export interface IEntityRepositoryWithFile<T extends BaseEntity, F extends BaseFile>
    extends IEntityRepository<T>,
        IBaseControllerWithFile<T, F> {
    FileMetadata: Entity;
}

export enum EnumRadPermission {
    Update,
    Delete,
    Create
}

export type IBaseEntityWithConstructor<T, A extends BaseEntity> = new (args?: A) => T;

export class WrappedEntityRepository<A extends BaseEntity, B extends A> implements IEntityRepository<B> {
    protected TargetClass: IBaseEntityWithConstructor<B, A>;
    private Controller: IBaseController<A>;
    public Metadata: Entity;
    protected Wrapper: (a: A) => B;

    public constructor(
        controller: IBaseController<A>,
        targetClass: IBaseEntityWithConstructor<B, A>,
        metadata: Entity,
        wrapper: (a: A) => B
    ) {
        this.TargetClass = targetClass;
        this.Controller = controller;
        this.Metadata = metadata;
        this.Wrapper = wrapper;
    }

    public static From<A extends BaseEntity, B extends A>(
        parent: WrappedEntityRepository<A, A>,
        targetClass: IBaseEntityWithConstructor<B, A>
    ) {
        return new WrappedEntityRepository(parent.Controller, targetClass, parent.Metadata, (a) =>
            WrapObject(a, targetClass)
        );
    }

    public async getAll(timestamp: number, continuationToken: string): Promise<IRadFeedResponse<B>> {
        let result = await this.Controller.getAll(timestamp, continuationToken);
        return {
            results: this.processMany(result.results),
            continuationToken: result.continuationToken
        };
    }

    public async getMulti(ids: string[]): Promise<B[]> {
        let result = await this.Controller.getMulti(ids);
        return this.processMany(result);
    }

    public async add(item: B): Promise<B> {
        let obj = toJS(item);

        // set default values
        obj.lastModified = obj.created = new Date();
        obj.isCurrentVersion = true;
        obj._ts = 0;
        obj.version = 0;

        let result = await this.Controller.add(obj);
        return this.process(result);
    }
    public async update(item: B): Promise<B> {
        let result = await this.Controller.update(toJS(item));
        return this.process(result);
    }

    public async get(id: string): Promise<B> {
        let result = await this.Controller.get(id);
        return this.process(result);
    }

    public async getView(
        parameters: any[],
        viewNr?: number | undefined,
        laterThanTimestamp?: number | undefined
    ): Promise<B[]> {
        let result = await this.Controller.getView(parameters ?? [], viewNr, laterThanTimestamp);
        return this.processMany(result);
    }

    public async delete(id: string): Promise<void> {
        await this.Controller.delete(id);
    }

    public async getVersions(id: string): Promise<B[]> {
        let result = await this.Controller.getVersions(id);
        return this.processMany(result);
    }

    /** Create empty object */
    public empty(): Promise<B> {
        let newObject = new this.TargetClass();

        // set some default values
        newObject.lastModified = newObject.created = new Date();
        newObject.version = 0;

        return Promise.resolve(newObject);
    }

    public getPermissions(object?: B): Promise<EnumRadPermission[]> {
        let result = [EnumRadPermission.Create];
        if (object?.objectId) {
            result.push(EnumRadPermission.Delete, EnumRadPermission.Update);
        }
        return Promise.resolve(result);
    }

    public getHistory(id: string): Promise<WorkflowHistory[]> {
        return this.Controller.getHistory(id);
    }

    private process(input: A): B {
        return this.Wrapper(input);
    }

    private processMany(input: A[]): B[] {
        return input.map((i) => this.Wrapper(i));
    }
}

export class WrappedEntityRepositoryWithFile<A extends BaseEntity, B extends A, F extends BaseFile>
    extends WrappedEntityRepository<A, B>
    implements IEntityRepositoryWithFile<B, F>
{
    public FileMetadata: Entity;
    private ControllerWithFile: IBaseControllerWithFile<A, F>;
    protected Wrapper: (a: A) => B;
    public constructor(
        controller: IBaseControllerWithFile<A, F>,
        targetClass: IBaseEntityWithConstructor<B, A>,
        metadata: Entity,
        fileMetadata: Entity,
        wrapper: (a: A) => B
    ) {
        super(controller, targetClass, metadata, wrapper);
        this.ControllerWithFile = controller;
        this.FileMetadata = fileMetadata;
    }

    public static From<A extends BaseEntity, B extends A, F extends BaseFile>(
        parent: WrappedEntityRepositoryWithFile<A, A, F>,
        targetClass: IBaseEntityWithConstructor<B, A>
    ) {
        return new WrappedEntityRepositoryWithFile(
            parent.ControllerWithFile,
            targetClass,
            parent.Metadata,
            parent.FileMetadata,
            (a) => WrapObject(a, targetClass)
        );
    }

    listFiles(parentId: string, folder?: string | undefined): Promise<F[]> {
        return this.ControllerWithFile.listFiles(parentId, folder);
    }

    downloadFile(id: string, version?: number): Promise<FileResponse> {
        return this.ControllerWithFile.downloadFile(id, version);
    }
    getFile(id: string): Promise<F> {
        return this.ControllerWithFile.getFile(id);
    }
    deleteFile(id: string): Promise<void> {
        return this.ControllerWithFile.deleteFile(id);
    }
    addFile(
        file?: FileParameter | undefined,
        parentObjectId?: string | undefined,
        folderName?: string | undefined
    ): Promise<F> {
        return this.ControllerWithFile.addFile(file, parentObjectId, folderName);
    }
    updateFile(file?: FileParameter | undefined, objectId?: string | undefined): Promise<F> {
        return this.ControllerWithFile.updateFile(file, objectId);
    }
    getFileVersions(id: string): Promise<F[]> {
        return this.ControllerWithFile.getFileVersions(id);
    }
}

export class EntityRepository<A extends BaseEntity> extends WrappedEntityRepository<A, A> {
    public constructor(
        controller: IBaseController<A>,
        targetClass: IBaseEntityWithConstructor<A, A>,
        metadata: Entity
    ) {
        super(controller, targetClass, metadata, (a) => a);
    }
}

export class EntityRepositoryWithFile<A extends BaseEntity, F extends BaseFile> extends WrappedEntityRepositoryWithFile<
    A,
    A,
    F
> {
    public constructor(
        controller: IBaseControllerWithFile<A, F>,
        targetClass: IBaseEntityWithConstructor<A, A>,
        metadata: Entity,
        fileMetadata: Entity
    ) {
        super(controller, targetClass, metadata, fileMetadata, (a) => a);
    }
}
