import * as mobx from "mobx";
import { Knz2GK2022Bereich, Knz2Repository } from ".";
import { IGroupedListDatasource, IGroupedListGroup } from "../../tsx/Generic";
import { Utils } from "../../Utils/Utils";

export class Knz2BereicheGroupedByKPN implements IGroupedListDatasource {
    @mobx.computed public get IsLoading(): boolean {
        return this.RunningRequests > 0;
    }

    @mobx.observable protected RunningRequests = 0;
    protected Model: Knz2Repository;
    protected Filter: (b: Knz2GK2022Bereich) => boolean;

    public constructor(model: Knz2Repository, filter: (b: Knz2GK2022Bereich) => boolean) {
        this.Model = model;
        this.Filter = filter;
    }

    public OnItemUpdated(item: any): Promise<void> {
        return Promise.resolve();
    }

    public async GetAllGroups(): Promise<IGroupedListGroup[]> {
        try {
            if (!this.Model.BereicheCache.isInitialized) {
                mobx.runInAction(() => {
                    this.RunningRequests++;
                });
            }
            let result = await this.FilteredGroups(this.Filter);
            return result;
        } finally {
            if (this.RunningRequests > 0) {
                mobx.runInAction(() => {
                    this.RunningRequests--;
                });
            }
        }
    }

    public async ExpandGroup(group: IGroupedListGroup): Promise<any[]> {
        return group.items || [];
    }

    protected async FilteredGroups(filter: (b: Knz2GK2022Bereich) => boolean): Promise<IGroupedListGroup[]> {
        let allBereiche = await this.Model.BereicheCache.GetAllMap();
        const filteredItems = Utils.MapFilterToArray(allBereiche, (k, v) => filter(v));

        // group by Abschnitt
        const groupedItems: Map<string, Knz2GK2022Bereich[]> = Utils.GroupBy(
            filteredItems,
            (row: Knz2GK2022Bereich) => row.knz2ProjektRef?.objectId
        );

        const groups: IGroupedListGroup[] = [];

        const projekte = (await this.Model.ProjekteCache.GetAll()).sort((a, b) =>
            a.k2F35ProjektBezeichnung > b.k2F35ProjektBezeichnung ? 1 : -1
        );

        if (groupedItems.has(undefined)) {
            groups.push({
                key: "A1",
                name: `Anlage 1/Anlage 3`,
                items: groupedItems.get(undefined).sort((a, b) => a.CompareTo(b)),
                isExpanded: true
            });
        }

        for (const p of projekte) {
            if (!groupedItems.has(p.objectId)) continue;
            groups.push({
                key: p.objectId,
                name: `Projekt: ${p.k2F35ProjektBezeichnung}`,
                items: groupedItems.get(p.objectId).sort((a, b) => a.CompareTo(b)),
                isExpanded: true
            });
        }

        return groups;
    }
}
