import { observer } from "mobx-react";
import { IComboBox, IComboBoxOption, SelectableOptionMenuItemType, VirtualizedComboBox } from "office-ui-fabric-react";
import * as React from "react";
import { Knz4LSIngBueroModel } from "../../generated/BackendClient";
import { Knz4Repository } from "../../Model/Knz4LS";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";
import { EasyFormContext } from "../Generic/Forms/EasyFormContext";

@observer
@CatchReactErrors
export class Knz4LSFirmaControl extends React.Component<
    {
        Model: Knz4Repository;
        SelectedId: string;
        OnChange: (newId: string) => void;
        Disabled?: boolean;
    },
    {
        Options: IComboBoxOption[];
        InitialText: string;
    }
> {
    public static contextType = EasyFormContext;

    public readonly state = {
        Options: [],
        InitialText: undefined
    };
    private mounted = false;

    public componentWillUnmount(): void {
        this.mounted = false;
    }

    public async componentDidMount(): Promise<void> {
        this.mounted = true;
        if (this.state.Options.length > 0) {
            return;
        }

        const items = await this.props.Model.GetFirmen();
        const itemsSorted = Array.from(items.values()).sort((f1, f2) =>
            f1.k4F30IngName?.toLowerCase() > f2.k4F30IngName?.toLowerCase() ? 1 : -1
        );
        const options: IComboBoxOption[] = [];

        for (const obj of itemsSorted.filter((f) => f.k4F40IstFavorit)) {
            options.push({
                key: `${obj.objectId}`,
                text: `${obj.k4F30IngName} - ${obj.k4F34IngOrt}`,
                data: obj
            });
        }
        options.push({ key: "divider", text: "-", itemType: SelectableOptionMenuItemType.Divider });
        for (const obj of itemsSorted.filter((f) => !f.k4F40IstFavorit)) {
            options.push({
                key: `${obj.objectId}`,
                text: `${obj.k4F30IngName} - ${obj.k4F34IngOrt}`,
                data: obj
            });
        }

        const initialOption = options.find((value) => {
            return value.key === String(this.props.SelectedId);
        });

        if (this.mounted) {
            this.setState({
                Options: options,
                InitialText: initialOption?.text
            });
        }
    }

    public render(): JSX.Element {
        const disabled = (this.props.Disabled !== undefined && this.props.Disabled) || this.context.Disabled;

        return (
            <VirtualizedComboBox
                selectedKey={`${this.props.SelectedId}`}
                label="Ingenieurbüro"
                allowFreeform={false}
                autoComplete="off"
                options={this.state.Options}
                text={this.state.InitialText}
                onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
                    this.props.OnChange((option.data as Knz4LSIngBueroModel).objectId);
                }}
                onResolveOptions={() => {
                    return this.getOptions();
                }}
                required={true}
                placeholder="Ingenieurbüro wählen"
                disabled={disabled}
            />
        );
    }

    private getOptions(): IComboBoxOption[] {
        if (this.state.InitialText) {
            this.setState({
                InitialText: undefined
            });
        }

        return this.state.Options;
    }
}
