import { mergeStyleSets } from "@uifabric/styling/lib/MergeStyles";
import { observer } from "mobx-react";
import { IComboBox, IComboBoxOption, VirtualizedComboBox } from "office-ui-fabric-react";
import * as React from "react";
import { EnumRegionalbereich } from "../../../generated/BackendClient";
import { Knz4LVRepository } from "../../../Model/Knz4LV";
import { Utils } from "../../../Utils";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";
import { EasyFormContext } from "../../Generic/Forms/EasyFormContext";

const classNames = mergeStyleSets({
    ComboBoxRow: {
        minWidth: "700px",
        display: "inline-block"
    },
    ComboBoxSpan1: {
        minWidth: "60px", // 6 Zeichen max
        marginRight: "8px",
        display: "inline-block"
    }
});

@observer
@CatchReactErrors
export class Knz4LVRegionalBereicheControl extends React.Component<
    {
        Model: Knz4LVRepository;
        Selected: EnumRegionalbereich;
        OnChange: (newId: EnumRegionalbereich) => void;
        Disabled?: boolean;
    },
    {
        Options: IComboBoxOption[];
        InitialText: string;
    }
> {
    public static contextType = EasyFormContext;

    public readonly state = {
        Options: [],
        InitialText: undefined
    };
    private mounted = false;

    public componentWillUnmount(): void {
        this.mounted = false;
    }

    public async componentDidMount(): Promise<void> {
        this.mounted = true;
        if (this.state.Options.length > 0) {
            return;
        }

        const options: IComboBoxOption[] = [];
        for (const obj of Utils.EnumToArray(EnumRegionalbereich)) {
            options.push({
                key: `${obj}`,
                text: `${obj}`,
                data: obj
            });
        }

        const initialOption = options.find((value) => {
            return value.key === this.props.Selected;
        });

        if (this.mounted) {
            this.setState({
                Options: options,
                InitialText: initialOption?.text
            });
        }
    }

    public render(): JSX.Element {
        const disabled = this.props.Disabled !== undefined && this.props.Disabled && this.context.Disabled;

        return (
            <VirtualizedComboBox
                selectedKey={`${this.props.Selected}`}
                label="Zugeordneter Regionalbereich"
                allowFreeform={true}
                autoComplete="on"
                options={this.state.Options}
                text={this.state.InitialText}
                onChange={(
                    event: React.FormEvent<IComboBox>,
                    option?: IComboBoxOption,
                    index?: number,
                    value?: string
                ) => {
                    this.props.OnChange(option.data as EnumRegionalbereich);
                }}
                onResolveOptions={() => {
                    return this.getOptions();
                }}
                required={true}
                placeholder="Regionalbereich wählen"
                disabled={disabled}
            />
        );
    }

    private getOptions(): IComboBoxOption[] {
        if (this.state.InitialText) {
            this.setState({
                InitialText: undefined
            });
        }

        return this.state.Options;
    }
}
