import * as mobx from "mobx";
import { observer } from "mobx-react";
import { Label } from "office-ui-fabric-react";
import * as React from "react";
import { Knz4LSMassnahme } from "../../generated/BackendClient";
import { Knz4LSObjekt } from "../../Model/Knz4LS";
import { nameof } from "../../Utils/Utils";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";
import { Knz4LSBelegeRow } from "./Knz4LSBelegeRow";

@observer
@CatchReactErrors
export class Knz4LSBelege extends React.Component<
    {
        Object: Knz4LSObjekt;
        Disabled: boolean;
    },
    {}
> {
    public readonly state = {};

    public render() {
        const zeilen: Knz4LSMassnahme[] = this.props.Object.k4F25Massnahmen?.slice() || [];

        // add rows to start of array
        zeilen.unshift(
            new Knz4LSMassnahme({
                datum: this.props.Object.k4F03KontierungsbelegDate,
                betrag: this.props.Object.k4F25Foerderbetrag,
                massnahme: this.props.Object.k4F25Massnahme
            })
        );

        const disabled = (this.props.Disabled !== undefined && this.props.Disabled) || this.context.Disabled;

        return (
            <>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm3">
                        <Label>Kontierungsdatum</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm3">
                        <Label>Maßnahme</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm3">
                        <Label>Förderbetrag</Label>
                    </div>
                </div>
                {zeilen.map((value, index) => (
                    <Knz4LSBelegeRow
                        key={index}
                        Index={index}
                        Object={value}
                        IsReadOnly={disabled}
                        PropDatum={nameof<Knz4LSMassnahme>("datum")}
                        PropBetrag={nameof<Knz4LSMassnahme>("betrag")}
                        PropMassnahme={nameof<Knz4LSMassnahme>("massnahme")}
                        AllowRemove={zeilen.length > 1}
                        AllowAdd={index === zeilen.length - 1}
                        OnChange={() => {
                            this.UpdateObject(zeilen);
                        }}
                        OnAdd={(index: number) => {
                            zeilen.push(
                                new Knz4LSMassnahme({ datum: undefined, betrag: undefined, massnahme: undefined })
                            );
                            this.UpdateObject(zeilen);
                        }}
                        OnRemove={(index: number) => {
                            zeilen.splice(index, 1);
                            this.UpdateObject(zeilen);
                        }}
                    />
                ))}
            </>
        );
    }

    private UpdateObject(strecken: Knz4LSMassnahme[]): void {
        mobx.runInAction(() => {
            this.props.Object.k4F03KontierungsbelegDate = strecken[0].datum;
            this.props.Object.k4F25Foerderbetrag = strecken[0].betrag;
            this.props.Object.k4F25Massnahme = strecken[0].massnahme;

            this.props.Object.k4F25Massnahmen = strecken.slice(1);
        });
    }
}
