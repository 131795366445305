import { Knz4LSObjekt, Knz4Repository } from ".";
import { Hierarchy } from "../../generated/BackendClient";
import { IGroupedListDatasource2, IGroupedListGroup2 } from "../../tsx/Generic/LargeGroupedList2";

export class Knz4LSObjektListModel implements IGroupedListDatasource2 {
    private Model: Knz4Repository;
    public constructor(model: Knz4Repository) {
        this.Model = model;
    }

    public async GetRootNode(): Promise<IGroupedListGroup2> {
        return {
            key: "root",
            name: "Root",
            data: null,
            children: undefined,
            items: undefined,
            notLoaded: true,
            isLoading: false,
            count: 0,
            startIndex: 0,
            level: -1,
            isCollapsed: true
        };
    }

    public async ExpandGroup(group: IGroupedListGroup2): Promise<void> {
        if (!group.notLoaded || group.isCollapsed) {
            return;
        }

        const folder: Hierarchy = group.data;
        if (group.level < 1) {
            group.children = await this._getGroups(group);
        } else {
            group.items = await this.Model.GetKnz4LSObjekt(folder);
            group.items.sort((a, b) => (a.K4F03Kennnummer > b.K4F03Kennnummer ? 1 : -1));
        }
        group.notLoaded = false;
        group.isLoading = false;
    }

    public async OnItemUpdated(rootNode: IGroupedListGroup2, item: Knz4LSObjekt): Promise<void> {
        const groups = this.FindItemGroups(rootNode, item);
        if (groups) {
            // check if item still belongs to groups, flag for reload if not
            for (const g of groups) {
                if (!!g.data && item.k4F01Kurzprojektnummer === (g.data as Hierarchy).id) {
                    g.notLoaded = true;
                }
            }
            // folder of item should alays refresh - maybe the sorting order changed
            groups[groups.length - 1].notLoaded = true;
        }

        // find new folder of item and make sure it reloads
        let bestGroup = this.FindBestMatchingGroup(rootNode, item.k4F01Kurzprojektnummer);
        
        if (!bestGroup) {
            // looks like we have a new KPN. Make sure that the Bundesland folder is reloaded then
            bestGroup = this.FindBestMatchingGroup(rootNode, item.k4F13ObjBundesland);
        }
        if (bestGroup) {
            bestGroup.notLoaded = true;
        }
    }

    /**
     * Find best matching group that contains item or must be reloaded to make item folder visible
     * @param node
     * @param fileRef
     */
    private FindBestMatchingGroup(node: IGroupedListGroup2, id: string): IGroupedListGroup2 {
        if (node.children) {
            for (const c of node.children) {
                const result = this.FindBestMatchingGroup(c as IGroupedListGroup2, id);
                if (result) {
                    return result;
                }
            }
        }

        return id === (node.data as Hierarchy)?.id ? node : undefined;
    }

    /**
     * Return path to item
     * @param group
     * @param item
     */
    private FindItemGroups(group: IGroupedListGroup2, item: Knz4LSObjekt): IGroupedListGroup2[] {
        if (group.items && !group.notLoaded) {
            if (group.items.some((i) => i.objectId === item.objectId)) {
                return [group];
            }
        }

        const result: IGroupedListGroup2[] = [];
        if (group.children) {
            for (const g of group.children as IGroupedListGroup2[]) {
                const output = this.FindItemGroups(g as IGroupedListGroup2, item);
                if (output) {
                    result.push(...output);
                }
            }
        }

        if (result.length > 0) {
            return [group, ...result];
        }
        return undefined;
    }

    private async _getGroups(parent: IGroupedListGroup2): Promise<IGroupedListGroup2[]> {
        const folders = await this.Model.GetKnz4LSObjektFolders(parent ? parent.data : null);

        return folders
            .sort((a, b) => (a.title > b.title ? 1 : -1))
            .map((f) => this.MapFolderToIGroup(f, parent.level + 1));
    }

    private MapFolderToIGroup(folder: Hierarchy, depth: number): IGroupedListGroup2 {
        return {
            key: folder.id,
            name: folder.title,
            data: folder,
            children: undefined,
            items: undefined,
            notLoaded: true,
            isLoading: false,
            count: 0,
            startIndex: 0,
            level: depth,
            isCollapsed: true
        };
    }
}
