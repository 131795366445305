import { ComboBox, IComboBox, IComboBoxOption, IComboBoxProps } from "office-ui-fabric-react";
import React from "react";
import { IFieldRenderer, IFieldRendererEditProps, IFieldRendererViewProps } from "../FieldRenderer";

function ConvertToComboboxItem(input: string): IComboBoxOption {
    return { key: input, text: input==="DB Netz Umweltschutz" ? "DB InfraGO AG, Umweltschutzmanagement": input };
}

function sortItems(input: string[]): string[] {
    return input.sort((f1, f2) => (f1?.toLowerCase() > f2?.toLowerCase() ? 1 : -1));
}

export interface RadChoiceFieldMultiProps {
    ComboBoxProps?: Partial<IComboBoxProps>;
    Value: string[];
    Choices: string[];
    OnChange?: (value: string[]) => void;
    MultiSelect: boolean;
}

export function RadChoiceFieldMulti({
    Choices,
    OnChange,
    Value,
    MultiSelect,
    ComboBoxProps
}: React.PropsWithChildren<RadChoiceFieldMultiProps>) {
    const currentItems = sortItems(Value || []);

    return (
        <ComboBox
            multiSelect={MultiSelect}
            selectedKey={currentItems}
            allowFreeform={false}
            autoComplete="on"
            options={sortItems(Choices).map((c) => ConvertToComboboxItem(c))}
            text={currentItems.join(", ").replace("DB Netz Umweltschutz", "DB InfraGO AG, Umweltschutzmanagement")}
            onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
                if (MultiSelect === true) {
                    if (!option) {
                        return;
                    }
                    let selected: string[] = currentItems || [];
                    
                    if (option.selected) {
                        selected.push(String(option.key === "DB Netz Umweltschutz" ? option.key : option.text));
                        selected = sortItems(selected);
                    } else {
                        selected = selected.filter((str) => {
                            if(str === "DB Netz Umweltschutz")
                                return false;
                            return str !== option.text;
                        });
                    }
                    OnChange(selected);
                } else {
                    if (option?.key) {
                        OnChange([option?.text]);
                    } else {
                        OnChange([]);
                    }
                }
            }}
            {...ComboBoxProps}
        />
    );
}

export const RadChoiceMultiFieldRenderer: IFieldRenderer = {
    EditField: (props: IFieldRendererEditProps) =>
        RadChoiceFieldMulti({
            Value: props.Value,
            ComboBoxProps: { label: props.Metadata.title, disabled: props.Disabled || props.Metadata.readOnly },
            OnChange: props.OnChange,
            Choices: (props.Metadata as any).choices,
            MultiSelect: true
        }),
    ShowField: (props: IFieldRendererViewProps) =>
        RadChoiceFieldMulti({
            Value: props.Value,
            ComboBoxProps: { label: props.Metadata.title, disabled: true },
            Choices: (props.Metadata as any).choices,
            MultiSelect: true
        }),

    ListField: (props: IFieldRendererViewProps) => <>{props.Value && props.Value.join(", ")}</>
};
