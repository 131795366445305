import { observer } from "mobx-react";
import * as React from "react";
import { Knz2DokumenteEditDialog, Knz2DokumenteFilterControl, Knz2DokumenteProjektList } from ".";
import { Knz2GK2018Projekt } from "../../../Model/Knz2/GK2018/Knz2Projekt";
import { Knz2ProjekteListModelFilter } from "../../../Model/Knz2/GK2018/Knz2ProjekteListModelFilter";
import { Knz2Repository } from "../../../Model/Knz2/GK2018/Knz2Repository";
import { ChoiceDialog, HandleError } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz2LSProjekteContainer extends React.Component<
    {
        Model: Knz2Repository;
    },
    {
        Projekt: Knz2GK2018Projekt;
        ShowEditDialog: boolean;
        Filter: Knz2ProjekteListModelFilter;
    }
> {
    public readonly state = {
        Projekt: undefined,
        ShowEditDialog: false,
        Filter: new Knz2ProjekteListModelFilter("")
    };
    private listRef: React.RefObject<Knz2DokumenteProjektList> = React.createRef();
    private choiceRef: React.RefObject<ChoiceDialog> = React.createRef();

    public render(): JSX.Element {
        return (
            <HandleError>
                <ChoiceDialog ref={this.choiceRef} />
                {this.state.ShowEditDialog && (
                    <Knz2DokumenteEditDialog
                        Model={this.props.Model}
                        InitialModel={this.state.Projekt}
                        hideDialog={false}
                        closeDialog={() => {
                            this.setState({ ShowEditDialog: false });
                        }}
                    />
                )}

                <Knz2DokumenteFilterControl
                    Model={this.props.Model}
                    OnUpdateFilter={(filter: Knz2ProjekteListModelFilter) => {
                        this.setState({ Filter: filter });
                    }}
                />
                <Knz2DokumenteProjektList
                    ref={this.listRef}
                    Model={this.props.Model}
                    OnEditProjekt={async (projekt) => {
                        this.setState({
                            Projekt: projekt,
                            ShowEditDialog: true
                        });
                    }}
                    Filter={this.state.Filter}
                />
            </HandleError>
        );
    }
}
