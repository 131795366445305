import { observer } from "mobx-react";
import { IColumn, ScrollablePane, ScrollbarVisibility } from "office-ui-fabric-react";
import * as React from "react";
import {
    Knz2Altprojekt,
    Knz2AltprojekteListModel,
    Knz2AltprojekteListModelFilter,
    Knz2Repository
} from "../../../Model/Knz2";
import { LargeDetailsList } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz2AltprojekteList extends React.Component<
    {
        Model: Knz2Repository;
        OnEditItem: (abschnitt: Knz2Altprojekt) => void;
        Filter: Knz2AltprojekteListModelFilter;
    },
    {
        ListModel: Knz2AltprojekteListModel;
    }
> {
    private columns: IColumn[] = [
        {
            key: "Feld01",
            name: "KPN",
            minWidth: 40,
            maxWidth: 40,
            isResizable: true,
            onRender: (item: Knz2Altprojekt) => item.k2F33Kurzprojektnummer
        },
        {
            key: "Feld03",
            name: "Vorschaujahr",
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            onRender: (item: Knz2Altprojekt) => item.k2F38ProjektVorschau
        },
        {
            key: "Feld02",
            name: "Bezeichnung",
            minWidth: 50,
            maxWidth: 400,
            isResizable: true,
            onRender: (item: Knz2Altprojekt) => item.k2F35ProjektBezeichnungLang
        }
    ];

    public readonly state = {
        ListModel: new Knz2AltprojekteListModel(this.props.Model, new Knz2AltprojekteListModelFilter(""))
    };

    public componentDidUpdate(prevProps): void {
        if (!this.props.Filter.Equals(prevProps.Filter)) {
            this.setState({ ListModel: new Knz2AltprojekteListModel(this.props.Model, this.props.Filter) });
        }
    }
    public render(): JSX.Element {
        return (
            <div style={{ position: "relative", height: "80vh" }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <LargeDetailsList
                        Columns={this.columns}
                        DataSource={this.state.ListModel}
                        OnItemInvoked={(item) => {
                            this.props.OnEditItem(item);
                        }}
                    />
                </ScrollablePane>
            </div>
        );
    }
}
