import { BaseEntity } from "../../generated/BackendClient";
import { IBaseEntityWithConstructor } from "./EntityRepository";

export function WrapObject<A extends BaseEntity, B extends A>(
    input: A,
    targetClass: IBaseEntityWithConstructor<B, A>
): B {
    let output = new targetClass(input);
    output["key"] = output.objectId; // make react happy :(
    return output;
}

export function WrapObjects<A extends BaseEntity, B extends A>(
    input: A[],
    targetClass: IBaseEntityWithConstructor<B, A>
): B[] {
    let output = input.map((i) => new targetClass(i));
    for (let o of output) {
        o["key"] = o.objectId; // make react happy :(
    }
    return output;
}

export function ArrayAsMap<A extends BaseEntity>(input: A[]): Map<string, A> {
    return new Map<string, A>(input.map((p) => [p.objectId, p]));
}
