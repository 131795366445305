import { observer } from "mobx-react";
import * as React from "react";
import { BerichteDetailsDialog } from "..";
import { RadRepository } from "../../../generated/RadRepository";
import { Bericht, BerichteRepository } from "../../../Model/Berichte";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class ShowBerichtPreview extends React.Component<
    {
        BerichtId: string;
        CloseDialog: () => void;
    },
    {
        BerichtItem: Bericht;
        BerichteRepository: BerichteRepository;
    }
> {
    public readonly state = { BerichtItem: undefined, BerichteRepository:undefined };

    public async componentDidMount(): Promise<void> {
        if (!this.props.BerichtId) {
            return;
        }

        let wf = RadRepository.BerichtModel;
        let item = await wf.get(this.props.BerichtId);
        let repository = new BerichteRepository(item.berichtKennzahlArt);
        let bericht = await repository.GetBericht(item.objectId)
      
        this.setState({
            BerichteRepository: new BerichteRepository(bericht.berichtKennzahlArt),
            BerichtItem: bericht
        });
    }

    public render(): JSX.Element {
        return (
            <>
                {this.state.BerichtItem && (
                    <BerichteDetailsDialog
                        Model={this.state.BerichteRepository}
                        Item={this.state.BerichtItem}
                        ReadOnly={true}
                        StartFreigabeProcess={(): void => {
                            return;
                        }}
                        CloseDialog={this.props.CloseDialog}
                    />
                )}
            </>
        );
    }
}
