import Dialog, { DialogType, IDialogProps } from "office-ui-fabric-react/lib/Dialog";
import * as React from "react";

export function DBDialog(props: React.PropsWithChildren<IDialogProps>) {
    return (
        <Dialog
            minWidth="900px"
            hidden={false}
            {...props}
            dialogContentProps={{
                type: DialogType.normal,
                ...props.dialogContentProps
            }}
            modalProps={{
                isBlocking: true,
                styles: {
                    root: {
                        alignItems: "start",
                        position: "unset"
                    }
                },
                ...props.modalProps
            }}
        >
            {props.children}
        </Dialog>
    );
}
