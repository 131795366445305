import { observer } from "mobx-react";
import { ScrollablePane, ScrollbarVisibility } from "office-ui-fabric-react";
import * as React from "react";
import { Knz4LVBereicheGrouped, Knz4LVList } from ".";
import { Knz4LV, Knz4LVListModelFilter, Knz4LVRepository } from "../../Model/Knz4LV";
import { LargeGroupedList } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz4LVDeletedItemsList extends React.Component<
    {
        Model: Knz4LVRepository;
        Filter: Knz4LVListModelFilter;
        OnEditKnz4LVItem: (knz4LVItem: Knz4LV) => void;
        OnSelectedItems?: (selectedItems: []) => void;
        LoadItemsFromPapierkorb?: boolean;
    },
    {
        ListModel: Knz4LVBereicheGrouped;
    }
> {
    private listRef: React.RefObject<LargeGroupedList> = React.createRef();

    constructor(props) {
        super(props);
        this.listRef = React.createRef();
        this.state = {
            ListModel: new Knz4LVBereicheGrouped(this.props.Model, this.props.LoadItemsFromPapierkorb)
        };
    }

    public async RefreshListItems(): Promise<void> {
        await this.listRef?.current?.RefreshGroups();
    }

    public async onItemUpdated(item: Knz4LV): Promise<void> {
        await this.state.ListModel.OnItemUpdated(item);
    }

    public render() {
        return (
            <div style={{ position: "relative", height: "80vh" }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <LargeGroupedList
                        Columns={Knz4LVList.columns}
                        DataSource={this.state.ListModel}
                        OnItemInvoked={(item: any) => {
                            this.props.OnEditKnz4LVItem(item);
                        }}
                        OnSelectedItems={(selectedItems: []) => {
                            if (this.props.OnSelectedItems) {
                                this.props.OnSelectedItems(selectedItems);
                            }
                        }}
                        ref={this.listRef}
                    />
                </ScrollablePane>
            </div>
        );
    }
}
