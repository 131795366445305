import { observer } from "mobx-react";
import { MessageBar, MessageBarType } from "office-ui-fabric-react";
import * as React from "react";
import { ItemWithWorkflow } from "../../../Model/Workflows/ItemWithWorkflow";

@observer
export class WorkflowErrorMessages extends React.Component<
    {
        Item: ItemWithWorkflow;
    },
    {}
> {
    public render(): JSX.Element {
        if (!this.props.Item.workflowInfo) {
            return null;
        }
        return (
            <>
                {this.props.Item.workflowInfo.errorMessages?.length > 0 && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        <div>
                            <b>Es sind Fehler aufgetreten:</b>
                        </div>
                        {this.props.Item.workflowInfo.errorMessages.map((msg, index) => {
                            return <div key={index}>{msg}</div>;
                        })}
                    </MessageBar>
                )}
            </>
        );
    }
}
