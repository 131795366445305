import { observer } from "mobx-react";
import {
    DefaultButton, DialogFooter, Pivot,
    PivotItem,
    PrimaryButton,
    TextField
} from "office-ui-fabric-react";
import * as React from "react";
import { Knz2BereicheTable } from ".";
import { LskRepository } from "../../../DAL/LskRepository";
import { Knz2Altprojekt, Knz2Repository } from "../../../Model/Knz2";
import { RadFolderView } from "../../../Rad/tsx/Controls/RadFolderView";
import { nameof, Utils } from "../../../Utils";
import { DBDialog, EasyForm, EasyTextField, Header, PleaseWait } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz2AltprojekteEditDialog extends React.Component<
    {
        Model: Knz2Repository;
        InitialItem: Knz2Altprojekt;
        closeDialog: (result: boolean, item: Knz2Altprojekt) => void;
    },
    {
        Item: Knz2Altprojekt;
        IsLoading: boolean;
    }
> {
    private repository = LskRepository.Knz2Altprojekt;
    public readonly state = {
        Item: new Knz2Altprojekt(this.props.InitialItem),
        IsLoading: false
    };

    public async componentDidMount(): Promise<void> {
        this.setState({ IsLoading: true });
        this.setState({
            Item: await LskRepository.Knz2Altprojekt.get(this.props.InitialItem.objectId),
            IsLoading: false
        });
    }

    public render(): JSX.Element {
        return (
            <DBDialog
                onDismiss={() => {
                    this.ExitDialog(false);
                }}
                dialogContentProps={{
                    title: "Altprojekt bearbeiten"
                }}
                maxWidth={1200}
            >
                <PleaseWait
                    ShowSpinner={this.state.IsLoading}
                    render={() => (
                        <>
                            <EasyForm Object={this.state.Item} Disabled={true} ShowFooter={false}>
                                <Pivot>
                                    <PivotItem headerText="Altprojekt">
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <Header>Altprojekt</Header>
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm2">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2Altprojekt>("k2F33Kurzprojektnummer")}
                                                        TextFieldProps={{ label: "KPN" }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm2">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2Altprojekt>("k2F38ProjektVorschau")}
                                                        TextFieldProps={{ label: "Vorschaujahr" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm2">
                                                    <TextField
                                                        value={Utils.FormatKilometer(this.state.Item.Summe1)}
                                                        label="Netto km"
                                                        disabled={true}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm2">
                                                    <TextField
                                                        value={Utils.FormatKilometer(this.state.Item.Summe2)}
                                                        label="Netto on Top km"
                                                        disabled={true}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm4">
                                                    <TextField
                                                        value={Utils.FormatKilometer(this.state.Item.Summe3)}
                                                        label="Summe Netto + Netto on Top km"
                                                        disabled={true}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2Altprojekt>(
                                                            "k2F35ProjektBezeichnungLang"
                                                        )}
                                                        TextFieldProps={{
                                                            label: "Bezeichnung",
                                                            multiline: true,
                                                            rows: 6
                                                        }}
                                                    />
                                                </div>{" "}
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2Altprojekt>("k2F41Bemerkungen")}
                                                        TextFieldProps={{
                                                            label: "Bemerkungen",
                                                            multiline: true,
                                                            disabled: false,
                                                            rows: 6
                                                        }}
                                                        Object={this.state.Item}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </PivotItem>
                                    <PivotItem headerText="Bereiche">
                                        <div className="ms-Grid" dir="ltr">
                                            {this.state.Item.k2F40WeitereStrecken?.length > 0 && (
                                                <>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12">
                                                            <Header>STU-Untersuchungbereich</Header>
                                                        </div>
                                                    </div>
                                                    <Knz2BereicheTable Items={this.state.Item.k2F40WeitereStrecken} />
                                                </>
                                            )}
                                            {this.state.Item.k2F41OnTopStrecken?.length > 0 && (
                                                <>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12">
                                                            <Header>On-Top-Strecken</Header>
                                                        </div>
                                                    </div>
                                                    <Knz2BereicheTable Items={this.state.Item.k2F41OnTopStrecken} />
                                                </>
                                            )}
                                            {this.state.Item.k2F42Bereiche?.length > 0 && (
                                                <>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12">
                                                            <Header>Bereiche</Header>
                                                        </div>
                                                    </div>
                                                    <Knz2BereicheTable Items={this.state.Item.k2F42Bereiche} />
                                                </>
                                            )}
                                        </div>
                                    </PivotItem>
                                    <PivotItem headerText="Dateien">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12">
                                                <RadFolderView
                                                    ObjectId={this.state.Item.objectId}
                                                    Disabled={true}
                                                    LabelText="Dateien"
                                                    Compact={false}
                                                    Repository={this.repository}
                                                />
                                            </div>
                                        </div>
                                    </PivotItem>
                                </Pivot>
                                <DialogFooter>
                                    <PrimaryButton
                                        onClick={() => {
                                            this.ExitDialog(true);
                                        }}
                                        text="Speichern"
                                        disabled={this.props.Model.IsReadOnly}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.ExitDialog(false);
                                        }}
                                        text="Abbrechen"
                                    />
                                </DialogFooter>
                            </EasyForm>
                        </>
                    )}
                />
            </DBDialog>
        );
    }

    private async ExitDialog(shouldSave: boolean) {
        if (shouldSave) {
            this.setState({
                IsLoading: true
            });
        }

        this.props.closeDialog(shouldSave, this.state.Item);
    }
}
