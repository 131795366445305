import { observer } from "mobx-react";
import {
    DetailsList,
    DetailsListLayoutMode,
    IconButton,
    ScrollablePane,
    ScrollbarVisibility,
    Selection,
    SelectionMode
} from "office-ui-fabric-react";
import * as React from "react";
import {
    Knz2BereicheGroupedByAbschnittList,
    Knz2BereichListModelFilter,
    Knz2GK2022Bereich,
    Knz2GK2022Projekt,
    Knz2Repository
} from "../../../Model/Knz2";
import { EnumBereichStatus } from "../../../Rad/DAL";
import { Utils } from "../../../Utils";
import { Header, PleaseWait } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";
import { Knz2EditBereichDialog, Knz2FilterControl } from "../Bereiche";
import { EnumColumnType, Knz2BereicheList } from "../Common";

@observer
@CatchReactErrors
export class Knz2DokumenteVerknüpfen extends React.Component<
    {
        Model: Knz2Repository;
        Projekt: Knz2GK2022Projekt;
        Disabled?: boolean;
    },
    {
        Filter: Knz2BereichListModelFilter;
        StuBereicheToAdd: Knz2GK2022Bereich[];
        StuBereicheToRemove: Knz2GK2022Bereich[];
        StuBereiche: Knz2GK2022Bereich[];
        IsLoading: boolean;
        ListModel: Knz2BereicheGroupedByAbschnittList;
        SelectedBereich: Knz2GK2022Bereich;
        ShowEditDialog: boolean;
    }
> {
    private listRef: React.RefObject<Knz2BereicheList>;
    private selection: Selection;

    constructor(props) {
        super(props);
        this.listRef = React.createRef();
        this.state = {
            Filter: new Knz2BereichListModelFilter("", null),
            StuBereicheToAdd: [],
            StuBereicheToRemove: [],
            StuBereiche: [],
            IsLoading: true,
            ListModel: new Knz2BereicheGroupedByAbschnittList(this.props.Model, (b: Knz2GK2022Bereich) => {
                return this.BereichFilterFunction(b);
            }),
            SelectedBereich: undefined,
            ShowEditDialog: false
        };

        this.selection = new Selection({
            onSelectionChanged: () => {
                let selectedBereicheToRemove = [];
                selectedBereicheToRemove = this.selection.getSelection();
                this.setState({ StuBereicheToRemove: selectedBereicheToRemove });
            }
        });
    }

    public async componentDidMount() {
        await this.Refresh();
        this.setState({ IsLoading: false });
    }

    public render(): JSX.Element {
        return (
            <>
                {this.state.ShowEditDialog && (
                    <Knz2EditBereichDialog
                        Model={this.props.Model}
                        InitialModel={this.state.SelectedBereich}
                        readOnly={this.props.Disabled}
                        closeDialog={async (shouldSave: boolean, newModel: Knz2GK2022Bereich) => {
                            if (shouldSave) {
                                await this.props.Model.SaveBereich(newModel);
                                // this.state.CurrentListModel.OnItemUpdated(newModel);
                            }
                            this.setState({ ShowEditDialog: false });
                            this.listRef.current.RefreshGroups();
                        }}
                    />
                )}

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm5">
                            <Header>
                                Verknüpfte Sanierungsbereiche (Netto km:{" "}
                                {Utils.FormatKilometer(this.props.Projekt.F15Summe1)}
                                {" km)"}
                            </Header>

                            <PleaseWait
                                ShowSpinner={this.state.IsLoading}
                                render={() => (
                                    <div style={{ position: "relative", height: "80vh" }}>
                                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                                            <DetailsList
                                                items={this.state.StuBereiche}
                                                columns={Knz2BereicheList.ColumnsAnlage1}
                                                setKey="set"
                                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                                compact={true}
                                                selectionMode={SelectionMode.multiple}
                                                selection={this.selection}
                                                onItemInvoked={(item: Knz2GK2022Bereich) => {
                                                    this.setState({ SelectedBereich: item, ShowEditDialog: true });
                                                }}
                                            />
                                        </ScrollablePane>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm1" style={{}}>
                            <div style={{ marginTop: "30vh", textAlign: "center" }}>
                                <IconButton
                                    primary={true}
                                    iconProps={{ iconName: "TriangleSolidLeft12" }}
                                    onClick={() => {
                                        this.AddBereicheToStuId();
                                    }}
                                    disabled={this.props.Disabled}
                                />
                                <IconButton
                                    iconProps={{ iconName: "TriangleSolidRight12" }}
                                    onClick={() => {
                                        this.RemoveBereicheFromStuId();
                                    }}
                                    disabled={this.props.Disabled}
                                />
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm6">
                            <Header>Verfügbare Sanierungsbereiche</Header>
                            <Knz2FilterControl
                                Model={this.props.Model}
                                OnUpdateFilter={(filter: Knz2BereichListModelFilter) => {
                                    this.setState({ Filter: filter });
                                    this.listRef.current.RefreshGroups();
                                }}
                                StatusValues={[
                                    EnumBereichStatus.InBearbeitung,
                                    EnumBereichStatus.Abgeschlossen,
                                    EnumBereichStatus.ErstsanierungErfolgt
                                ]}
                            />
                            <Knz2BereicheList
                                Model={this.props.Model}
                                ref={this.listRef}
                                OnSelectedItems={(selectedItems: []) => {
                                    this.setState({ StuBereicheToAdd: selectedItems });
                                }}
                                ListModel={this.state.ListModel}
                                OnEditBereich={(bereich: Knz2GK2022Bereich) => {
                                    this.setState({ SelectedBereich: bereich, ShowEditDialog: true });
                                }}
                                ColumnType={EnumColumnType.Anlage1}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    private async AddBereicheToStuId() {
        const filteredBereiche: Knz2GK2022Bereich[] = this.state.StuBereicheToAdd.filter(
            (bereich: Knz2GK2022Bereich) => {
                return !this.state.StuBereiche.some((b) => b.objectId === bereich.objectId);
            }
        );

        if (filteredBereiche.length > 0) {
            this.setState({
                IsLoading: true
            });

            await this.props.Model.SaveBereicheByStu(filteredBereiche, this.props.Projekt);
            filteredBereiche.forEach((b: Knz2GK2022Bereich) => {
                this.state.ListModel.OnItemUpdated(b);
            });
            this.Refresh();
            this.setState({
                StuBereicheToAdd: [],
                IsLoading: false
            });
        }
    }

    private async RemoveBereicheFromStuId() {
        if (this.state.StuBereicheToRemove.length > 0) {
            this.setState({
                IsLoading: true
            });

            await this.props.Model.SaveBereicheByStu(this.state.StuBereicheToRemove);
            this.state.StuBereicheToRemove.forEach((b: Knz2GK2022Bereich) => {
                this.state.ListModel.OnItemUpdated(b);
            });
            await this.Refresh();
            this.selection.setItems(this.selection.getItems(), true);
            this.setState({
                StuBereicheToRemove: [],
                IsLoading: false
            });
        }
    }

    private async Refresh() {
        await this.props.Model.LoadBereicheByStuIdId(this.props.Projekt);
        this.setState({ StuBereiche: this.props.Projekt.Bereiche.filter((b) => !b.f44IstGeteilt) });
        await this.listRef.current.RefreshGroups();
    }

    private BereichFilterFunction(b: Knz2GK2022Bereich): boolean {
        if (b.knz2ProjektRef) {
            return false;
        }
        if (b.f44IstGeteilt) {
            return false;
        }

        if (b.f25Status !== EnumBereichStatus.InBearbeitung && b.f25Status !== EnumBereichStatus.ErstsanierungErfolgt) {
            return false;
        }
        if (this.state.Filter.status && this.state.Filter.status !== b.f25Status) {
            return false;
        }

        if (this.state.Filter.text) {
            if (
                Utils.IncludesText(b.f02Bezeichnung, this.state.Filter.text) ||
                Utils.IncludesText(b.f01Streckennummer, this.state.Filter.text) ||
                Utils.IncludesText(b.f27WeitereStrecken, this.state.Filter.text)
            ) {
                return true;
            }

            return false;
        }

        return true;
    }
}
