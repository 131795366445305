import { observer } from "mobx-react";
import { ActionButton, SearchBox } from "office-ui-fabric-react";
import * as React from "react";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz2SimpleFilterControl extends React.Component<
    {
        OnUpdateFilter: (filterText: string) => void;
        Text: string;
    },
    {
        filterText: string;
    }
> {
    constructor(props) {
        super(props);
        this.state = { filterText: "" };
    }

    public componentDidUpdate(prevProps, prevState, snapshot): void {
        if (prevState.filterText !== this.state.filterText) {
            this.props.OnUpdateFilter(this.state.filterText);
        }
    }

    public render(): JSX.Element {
        return (
            <div className="ms-Grid" dir="ltr" style={{ maxWidth: "700px", padding: "0px 0px 0px 0px" }}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm8" style={{ marginTop: "4px" }}>
                        <SearchBox
                            placeholder={this.props.Text}
                            iconProps={{ iconName: "Filter" }}
                            onChange={(event: any, value: string) => {
                                this.setState({ filterText: value });
                            }}
                            onClear={() => {
                                this.setState({ filterText: "" });
                            }}
                            value={this.state.filterText}
                            onSearch={(newValue: any) => {
                                this.setState({ filterText: newValue });
                            }}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm3">
                        <ActionButton
                            iconProps={{ iconName: "ClearFilter" }}
                            allowDisabledFocus={true}
                            onClick={() => {
                                this.setState({ filterText: "" });
                            }}
                        >
                            Filter zurücksetzen
                        </ActionButton>
                    </div>
                </div>
            </div>
        );
    }
}
