import * as mobx from "mobx";
import { LskRepository } from "../../../DAL/LskRepository";
import { EnumBereichStatus, EnumUserRole } from "../../../generated/BackendClient";
import { EntityCache } from "../../../Rad/DAL";
import { UserRepository } from "../../../Rad/DAL/UserRepository";
import { Utils } from "../../../Utils";
import { EnumLVStatus } from "../Knz2Projekt";
import { Knz2GK2018Bereich } from "./Knz2Bereich";
import { Knz2GK2018Projekt } from "./Knz2Projekt";

export enum EnumKnz2FoRi {
    Foeri2022 = "FöRil 2022",
    FoeRi2019 = "FöRil 2019",
    FoeRiLt2019 = "FöRil < 2019"
}

// helper arrays used to identify Anlage 1 and Anlage 3
const Knz2BereichStatusAnlage3 = [
    EnumBereichStatus.Anlage3,
    EnumBereichStatus.VorschlagBMVI,
    EnumBereichStatus.ErstsanierungErfolgt
];
const Knz2BereichStatusAnlage1 = [EnumBereichStatus.InBearbeitung, EnumBereichStatus.Abgeschlossen];

// define valid status for each key
const Knz2AllowedStatus: { [key in keyof typeof EnumBereichStatus]: EnumBereichStatus[] } = {
    Anlage3: [EnumBereichStatus.VorschlagBMVI, EnumBereichStatus.ErstsanierungErfolgt, EnumBereichStatus.Ausgeblendet],
    VorschlagBMVI: [
        EnumBereichStatus.InBearbeitung,
        EnumBereichStatus.ErstsanierungErfolgt,
        EnumBereichStatus.Anlage3,
        EnumBereichStatus.Ausgeblendet
    ],
    InBearbeitung: [
        EnumBereichStatus.Abgeschlossen,
        EnumBereichStatus.ErstsanierungErfolgt,
        EnumBereichStatus.Anlage3,
        EnumBereichStatus.Ausgeblendet
    ],
    Abgeschlossen: [EnumBereichStatus.ErstsanierungErfolgt, EnumBereichStatus.InBearbeitung],
    Ausgeblendet: [...(Object.values(EnumBereichStatus) as EnumBereichStatus[])],
    ErstsanierungErfolgt: [...(Object.values(EnumBereichStatus) as EnumBereichStatus[])]
};

export { Knz2BereichStatusAnlage3, Knz2BereichStatusAnlage1 };

export enum EnumKnz2Herkunft {
    Manuell = "Manuell",
    Anlage1 = "Anlage 1",
    Anlage3 = "Anlage 3"
}

export enum EnumKnz2Grenzwert {
    db54 = "54 dB(A)",
    db57 = "57 dB(A)",
    db65 = "65 dB(A)"
}

export class Knz2Repository {
    @mobx.observable
    public IsReadOnly = true;

    public BereicheCache = new EntityCache(LskRepository.Knz2GK2018Bereich);
    public AbschnitteCache = new EntityCache(LskRepository.Knz2GK2018Abschnitt);
    public ProjekteCache = new EntityCache(LskRepository.Knz2GK2018Projekt);

    public constructor() {
        (async () => {
            let currentUser = await UserRepository.GetCurrentUser();
            mobx.runInAction(() => {
                this.IsReadOnly =
                    !currentUser.currentUser.isAdmin && !currentUser.allowedScopes.includes(EnumUserRole.Knz2Benutzer);
            });
        })();
    }

    public GetInitialBereich(): Knz2GK2018Bereich {
        const b = new Knz2GK2018Bereich();
        b.f25Status = EnumBereichStatus.Anlage3;
        b.f09Herkunft = EnumKnz2Herkunft.Manuell;
        b.f44IstGeteilt = false;
        return b;
    }

    public GetAllowedBereichStatus(currentStatus: EnumBereichStatus): EnumBereichStatus[] {
        let currentKey: string;
        for (const key of Object.keys(EnumBereichStatus)) {
            if (EnumBereichStatus[key] === currentStatus) {
                currentKey = key;
            }
        }
        if (!currentKey) {
            return [EnumBereichStatus.Anlage3];
        }
        const allowedStatus = new Set<EnumBereichStatus>([...Knz2AllowedStatus[currentKey], currentStatus]);
        const output: EnumBereichStatus[] = [];
        for (const val of Object.values(EnumBereichStatus) as EnumBereichStatus[]) {
            if (allowedStatus.has(val)) {
                output.push(val);
            }
        }
        return output;
    }

    public async LoadBereicheByStuIdId(projekt: Knz2GK2018Projekt): Promise<void> {
        let all = await this.BereicheCache.GetAllMap();
        mobx.runInAction(() => {
            projekt.Bereiche = Utils.MapFilterToArray(all, (k, v) => v.knz2ProjektRef?.objectId == projekt.objectId);
        });
    }

    public AutoFillKmVonBisData(item: Knz2GK2018Bereich): Knz2GK2018Bereich {
        if (item.f25Status === EnumBereichStatus.InBearbeitung) {
            if (!item.f05KmVon1 && !item.f06KmBis1) {
                item.f05KmVon1 = item.f07KmVon3;
                item.f06KmBis1 = item.f08KmBis3;
            }
        }
        return item;
    }

    public async GetProjectsByKPN(kpn: string): Promise<Knz2GK2018Projekt[]> {
        const filteredItems = await LskRepository.Knz2GK2018Projekt.getView([kpn], 0, 0);
        return filteredItems;
    }

    /**
     * Update attachment count object with LV
     * @param projekt
     */
    public AppendLVIntoAttCounts(projekt: Knz2GK2018Projekt): { [key: string]: number } {
        let counts = projekt.k2F30AttCount || {};
        if (projekt.k2F24AbsStatus === EnumLVStatus.LarmvorsorgeUmgesetzt) {
            counts["lv-status"] = 1;
        } else {
            counts["lv-status"] = 0;
        }

        const reqAtt = projekt.getAttachmentsByStuStatus;
        for (const key of Array.from(reqAtt.keys())) {
            if (!reqAtt.get(key)) {
                counts[key] = -1;
            } else {
                if (counts[key] === undefined) {
                    counts[key] = 0;
                }
            }
        }

        return counts;
    }

    /**
     * Get Conditions for Tabs
     * @param projekt
     */
    public getProjectTabConditions(projekt: Knz2GK2018Projekt): Map<string, boolean> {
        return projekt.getTabsByStuStatus ?? new Map<string, boolean>();
    }
}
