import { observer } from "mobx-react";
import { CommandBar } from "office-ui-fabric-react";
import * as React from "react";
import { Knz2Repository } from "../../../Model/Knz2";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz2CommandBar extends React.Component<
    {
        Model: Knz2Repository;
        OnClickNewBereich: () => void;
        OnClickManageAbschnitte: () => void;
        UpdateStatusClick: () => void;
        DivideBereichClick: () => void;
        ExportBereichClick: () => void;
        AbschnitteDatenExportClick: () => void;
        ValidateBereichClick: () => void;
        UpdateStatusAvailable: boolean;
        DivideBereichAvailable: boolean;
    },
    {}
> {

    public readonly state = {}

    public render(): JSX.Element {
        return (
            <div>
                <CommandBar
                    items={[
                        {
                            key: "option1",
                            name: "Neuer Lärmsanierungsbereich",
                            iconProps: {
                                iconName: "Add"
                            },
                            onClick: () => {
                                this.props.OnClickNewBereich();
                            },
                            disabled: this.props.Model.IsReadOnly
                        },
                        {
                            key: "option2",
                            name: "Lärmsanierungsabschnitte verwalten",
                            iconProps: {
                                iconName: "Folder"
                            },
                            onClick: () => {
                                this.props.OnClickManageAbschnitte();
                            },
                            disabled: this.props.Model.IsReadOnly
                        },
                        {
                            key: "option3",
                            name: "Status ändern",
                            iconProps: {
                                iconName: "TripleColumnEdit"
                            },
                            onClick: () => {
                                this.props.UpdateStatusClick();
                            },
                            disabled: this.props.Model.IsReadOnly || !this.props.UpdateStatusAvailable
                        },
                        {
                            key: "option4",
                            name: "Bereich teilen",
                            iconProps: {
                                iconName: "Split"
                            },
                            onClick: () => {
                                this.props.DivideBereichClick();
                            },
                            disabled: this.props.Model.IsReadOnly || !this.props.DivideBereichAvailable
                        },
                        {
                            key: "option5",
                            name: "Excel Export",
                            iconProps: {
                                iconName: "ExcelDocument"
                            },
                            subMenuProps: {
                                items: [
                                    {
                                        key: "option51",
                                        name: "Knz2 - Gesamtexport",
                                        onClick: (): void => {
                                            this.props.ExportBereichClick();
                                        }
                                    },
                                    {
                                        key: "option52",
                                        name: "BMVI Freigaben Anl. 3",
                                        onClick: (): void => {
                                            this.props.AbschnitteDatenExportClick();
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            key: "option6",
                            name: "Validiere Bereiche",
                            iconProps: {
                                iconName: "InboxCheck"
                            },
                            onClick: () => {
                                this.props.ValidateBereichClick();
                            }
                        }
                    ]}
                />
            </div>
        );
    }
}
