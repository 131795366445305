import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from "office-ui-fabric-react";
import * as React from "react";
import { PleaseWait, ShowUser } from "..";
import { BaseEntity, WorkflowHistory } from "../../../generated/BackendClient";
import { IEntityRepository } from "../../../Rad/DAL/EntityRepository";
import { nameof, Utils } from "../../../Utils";
import { CatchReactErrors } from "../Error-Handler/Decorators";

@CatchReactErrors
export class WorkflowHistoryList<T extends BaseEntity> extends React.Component<
    {
        Repository: IEntityRepository<T>;
        ParentObject: BaseEntity;
    },
    {
        Items: WorkflowHistory[];
    }
> {
    public readonly state = { Items: undefined };

    private columns: IColumn[] = [
        {
            key: "col1",
            name: "Beschreibung",
            fieldName: "Name",
            minWidth: 360,
            isResizable: true,
            onRender: (item: WorkflowHistory) => <span className="nl2br">{item.description}</span>
        },
        {
            key: "col2",
            name: "Benutzer",
            minWidth: 120,
            isResizable: true,
            onRender: (item: WorkflowHistory) => (
                <ShowUser Object={item} PropertyName={nameof<WorkflowHistory>("user")} />
            )
        },
        {
            key: "col3",
            name: "Geändert am",
            minWidth: 160,
            isResizable: true,
            onRender: (item: WorkflowHistory) => <>{item.timestamp && Utils.FormatDateTime(item.timestamp)}</>
        }
    ];

    public async componentDidMount(): Promise<void> {
        await this.fetchWorkFlowHistoryItems();
    }

    public render(): JSX.Element {
        return (
            <PleaseWait
                ShowSpinner={this.state.Items === undefined}
                render={() => (
                    <DetailsList
                        items={this.state.Items}
                        columns={this.columns}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        compact={true}
                        selectionMode={SelectionMode.none}
                    />
                )}
            />
        );
    }

    private async fetchWorkFlowHistoryItems(): Promise<void> {
        this.setState({
            Items: await this.props.Repository.getHistory(this.props.ParentObject.objectId)
        });
    }
}
