import { observer } from "mobx-react";
import * as React from "react";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";
import { ITaskEditorProps, TaskEditDialogStore } from "./TaskEditDialogStore";

@observer
@CatchReactErrors
export class TaskEditRouter extends React.Component<ITaskEditorProps, {}> {
    public readonly state = {};

    public render(): JSX.Element {
        let key = this.props.Model.Task.type;
        if (!key) {
            key = "ApproveDenyWithComment";
        }
        const component = TaskEditDialogStore.Get(key);

        if (!component) {
            throw new Error(`Could not find handler for task type ${key}`);
        }

        return React.createElement(component, { ...this.props }, null);
    }
}
