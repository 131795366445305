import { observer } from "mobx-react";
import { DefaultButton, DialogFooter, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { TaskEditRouter } from ".";
import { WorkflowRepository } from "../../Model/Workflows";
import { TaskEditModel } from "../../Model/Workflows/TaskEditModel";
import { DBDialog, PleaseWait } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class TaskEditContainer extends React.Component<
    RouteComponentProps<{}> & {
        Repository: WorkflowRepository;
        TaskId: string;
        CloseDialog: (didSave: boolean) => void;
    },
    {
        Model: TaskEditModel;
        CanSaveTask: boolean;
    }
> {
    public readonly state = { Model: new TaskEditModel(this.props.Repository), CanSaveTask: false };
    public async componentDidMount(): Promise<void> {
        this.setState({ CanSaveTask: false });
        await this.state.Model.LoadTask(this.props.TaskId);
    }

    public render(): JSX.Element {
        return (
            <DBDialog
                onDismiss={() => {
                    this.props.CloseDialog(false);
                }}
                dialogContentProps={{
                    title: "Aufgabe bearbeiten"
                }}
            >
                <PleaseWait
                    ShowSpinner={!this.state.Model || this.state.Model.IsLoading}
                    render={() =>
                        this.state.Model.TaskNotFound ? (
                            <>
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12">
                                            Diese Aufgabe wurde bereits bearbeitet.
                                        </div>
                                    </div>
                                </div>
                                <DialogFooter>
                                    <PrimaryButton
                                        onClick={async () => {
                                            this.props.history.push("/Tasks");
                                            this.props.CloseDialog(true);
                                        }}
                                        text="OK"
                                    />
                                </DialogFooter>
                            </>
                        ) : (
                            <>
                                <TaskEditRouter
                                    Model={this.state.Model}
                                    OnValidate={(isValid) => {
                                        this.setState({ CanSaveTask: isValid });
                                    }}
                                />

                                <DialogFooter>
                                    <DefaultButton
                                        onClick={() => {
                                            this.props.history.push("/Tasks");
                                            this.props.CloseDialog(false);
                                        }}
                                        text="Abbrechen"
                                    />
                                    <PrimaryButton
                                        onClick={async () => {
                                            await this.state.Model.SaveTask();
                                            this.props.history.push("/Tasks");
                                            this.props.CloseDialog(true);
                                        }}
                                        text="Speichern"
                                        disabled={!this.state.CanSaveTask}
                                    />
                                </DialogFooter>
                            </>
                        )
                    }
                />
            </DBDialog>
        );
    }
}
