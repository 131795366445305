import { Knz4Repository } from ".";
import { IDetailListDatasource } from "../../tsx/Generic";
import { Knz4LSObjekt } from "./Knz4LSObjekt";

export class Knz4LSObjektListModelOhneFreigabe implements IDetailListDatasource {
    private Model: Knz4Repository;
    public constructor(model: Knz4Repository) {
        this.Model = model;
    }
    IsLoading = false;
    GetAllItems(): Promise<Map<string, Knz4LSObjekt>> {
        return this.Model.GetAllKnz4LSObjektOhneFreigabe();
    }
    Equals(source: Knz4LSObjektListModelOhneFreigabe): boolean {
        return this == source;
    }
    Sort(items: Knz4LSObjekt[]): void {
        items.sort((a, b) => (a.created < b.created ? 1 : -1));
    }
}
