import {
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IDetailsListProps,
    Selection,
    SelectionMode
} from "office-ui-fabric-react";
import * as React from "react";
import { BaseEntity, BaseUserModel, Entity } from "../../DAL";
import { RenderListField } from "../RadFields/FieldRenderer";
import { useRadListContext } from "./RadListContext";

export interface RadListProps<T extends BaseEntity> {
    DetailsListProps?: IDetailsListProps;
    OnItemInvoked?: (item: T) => void;
    Metadata: Entity;
}

export function RadList<T extends BaseEntity>({
    DetailsListProps,
    OnItemInvoked,
    Metadata
}: React.PropsWithChildren<RadListProps<T>>) {
    const { state, dispatch } = useRadListContext();
    let columns: IColumn[] = state.CurrentView.fields.map((f) => ({
        key: f.fieldName,
        name: f.columnHeader,
        onRender: (item) => RenderListField({ Value: item[f.fieldName], Metadata: Metadata.fields[f.fieldName] }),
        minWidth: f.minWidth,
        maxWidth: f.maxWidth > 0 ? f.maxWidth : null,
        isCollapsible: f.isCollapsible,
        isResizable: f.isResizable
    }));

    function getItems(): BaseEntity[] {
        if(state.Items.every(be => be instanceof BaseUserModel)) {
            return state.Items.map(el => {
                if((el as BaseUserModel).roles && (el as BaseUserModel).roles.includes("DB Netz Umweltschutz"))
                {
                    (el as BaseUserModel).roles[(el as BaseUserModel).roles.indexOf("DB Netz Umweltschutz")] = "DB InfraGO AG, Umweltschutzmanagement";
                }
                return el;
            })
    
        }
        return [...state.Items];
    }

    const selection = new Selection({
        onSelectionChanged: () => {
            dispatch({ type: "setSelectedItems", SelectedItems: selection.getSelection() as T[] });
        }
    });
    return (
        <DetailsList
            items={getItems()}                
            columns={columns}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            compact={true}
            selectionMode={SelectionMode.multiple}
            onItemInvoked={(item) => {
                OnItemInvoked && OnItemInvoked(item);
            }}
            onActiveItemChanged={(item) => {
                //   this.props.OnActiveItemChanged && this.props.OnActiveItemChanged(item);
            }}
            selection={selection}
            //  enableShimmer={state.IsLoading}
            onRenderMissingItem={(idx, props) => {
                // TODO: trigger lazy loading
                console.log(idx, props);
                return null;
            }}
            {...DetailsListProps}
        />
    );
}
