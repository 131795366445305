import {
    ComboBox,
    CompoundButton,
    DefaultButton, DialogFooter, IComboBox,
    IComboBoxOption,
    MessageBarType,
    ProgressIndicator
} from "office-ui-fabric-react";
import * as React from "react";
import { Knz2GK2022Bereich, Knz2Repository } from "../../../Model/Knz2";
import { EnumBereichStatus } from "../../../Rad/DAL";
import { Utils } from "../../../Utils";
import { DBDialog, IValidationMessage, ValidationMessages } from "../../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../../Generic/Error-Handler/Decorators";

enum EnumDialogState {
    Initial,
    Confirm,
    Processing,
    Done
}

@CatchReactErrors
export class Knz2UpdateStatusDialog extends React.Component<
    {
        Items: Knz2GK2022Bereich[];
        CloseDialog: () => void;
        Model: Knz2Repository;
    },
    {
        TargetStatus: EnumBereichStatus;
        Options: IComboBoxOption[];
        State: EnumDialogState;
        ItemsToProcess: Knz2GK2022Bereich[];
        ItemsProcessed: number;
        ValidationMessages: IValidationMessage[];
    }
> {
    public readonly state = {
        TargetStatus: undefined,
        State: EnumDialogState.Initial,
        Options: [],
        ItemsToProcess: [],
        ItemsProcessed: 0,
        ValidationMessages: null
    };

    public componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.TargetStatus !== prevState.TargetStatus) {
            const output: Knz2GK2022Bereich[] = [];
            for (const item of this.props.Items) {
                if (item.f25Status !== this.state.TargetStatus) {
                    output.push(item);
                }
            }

            this.setState({ ItemsToProcess: output });
        }
    }

    public async componentDidMount() {
        const availableStatus = Utils.EnumToArray(EnumBereichStatus);
        const options: IComboBoxOption[] = [];
        for (const option of availableStatus) {
            options.push({
                key: option,
                text: option,
                data: option
            });
        }

        this.setState({
            TargetStatus: undefined,
            ItemsToProcess: [],
            Options: options,
            ItemsProcessed: 0,
            State: EnumDialogState.Confirm
        });
    }

    public render() {
        return (
            <DBDialog
                onDismiss={() => {
                    if (this.state.State !== EnumDialogState.Processing) {
                        this.props.CloseDialog();
                    }
                }}
                dialogContentProps={{
                    title: "Status ändern"
                }}
                minWidth="760px"
            >
                <ValidationMessages Messages={this.state.ValidationMessages} />
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            Bitte wählen Sie den Status aus, in den die gewählten Bereiche überführt werden sollen.
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6" style={{ marginTop: "20px" }}>
                            <ComboBox
                                selectedKey={this.state.TargetStatus}
                                allowFreeform={false}
                                autoComplete="on"
                                options={this.state.Options}
                                onChange={(
                                    event: React.FormEvent<IComboBox>,
                                    option?: IComboBoxOption,
                                    index?: number,
                                    value?: string
                                ) => {
                                    this.setState({ TargetStatus: option.data });
                                    this.UpdateValidationMessages(option.data); // async
                                }}
                                text={this.state.TargetStatus}
                                required={true}
                                label="Zielstatus"
                                disabled={this.state.State !== EnumDialogState.Confirm}
                            />
                        </div>
                    </div>
                    {this.state.State === EnumDialogState.Confirm && (
                        <div className="ms-Grid-row" style={{ marginTop: "20px", textAlign: "center" }}>
                            <div className="ms-Grid-col ms-sm12">
                                <CompoundButton
                                    primary={true}
                                    onClick={() => {
                                        this.StartProcessing();
                                    }}
                                    secondaryText={`${this.state.ItemsToProcess.length} von ${
                                        this.props.Items.length
                                    } Bereich${this.props.Items.length > 1 ? "e" : ""} werden verändert`}
                                    disabled={this.state.ItemsToProcess.length === 0}
                                >
                                    {this.state.ItemsToProcess.length > 0
                                        ? `${this.state.ItemsToProcess.length} Bereich${
                                              this.state.ItemsToProcess.length > 1 ? "e" : ""
                                          } aktualisieren`
                                        : "Kein passender Bereich"}
                                </CompoundButton>
                            </div>
                        </div>
                    )}
                    {this.state.State === EnumDialogState.Processing && (
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12">
                                <ProgressIndicator
                                    label="Bereiche anpassen"
                                    description={`Ändere Bereich ${this.state.ItemsProcessed} von ${this.state.ItemsToProcess.length}`}
                                    percentComplete={this.state.ItemsProcessed / this.state.ItemsToProcess.length}
                                />
                            </div>
                        </div>
                    )}
                    {this.state.State === EnumDialogState.Done && (
                        <div className="ms-Grid-row" style={{ marginTop: "20px" }}>
                            <div className="ms-Grid-col ms-sm12">
                                {this.state.ItemsProcessed === 1 ? (
                                    <>Es wurde ein Bereich geändert.</>
                                ) : (
                                    <>Es wurden {this.state.ItemsProcessed} Bereiche geändert.</>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <DialogFooter>
                    <DefaultButton
                        onClick={() => {
                            this.props.CloseDialog();
                        }}
                        text="Schließen"
                        disabled={this.state.State === EnumDialogState.Processing}
                    />
                </DialogFooter>
            </DBDialog>
        );
    }

    private async UpdateValidationMessages(status: EnumBereichStatus): Promise<IValidationMessage[]> {
        if (status === EnumBereichStatus.Abgeschlossen) {
            const currentYear = new Date().getFullYear();
            const projektIds = new Set(this.props.Items.map((i) => i.knz2ProjektRef?.objectId));

            if (projektIds.has(null) || projektIds.has(undefined)) {
                this.setState({
                    ValidationMessages: [
                        { Type: MessageBarType.error, Text: "Mindest ein Bereich ist keinem Projekt zugeordnet." }
                    ]
                });
                return;
            }
            for (const id of projektIds) {
                const p = await this.props.Model.ProjekteCache.GetById(id);
                if (Number(p?.k2F38ProjektVorschau) !== currentYear) {
                    this.setState({
                        ValidationMessages: [
                            {
                                Type: MessageBarType.warning,
                                Text: "Mindest ein Bereich ist einem Projekt zugeordnet, dessen Vorschaujahr nicht in diesem Jahr ist."
                            }
                        ]
                    });
                    return;
                }
            }
        }
        this.setState({
            ValidationMessages: null
        });
    }

    @CatchReactErrorsMethod()
    private async StartProcessing(): Promise<void> {
        this.setState({ State: EnumDialogState.Processing });
        try {
            let i = 1;
            for (const item of this.state.ItemsToProcess) {
                this.setState({ ItemsProcessed: i++ });
                item.f25Status = this.state.TargetStatus;
                // Autofill KmVon1 and KmBis values from KmVon3 and KmBis3
                this.props.Model.AutoFillKmVonBisData(item);
                await this.props.Model.SaveBereich(item);
            }
        } finally {
            this.setState({ State: EnumDialogState.Done });
        }
    }
}
