import { mergeStyleSets } from "@uifabric/merge-styles";
import { memoizeFunction } from "@uifabric/utilities";

export interface IComponentClassNames {
    bubble: string;
}

export const getClassNames = memoizeFunction(
    (): IComponentClassNames => {
        return mergeStyleSets({
            bubble: {
                color: "#fff",
                transition: "background-color 1000ms linear",
                background: "#00aabb",
                borderRadius: "6px",
                paddingLeft: "4px",
                lineHeight: "18px"
            }
        });
    }
);
