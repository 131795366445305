import { observer } from "mobx-react";
import * as React from "react";
import { Knz2GK2022Projekt, Knz2Repository } from "../../../Model/Knz2";
import { EnumFormblattArt, TypedEntityReference } from "../../../generated/BackendClient";
import { RadRepository } from "../../../generated/RadRepository";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";
import { Knz2DokumenteEditDialog, Knz2DokumenteEditDialogTab } from "../Projekte";

@observer
@CatchReactErrors
export class Knz2ShowFormblattPreview extends React.Component<
    {
        DocumentUrl: TypedEntityReference;
        CloseDialog: () => void;
    },
    {
        ProjektItem: Knz2GK2022Projekt;
        ProjektRepository: Knz2Repository;
        InitialTab: Knz2DokumenteEditDialogTab;
    }
> {
    public readonly state = { ProjektItem: undefined, ProjektRepository: undefined, InitialTab: undefined };

    public async componentDidMount(): Promise<void> {
        if (!this.props.DocumentUrl) {
            return;
        }

        if (this.props.DocumentUrl.entityType.includes("FormblattModel")) {
            const pRepository = new Knz2Repository();
            const formblatt = await RadRepository.Knz2GK2022FormblattModel.get(this.props.DocumentUrl.objectId);
            const pItem = await pRepository.ProjekteCache.GetById(formblatt.refProjekt.objectId);
            let initialTab: Knz2DokumenteEditDialogTab;
            switch (formblatt.formblattArt) {
                case EnumFormblattArt.Aktiv:
                    initialTab = Knz2DokumenteEditDialogTab.FormblattA;
                    break;
                case EnumFormblattArt.Passiv:
                    initialTab = Knz2DokumenteEditDialogTab.FormblattP;
                    break;
                case EnumFormblattArt.OhneUmsetzung:
                    initialTab = Knz2DokumenteEditDialogTab.FormblattOU;
                    break;
            }
            this.setState({
                ProjektRepository: pRepository,
                ProjektItem: pItem,
                InitialTab: initialTab
            });
        } else {
            throw new Error(`Unknown entity type: ${this.props.DocumentUrl.entityType}`);
        }
    }

    public render(): JSX.Element {
        return (
            <>
                {this.state.ProjektItem && (
                    <Knz2DokumenteEditDialog
                        Model={this.state.ProjektRepository}
                        InitialModel={this.state.ProjektItem}
                        hideDialog={false}
                        closeDialog={this.props.CloseDialog}
                        readonly={true}
                        initialTab={this.state.InitialTab}
                    />
                )}
            </>
        );
    }
}
