import * as mobx from "mobx";
import { observer } from "mobx-react";
import { ITextFieldProps, TextField } from "office-ui-fabric-react";
import * as React from "react";
import { Utils } from "../../Utils";
import { CatchReactErrors } from "./Error-Handler/Decorators";

@observer
@CatchReactErrors
export class NumberField extends React.Component<
    {
        TextFieldProps: ITextFieldProps;
        ValidateRule?: (value: number) => string;
        OnChange: (value: number) => void;
        InitialValue: number;
        Decimals?: number;
    },
    {
        numberString: string;
    }
> {
    public readonly state = {
        numberString: ""
    };
    private disposer: mobx.IReactionDisposer;

    public async componentDidMount(): Promise<void> {
        // if underlying value is updated, refresh value of number field
        this.disposer = mobx.reaction(
            () => this.props.InitialValue,
            (val) => this.RefreshValue(),
            { fireImmediately: true }
        );
    }

    public componentWillUnmount(): void {
        this.disposer();
    }

    public render(): JSX.Element {
        return (
            <TextField
                value={this.state.numberString}
                onChange={(e: React.FormEvent<HTMLElement>, value: string) => {
                    this.setState({ numberString: value });
                }}
                onGetErrorMessage={(input: string): string => {
                    if (input === undefined) {
                        return "";
                    }
                    const value = this.GetValue(input);
                    if (isNaN(value)) {
                        return "Bitte eine Zahl eingeben!";
                    }
                    if (typeof this.props.ValidateRule === "function") {
                        return this.props.ValidateRule(value);
                    }

                    return "";
                }}
                autoComplete="off"
                onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    const value = this.GetValue(this.state.numberString);
                    this.RefreshValue();
                    if (value !== null) {
                        this.props.OnChange(value);
                    }
                }}
                {...this.props.TextFieldProps}
            />
        );
    }

    private RefreshValue(): void {
        if (!Utils.IsNumber(this.props.InitialValue)) {
            this.setState({ numberString: "" });
            return;
        }
        this.setState({
            numberString: new Intl.NumberFormat("de-DE", {
                useGrouping: true,
                minimumFractionDigits: this.props.Decimals,
                maximumFractionDigits: this.props.Decimals
            }).format(this.props.InitialValue)
        });
    }

    private GetValue(input: string): number {
        const temp = input?.replace(/\./g, "").replace(/,/g, ".");
        if (temp == null || temp === "") {
            return null;
        }

        let n = Number(temp);
        if (this.props.Decimals !== undefined) {
            n = +n.toFixed(this.props.Decimals);
        }
        return n;
    }
}
