import { decorate, observable } from "mobx";
import * as entity from "./BackendClient";

export function MakeNonPlainObjectObservable(any: { new () }) {
  let instance = new any();
  instance.init({});
  let props = {};
  for (let key of Object.keys(instance)) {
    props[key] = observable;
  }
  decorate(any, props);
}


export function InitMetadata() {
	MakeNonPlainObjectObservable(entity.BerichtModel);
	MakeNonPlainObjectObservable(entity.Folder);
	MakeNonPlainObjectObservable(entity.Knz2AltprojektModel);
	MakeNonPlainObjectObservable(entity.Knz2GK2018AbschnittModel);
	MakeNonPlainObjectObservable(entity.Knz2GK2018BereichModel);
	MakeNonPlainObjectObservable(entity.Knz2GK2018FormblattModel);
	MakeNonPlainObjectObservable(entity.Knz2GK2018ProjektModel);
	MakeNonPlainObjectObservable(entity.Knz2GK2022AbschnittModel);
	MakeNonPlainObjectObservable(entity.Knz2GK2022BereichModel);
	MakeNonPlainObjectObservable(entity.Knz2GK2022FormblattModel);
	MakeNonPlainObjectObservable(entity.Knz2GK2022ProjektModel);
	MakeNonPlainObjectObservable(entity.Knz3AbkModel);
	MakeNonPlainObjectObservable(entity.Knz3DatenImportModel);
	MakeNonPlainObjectObservable(entity.Knz4LSAbsenderModel);
	MakeNonPlainObjectObservable(entity.Knz4LSDatenImportModel);
	MakeNonPlainObjectObservable(entity.Knz4LSIngBueroModel);
	MakeNonPlainObjectObservable(entity.Knz4LSModel);
	MakeNonPlainObjectObservable(entity.Knz4LSSettingModel);
	MakeNonPlainObjectObservable(entity.Knz4LVDatenImportModel);
	MakeNonPlainObjectObservable(entity.Knz4LVModel);
	MakeNonPlainObjectObservable(entity.BaseFile);
	MakeNonPlainObjectObservable(entity.BaseUserModel);
}
