import { observer } from "mobx-react";
import {
    DefaultButton,
    DialogFooter,
    Label,
    MessageBarType,
    Pivot,
    PivotItem,
    PrimaryButton,
    TextField
} from "office-ui-fabric-react";
import * as React from "react";
import { LskRepository } from "../../DAL/LskRepository";
import {
    BaseFile, EnumApprovalWorkflowStatus,
    EnumKennzahl,
    EnumWorkflowStatusStype
} from "../../generated/BackendClient";
import { allMetadata, RadRepository } from "../../generated/RadRepository";
import { Bericht, BerichteRepository, EnumBerichtFreigabeStatus } from "../../Model/Berichte";
import { nameof, Utils } from "../../Utils";
import {
    ChoiceDialog,
    DBDialog,
    EasyCombobox,
    EasyForm,
    EasyTextField,
    FolderView,
    IValidationMessage,
    LoadingDialog,
    ObserverSpan,
    ShowUser,
    ValidationMessages
} from "../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../Generic/Error-Handler/Decorators";
import { WorkflowErrorMessages, WorkflowHistoryList, WorkflowStatus } from "../Generic/Workflows";

@observer
@CatchReactErrors
export class BerichteDetailsDialog extends React.Component<
    {
        Model: BerichteRepository;
        Item: Bericht;
        ReadOnly: boolean;
        CloseDialog: (shouldUpdate: boolean, updatedModel: Bericht) => void;
        StartFreigabeProcess?: (currentBericht: Bericht) => void;
        PublishTm1?: (currentBericht: Bericht) => void;
        RetractBericht?: (currentBericht: Bericht) => Promise<void>;
    },
    {
        CurrentBericht: Bericht;
        AdditionalFilesCount: number;
    }
> {
    public readonly state = {
        AdditionalFilesCount: 0,
        CurrentBericht: this.props.Item.Clone()
    };

    public async componentDidMount(): Promise<void> {
        let files = await LskRepository.Bericht.listFiles(this.state.CurrentBericht.objectId, "Zusätzliche Dateien");
        this.setState({ AdditionalFilesCount: files.length });
    }
    private loadingRef: React.RefObject<LoadingDialog> = React.createRef();
    private yesNoRef: React.RefObject<ChoiceDialog> = React.createRef();

    public render(): JSX.Element {
        const isKommentarRequired = this.props.Model.IsKnz2;
        return (
            <DBDialog
                onDismiss={() => {
                    this.props.CloseDialog(false, null);
                }}
                dialogContentProps={{
                    title: "Bericht anzeigen"
                }}
                minWidth="760px"
            >
                <WorkflowErrorMessages Item={this.state.CurrentBericht} />
                <ValidationMessages Messages={this.ShowAdditionalFilesValidationMsg()} />
                <LoadingDialog ref={this.loadingRef} />
                <ChoiceDialog ref={this.yesNoRef} />
                <Pivot>
                    <PivotItem headerText="Bericht anzeigen">
                        <EasyForm Object={this.state.CurrentBericht} Disabled={true}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm2">
                                        <Label>Berichtart</Label>
                                        <ObserverSpan PropertyName={nameof<Bericht>("berichtArt")} />
                                    </div>
                                    <div className="ms-Grid-col ms-sm2">
                                        <Label>Jahr</Label>
                                        <ObserverSpan PropertyName={nameof<Bericht>("berichtJahr")} />
                                    </div>
                                    <div className="ms-Grid-col ms-sm2">
                                        <Label>Kennzahl Art</Label>
                                        <ObserverSpan PropertyName={nameof<Bericht>("berichtKennzahlArt")} />
                                    </div>
                                    <div className="ms-Grid-col ms-sm3">
                                        <Label>{this.props.Model.DisplayNameKennzahl1}</Label>
                                        {this.props.Model.Kennzahl === EnumKennzahl.Knz1 ? (
                                            <ObserverSpan
                                                PropertyName={nameof<Bericht>("berichtKennzahlWert")}
                                                FormatFunction={Utils.FormatNumberToEuro}
                                            />
                                        ) : this.props.Model.Kennzahl ===
                                          (EnumKennzahl.Knz3LS || EnumKennzahl.Knz3LV) ? (
                                            <ObserverSpan
                                                PropertyName={nameof<Bericht>("berichtKennzahlWert")}
                                                FormatFunction={Utils.FormatNumber}
                                                Suffix=" m"
                                            />
                                        ) : (
                                            <ObserverSpan
                                                PropertyName={nameof<Bericht>("berichtKennzahlWert")}
                                                FormatFunction={Utils.FormatNumber}
                                            />
                                        )}
                                    </div>
                                    {this.props.Model.DisplayNameKennzahl2 && (
                                        <div className="ms-Grid-col ms-sm3">
                                            <Label>{this.props.Model.DisplayNameKennzahl2}</Label>
                                            <ObserverSpan
                                                PropertyName={nameof<Bericht>("berichtKennzahlWert2")}
                                                FormatFunction={Utils.FormatNumber}
                                            />
                                        </div>
                                    )}
                                </div>
                                {this.props.Model.Kennzahl === EnumKennzahl.Knz1 && (
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6">
                                            <Label>Knz Lärmsanierungsprogramm Wert</Label>
                                            <ObserverSpan
                                                PropertyName={nameof<Bericht>("berichtKennzahlLSPDateiWert")}
                                                FormatFunction={Utils.FormatNumberToEuro}
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm6">
                                            <Label>Knz Zentrale Wert</Label>
                                            <ObserverSpan
                                                PropertyName={nameof<Bericht>("berichtKennzahlZentraleDateiWert")}
                                                FormatFunction={Utils.FormatNumberToEuro}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6">
                                        <Label>Status</Label>
                                        <WorkflowStatus Item={this.state.CurrentBericht} />
                                    </div>
                                    {[EnumKennzahl.Knz2LS, EnumKennzahl.Knz2LV].includes(this.props.Model.Kennzahl) &&
                                        this.state.CurrentBericht.berichtFreitext && (
                                            <div className="ms-Grid-col ms-sm6">
                                                <Label>Validierung Status</Label>
                                                <ObserverSpan PropertyName={nameof<Bericht>("berichtFreitext")} />
                                            </div>
                                        )}
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12">
                                        <FolderView
                                            Repository={LskRepository.Bericht}
                                            ParentObjectId={this.state.CurrentBericht.objectId}
                                            Disabled={true}
                                            Label="Dateien"
                                            Compact={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            {this.props.Model.IsBerichtFreigabeStatusZeigen && (
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm4">
                                        {["Bereit", "Abgelehnt"].includes(this.state.CurrentBericht.berichtWFStatus) ? (
                                            <EasyCombobox
                                                PropertyName={nameof<Bericht>("berichtFreigabeStatus")}
                                                ComboBoxProps={{ label: "Bericht Freigabe Status", disabled: false }}
                                                Options={Utils.EnumToComboBoxOption(
                                                    this.props.Model.GetBerichtFreigabeStatus,
                                                    allMetadata.enumMapping[
                                                        "DB.LSK.Model.Enums+EnumApprovalWorkflowStatus"
                                                    ]
                                                )}
                                            />
                                        ) : (
                                            this.state.CurrentBericht.berichtFreigabeStatusUser && (
                                                <>
                                                    <Label>Bericht Freigabe Status</Label>
                                                    <ObserverSpan
                                                        PropertyName={nameof<Bericht>("berichtFreigabeStatus")}
                                                    />
                                                </>
                                            )
                                        )}
                                    </div>
                                    {this.state.CurrentBericht.berichtFreigabeStatusUser && (
                                        <>
                                            <div className="ms-Grid-col ms-sm4">
                                                <Label>Freigegeben Status durch</Label>
                                                <ShowUser PropertyName={nameof<Bericht>("berichtFreigabeStatusUser")} />
                                            </div>
                                            <div className="ms-Grid-col ms-sm4">
                                                <TextField
                                                    label="Freigegeben Status am"
                                                    disabled={true}
                                                    readOnly={true}
                                                    value={
                                                        this.state.CurrentBericht.berichtFreigabeStatusAm &&
                                                        Utils.FormatDate(
                                                            this.state.CurrentBericht.berichtFreigabeStatusAm
                                                        )
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            {this.state.CurrentBericht.berichtTm1TransferDate && (
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm4">
                                        <Label>An TM1 übertragen am</Label>
                                        <ObserverSpan
                                            PropertyName={nameof<Bericht>("berichtTm1TransferDate")}
                                            FormatFunction={Utils.FormatDateTime}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm4">
                                        <Label>An TM1 übertragen von</Label>
                                        <ShowUser PropertyName={nameof<Bericht>("berichtTm1TransferUser")} />
                                    </div>
                                </div>
                            )}
                        </EasyForm>
                    </PivotItem>
                    <PivotItem headerText="Workflow-Verlauf">
                        <WorkflowHistoryList
                            Repository={RadRepository.BerichtModel}
                            ParentObject={this.state.CurrentBericht}
                        />
                    </PivotItem>
                    <PivotItem headerText="Zusätzliche Dateien">
                        <EasyForm Object={this.state.CurrentBericht} Disabled={false}>
                            <EasyTextField
                                PropertyName={nameof<Bericht>("berichtKommentar")}
                                TextFieldProps={{
                                    label: "Kommentar",
                                    required: isKommentarRequired,
                                    disabled: ![
                                        EnumApprovalWorkflowStatus.Bereit,
                                        EnumApprovalWorkflowStatus.Abgelehnt,
                                        EnumApprovalWorkflowStatus.Bearbeitbar,
                                        EnumApprovalWorkflowStatus.Zurueckgezogen
                                    ].includes(this.state.CurrentBericht.berichtWFStatus),
                                    multiline: true,
                                    rows: 3
                                }}
                            />
                            <FolderView
                                Repository={LskRepository.Bericht}
                                ParentObjectId={this.state.CurrentBericht.objectId}
                                FolderName="Zusätzliche Dateien"
                                Label="Zusätzliche Dateien"
                                Disabled={
                                    ![
                                        EnumApprovalWorkflowStatus.Bereit,
                                        EnumApprovalWorkflowStatus.Abgelehnt,
                                        EnumApprovalWorkflowStatus.Bearbeitbar
                                    ].includes(this.state.CurrentBericht.berichtWFStatus)
                                }
                                OnFolderLoaded={(files: BaseFile[]) => {
                                    this.setState({ AdditionalFilesCount: files.length });
                                }}
                                OnDeleteItemClick={async (item: BaseFile, files: BaseFile[]) => {
                                    let wf = RadRepository.BerichtModel;
                                    await wf.deleteFile(item.objectId);
                                }}
                                IsCRUDType={true}
                            />
                        </EasyForm>
                    </PivotItem>
                </Pivot>
                <DialogFooter>
                    <ValidationMessages Messages={this.state.CurrentBericht.FreigabeValidationMessages} />

                    {!this.props.ReadOnly && (
                        <div style={{ float: "left" }}>
                            {this.state.CurrentBericht.berichtWFStatus !== EnumApprovalWorkflowStatus.Freigegeben && (
                                <DefaultButton
                                    iconProps={{
                                        iconName: "DocumentApproval"
                                    }}
                                    style={{ marginRight: "12px" }}
                                    onClick={() => {
                                        if (this.props.StartFreigabeProcess) {
                                            this.props.StartFreigabeProcess(this.state.CurrentBericht);
                                        }
                                    }}
                                    text="Freigabe starten"
                                    disabled={!this.CanStartFreigabe}
                                />
                            )}
                            {this.props.Item.berichtKennzahlArt !== EnumKennzahl.Knz3SP &&
                                this.props.PublishTm1 &&
                                this.state.CurrentBericht.IsReadyForTm1 && (
                                    <DefaultButton
                                        iconProps={{
                                            iconName: "PublishContent"
                                        }}
                                        style={{ marginRight: "12px" }}
                                        onClick={() => {
                                            this.props.PublishTm1(this.state.CurrentBericht);
                                        }}
                                        text="An TM1 senden"
                                    />
                                )}
                            {this.state.CurrentBericht.berichtWFStatus === EnumApprovalWorkflowStatus.Freigegeben && (
                                <DefaultButton
                                    iconProps={{
                                        iconName: "DictionaryRemove"
                                    }}
                                    style={{ marginRight: "12px" }}
                                    onClick={async () => {
                                        const result = await this.yesNoRef.current.ExecuteYesNo(
                                            "Warnung",
                                            <>Möchten Sie die Freigabe dieses Berichts wirklich zurückziehen?</>,
                                            true
                                        );
                                        if (result) {
                                            await this.props.RetractBericht(this.state.CurrentBericht);
                                            await this.ExitDialog(false);
                                        }
                                    }}
                                    text="Bericht zurückziehen"
                                />
                            )}
                        </div>
                    )}
                    <DefaultButton
                        onClick={() => {
                            this.ExitDialog(true);
                        }}
                        text="Speichern"
                        disabled={
                            ![
                                EnumApprovalWorkflowStatus.Bereit,
                                EnumApprovalWorkflowStatus.Abgelehnt,
                                EnumApprovalWorkflowStatus.Bearbeitbar,
                                EnumApprovalWorkflowStatus.Zurueckgezogen
                            ].includes(this.state.CurrentBericht.berichtWFStatus)
                        }
                    />
                    <PrimaryButton
                        onClick={() => {
                            this.ExitDialog(false);
                        }}
                        text="Schließen"
                    />
                </DialogFooter>
            </DBDialog>
        );
    }

    private get CanStartFreigabe(): boolean {
        if (
            [
                EnumWorkflowStatusStype.Initializing,
                EnumWorkflowStatusStype.Running,
                EnumWorkflowStatusStype.Waiting
            ].includes(this.state.CurrentBericht.workflowInfo.statusType)
        ) {
            return false;
        }

        if (!this.IsAdditionalFilesValidated(this.state.CurrentBericht)) {
            return false;
        }

        if (
            ![EnumApprovalWorkflowStatus.Bereit, EnumApprovalWorkflowStatus.Abgelehnt].includes(
                this.state.CurrentBericht.berichtWFStatus
            )
        ) {
            return false;
        }

        if (this.props.Model.IsBerichtFreigabeStatusZeigen) {
            const val = this.state.CurrentBericht.berichtFreigabeStatus as EnumBerichtFreigabeStatus;
            if (
                ![EnumBerichtFreigabeStatus.freigabeFehlerbericht, EnumBerichtFreigabeStatus.freigabebericht].includes(
                    val
                )
            ) {
                return false;
            }
        }
        return true;
    }

    private ShowAdditionalFilesValidationMsg(): IValidationMessage[] {
        if (!this.IsAdditionalFilesValidated(this.state.CurrentBericht)) {
            return [
                {
                    Text: "Bitte laden Sie zusätzliche Dateien hoch und geben einen Kommentar ein.",
                    Type: MessageBarType.error
                }
            ];
        }

        return [];
    }

    private IsAdditionalFilesValidated(currentBericht: Bericht): boolean {
        if (
            this.props.Model.IsKnz2 &&
            [EnumApprovalWorkflowStatus.Bereit, EnumApprovalWorkflowStatus.Abgelehnt].includes(
                this.state.CurrentBericht.berichtWFStatus
            )
        ) {
            if (this.state.AdditionalFilesCount === 0 || !currentBericht.berichtKommentar) {
                return false;
            }
        }

        return true;
    }

    @CatchReactErrorsMethod()
    private async ExitDialog(shouldSave: boolean) {
        if (shouldSave) {
            this.props.CloseDialog(true, this.state.CurrentBericht);
        } else {
            this.props.CloseDialog(false, undefined);
        }
    }
}
