import * as React from "react";
import { AbstractFieldBaseAttribute } from "../../DAL";
import {
    RadCheckboxRenderer,
    RadChoiceFieldRenderer,
    RadChoiceMultiFieldRenderer,
    RadDateFieldRenderer,
    RadDateTimeFieldRenderer,
    RadFallbackRenderer,
    RadNumberFieldRenderer,
    RadTextFieldRenderer,
    RadVersionRenderer
} from "./Renderers";
import { RadUserFieldRenderer, RadUserFieldRendererMulti } from "./Renderers/RadUserField";

export interface IFieldRendererEditProps {
    OnChange?: (value: any) => void;
    Value: any;
    Disabled?: boolean;
    Metadata: AbstractFieldBaseAttribute;
}

export interface IFieldRendererViewProps {
    Value: any;
    Metadata: AbstractFieldBaseAttribute;
}

export interface IFieldRenderer {
    EditField: React.FunctionComponent<IFieldRendererEditProps>;
    ShowField: React.FunctionComponent<IFieldRendererViewProps>;
    ListField: React.FunctionComponent<IFieldRendererViewProps>;
}

const FieldRendererRegistry = new Map<string, IFieldRenderer>();
export function RegisterFieldRenderer(rendererName: string, renderer: IFieldRenderer) {
    if (FieldRendererRegistry.has(rendererName)) {
        throw new Error(`Renderer ${rendererName} is already registered!`);
    }
    FieldRendererRegistry.set(rendererName, renderer);
}

export function InitFieldRenderers(): void {
    RegisterFieldRenderer("CheckBox", RadCheckboxRenderer);
    RegisterFieldRenderer("Date", RadDateFieldRenderer);
    RegisterFieldRenderer("DateTime", RadDateTimeFieldRenderer);
    RegisterFieldRenderer("Choice", RadChoiceFieldRenderer);
    RegisterFieldRenderer("ChoiceMulti", RadChoiceMultiFieldRenderer);
    RegisterFieldRenderer("Text", RadTextFieldRenderer);
    RegisterFieldRenderer("Number", RadNumberFieldRenderer);
    RegisterFieldRenderer("User", RadUserFieldRenderer);
    RegisterFieldRenderer("UserMulti", RadUserFieldRendererMulti);
    RegisterFieldRenderer("Version", RadVersionRenderer);

    /*
Lookup
*/
}

export function RenderEditField(props: IFieldRendererEditProps): React.ReactElement {
    let renderer = FieldRendererRegistry.get(props.Metadata?.fieldRenderer);
    if (!renderer) {
        console.warn(`Could not get field renderer ${props.Metadata.fieldRenderer}`);
        renderer = RadFallbackRenderer;
    }
    return renderer.EditField(props);
}

export function RenderListField(props: IFieldRendererEditProps): React.ReactElement {
    let renderer = FieldRendererRegistry.get(props.Metadata?.fieldRenderer);
    if (!renderer) {
        console.warn(`Could not get field renderer ${props.Metadata.fieldRenderer}`);
        renderer = RadFallbackRenderer;
    }
    return renderer.ListField(props);
}
