import * as mobx from "mobx";
import { observer } from "mobx-react";
import { Checkbox, ICheckboxProps } from "office-ui-fabric-react";
import * as React from "react";
import { getClassNames } from "./EasyCheckbox.classNames";
import { EasyFormContext } from "./EasyFormContext";

@observer
export class EasyCheckbox extends React.Component<
    {
        Object?: object;
        PropertyName: string;
        CheckboxProps?: ICheckboxProps;
        OnChange?: () => void;
        Required?: boolean;
    },
    {}
> {
    public static contextType = EasyFormContext;
    private get Object(): object {
        return this.props.Object || this.context.Object;
    }

    public render(): JSX.Element {
        const { label, required } = getClassNames();
        return (
            <Checkbox
                checked={this.Object[this.props.PropertyName] || ""}
                onChange={(e: React.FormEvent<HTMLElement | HTMLInputElement>, value?: boolean) => {
                    mobx.runInAction(() => {
                        this.Object[this.props.PropertyName] = value;
                    });
                    if (this.props.OnChange) {
                        this.props.OnChange();
                    }
                }}
                disabled={this.context.Disabled}
                {...this.props.CheckboxProps}
                onRenderLabel={(props) => {
                    if (!props) {
                        return null;
                    }
                    return props.label ? (
                        <span aria-hidden="true" className={label} title={props.title}>
                            {props.label}
                            {this.props.Required && <span className={required}> *</span>}
                        </span>
                    ) : null;
                }}
            />
        );
    }
}
