import { Mutex } from "async-mutex";
import { BaseEntity, IBaseController } from ".";

export class GenericEntityCache<T extends BaseEntity> {
    private ItemCache = new Map<string, T>();
    private ItemGetter: (timestamp: number) => Promise<T[]>;
    private LastTimestamp: number = 0;
    protected LastUpdate: Date;
    private mutex = new Mutex();
    private MaxAgeInSeconds: number;

    public constructor(itemGetter: (timestamp: number) => Promise<T[]>, maxAgeInSeconds: number = 60) {
        this.ItemGetter = itemGetter;
        this.MaxAgeInSeconds = maxAgeInSeconds;
    }

    public async GetById(id: string): Promise<T> {
        await this.LoadItems();
        return this.ItemCache.get(id);
    }

    public async GetAllMap(onlyWhenDirty: boolean = false): Promise<Map<string, T>> {
        let gotUpdate = await this.LoadItems();
        if (onlyWhenDirty && !gotUpdate) return null;
        return this.ItemCache;
    }

    public async GetAll(onlyWhenDirty: boolean = false): Promise<T[]> {
        let gotUpdate = await this.LoadItems();
        if (onlyWhenDirty && !gotUpdate) return null;
        return [...this.ItemCache.values()];
    }

    private UpdateLastModified(items: T[]) {
        for (const item of items) {
            if (item._ts > this.LastTimestamp || !this.LastTimestamp) {
                this.LastTimestamp = item._ts;
            }
        }
    }

    public ForceUpdate(): void {
        this.LastUpdate = new Date(2000, 1, 1);
    }

    private NeedUpdate(): boolean {
        if (this.ItemCache.size == 0) {
            return true;
        }
        let age = new Date().getTime() - this.LastUpdate.getTime();

        if (age > this.MaxAgeInSeconds * 1000) {
            return true;
        }
        return false;
    }

    private async LoadItems(): Promise<boolean> {
        if (!this.NeedUpdate()) return false;

        const release = await this.mutex.acquire();
        try {
            if (!this.NeedUpdate()) return false;

            let allItems = await this.ItemGetter(this.LastTimestamp);
            this.LastUpdate = new Date();

            if (!allItems) {
                return false;
            } else {
                for (const li of allItems) {
                    this.ItemCache.set(li.objectId, li);
                }

                this.UpdateLastModified(Array.from(this.ItemCache.values()));
                return true;
            }
        } finally {
            release();
        }
    }

    public isInitialized(): boolean {
        return !!this.LastUpdate;
    }
}

export class EntityCache<T extends BaseEntity> extends GenericEntityCache<T> {
    protected Controller: IBaseController<T>;

    public constructor(controller: IBaseController<T>, maxAgeInSeconds: number = 60) {
        super((ts) => this.itemLoader(ts), maxAgeInSeconds);
        this.Controller = controller;
    }

    private async itemLoader(timestamp: number): Promise<T[]> {
        let output: T[] = [];
        let continuationToken: string = "";
        do {
            let result = await this.Controller.getAll(timestamp, continuationToken);
            output.push(...result.results);
            continuationToken = result.continuationToken;
        } while (continuationToken);

        return output;
    }
    public async add(item: T): Promise<T> {
        let result = await this.Controller.add(item);
        this.ForceUpdate();
        return result;
    }

    public async update(item: T): Promise<T> {
        let result = await this.Controller.update(item);
        this.ForceUpdate();
        return result;
    }
}
