import { IComboBoxProps } from "office-ui-fabric-react";
import React from "react";
import { RadChoiceFieldMulti } from ".";
import { IFieldRenderer, IFieldRendererEditProps, IFieldRendererViewProps } from "../FieldRenderer";

export interface RadChoiceFieldProps {
    ComboBoxProps?: Partial<IComboBoxProps>;
    Value: string;
    Choices: string[];
    OnChange?: (value: string) => void;
}

export function RadChoiceField({
    Choices,
    OnChange,
    Value,
    ComboBoxProps
}: React.PropsWithChildren<RadChoiceFieldProps>) {
    return (
        <RadChoiceFieldMulti
            Choices={Choices}
            MultiSelect={false}
            Value={Value ? [Value] : []}
            OnChange={(v) => OnChange(v?.length > 0 ? v[0] : null)}
            ComboBoxProps={ComboBoxProps}
        />
    );
}

export const RadChoiceFieldRenderer: IFieldRenderer = {
    EditField: (props: IFieldRendererEditProps) =>
        RadChoiceField({
            Value: props.Value,
            ComboBoxProps: { label: props.Metadata.title, disabled: props.Disabled || props.Metadata.readOnly },
            OnChange: props.OnChange,
            Choices: (props.Metadata as any).choices
        }),
    ShowField: (props: IFieldRendererViewProps) =>
        RadChoiceField({
            Value: props.Value,
            ComboBoxProps: { label: props.Metadata.title, disabled: true },
            Choices: (props.Metadata as any).choices
        }),
    ListField: (props: IFieldRendererViewProps) => <>{props.Value}</>
};
