import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  Link,
  SelectionMode,
  GroupHeader,
  IGroupDividerProps
} from "office-ui-fabric-react";
import * as React from "react";
import { MessageDialog, PleaseWait } from ".";
import { BaseEntity, BaseFile } from "../../generated/BackendClient";
import { IEntityRepositoryWithFile } from "../../Rad/DAL";
import { CatchReactErrors } from "./Error-Handler/Decorators";
import { EasyFormContext } from "./Forms/EasyFormContext";

@CatchReactErrors
export class FolderViewInList<T extends BaseEntity, F extends BaseFile> extends React.Component<
  {
      Repository: IEntityRepositoryWithFile<T, F>;
      ParentObjectId: string;
      FolderName?: string;
      Label?: string;
      OnFolderLoaded?: (files: F[]) => void;
  },
  {
      Files: F[];
      Errors: any[];
      ShowVersionOf: F;
      IsLoading: boolean;
  }
> {
  public static contextType = EasyFormContext;
  public readonly state = { Files: [], Errors: [], List: undefined, ShowVersionOf: undefined, IsLoading: true };
  private msgRef: React.RefObject<MessageDialog> = React.createRef();
  private mounted = false;

  public async componentDidMount(): Promise<void> {
      this.mounted = true;
      await this.RefreshFiles();
  }

  public componentWillUnmount(): void {
      this.mounted = false;
  }

  public async componentDidUpdate(prevProps): Promise<void> {
      if (this.props.ParentObjectId !== prevProps.ParentObjectId || this.props.FolderName !== prevProps.FolderName) {
          await this.RefreshFiles();
      }
  }

  public render(): JSX.Element {
      return (
              <DetailsList
                isHeaderVisible={false}
                
                items={this.state.Files}
                columns={this.GetColumns()}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                compact={true}
                selectionMode={SelectionMode.none}
              />);
  }

  private GetColumns(): IColumn[] {
      const cols = [
          {
              key: "col1",
              name: "Name",
              minWidth: 200,
              isResizable: true,
              onRender: (item: BaseFile) => (
                  <>
                      {item.downloadUrl ? (
                          <Link href={item.downloadUrl} download={true}>
                              {item.name}
                          </Link>
                      ) : (
                          <>{item.name}</>
                      )}
                  </>
              )
          }
      ];
      return cols.filter((obj: any) => {
          return obj !== false;
      });
  }


  private async RefreshFiles(): Promise<void> {
      if (!this.mounted) return;
      this.setState({ IsLoading: true });
      const files = await this.props.Repository.listFiles(this.props.ParentObjectId, this.props.FolderName);
      files.sort((a, b) => (a.created < b.created ? 1 : -1));
      this.setState({ Files: files, IsLoading: false });
      if (this.props.OnFolderLoaded) {
          this.props.OnFolderLoaded(files);
      }
  }
}
