import { DetailsList, DetailsListLayoutMode, IGroup, SelectionMode } from "office-ui-fabric-react";
import * as React from "react";
import { PleaseWait } from "../../../tsx/Generic";
import { Utils } from "../../../Utils/Utils";
import {
    BaseEntity,
    BaseFile,
    BaseUserModel,
    Entity,
    IEntityRepository,
    IEntityRepositoryWithFile,
    UserRepository
} from "../../DAL";
import { RenderListField } from "../RadFields/FieldRenderer";

export interface RadVersionHistoryListProps<T extends BaseEntity> {
    GetVersions: () => Promise<T[]>;
    IgnoreFields?: string[];
    Metadata: Entity;
}

/**
 * Determine the version history for a list item
 * @param item
 * @param ignoreFieldInternalNames string[] of fields that should be ignored (e.g. "Name")
 */
function GetFieldChanges<T extends BaseEntity>(
    metadata: Entity,
    versions: T[],
    ignoreFieldInternalNames: string[]
): Map<Number, string[]> {
    // get all fields we want to analyze
    let fields = Object.values(metadata.fields)
        .filter((f) => f.showInVersionHistory && !f.internal && !ignoreFieldInternalNames.includes(f.fieldName))
        .map((f) => f.fieldName);

    // detect field changes
    let changedFields = new Map<Number, string[]>();
    changedFields.set(1, fields); // show all fields for version 1.0
    for (let i = 1; i < versions.length; i++) {
        changedFields.set(
            versions[i].version,
            fields.filter((f) => versions[i - 1][f] !== versions[i][f])
        );
    }
    return changedFields;
}

async function GetListItems<T extends BaseEntity>(
    metadata: Entity,
    versions: T[],
    ignoreFieldInternalNames: string[]
): Promise<[IGroup[], any[]]> {
    let changes = GetFieldChanges(metadata, versions, ignoreFieldInternalNames ?? []);

    const groups: IGroup[] = [];
    const items: any[] = [];

    const users = new Map<string, BaseUserModel>();

    for (const v of versions) {
        let c = changes.get(v.version);
        let userId = v.lastModifiedBy?.objectId;
        if (!!userId && !users.has(userId)) {
            users.set(userId, await UserRepository.LookupUser(userId));
        }
        groups.push({
            key: `ver${v.version}`,
            name: `Version ${v.version} am ${Utils.FormatDateTime(v.lastModified)} von ${
                users.get(userId)?.displayName ?? "Unbekannt"
            }`,
            startIndex: items.length,
            count: c?.length ?? 0
        });
        if (c?.length > 0) {
            items.push(
                ...c.map((f, idx) => {
                    return {
                        key: idx,
                        fieldName: f,
                        fieldValue: v[f]
                    };
                })
            );
        }
    }

    return [groups, items];
}

export function RadVersionHistoryList<T extends BaseEntity>({
    Metadata,
    GetVersions,
    IgnoreFields
}: React.PropsWithChildren<RadVersionHistoryListProps<T>>) {
    const [isLoading, setIsLoading] = React.useState(true);
    const [state, setState] = React.useState({ Items: [], Groups: [] });

    // lazy load version history
    React.useEffect(() => {
        (async () => {
            setIsLoading(true);
            let versions = await GetVersions();
            let [groups, items] = await GetListItems(Metadata, versions, IgnoreFields);

            setState({ Items: items, Groups: groups });
            setIsLoading(false);
        })();
    }, []);

    return (
        <PleaseWait
            ShowSpinner={isLoading}
            render={() => (
                <DetailsList
                    items={state.Items}
                    columns={[
                        {
                            key: "key",
                            name: "Feld",
                            minWidth: 50,
                            isResizable: true,
                            onRender: (item) => <>{Metadata.fields[item.fieldName].title}</>
                        },
                        {
                            key: "value",
                            name: "Wert",
                            minWidth: 400,
                            isResizable: true,
                            onRender: (item) =>
                                RenderListField({ Value: item.fieldValue, Metadata: Metadata.fields[item.fieldName] })
                        }
                    ]}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    compact={true}
                    selectionMode={SelectionMode.none}
                    groups={state.Groups}
                    groupProps={{
                        showEmptyGroups: true
                    }}
                />
            )}
        />
    );
}

export interface RadVersionHistoryListEntityProps<T extends BaseEntity> {
    ObjectId: string;
    Repository: IEntityRepository<T>;
    IgnoreFields?: string[];
}

export function RadVersionHistoryListEntity<T extends BaseEntity>({
    ObjectId,
    Repository,
    IgnoreFields
}: React.PropsWithChildren<RadVersionHistoryListEntityProps<T>>) {
    return RadVersionHistoryList({
        GetVersions: () => Repository.getVersions(ObjectId),
        IgnoreFields,
        Metadata: Repository.Metadata
    });
}

export interface RadVersionHistoryListFileProps<T extends BaseEntity, F extends BaseFile> {
    FileId: string;
    Repository: IEntityRepositoryWithFile<T, F>;
    IgnoreFields?: string[];
}

export function RadVersionHistoryListFile<T extends BaseEntity, F extends BaseFile>({
    FileId,
    Repository,
    IgnoreFields
}: React.PropsWithChildren<RadVersionHistoryListFileProps<T, F>>) {
    return RadVersionHistoryList({
        GetVersions: () => Repository.getFileVersions(FileId),
        IgnoreFields,
        Metadata: Repository.FileMetadata
    });
}
