import * as entity from "./BackendClient";
import { EntityRepository, EntityRepositoryWithFile } from "../Rad/DAL/EntityRepository";
import allMetadata from "./EntityMetadata.json";
export { default as allMetadata } from "./EntityMetadata.json";

export class RadRepository {
	static BerichtModel = new EntityRepositoryWithFile<entity.BerichtModel, entity.BaseFile>(new entity.BerichtClient(), entity.BerichtModel, allMetadata.entities.BerichtModel as any, allMetadata.entities.BaseFile as any);
	static Folder = new EntityRepositoryWithFile<entity.Folder, entity.BaseFile>(new entity.FolderClient(), entity.Folder, allMetadata.entities.Folder as any, allMetadata.entities.BaseFile as any);
	static Knz2AltprojektModel = new EntityRepositoryWithFile<entity.Knz2AltprojektModel, entity.BaseFile>(new entity.Knz2AltprojektClient(), entity.Knz2AltprojektModel, allMetadata.entities.Knz2AltprojektModel as any, allMetadata.entities.BaseFile as any);
	static Knz2GK2018AbschnittModel = new EntityRepository<entity.Knz2GK2018AbschnittModel>(new entity.Knz2GK2018AbschnittClient(), entity.Knz2GK2018AbschnittModel, allMetadata.entities.Knz2GK2018AbschnittModel as any);
	static Knz2GK2018BereichModel = new EntityRepository<entity.Knz2GK2018BereichModel>(new entity.Knz2GK2018BereichClient(), entity.Knz2GK2018BereichModel, allMetadata.entities.Knz2GK2018BereichModel as any);
	static Knz2GK2018FormblattModel = new EntityRepository<entity.Knz2GK2018FormblattModel>(new entity.Knz2GK2018FormblattClient(), entity.Knz2GK2018FormblattModel, allMetadata.entities.Knz2GK2018FormblattModel as any);
	static Knz2GK2018ProjektModel = new EntityRepositoryWithFile<entity.Knz2GK2018ProjektModel, entity.BaseFile>(new entity.Knz2GK2018ProjektClient(), entity.Knz2GK2018ProjektModel, allMetadata.entities.Knz2GK2018ProjektModel as any, allMetadata.entities.BaseFile as any);
	static Knz2GK2022AbschnittModel = new EntityRepository<entity.Knz2GK2022AbschnittModel>(new entity.Knz2GK2022AbschnittClient(), entity.Knz2GK2022AbschnittModel, allMetadata.entities.Knz2GK2022AbschnittModel as any);
	static Knz2GK2022BereichModel = new EntityRepository<entity.Knz2GK2022BereichModel>(new entity.Knz2GK2022BereichClient(), entity.Knz2GK2022BereichModel, allMetadata.entities.Knz2GK2022BereichModel as any);
	static Knz2GK2022FormblattModel = new EntityRepository<entity.Knz2GK2022FormblattModel>(new entity.Knz2GK2022FormblattClient(), entity.Knz2GK2022FormblattModel, allMetadata.entities.Knz2GK2022FormblattModel as any);
	static Knz2GK2022ProjektModel = new EntityRepositoryWithFile<entity.Knz2GK2022ProjektModel, entity.BaseFile>(new entity.Knz2GK2022ProjektClient(), entity.Knz2GK2022ProjektModel, allMetadata.entities.Knz2GK2022ProjektModel as any, allMetadata.entities.BaseFile as any);
	static Knz3AbkModel = new EntityRepository<entity.Knz3AbkModel>(new entity.Knz3AbkModelClient(), entity.Knz3AbkModel, allMetadata.entities.Knz3AbkModel as any);
	static Knz3DatenImportModel = new EntityRepositoryWithFile<entity.Knz3DatenImportModel, entity.BaseFile>(new entity.Knz3DatenimportClient(), entity.Knz3DatenImportModel, allMetadata.entities.Knz3DatenImportModel as any, allMetadata.entities.BaseFile as any);
	static Knz4LSAbsenderModel = new EntityRepository<entity.Knz4LSAbsenderModel>(new entity.Knz4LSAbsenderClient(), entity.Knz4LSAbsenderModel, allMetadata.entities.Knz4LSAbsenderModel as any);
	static Knz4LSDatenImportModel = new EntityRepositoryWithFile<entity.Knz4LSDatenImportModel, entity.BaseFile>(new entity.Knz4LSDatenimportClient(), entity.Knz4LSDatenImportModel, allMetadata.entities.Knz4LSDatenImportModel as any, allMetadata.entities.BaseFile as any);
	static Knz4LSIngBueroModel = new EntityRepository<entity.Knz4LSIngBueroModel>(new entity.Knz4LSIngBueroClient(), entity.Knz4LSIngBueroModel, allMetadata.entities.Knz4LSIngBueroModel as any);
	static Knz4LSModel = new EntityRepository<entity.Knz4LSModel>(new entity.Knz4LSClient(), entity.Knz4LSModel, allMetadata.entities.Knz4LSModel as any);
	static Knz4LSSettingModel = new EntityRepository<entity.Knz4LSSettingModel>(new entity.Knz4LSSettingClient(), entity.Knz4LSSettingModel, allMetadata.entities.Knz4LSSettingModel as any);
	static Knz4LVDatenImportModel = new EntityRepositoryWithFile<entity.Knz4LVDatenImportModel, entity.BaseFile>(new entity.Knz4LVDatenimportClient(), entity.Knz4LVDatenImportModel, allMetadata.entities.Knz4LVDatenImportModel as any, allMetadata.entities.BaseFile as any);
	static Knz4LVModel = new EntityRepository<entity.Knz4LVModel>(new entity.Knz4LVClient(), entity.Knz4LVModel, allMetadata.entities.Knz4LVModel as any);
	static BaseUserModel = new EntityRepository<entity.BaseUserModel>(new entity.UsersClient(), entity.BaseUserModel, allMetadata.entities.BaseUserModel as any);
}
