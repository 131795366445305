import { DialogFooter, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { WorkflowHistoryList } from ".";
import { DBDialog } from "..";
import { BaseEntity } from "../../../generated/BackendClient";
import { IEntityRepository } from "../../../Rad/DAL/EntityRepository";

export class WorkflowHistoryModal<T extends BaseEntity> extends React.Component<
    {
        Repository: IEntityRepository<T>;
        ParentObject: BaseEntity;
        OnClose: () => void;
    },
    {}
> {
    public render() {
        return (
            <DBDialog
                onDismiss={this.props.OnClose}
                dialogContentProps={{
                    title: `Workflowhistorie`
                }}
            >
                <WorkflowHistoryList Repository={this.props.Repository} ParentObject={this.props.ParentObject} />
                <DialogFooter>
                    <PrimaryButton onClick={this.props.OnClose} text="Ok" />
                </DialogFooter>
            </DBDialog>
        );
    }
}
