import { DefaultButton, DialogFooter, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { EntityReferenceOfKnz2GK2022BereichModel } from "../../../generated/BackendClient";
import { Knz2GK2022Bereich, Knz2Repository } from "../../../Model/Knz2";
import { Utils } from "../../../Utils";
import { DBDialog, NumberField, PleaseWait } from "../../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../../Generic/Error-Handler/Decorators";

@CatchReactErrors
export class Knz2DivideBereichDialog extends React.Component<
    {
        Model: Knz2Repository;
        Item: Knz2GK2022Bereich;
        CloseDialog: (result: boolean, newModel1: Knz2GK2022Bereich, newModel2: Knz2GK2022Bereich) => void;
    },
    {
        kmVon: number;
        kmBis: number;
        kmTeilen: number;
        IsLoading: boolean;
        IsValid: boolean;
        UseAnlage1: boolean;
    }
> {
    public readonly state = {
        kmVon: -1,
        kmBis: -1,
        kmTeilen: -1,
        IsValid: false,
        IsLoading: false,
        UseAnlage1: true
    };

    public componentDidMount(): void {
        this.componentDidUpdate({});
    }

    public componentDidUpdate(prevProps): void {
        if (prevProps.Item !== this.props.Item) {
            const useAnlage1 = this.props.Item.f05KmVon1 !== null && this.props.Item.f06KmBis1 !== null;
            if (useAnlage1) {
                this.setState({
                    UseAnlage1: useAnlage1,
                    kmVon: this.props.Item.f05KmVon1,
                    kmBis: this.props.Item.f06KmBis1,
                    kmTeilen: null
                });
            } else {
                this.setState({
                    UseAnlage1: useAnlage1,
                    kmVon: this.props.Item.f07KmVon3,
                    kmBis: this.props.Item.f08KmBis3,
                    kmTeilen: null
                });
            }
        }
    }

    public render() {
        return (
            <DBDialog
                onDismiss={() => {
                    this.ExitDialog(false);
                }}
                dialogContentProps={{
                    title: "Bereich teilen"
                }}
                minWidth="760px"
            >
                <PleaseWait
                    ShowSpinner={this.state.IsLoading}
                    render={() => (
                        <>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12">
                                        An welchem Kilometer soll der Bereich geteilt werden? Bitte geben Sie eine Zahl
                                        zwischen {Utils.FormatKilometer(this.state.kmVon)} und{" "}
                                        {Utils.FormatKilometer(this.state.kmBis)} ein.
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6" style={{ marginTop: "20px" }}>
                                        <NumberField
                                            InitialValue={this.state.kmTeilen}
                                            OnChange={(value: number) => {
                                                this.setState({ kmTeilen: value });
                                            }}
                                            TextFieldProps={{
                                                label: "Teilung durchführen bei km",
                                                required: true
                                            }}
                                            ValidateRule={(value: number): string => {
                                                if (value > this.state.kmVon && value < this.state.kmBis) {
                                                    this.setState({ IsValid: true });
                                                    return "";
                                                }

                                                this.setState({ IsValid: false });
                                                return `Diese Zahl muss größer als ${Utils.FormatKilometer(
                                                    this.state.kmVon
                                                )} und kleiner als ${Utils.FormatKilometer(this.state.kmBis)} sein.`;
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <DialogFooter>
                                <PrimaryButton
                                    onClick={() => {
                                        this.ExitDialog(true);
                                    }}
                                    text="Teilen"
                                    disabled={!this.state.IsValid}
                                />
                                <DefaultButton
                                    onClick={() => {
                                        this.ExitDialog(false);
                                    }}
                                    text="Abbrechen"
                                />
                            </DialogFooter>
                        </>
                    )}
                />
            </DBDialog>
        );
    }

    @CatchReactErrorsMethod()
    private async ExitDialog(shouldSave: boolean) {
        if (shouldSave) {
            if (!this.state.IsValid) {
                return;
            }
            this.setState({
                IsLoading: true
            });

            let bereichClone1: Knz2GK2022Bereich = this.props.Item.Clone();
            let bereichClone2: Knz2GK2022Bereich = this.props.Item.Clone();
            bereichClone2.f43GeteiltAus = bereichClone1.f43GeteiltAus = new EntityReferenceOfKnz2GK2022BereichModel({
                objectId: this.props.Item.objectId,
                legacyId: null
            });

            if (this.state.UseAnlage1) {
                bereichClone2.f06KmBis1 = bereichClone1.f05KmVon1 = this.state.kmTeilen;
            } else {
                bereichClone2.f08KmBis3 = bereichClone1.f07KmVon3 = this.state.kmTeilen;
            }

            bereichClone1 = await this.props.Model.AddBereich(bereichClone1);
            bereichClone2 = await this.props.Model.AddBereich(bereichClone2);

            this.props.Item.f44IstGeteilt = true;

            await this.props.Model.SaveBereich(this.props.Item);
            this.setState({
                IsLoading: false
            });

            this.props.CloseDialog(true, bereichClone1, bereichClone2);
        }

        this.props.CloseDialog(false, undefined, undefined);
    }
}
