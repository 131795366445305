import { DefaultButton, Label, memoizeFunction, mergeStyleSets } from "office-ui-fabric-react";
import * as React from "react";
import Files from "react-butterfiles";
import { FileParameter } from "../../generated/BackendClient";
import { CatchReactErrors } from "./Error-Handler/Decorators";

interface IFileUploaderClassNames {
    required: string;
}

const getClassNames = memoizeFunction(
    (): IFileUploaderClassNames => {
        return mergeStyleSets({
            required: {
                color: "rgb(164, 38, 44)"
            }
        });
    }
);

@CatchReactErrors
export class FileUploader extends React.Component<
    {
        Label?: string;
        OnUploaded?: (file: FileParameter) => void;
        FileObject?: FileParameter;
        Required?: boolean;
    },
    {
        File: FileParameter;
        Errors: any[];
    }
> {
    public readonly state = { File: undefined, Errors: [] };
    public spanStyle = {
        padding: "10px",
        color: "Blue"
    };
    constructor(props) {
        super(props);
    }

    public componentDidMount(): void {
        if (this.props.FileObject) {
            this.setState({ File: this.props.FileObject });
        }
    }
    public render(): JSX.Element {
        const { required } = getClassNames();
        return (
            <>
                {this.props.Label && (
                    <Label>
                        {this.props.Label} {this.props.Required && <span className={required}> *</span>}
                    </Label>
                )}
                <Files
                    multiple={false}
                    maxSize="100mb"
                    multipleMaxSize="100mb"
                    convertToBase64={true}
                    onSuccess={(files) => {
                        this.onDrop(files);
                    }}
                    onError={(errors) => console.log(errors)}
                >
                    {({ browseFiles, getDropZoneProps }) => {
                        return (
                            <div>
                                <div
                                    {...getDropZoneProps({
                                        style: {
                                            minHeight: 40,
                                            border: "2px lightgray dashed",
                                            paddingTop: "1px"
                                        }
                                    })}
                                >
                                    {this.state.File && (
                                        <span style={this.spanStyle} key={this.state.File.data.name}>
                                            {this.state.File.data.name}
                                        </span>
                                    )}
                                </div>
                                <div>
                                    Sie können Datei in den gestrichelten Bereich ziehen oder den folgenden Knopf
                                    drücken:
                                    <DefaultButton text="Dateien hochladen..." onClick={browseFiles} />
                                </div>
                            </div>
                        );
                    }}
                </Files>
            </>
        );
    }

    private async onDrop(accepted: any[]): Promise<void> {
        const file: FileParameter = { fileName: accepted[0].src.file.name, data: accepted[0].src.file };
        // force component refresh
        this.setState({ File: file });
        if (this.props.OnUploaded) {
            this.props.OnUploaded(file);
        }
    }
}
