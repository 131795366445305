import { BaseEntity, IEntityRepository } from "../../DAL";

export type RadEditDialogState<T extends BaseEntity> = {
    IsLoading: boolean;
    Model: T;
    Repository: IEntityRepository<T>;
    ObjectId: string;
    OnDialogClosed: (wasSaved: boolean, newModel: T) => void;
};

export type RadEditDialogAction<T extends BaseEntity> =
    | { type: "setObjectId"; objectId: string }
    | { type: "itemLoaded"; item: T }
    | { type: "updateField"; fieldName: string; value: any }
    | { type: "closeDialog" }
    | { type: "Loading" };

export function RadEditDialogStateReducer<T extends BaseEntity>() {
    return (state: RadEditDialogState<T>, action: RadEditDialogAction<T>): RadEditDialogState<T> => {
        switch (action.type) {
            case "setObjectId":
                return { ...state, ObjectId: action.objectId, Model: null, IsLoading: true };
            case "itemLoaded":
                return { ...state, IsLoading: false, Model: action.item };
            case "closeDialog":
                return { ...state, IsLoading: true };
            case "updateField":
                return { ...state, Model: { ...state.Model, [action.fieldName]: action.value } };
            default:
                throw new Error("Action not supported");
        }
    };
}

export async function RadEditDialogLoad<T extends BaseEntity>(
    dispatch: (action: RadEditDialogAction<T>) => void,
    state: RadEditDialogState<T>
): Promise<void> {
    let item: T;
    if (state.ObjectId) {
        item = await state.Repository.get(state.ObjectId);
    } else {
        item = await state.Repository.empty();
    }

    dispatch({ type: "itemLoaded", item: item });
}

export async function RadEditDialogClose<T extends BaseEntity>(
    dispatch: (action: RadEditDialogAction<T>) => void,
    state: RadEditDialogState<T>,
    shouldSave: boolean
): Promise<void> {
    dispatch({ type: "closeDialog" });

    if (!shouldSave) {
        state.OnDialogClosed(shouldSave, state.Model);
        return;
    }

    let newItem: T;
    if (state.Model.objectId) {
        newItem = await state.Repository.update(state.Model);
    } else {
        newItem = await state.Repository.add(state.Model);
    }

    state.OnDialogClosed(shouldSave, newItem);
}
