import { observer } from "mobx-react";
import { IconButton, TextField } from "office-ui-fabric-react";
import * as React from "react";
import { Utils } from "../../../Utils";
import { EasyNumberField, EasyTextField } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz2KilometrierungRow extends React.Component<
    {
        Object: object;
        IsReadOnly: boolean;
        PropKmVon: string;
        PropKmBis: string;
        PropKmStreckeNr: string;
        OnChange: (index: number) => void;
        OnAdd: (index: number) => void;
        OnRemove: (index: number) => void;
        Index: number;
        AllowRemove: boolean;
        AllowAdd: boolean;
    },
    {}
> {
    public readonly state = {};

    public render(): JSX.Element {
        return (
            <div className="ms-Grid-row" style={{ marginBottom: "3px" }}>
                <div className="ms-Grid-col ms-sm2">
                    <EasyTextField
                        Object={this.props.Object}
                        PropertyName={this.props.PropKmStreckeNr}
                        TextFieldProps={{
                            mask: "9999",
                            maxLength: 4,
                            required: true,
                            disabled: this.props.IsReadOnly
                        }}
                        OnChange={() => {
                            this.props.OnChange(this.props.Index);
                        }}
                    />
                </div>
                <div className="ms-Grid-col ms-sm2">
                    <EasyNumberField
                        Object={this.props.Object}
                        PropertyName={this.props.PropKmVon}
                        TextFieldProps={{ required: true, disabled: this.props.IsReadOnly }}
                        OnChange={() => {
                            this.props.OnChange(this.props.Index);
                        }}
                    />
                </div>
                <div className="ms-Grid-col ms-sm2">
                    <EasyNumberField
                        Object={this.props.Object}
                        PropertyName={this.props.PropKmBis}
                        TextFieldProps={{ required: true, disabled: this.props.IsReadOnly }}
                        OnChange={() => {
                            this.props.OnChange(this.props.Index);
                        }}
                    />
                </div>
                <div className="ms-Grid-col ms-sm2">
                    <TextField
                        value={
                            this.props.Object &&
                            Utils.FormatKilometer(
                                Utils.KilometerDifference(
                                    this.props.Object[this.props.PropKmVon],
                                    this.props.Object[this.props.PropKmBis]
                                )
                            )
                        }
                        disabled={true}
                        readOnly={true}
                    />
                </div>
                <div className="ms-Grid-col ms-sm1">
                    {this.props.AllowRemove && (
                        <IconButton
                            iconProps={{ iconName: "Remove" }}
                            title="Zeile löschen"
                            onClick={() => {
                                this.props.OnRemove(this.props.Index);
                            }}
                        />
                    )}
                </div>
                <div className="ms-Grid-col ms-sm1">
                    {this.props.AllowAdd && (
                        <IconButton
                            iconProps={{ iconName: "Add" }}
                            title="Zeile ergänzen"
                            onClick={() => {
                                this.props.OnAdd(this.props.Index);
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}
