import * as mobx from "mobx";
import { Bericht, BerichteRepository } from ".";
import { IDetailListDatasource } from "../../tsx/Generic/LargeDetailsList";

export class BerichteDetailListModel implements IDetailListDatasource {
    @mobx.computed public get IsLoading(): boolean {
        return this.RunningRequests > 0;
    }
    private BerichteItems: Map<string, Bericht>;

    @mobx.observable private RunningRequests = 0;
    private Model: BerichteRepository;
    private includeRecycleBinItems: boolean;

    public constructor(model: BerichteRepository, includeRecycleBinItems?: boolean) {
        this.Model = model;
        this.includeRecycleBinItems = includeRecycleBinItems;
    }

    public Sort(items: Bericht[]): void {
        items.sort((a, b) => (a.created < b.created ? 1 : -1));
    }

    public async GetAllItems(): Promise<Map<string, Bericht>> {
        this.BerichteItems = await this.Model.GetBerichte(this.includeRecycleBinItems);

        return this.BerichteItems;
    }

    public Equals(source: BerichteDetailListModel): boolean {
        return this === source;
    }
}
