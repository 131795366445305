import { observer } from "mobx-react";
import { DefaultButton, DialogFooter, MessageBarType, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import {
    AbstractDatenImportModel,
    EnumResultStatus,
    ExcelRowResult,
    FileParameter
} from "../../generated/BackendClient";
import { DatenImport } from "../../Model/DatenImport";
import { DBDialog, EasyForm, FileUploader, IValidationMessage, ValidationMessages } from "../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../Generic/Error-Handler/Decorators";

@CatchReactErrors
@observer
export class DatenImportNewDialog extends React.Component<
    {
        CloseDialog: () => void;
        ValidateExcel: (file: FileParameter) => Promise<ExcelRowResult[]>;
        ImportExcel: (file: FileParameter) => Promise<void>;
    },
    {
        DatenImport: AbstractDatenImportModel;
        ValidationErrorMessage: string[];
        SelectedFile: FileParameter;
        IsFileSelected: boolean;
        IsFileValidated: boolean;
    }
> {
    public readonly state = {
        DatenImport: new DatenImport(), // TODO!?
        ValidationErrorMessage: [],
        IsFileSelected: false,
        IsFileValidated: false,
        SelectedFile: undefined
    };

    public render(): JSX.Element {
        return (
            <DBDialog
                onDismiss={() => {
                    this.ExitDialog(false);
                }}
                dialogContentProps={{
                    title: "Neuer Datenimport"
                }}
                minWidth="760px"
            >
                <EasyForm Object={this.state.DatenImport} ShowFooter={true}>
                    <ValidationMessages Messages={this.showFileValidationMsg()} />
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12">
                                <FileUploader
                                    Label="Zu importierende Datei"
                                    OnUploaded={(file) => {
                                        this.setState({
                                            SelectedFile: file,
                                            IsFileValidated: false,
                                            ValidationErrorMessage: []
                                        });
                                    }}
                                    FileObject={this.state.SelectedFile}
                                />
                            </div>
                        </div>
                    </div>
                    <DialogFooter>
                        <PrimaryButton
                            onClick={() => {
                                this.StartValidatingFile();
                            }}
                            text="Validierung starten"
                            disabled={
                                !this.state.DatenImport.IsValid ||
                                this.state.SelectedFile === undefined ||
                                this.state.IsFileValidated
                            }
                        />
                        <PrimaryButton
                            onClick={() => {
                                this.ExitDialog(true);
                            }}
                            text="Datenimport starten"
                            disabled={!this.state.IsFileValidated}
                        />
                        <DefaultButton
                            onClick={() => {
                                this.ExitDialog(false);
                            }}
                            text="Abbrechen"
                        />
                    </DialogFooter>
                </EasyForm>
            </DBDialog>
        );
    }

    @CatchReactErrorsMethod()
    private async ExitDialog(shouldSave: boolean) {
        if (shouldSave) {
            if (!this.state.DatenImport.IsValid) {
                return;
            }
            await this.props.ImportExcel(this.state.SelectedFile);
            this.props.CloseDialog();
        } else {
            this.props.CloseDialog();
        }
    }

    private showFileValidationMsg(): IValidationMessage[] {
        if (this.state.SelectedFile && this.state.ValidationErrorMessage.length > 0) {
            return [
                {
                    Text: "Die Datei kann nicht validiert werden:\n" + this.state.ValidationErrorMessage.join("\n"),
                    Type: MessageBarType.error
                }
            ];
        } else if (
            this.state.IsFileValidated &&
            this.state.SelectedFile &&
            this.state.ValidationErrorMessage.length === 0
        ) {
            return [
                {
                    Text: "Die Datei wurde erfolgreich validiert.",
                    Type: MessageBarType.success
                }
            ];
        }
        return [];
    }

    private async StartValidatingFile(): Promise<void> {
        const validationResults = await this.props.ValidateExcel(this.state.SelectedFile);
        this.setState({
            ValidationErrorMessage: validationResults.map((r) => `Zeile ${r.lineNumber}: ${r.message} (${r.status})`),
            IsFileValidated: validationResults.filter((r) => r.status === EnumResultStatus.Error).length === 0
        });
    }
}
