import { observer } from "mobx-react";
import { DefaultButton, DialogFooter, Label, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { Knz2FormblattPruefung1, Knz2FormblattPruefung2, Knz2FormblattPruefung3 } from ".";
import { BaseUserModel, Knz2FormblattPModelInit, UsersClient } from "../../../generated/BackendClient";
import { nameof, Utils } from "../../../Utils";
import { DBDialog, EasyForm, PleaseWait } from "../../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../../Generic/Error-Handler/Decorators";
import { EasyCheckbox, EasyCombobox, EasyTextField } from "../../Generic/Forms";

@observer
@CatchReactErrors
export class Knz2FormblattPStartDialog extends React.Component<
    { CloseDialog: (shouldSave: boolean, model: Knz2FormblattPModelInit) => void },
    { Model: Knz2FormblattPModelInit; IsLoading: boolean; Users: BaseUserModel[] }
> {
    public readonly state = {
        IsLoading: true,
        Users: undefined,
        Model: new Knz2FormblattPModelInit() // this is a mobx.observable!
    };

    public async componentDidMount(): Promise<void> {
        const wf = new UsersClient();
        const groupUsers = await wf.getUsersInRole("Knz2 Freigabe Formblatt P");
        this.setState({
            Users: groupUsers,
            IsLoading: false
        });
        if (!this.state.Model.rM208a) {
            this.state.Model.rM208a = false;
        }
    }

    public render(): JSX.Element {
        return (
            <DBDialog
                onDismiss={() => {
                    this.ExitDialog(false);
                }}
                dialogContentProps={{
                    title: "Freigabe für Formblatt P starten"
                }}
                minWidth="760px"
            >
                <PleaseWait
                    ShowSpinner={this.state.IsLoading}
                    render={() => (
                        <EasyForm Object={this.state.Model} ShowFooter={true}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6">
                                        <EasyCombobox
                                            PropertyName={nameof<Knz2FormblattPModelInit>("rM204")}
                                            ComboBoxProps={{ label: "Freigebende Projektleitung", required: true }}
                                            Options={this.state.Users.map((u: BaseUserModel) => u.displayName)}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm6">
                                        <EasyTextField
                                            PropertyName={nameof<Knz2FormblattPModelInit>("rM205")}
                                            TextFieldProps={{ label: "Abteilung der PL", required: true }}
                                        />
                                    </div>
                                </div>

                                <Knz2FormblattPruefung1 PropertyName={nameof<Knz2FormblattPModelInit>("rM208a")} />
                                <Knz2FormblattPruefung2
                                    Options={{ rM208b: "Schallschutzfenster", rM208c: "Schalldämmlüfter" }}
                                />

                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm8">
                                        Alle Eigentümer der förderfähigen Objekte wurden mit Anschreiben informiert:
                                        <EasyCheckbox
                                            PropertyName={nameof<Knz2FormblattPModelInit>("rM208d")}
                                            CheckboxProps={{ label: "vollständig abgeschlossen" }}
                                            Required
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm8">
                                        <Label>Weitere Prüfungen</Label>
                                    </div>
                                </div>

                                <Knz2FormblattPruefung3 PropertyName={nameof<Knz2FormblattPModelInit>("rM209")} />
                            </div>

                            <DialogFooter>
                                <PrimaryButton
                                    onClick={() => {
                                        this.ExitDialog(true);
                                    }}
                                    text="Freigabe starten"
                                    disabled={!this.IsValid}
                                />
                                <DefaultButton
                                    onClick={() => {
                                        this.ExitDialog(false);
                                    }}
                                    text="Abbrechen"
                                />
                            </DialogFooter>
                        </EasyForm>
                    )}
                />
            </DBDialog>
        );
    }

    private get IsValid(): boolean {
        const model = this.state.Model;
        return (
            model.rM209 &&
            !Utils.IsNullOrEmpty(model.rM208d) &&
            !Utils.IsNullOrEmpty(model.rM204) &&
            !Utils.IsNullOrEmpty(model.rM205) &&
            !Utils.IsNullOrEmpty(model.rM208b) &&
            !Utils.IsNullOrEmpty(model.rM208c)
        );
    }

    @CatchReactErrorsMethod()
    private async ExitDialog(shouldSave: boolean) {
        this.props.CloseDialog(shouldSave, this.state.Model);
    }
}
