import { observer } from "mobx-react";
import { MessageBar, MessageBarType } from "office-ui-fabric-react/lib/MessageBar";
import * as React from "react";

@observer
export class EasyMessage extends React.Component<
    {
        Object?: object;
        PropertyName?: string;
        MessageText: string;
        MessageType: MessageBarType;
    },
    {}
> {
    public render() {
        if (this.props.PropertyName) {
            return (
                !this.props.Object[this.props.PropertyName] && (
                    <MessageBar messageBarType={this.props.MessageType}>
                        <span>{this.props.MessageText}</span>
                    </MessageBar>
                )
            );
        } else {
            return (
                <MessageBar messageBarType={this.props.MessageType}>
                    <span>{this.props.MessageText}</span>
                </MessageBar>
            );
        }
    }
}
