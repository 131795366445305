import { observer } from "mobx-react";
import { Label } from "office-ui-fabric-react";
import * as React from "react";
import { KilometerModel, Knz2AltprojektBereichModel } from "../../../generated/BackendClient";
import { Utils } from "../../../Utils";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz2BereicheTable extends React.Component<
    {
        Items: Knz2AltprojektBereichModel[] | KilometerModel[];
    },
    {}
> {
    public readonly state = {};

    public render(): JSX.Element {
        return (
            <>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm2">
                        <Label>Strecken-Nr.</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm2">
                        <Label>km von</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm2">
                        <Label>km bis</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm2">
                        <Label>Summe km</Label>
                    </div>
                    {(this.props.Items[0] as Knz2AltprojektBereichModel).bezeichnung !== undefined && (
                        <div className="ms-Grid-col ms-sm4">
                            <Label>Bezeichnung</Label>
                        </div>
                    )}
                </div>
                {this.props.Items.map((value, index) => (
                    <div className="ms-Grid-row" key={index}>
                        <div className="ms-Grid-col ms-sm2">{value.streckeNr}</div>
                        <div className="ms-Grid-col ms-sm2">{Utils.FormatKilometer(value.kmVon)}</div>
                        <div className="ms-Grid-col ms-sm2">{Utils.FormatKilometer(value.kmBis)}</div>
                        <div className="ms-Grid-col ms-sm2">
                            {Utils.FormatKilometer(Utils.KilometerDifference(value.kmBis, value.kmVon))}
                        </div>
                        {(this.props.Items[0] as Knz2AltprojektBereichModel).bezeichnung !== undefined && (
                            <div className="ms-Grid-col ms-sm4">
                                {(value as Knz2AltprojektBereichModel).bezeichnung}
                            </div>
                        )}
                    </div>
                ))}
            </>
        );
    }
}
