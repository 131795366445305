import * as mobx from "mobx";
import { Knz2GK2022BereichModel } from "../../Rad/DAL";
import { Utils } from "../../Utils";
import { Knz2BereichStatusAnlage1, Knz2BereichStatusAnlage3 } from "./Knz2Repository";

export class Knz2GK2022Bereich extends Knz2GK2022BereichModel {
    constructor(parent?: Knz2GK2022Bereich) {
        super(parent);
    }
    /**
     * returns true if all required fields are filled
     */
    @mobx.computed
    public get IsValid(): boolean {
        if (
            !this.knz2AbschnittRef?.objectId ||
            !this.f01Streckennummer ||
            !this.f02Bezeichnung ||
            !this.f26Bundesland
        ) {
            return false;
        }

        if (this.IsInAnlage1) {
            if (this.f05KmVon1 === null || this.f06KmBis1 === null) {
                return false;
            }
        }

        if (this.IsInAnlage3) {
            if (this.f07KmVon3 === null || this.f08KmBis3 === null) {
                return false;
            }
        }

        return true;
    }

    @mobx.computed
    public get IsInAnlage1(): boolean {
        return Knz2BereichStatusAnlage1.includes(this.f25Status) && !this.f44IstGeteilt;
    }

    @mobx.computed
    public get IsInAnlage3(): boolean {
        return Knz2BereichStatusAnlage3.includes(this.f25Status) && !this.f44IstGeteilt;
    }

    @mobx.computed
    public get f26BundeslandString(): string {
        return this.f26Bundesland?.join(", ") || "";
    }

    @mobx.computed
    public get f05LaengeKm1(): number {
        return Utils.KilometerDifference(this.f06KmBis1, this.f05KmVon1);
    }

    @mobx.computed
    public get f07LaengeKm3(): number {
        return Utils.KilometerDifference(this.f08KmBis3, this.f07KmVon3);
    }

    public get StreckenLaenge(): number {
        if (this.IsInAnlage1) {
            return this.f05LaengeKm1;
        }
        if (this.IsInAnlage3) {
            return this.f07LaengeKm3;
        }
        return 0;
    }

    public Clone(): Knz2GK2022Bereich {
        return new Knz2GK2022Bereich(this);
    }

    public CompareTo(other: Knz2GK2022Bereich): number {
        // 1. Sortierkriterium: Streckennummer
        if (other.f01Streckennummer !== this.f01Streckennummer) {
            return other.f01Streckennummer > this.f01Streckennummer ? -1 : 1;
        }

        const thiskm: number = this.f05KmVon1 !== null ? this.f05KmVon1 : this.f07KmVon3;
        const otherkm: number = other.f05KmVon1 !== null ? other.f05KmVon1 : other.f07KmVon3;

        return otherkm > thiskm ? -1 : 1;
    }
}
