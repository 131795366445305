import { Spinner } from "office-ui-fabric-react";
import * as React from "react";

export class PleaseWaitProcessing extends React.Component<
    {
        ShowSpinner: boolean;
        TextToShow?: string;
    },
    {}
> {
    public render(): JSX.Element {
        if (this.props.ShowSpinner) {
            if (this.props.TextToShow) {
                return (
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <strong>
                                <Spinner
                                    label={this.props.TextToShow}
                                    labelPosition="left"
                                    style={{ color: "white", float: "right", padding: "10px" }}
                                />
                            </strong>
                        </div>
                    </div>
                );
            } else {
                return (
                    <strong>
                        <Spinner labelPosition="left" style={{ color: "white", float: "right", padding: "10px" }} />
                    </strong>
                );
            }
        } else {
            return null;
        }
    }
}
