import { Mutex } from "async-mutex";
import { observer } from "mobx-react";
import {
    Check,
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    GroupFooter,
    GroupHeader,
    IColumn,
    IDetailsListProps,
    IDetailsRowProps,
    IGroup,
    IGroupDividerProps,
    IObjectWithKey,
    ITooltipHostProps,
    ScrollablePane,
    ScrollbarVisibility,
    Selection,
    SelectionMode,
    Sticky,
    StickyPositionType,
    TooltipHost
} from "office-ui-fabric-react";
import { DetailsHeader, IDetailsHeaderProps } from "office-ui-fabric-react/lib/components/DetailsList/DetailsHeader";
import * as React from "react";
import { CatchReactErrors, CatchReactErrorsMethod } from "./Error-Handler/Decorators";
import { PleaseWait } from "./PleaseWait";

export interface IGroupedListDatasource {
    IsLoading: boolean;
    GetAllGroups(): Promise<IGroupedListGroup[]>;
    ExpandGroup(group: IGroupedListGroup): Promise<any[]>;
    OnItemUpdated(item: any): Promise<void>;
}

export interface IGroupedListGroup {
    key: string;
    name: string;
    items: any[];
    data?: any;
    isExpanded: boolean;
    children?: IGroup[];
}

export interface IGroupedListFilter {
    Equals(other: IGroupedListFilter): boolean;
    IsSet(): boolean;
}

interface IGroupedListGroupInternal extends IGroup, IGroupedListGroup {}
enum CheckStatus {
    Unchecked,
    Checked,
    Halfchecked
}
@observer
@CatchReactErrors
export class LargeGroupedList extends React.Component<
    {
        Columns: IColumn[];
        DataSource: IGroupedListDatasource;
        OnItemInvoked: (item: any) => void;
        OnSelectedItems: (selectedItems: {}) => void;
        DetailsListProps?: any | IDetailsListProps;
        CanSelectItem?: (item: any) => boolean;
    },
    {
        Items: any[];
        Groups: IGroupedListGroupInternal[];
        selectedItems: any[];
        IsLoading: boolean;
    }
> {
    public readonly state = {
        IsLoading: true,
        Items: [],
        Groups: [],
        selectedItems: []
    };

    private selection = new Selection({
        onSelectionChanged: () => {
            const groups = this.state.Groups.slice();
            this.props.OnSelectedItems(this.selection.getSelection());
            this.setState({ selectedItems: this.selection.getSelection(), Groups: groups });
        },
        canSelectItem: (item: IObjectWithKey, index?: number): boolean => {
            if (this.props.CanSelectItem) {
                return this.props.CanSelectItem(item);
            }
            return true;
        }
    });
    private mutex = new Mutex();

    public async componentDidUpdate(prevProps) {
        if (prevProps.DataSource !== this.props.DataSource) {
            await this.RefreshGroups(); // async
        }
    }

    public async componentDidMount() {
        await this.RefreshGroups();
    }

    @CatchReactErrorsMethod()
    public async RefreshGroups(): Promise<void> {
        const release = await this.mutex.acquire();
        this.setState({ IsLoading: true });
        try {
            const oldGroups = new Map<string, IGroupedListGroupInternal>(
                this.state.Groups.map((g) => [g.key, g] as [string, IGroupedListGroupInternal])
            );

            const rawGroups = await this.props.DataSource.GetAllGroups();
            const groups: IGroupedListGroupInternal[] = [];
            let items: any[] = [];
            for (const obj of rawGroups) {
                // try to merge with old groups, if available
                let group: any = oldGroups.get(obj.key) || {};
                group = {
                    isCollapsed: true,
                    ...group,
                    ...obj,
                    startIndex: items.length,
                    level: 0,
                    count: obj.items ? obj.items.length : 0
                };
                if (obj.items) {
                    items = items.concat(obj.items.slice()); // slice() for mobx
                }
                groups.push(group);
            }

            this.setState({
                Groups: groups,
                Items: items
            });
        } finally {
            this.setState({ IsLoading: false });

            release();
        }
    }

    public render(): JSX.Element {
        return (
            <div style={{ position: "relative", height: "80vh" }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <PleaseWait
                        ShowSpinner={!this.props.DataSource || this.props.DataSource.IsLoading || this.state.IsLoading}
                        render={() => (
                            <DetailsList
                                {...this.props.DetailsListProps}
                                items={this.state.Items}
                                columns={this.props.Columns}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                compact={true}
                                selectionMode={SelectionMode.multiple}
                                selection={this.selection}
                                groups={this.state.Groups}
                                onRenderRow={this._onRenderRow}
                                groupProps={{
                                    onRenderHeader: this._onRenderGroupHeader.bind(this),
                                    onRenderFooter: this._onRenderGroupFooter.bind(this),
                                    showEmptyGroups: true
                                }}
                                // implement custom getGroupHeight() as we sometimes get strange exceptions otherwise
                                getGroupHeight={(group: IGroupedListGroupInternal) => {
                                    return 32 + (!group.isCollapsed && group.items ? group.items.length * 32 : 0);
                                }}
                                onItemInvoked={(item) => {
                                    this.props.OnItemInvoked(item);
                                }}
                                constrainMode={ConstrainMode.unconstrained} // to make the sticky header work!
                                onRenderDetailsHeader={this._onRenderdetailsHeader}
                            />
                        )}
                    />
                </ScrollablePane>
            </div>
        );
    }

    private _onRenderRow(props: IDetailsRowProps): JSX.Element {
        return <DetailsRow {...props} groupNestingDepth={0} />;
    }

    private _onRenderGroupHeader(props: IGroupDividerProps): JSX.Element {
        let checked = this.selection.isAllSelected() || props.selected ? CheckStatus.Checked : CheckStatus.Unchecked;

        if (checked === CheckStatus.Unchecked) {
            const currentGroup = this.state.Groups.find((g) => g.name === props.group.name);
            if (this.state.selectedItems.length > 0) {
                const selectedItemsInGroup: any[] = [];
                for (const item of currentGroup.items) {
                    const itemFound = this.state.selectedItems.find(
                        (selectedItem: any) => selectedItem["objectId"] === item["objectId"]
                    );
                    if (itemFound) {
                        selectedItemsInGroup.push(itemFound);
                    }
                }
                if (selectedItemsInGroup.length > 0) {
                    checked = CheckStatus.Halfchecked;
                }
            }
        }

        return (
            <GroupHeader
                {...props}
                onRenderGroupHeaderCheckbox={(p) => this._onRenderGroupHeaderCheckbox(checked)}
                styles={checked !== CheckStatus.Unchecked ? { check: { opacity: 1 } } : {}}
            />
        );
    }

    private _onRenderGroupHeaderCheckbox(status: CheckStatus) {
        if (status === CheckStatus.Halfchecked) {
            return (
                <span style={{ opacity: 0.3 }}>
                    <Check checked={true} />
                </span>
            );
        }

        return <Check checked={status === CheckStatus.Checked} />;
    }

    private _onRenderGroupFooter(props: IGroupDividerProps): JSX.Element {
        return <GroupFooter {...props} />;
    }

    // implements a stickyheader
    private _onRenderdetailsHeader(props: IDetailsHeaderProps): JSX.Element {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                <DetailsHeader
                    {...props}
                    groupNestingDepth={0}
                    onRenderColumnHeaderTooltip={(tooltipHostProps: ITooltipHostProps) => (
                        <TooltipHost {...tooltipHostProps} />
                    )}
                />
            </Sticky>
        );
    }
}
