import { observer } from "mobx-react";
import {
    DefaultButton, DialogFooter, Pivot,
    PivotItem,
    PrimaryButton
} from "office-ui-fabric-react";
import * as React from "react";
import { Knz4LVRegionalBereicheControl } from ".";
import { LskRepository } from "../../DAL/LskRepository";
import { EnumRegionalbereich } from "../../generated/BackendClient";
import { Knz4LV, Knz4LVRepository } from "../../Model/Knz4LV";
import { RadVersionHistoryListEntity } from "../../Rad/tsx/Controls";
import { Bundeslaender, nameof } from "../../Utils/Utils";
import { DBDialog, EasyCombobox, EasyDatePicker, EasyForm, EasyNumberField, EasyTextField, Header, PleaseWait } from "../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz4LVEditDialog extends React.Component<
    {
        Model: Knz4LVRepository;
        InitialModel: Knz4LV;
        hideDialog: boolean;
        closeDialog: (result: boolean, newModel: Knz4LV) => Promise<void>;
    },
    {
        Item: Knz4LV;
        Maßnahme: string[];
        IsLoading: boolean;
        Disabled: boolean;
        RegionalBereichDisabled: boolean;
    }
> {
    private repository = LskRepository.Knz4LVModel;
    public readonly state = {
        Item: this.props.InitialModel.Clone(), // clone source object and dereference mobx properties
        Maßnahme: [],
        IsLoading: true,
        Disabled: false,
        RegionalBereichDisabled: false
    };

    public async componentDidMount() {
        if (this.props.InitialModel.objectId) {
            const knz4LVItem: Knz4LV = await this.repository.get(this.props.InitialModel.objectId);

            if (await this.props.Model.CanEditItem(this.props.InitialModel)) {
                this.setState({ Disabled: false, RegionalBereichDisabled: false });
            } else {
                this.setState({ Disabled: true, RegionalBereichDisabled: true });
            }
    
            this.setState({
                IsLoading: false,
                Maßnahme: await this.props.Model.GetMassnahme(),
                Item: knz4LVItem
            });
        } else {
            this.setState({
                IsLoading: false,
                Maßnahme: await this.props.Model.GetMassnahme(),
                Item: this.props.InitialModel
            });
        }

    }

    public render(): JSX.Element {
        return (
            <DBDialog
                onDismiss={() => {
                    this.ExitDialog(false);
                }}
                dialogContentProps={{
                    title: (this.props.InitialModel?.objectId && "Maßnahme bearbeiten") || "Maßnahme erstellen"
                }}
                maxWidth={1200}
            >
                <PleaseWait
                    ShowSpinner={this.state.IsLoading}
                    render={() => (
                        <EasyForm Object={this.state.Item} Disabled={this.state.Disabled} ShowFooter={true}>
                            <Pivot>
                                <PivotItem headerText="Regionalbereich">
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12">
                                                <Header>Regionalbereich</Header>
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm8">
                                                <Knz4LVRegionalBereicheControl
                                                    Model={this.props.Model}
                                                    Selected={this.state.Item.k4LVF32Regionalbereich}
                                                    Disabled={this.state.RegionalBereichDisabled}
                                                    OnChange={async (rb: EnumRegionalbereich) => {
                                                        this.setState({ IsLoading: true });
                                                        this.state.Item.k4LVF32Regionalbereich = rb;
                                                        const mayAccess =
                                                            await this.props.Model.CanWriteRegionalBereich(rb);
                                                        this.setState({
                                                            IsLoading: false,
                                                            Disabled: !mayAccess,
                                                            RegionalBereichDisabled: false
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </PivotItem>
                                <PivotItem headerText="Wohnungsdaten">
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12">
                                                <Header>Wohnungsdaten</Header>
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm4">
                                                <EasyTextField
                                                    PropertyName={nameof<Knz4LV>("k4LVF10Vorname")}
                                                    TextFieldProps={{
                                                        label: "Vorname Wohnungseigentümer",
                                                        placeholder: "Befüllung optional"
                                                    }}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm4">
                                                <EasyTextField
                                                    PropertyName={nameof<Knz4LV>("k4LVF09Nachname")}
                                                    TextFieldProps={{
                                                        label: "Nachname Wohnungseigentümer",
                                                        placeholder: "Befüllung optional"
                                                    }}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm4">
                                                <EasyNumberField
                                                    PropertyName={nameof<Knz4LV>("k4F06AnzahlWohnungseinheiten")}
                                                    TextFieldProps={{
                                                        label: "Anzahl Wohnungseinheiten",
                                                        required: true
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6">
                                                <EasyTextField
                                                    PropertyName={nameof<Knz4LV>("k4LVF01Strasse")}
                                                    TextFieldProps={{ label: "Straße", required: true }}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3">
                                                <EasyTextField
                                                    PropertyName={nameof<Knz4LV>("k4LVF02Hausnr")}
                                                    TextFieldProps={{ label: "Hausnummer", required: true }}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3">
                                                <EasyTextField
                                                    PropertyName={nameof<Knz4LV>("k4LVF03Erganzung")}
                                                    TextFieldProps={{
                                                        label: "Ergänzung",
                                                        placeholder: "Befüllung optional"
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm3">
                                                <EasyTextField
                                                    PropertyName={nameof<Knz4LV>("k4LVF04PLZ")}
                                                    TextFieldProps={{ label: "PLZ", maxLength: 5, required: true }}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3">
                                                <EasyTextField
                                                    PropertyName={nameof<Knz4LV>("k4LVF05Ort")}
                                                    TextFieldProps={{ label: "Ort", required: true }}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3">
                                                <EasyCombobox
                                                    PropertyName={nameof<Knz4LV>("k4LVF05Bundesland")}
                                                    Options={Bundeslaender}
                                                    MultiSelect={false}
                                                    ComboBoxProps={{ label: "Bundesland", required: true }}
                                                />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12">
                                                <EasyTextField
                                                    PropertyName={nameof<Knz4LV>("k4LVF11Bemerkung")}
                                                    TextFieldProps={{
                                                        label: "Bemerkungen",
                                                        multiline: true,
                                                        rows: 2,
                                                        placeholder: "Befüllung optional"
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </PivotItem>
                                <PivotItem headerText="Maßnahmen">
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12">
                                                <Header>Maßnahmen</Header>
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6">
                                                <EasyTextField
                                                    PropertyName={nameof<Knz4LV>("k4LVF20Untersuchungsbericht")}
                                                    TextFieldProps={{
                                                        label: "Nummer Schalltechnische Untersuchung",
                                                        required: true
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6">
                                                <EasyTextField
                                                    PropertyName={nameof<Knz4LV>("k4LVF13SAPProjektnummer")}
                                                    TextFieldProps={{ label: "SAP Projektnummer", required: true }}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm6">
                                                <EasyTextField
                                                    PropertyName={nameof<Knz4LV>("k4LVF14Projektabschnitt")}
                                                    TextFieldProps={{
                                                        label: "Projektabschnitt",
                                                        placeholder: "Befüllung optional"
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm4">
                                                <EasyTextField
                                                    PropertyName={nameof<Knz4LV>("k4LVF17Rechnungsnummer")}
                                                    TextFieldProps={{
                                                        label: "SAP Buchungsbelegnummer",
                                                        placeholder: "Befüllung optional"
                                                    }}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm4">
                                                <EasyNumberField
                                                    PropertyName={nameof<Knz4LV>("k4LVF18Rechnungsbetrag")}
                                                    Decimals={2}
                                                    TextFieldProps={{
                                                        label: "Rechnungsbetrag",
                                                        suffix: "€",
                                                        required: true
                                                    }}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm4">
                                                <EasyDatePicker
                                                    PropertyName={nameof<Knz4LV>("k4LVF16Rechnungsdatum")}
                                                    DatePickerProps={{ label: "Rechnungsdatum", isRequired: true }}
                                                />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6">
                                                <EasyCombobox
                                                    PropertyName={nameof<Knz4LV>("k4LVF12Massnahme")}
                                                    Options={this.state.Maßnahme}
                                                    MultiSelect={false}
                                                    ComboBoxProps={{ label: "Maßnahme", required: true }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </PivotItem>
                                {this.props.InitialModel?.objectId && (
                                    <PivotItem headerText="Historie">
                                        <Header>Historie</Header>
                                        <RadVersionHistoryListEntity
                                            ObjectId={this.props.InitialModel.objectId}
                                            Repository={this.repository}
                                        />
                                    </PivotItem>
                                )}
                            </Pivot>
                            <DialogFooter>
                                <PrimaryButton
                                    onClick={() => {
                                        this.ExitDialog(true);
                                    }}
                                    text="Speichern"
                                    disabled={!this.state.Item.IsValid || this.state.Disabled}
                                />
                                <DefaultButton
                                    onClick={() => {
                                        this.ExitDialog(false);
                                    }}
                                    text="Abbrechen"
                                />
                            </DialogFooter>
                        </EasyForm>
                    )}
                />
            </DBDialog>
        );
    }

    @CatchReactErrorsMethod()
    private async ExitDialog(shouldSave: boolean) {
        if (shouldSave) {
            if (!this.state.Item.IsValid) {
                return;
            }
            this.setState({
                IsLoading: true
            });
        }

        await this.props.closeDialog(shouldSave, this.state.Item);
    }
}
