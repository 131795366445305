import { observer } from "mobx-react";
import { DialogFooter, Link, MessageBar, Pivot, PivotItem, PrimaryButton, TextField } from "office-ui-fabric-react";
import * as React from "react";
import { LskRepository } from "../../../DAL/LskRepository";
import allMetadata from "../../../generated/EntityMetadata.json";
import { Knz2GK2018Abschnitt } from "../../../Model/Knz2/GK2018/Knz2Abschnitt";
import { Knz2GK2018Bereich } from "../../../Model/Knz2/GK2018/Knz2Bereich";
import {
    EnumFormAStatus,
    EnumFormPStatus,
    EnumLVStatus,
    Knz2GK2018Projekt
} from "../../../Model/Knz2/GK2018/Knz2Projekt";
import {
    EnumKnz2FoRi,
    EnumKnz2Grenzwert,
    Knz2BereichStatusAnlage1,
    Knz2BereichStatusAnlage3,
    Knz2Repository
} from "../../../Model/Knz2/GK2018/Knz2Repository";

import { EnumStuStatus } from "../../../Rad/DAL";
import { RadVersionHistoryListEntity } from "../../../Rad/tsx/Controls/RadVersionHistoryList";
import { Bundeslaender, nameof, Utils } from "../../../Utils";
import {
    ChoiceDialog,
    DBDialog,
    EasyCombobox,
    EasyContainer,
    EasyDatePicker,
    EasyForm,
    EasyNumberField,
    EasyTextField,
    FolderView,
    Header,
    PleaseWait
} from "../../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../../Generic/Error-Handler/Decorators";
import { Knz2AbschnitteControl } from "../Common";

interface Knz2EditBereichDialogState {
    Projekt: Knz2GK2018Projekt;
    IsLoading: boolean;
    ShowUrsprungsBereich: Knz2GK2018Bereich;
    Abschnitt: Knz2GK2018Abschnitt;
}

@observer
@CatchReactErrors
export class Knz2EditBereichDialog extends React.Component<
    {
        Model: Knz2Repository;
        InitialModel: Knz2GK2018Bereich;
        closeDialog: () => void;
    },
    Knz2EditBereichDialogState
> {
    public readonly state: Knz2EditBereichDialogState = {
        IsLoading: true,
        Projekt: undefined,
        ShowUrsprungsBereich: undefined,
        Abschnitt: undefined
    };
    private Bereich: Knz2GK2018Bereich;
    private yesNoRef: React.RefObject<ChoiceDialog> = React.createRef();

    public async componentDidUpdate(prevProps): Promise<void> {
        if (prevProps.InitialModel !== this.props.InitialModel) {
            await this.LoadModelFrom(this.props.InitialModel);
        }
        if (this.Bereich.knz2AbschnittRef?.objectId !== this.state.Abschnitt?.objectId) {
            let aid = this.Bereich.knz2AbschnittRef?.objectId;
            if (!aid) {
                this.setState({ Abschnitt: null });
            } else {
                this.setState({ Abschnitt: await this.props.Model.AbschnitteCache.GetById(aid) });
            }
        }
    }

    public async componentDidMount(): Promise<void> {
        await this.LoadModelFrom(this.props.InitialModel);
    }

    public render(): JSX.Element {
        return (
            <DBDialog
                onDismiss={(): void => {
                    this.ExitDialog();
                }}
                dialogContentProps={{
                    title: "Lärmsanierungsbereich ansehen"
                }}
            >
                <ChoiceDialog ref={this.yesNoRef} />
                <PleaseWait
                    ShowSpinner={this.state.IsLoading}
                    render={(): React.ReactNode => (
                        <EasyContainer>
                            {this.state.ShowUrsprungsBereich && (
                                <Knz2EditBereichDialog
                                    Model={this.props.Model}
                                    InitialModel={this.state.ShowUrsprungsBereich}
                                    closeDialog={(): void => {
                                        this.setState({ ShowUrsprungsBereich: undefined });
                                    }}
                                />
                            )}
                            <Pivot>
                                <PivotItem headerText="Lärmsanierungsbereich">
                                    {this.Bereich.f44IstGeteilt && (
                                        <MessageBar>Dieser Bereich wurde geteilt und ist gesperrt.</MessageBar>
                                    )}
                                    {this.Bereich.f43GeteiltAus && (
                                        <MessageBar>
                                            Dieser Bereich entstammt einem geteilten Ursprungsbereich.
                                            <br />
                                            <Link
                                                onClick={(): void => {
                                                    this.openUrsprungBereich();
                                                }}
                                            >
                                                Ursprungsbereich anschauen
                                            </Link>
                                        </MessageBar>
                                    )}
                                    <EasyForm Object={this.Bereich} Disabled>
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <Header>Allgemeines</Header>
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm9">
                                                    <Knz2AbschnitteControl
                                                        Model={this.props.Model}
                                                        SelectedId={this.Bereich.knz2AbschnittRef?.objectId}
                                                        OnChange={(newId): void => {}}
                                                        ShowA1={!!this.props.InitialModel.objectId}
                                                    />
                                                </div>

                                                <div className="ms-Grid-col ms-sm3">
                                                    <TextField
                                                        value={
                                                            this.state.Abschnitt &&
                                                            Utils.FormatNumber(this.state.Abschnitt.f03PKZ)
                                                        }
                                                        disabled={true}
                                                        label={"PKZ"}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6">
                                                    <EasyCombobox
                                                        PropertyName={nameof<Knz2GK2018Bereich>("f43Forderrichtlinie")}
                                                        ComboBoxProps={{ label: "Förderrichtlinie" }}
                                                        Options={Utils.EnumToArray(EnumKnz2FoRi)}
                                                        AddEmptyOption={true}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm6">
                                                    <EasyCombobox
                                                        PropertyName={nameof<Knz2GK2018Bereich>("f34Grenzwert")}
                                                        ComboBoxProps={{ label: "Auslösewert (dB(A))" }}
                                                        Options={Utils.EnumToArray(EnumKnz2Grenzwert)}
                                                        AddEmptyOption={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm3">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2GK2018Bereich>("f01Streckennummer")}
                                                        TextFieldProps={{
                                                            label: "Strecken-Nr.",
                                                            mask: "9999",
                                                            maxLength: 4,
                                                            required: true
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm9">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2GK2018Bereich>("f02Bezeichnung")}
                                                        TextFieldProps={{ label: "Bezeichnung", required: true }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm3">
                                                    <EasyNumberField
                                                        PropertyName={nameof<Knz2GK2018Bereich>("f05KmVon1")}
                                                        TextFieldProps={{
                                                            label: "km von lt. Anlage 1",
                                                            required: true,
                                                            disabled: !Knz2BereichStatusAnlage1.includes(
                                                                this.Bereich.f25Status
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm3">
                                                    <EasyNumberField
                                                        PropertyName={nameof<Knz2GK2018Bereich>("f06KmBis1")}
                                                        TextFieldProps={{
                                                            label: "km bis lt. Anlage 1",
                                                            required: true,
                                                            disabled: !Knz2BereichStatusAnlage1.includes(
                                                                this.Bereich.f25Status
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm3">
                                                    <TextField
                                                        value={
                                                            this.Bereich &&
                                                            Utils.FormatKilometer(this.Bereich.f05LaengeKm1)
                                                        }
                                                        label="Summe km lt. Anlage 1"
                                                        disabled={true}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm3">
                                                    <EasyNumberField
                                                        PropertyName={nameof<Knz2GK2018Bereich>("f07KmVon3")}
                                                        TextFieldProps={{
                                                            label: "km von lt. Anlage 3",
                                                            required: true,
                                                            disabled: !Knz2BereichStatusAnlage3.includes(
                                                                this.Bereich.f25Status
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm3">
                                                    <EasyNumberField
                                                        PropertyName={nameof<Knz2GK2018Bereich>("f08KmBis3")}
                                                        TextFieldProps={{
                                                            label: "km bis lt. Anlage 3",
                                                            required: true,
                                                            disabled: !Knz2BereichStatusAnlage3.includes(
                                                                this.Bereich.f25Status
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm3">
                                                    <TextField
                                                        value={
                                                            this.Bereich &&
                                                            Utils.FormatKilometer(this.Bereich.f07LaengeKm3)
                                                        }
                                                        label="Summe km lt. Anlage 3"
                                                        disabled={true}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm3">
                                                    <EasyCombobox
                                                        PropertyName={nameof<Knz2GK2018Bereich>("f26Bundesland")}
                                                        Options={Bundeslaender}
                                                        MultiSelect={true}
                                                        ComboBoxProps={{ label: "Bundesland", required: true }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm9">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2GK2018Bereich>("f27WeitereStrecken")}
                                                        TextFieldProps={{ label: "Weitere Strecken" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2GK2018Bereich>("f28Bemerkungen")}
                                                        TextFieldProps={{
                                                            label: "Bemerkungen",
                                                            multiline: true,
                                                            rows: 2
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6" />
                                                <div className="ms-Grid-col ms-sm6">
                                                    <EasyCombobox
                                                        PropertyName={nameof<Knz2GK2018Bereich>("f25Status")}
                                                        Options={this.props.Model.GetAllowedBereichStatus(
                                                            this.props.InitialModel.f25Status
                                                        ).map((b) => ({
                                                            key: b,
                                                            text: allMetadata.enumMapping[
                                                                "DB.LSK.Model.Enums+EnumBereichStatus"
                                                            ][b]
                                                        }))}
                                                        MultiSelect={false}
                                                        ComboBoxProps={{ label: "Status" }}
                                                        OnChange={(oldValue, newValue): void => {}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </EasyForm>
                                </PivotItem>
                                {this.Bereich.knz2ProjektRef && (
                                    <PivotItem headerText="STU-ID Prüfprotokoll">
                                        <EasyForm Object={this.state.Projekt} Disabled={true}>
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <Header>STU-ID</Header>
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm6">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz2GK2018Projekt>("k2F14StuId")}
                                                            TextFieldProps={{ label: "STU ID", required: false }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyCombobox
                                                            PropertyName={nameof<Knz2GK2018Projekt>("k2F32StuStatus")}
                                                            Options={Utils.EnumToComboBoxOption(
                                                                EnumStuStatus,
                                                                allMetadata.enumMapping[
                                                                    "DB.LSK.Model.Enums+EnumStuStatus"
                                                                ]
                                                            )}
                                                            AddEmptyOption={true}
                                                            ComboBoxProps={{ label: "Status", required: false }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyDatePicker
                                                            PropertyName={nameof<Knz2GK2018Projekt>("k2F41StuIdDate")}
                                                            DatePickerProps={{ label: "Datum", isRequired: false }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm4">
                                                        <TextField
                                                            value={
                                                                this.state.Projekt &&
                                                                Utils.FormatKilometer(this.state.Projekt.F15Summe1)
                                                            }
                                                            label="Netto km"
                                                            disabled={true}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm4">
                                                        <EasyNumberField
                                                            PropertyName={nameof<Knz2GK2018Projekt>("k2F16Summe2")}
                                                            TextFieldProps={{ label: "Netto on Top km" }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm4">
                                                        <TextField
                                                            value={
                                                                this.state.Projekt &&
                                                                Utils.FormatKilometer(this.state.Projekt.F16Summe3)
                                                            }
                                                            label="Summe Netto + Netto on Top km"
                                                            disabled={true}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz2GK2018Projekt>(
                                                                "k2F17StuAnmerkungen"
                                                            )}
                                                            TextFieldProps={{
                                                                label: "Bemerkungen",
                                                                multiline: true,
                                                                rows: 2
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <FolderView
                                                            Label="Hochgeladene Dateien"
                                                            ParentObjectId={this.Bereich.knz2ProjektRef.objectId}
                                                            Repository={LskRepository.Knz2GK2018Projekt}
                                                            FolderName="STU"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </EasyForm>
                                    </PivotItem>
                                )}
                                {this.Bereich.knz2ProjektRef && (
                                    <PivotItem headerText="Formblatt OU">
                                        <EasyForm Object={this.state.Projekt} Disabled={true}>
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <Header>Ohne Umsetzung</Header>
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz2GK2018Projekt>("k2F38FormOUID")}
                                                            TextFieldProps={{ label: "Formblatt OU ID" }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyDatePicker
                                                            PropertyName={nameof<Knz2GK2018Projekt>("k2F20FormOUDate")}
                                                            DatePickerProps={{
                                                                label: "Fertigstellungsdatum",
                                                                isRequired: false
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <FolderView
                                                            Label="Hochgeladene Dateien"
                                                            ParentObjectId={this.Bereich.knz2ProjektRef.objectId}
                                                            Repository={LskRepository.Knz2GK2018Projekt}
                                                            FolderName="OU"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </EasyForm>
                                    </PivotItem>
                                )}
                                {this.Bereich.knz2ProjektRef && (
                                    <PivotItem headerText="Formblatt A">
                                        <EasyForm Object={this.state.Projekt} Disabled={true}>
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <Header>Aktive Maßnahmen</Header>
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz2GK2018Projekt>("k2F38FormAID")}
                                                            TextFieldProps={{ label: "Formblatt A ID" }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm6">
                                                        <EasyCombobox
                                                            PropertyName={nameof<Knz2GK2018Projekt>("k2F19FormAStatus")}
                                                            Options={Utils.EnumToArray(EnumFormAStatus)}
                                                            AddEmptyOption={true}
                                                            ComboBoxProps={{ label: "Status", required: false }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyDatePicker
                                                            PropertyName={nameof<Knz2GK2018Projekt>("k2F20FormADate")}
                                                            DatePickerProps={{
                                                                label: "Fertigstellungsdatum",
                                                                isRequired: false
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <FolderView
                                                            Label="Hochgeladene Dateien"
                                                            ParentObjectId={this.Bereich.knz2ProjektRef.objectId}
                                                            Repository={LskRepository.Knz2GK2018Projekt}
                                                            FolderName="A"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </EasyForm>
                                    </PivotItem>
                                )}
                                {this.Bereich.knz2ProjektRef && (
                                    <PivotItem headerText="Formblatt P">
                                        <EasyForm Object={this.state.Projekt} Disabled={true}>
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <Header>Passive Maßnahmen</Header>
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz2GK2018Projekt>("k2F38FormPID")}
                                                            TextFieldProps={{ label: "Formblatt P ID" }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm6">
                                                        <EasyCombobox
                                                            PropertyName={nameof<Knz2GK2018Projekt>("k2F22FormPStatus")}
                                                            Options={Utils.EnumToArray(EnumFormPStatus)}
                                                            AddEmptyOption={true}
                                                            ComboBoxProps={{ label: "Status", required: false }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyDatePicker
                                                            PropertyName={nameof<Knz2GK2018Projekt>("k2F23FormPDate")}
                                                            DatePickerProps={{
                                                                label: "Fertigstellungsdatum",
                                                                isRequired: false
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <FolderView
                                                            Label="Hochgeladene Dateien"
                                                            ParentObjectId={this.Bereich.knz2ProjektRef.objectId}
                                                            Repository={LskRepository.Knz2GK2018Projekt}
                                                            FolderName="P"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </EasyForm>
                                    </PivotItem>
                                )}
                                {this.Bereich.knz2ProjektRef && (
                                    <PivotItem headerText="LV Status">
                                        <EasyForm Object={this.state.Projekt} Disabled={true}>
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <Header>LV Status</Header>
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm10">
                                                        <EasyCombobox
                                                            PropertyName={nameof<Knz2GK2018Projekt>("k2F24AbsStatus")}
                                                            Options={Utils.EnumToArray(EnumLVStatus)}
                                                            AddEmptyOption={true}
                                                            ComboBoxProps={{ label: "Status", required: false }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </EasyForm>
                                    </PivotItem>
                                )}
                                {this.props.InitialModel.objectId && (
                                    <PivotItem headerText="Historie">
                                        <Header>Historie</Header>
                                        <RadVersionHistoryListEntity
                                            ObjectId={this.props.InitialModel.objectId}
                                            Repository={LskRepository.Knz2GK2018Bereich}
                                        />
                                    </PivotItem>
                                )}
                            </Pivot>
                            <DialogFooter>
                                <PrimaryButton
                                    onClick={(): void => {
                                        this.ExitDialog();
                                    }}
                                    text="Schließen"
                                />
                            </DialogFooter>
                        </EasyContainer>
                    )}
                />
            </DBDialog>
        );
    }

    private async openUrsprungBereich(): Promise<void> {
        const bereich = await this.props.Model.BereicheCache.GetById(this.Bereich.f43GeteiltAus.objectId);
        this.setState({
            ShowUrsprungsBereich: bereich
        });
    }

    private async LoadModelFrom(model: Knz2GK2018Bereich): Promise<void> {
        this.Bereich = model.Clone();

        this.setState({
            IsLoading: true,
            Projekt:
                model.knz2ProjektRef?.objectId &&
                (await this.props.Model.ProjekteCache.GetById(model.knz2ProjektRef.objectId))
        });

        this.setState({
            IsLoading: false
        });
    }

    @CatchReactErrorsMethod()
    private async ExitDialog(): Promise<void> {
        this.props.closeDialog();
    }
}
