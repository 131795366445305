import { IGroupedListFilter } from "../../tsx/Generic/LargeGroupedList";

export class Knz4LVListModelFilter implements IGroupedListFilter {
    public text: string;

    constructor(text: string) {
        this.text = text && text.length >= 3 ? text : "";
    }

    public Equals(other: Knz4LVListModelFilter): boolean {
        if (!other) {
            return false;
        }
        return this.text === other.text;
    }

    public IsSet(): boolean {
        return !!this.text;
    }
}
