import { Checkbox, ICheckboxProps, mergeStyleSets } from "office-ui-fabric-react";
import * as React from "react";
import { IFieldRenderer, IFieldRendererEditProps, IFieldRendererViewProps } from "../FieldRenderer";

const getClassNames = () =>
    mergeStyleSets({
        label: {
            color: "rgb(51, 51, 51)",
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: "4px"
        },
        required: {
            color: "rgb(164, 38, 44)"
        }
    });

export interface RadCheckboxProps {
    CheckboxProps?: ICheckboxProps;
    Value: boolean;
    OnChange?: (value: boolean) => void;
    Required?: boolean;
}

export function RadCheckbox({ CheckboxProps, OnChange, Value, Required }: React.PropsWithChildren<RadCheckboxProps>) {
    const classNames = React.useMemo(() => getClassNames(), []);
    return (
        <Checkbox
            checked={Value || false}
            onChange={(e: React.FormEvent<HTMLElement | HTMLInputElement>, value?: boolean) => {
                if (OnChange) {
                    OnChange(value);
                }
            }}
            {...CheckboxProps}
            onRenderLabel={(props) => {
                if (!props) {
                    return null;
                }
                return props.label ? (
                    <span aria-hidden="true" className={classNames.label} title={props.title}>
                        {props.label}
                        {Required && <span className={classNames.required}> *</span>}
                    </span>
                ) : null;
            }}
        />
    );
}

export const RadCheckboxRenderer: IFieldRenderer = {
    EditField: (props: IFieldRendererEditProps) =>
        RadCheckbox({
            Value: props.Value,
            CheckboxProps: { label: props.Metadata.title, disabled: props.Disabled },
            OnChange: props.OnChange
        }),
    ShowField: (props: IFieldRendererViewProps) =>
        RadCheckbox({ Value: props.Value, CheckboxProps: { label: props.Metadata.title, disabled: true } }),
    ListField: (props: IFieldRendererViewProps) => <>{props.Value ? "Ja" : "Nein"}</>
};
