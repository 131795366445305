import { DefaultButton, DialogFooter, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { DBDialog } from ".";
import { CatchReactErrors } from "./Error-Handler/Decorators";

@CatchReactErrors
export class ChoiceDialog extends React.Component<
    {},
    {
        hideDialog: boolean;
        closeDialog: (result: number) => void;
        Title: string;
        Message: JSX.Element;
        Options: [string, boolean][];
    }
> {
    public readonly state = {
        hideDialog: true,
        closeDialog: undefined,
        Title: "",
        Message: undefined,
        Options: []
    };

    public async Execute(title: string, message: JSX.Element, Options: [string, boolean][]): Promise<number> {
        return new Promise<number>((resolve) => {
            this.setState({
                hideDialog: false,
                Title: title,
                Message: message,
                Options: Options,
                closeDialog: (result: number) => {
                    this.setState({ hideDialog: true });
                    resolve(result);
                }
            });
        });
    }

    public async ExecuteYesNo(title: string, message: JSX.Element, defaultNo: boolean): Promise<boolean> {
        const options: [string, boolean][] = [
            ["Nein", defaultNo === true],
            ["Ja", defaultNo !== true]
        ];

        const result = await this.Execute(title, message, options);
        return result === 1; // return true if "Ja" was chosen
    }
    public render(): JSX.Element {
        return (
            !this.state.hideDialog && (
                <DBDialog
                    onDismiss={() => {
                        this.state.closeDialog(false);
                    }}
                    dialogContentProps={{
                        title: this.state.Title
                    }}
                    minWidth="450px"
                    maxWidth={800}
                >
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12">{this.state.Message}</div>
                        </div>
                    </div>
                    <DialogFooter>
                        {this.state.Options.map((value, idx) => {
                            if (value[1]) {
                                return (
                                    <PrimaryButton
                                        onClick={() => {
                                            this.state.closeDialog(idx);
                                        }}
                                        text={value[0]}
                                        key={idx}
                                    />
                                );
                            } else {
                                return (
                                    <DefaultButton
                                        onClick={() => {
                                            this.state.closeDialog(idx);
                                        }}
                                        text={value[0]}
                                        key={idx}
                                    />
                                );
                            }
                        })}
                    </DialogFooter>
                </DBDialog>
            )
        );
    }
}
