import { CompoundButton, DialogFooter, PrimaryButton, Stack } from "office-ui-fabric-react";
import * as React from "react";
import { DBDialog, HandleError } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@CatchReactErrors
export class Knz4LSDocCreateDialog extends React.Component<
    {
        CloseDialog: () => void;
        ExportDocument: () => void;
        ExportRuecksendung: () => void;
    },
    {}
> {
    public readonly state = {};

    public render(): JSX.Element {
        return (
            <DBDialog
                onDismiss={() => {
                    this.props.CloseDialog();
                }}
                dialogContentProps={{
                    title: "Dokumente erzeugen"
                }}
                maxWidth={1200}
            >
                <HandleError>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12">
                                Das Objekt wurde gespeichert. Möchten Sie direkt die dazugehörigen Dokumente erzeugen?
                                <br />
                                <Stack horizontal tokens={{ childrenGap: 40 }}>
                                    <CompoundButton onClick={this.props.ExportDocument}>Prüfprotokoll</CompoundButton>
                                    <CompoundButton onClick={this.props.ExportRuecksendung}>Rücksendung</CompoundButton>
                                </Stack>
                            </div>
                        </div>
                    </div>
                    <DialogFooter>
                        <PrimaryButton
                            onClick={() => {
                                this.props.CloseDialog();
                            }}
                            text="Schließen"
                        />
                    </DialogFooter>
                </HandleError>
            </DBDialog>
        );
    }
}
