import { observer } from "mobx-react";
import {
    DetailsRow,
    IColumn,
    IDetailsListProps,
    IDetailsRowProps,
    IRenderFunction,
    memoizeFunction,
    mergeStyleSets,
    SelectionMode
} from "office-ui-fabric-react";
import * as React from "react";
import { CatchReactErrors } from "./Error-Handler/Decorators";
import { IDetailListDatasource, LargeDetailsList } from "./LargeDetailsList";

const getClassNames = memoizeFunction((): any => {
    return mergeStyleSets({
        selectedRow: { fontWeight: "bold" }
    });
});
@observer
@CatchReactErrors
export class LargeDetailsListSelectable extends React.Component<
    {
        Columns: IColumn[];
        DataSource: IDetailListDatasource;
        OnActiveItemChanged?: (item: any) => void;
        DetailsListProps?: IDetailsListProps | any;
        CurrentItem: any;
    },
    {}
> {
    public readonly state = {};
    private listRef: React.RefObject<LargeDetailsList> = React.createRef();

    public render() {
        return (
            <LargeDetailsList
                Columns={this.props.Columns}
                DataSource={this.props.DataSource}
                DetailsListProps={{ selectionMode: SelectionMode.none, onRenderRow: this._onRenderRow }}
                OnActiveItemChanged={this.props.OnActiveItemChanged}
                {...this.props.DetailsListProps}
                ref={this.listRef}
            />
        );
    }

    public async RefreshItems(): Promise<void> {
        return this.listRef.current.RefreshItems();
    }

    protected _onRenderRow = (
        props: IDetailsRowProps,
        defaultRender?: IRenderFunction<IDetailsRowProps>
    ): JSX.Element => {
        const { selectedRow } = getClassNames();
        if (props.item === this.props.CurrentItem) {
            return <DetailsRow {...props} className={selectedRow} />;
        } else {
            return <DetailsRow {...props} />;
        }
    };
}
