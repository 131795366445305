import { observer } from "mobx-react";
import {
    DefaultButton,
    DialogFooter,
    Label,
    MessageBarType,
    Pivot,
    PivotItem,
    PrimaryButton,
    TextField
} from "office-ui-fabric-react";
import * as React from "react";
import { Knz2DokumenteVerknüpfen, Knz2Kilometrierung } from ".";
import { LskRepository } from "../../../DAL/LskRepository";
import { allMetadata } from "../../../generated/RadRepository";
import { EnumFormAStatus, EnumFormPStatus, EnumLVStatus, Knz2GK2022Projekt, Knz2Repository } from "../../../Model/Knz2";
import { EnumFormblattArt, EnumStuStatus, Knz2Client } from "../../../Rad/DAL";
import { RadVersionHistoryListEntity } from "../../../Rad/tsx/Controls";
import { nameof, Utils } from "../../../Utils";
import {
    DBDialog,
    EasyCombobox,
    EasyDatePicker,
    EasyForm,
    EasyNumberField,
    EasyTextField,
    FolderView,
    Header,
    IValidationMessage,
    MessageDialog,
    PleaseWait,
    ValidationMessages
} from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";
import { Knz2ErstSpeichern } from "./Knz2ErstSpeichern";
import { Knz2FormblattStatus } from "./Knz2FormblattStatus";
export enum Knz2DokumenteEditDialogTab {
    FormblattA = "A",
    FormblattP = "P",
    FormblattOU = "OU",
    Verknüpfen = "V"
}
@observer
@CatchReactErrors
export class Knz2DokumenteEditDialog extends React.Component<
    {
        Model: Knz2Repository;
        InitialModel: Knz2GK2022Projekt;
        hideDialog: boolean;
        closeDialog: (result: boolean, newModel: Knz2GK2022Projekt) => void;
        readonly?: boolean;
        initialTab?: Knz2DokumenteEditDialogTab;
    },
    {
        Projekt: Knz2GK2022Projekt;
        Status: string[];
        IsLoading: boolean;
        minWidth: string;
        IsNewKpn: boolean;
        tabConditions: Map<string, boolean>;
    }
> {
    public readonly state = {
        Projekt: this.props.InitialModel.Clone(),
        tabConditions: this.props.Model.getProjectTabConditions(this.props.InitialModel),
        Status: [],
        IsLoading: false,
        IsNewKpn: true,
        minWidth: "55%"
    };

    private msgRef: React.RefObject<MessageDialog> = React.createRef();

    public async componentDidMount() {
        await this.props.Model.LoadBereicheByStuIdId(this.state.Projekt);
    }

    public render(): JSX.Element {
        return (
            <DBDialog
                onDismiss={() => {
                    this.ExitDialog(false);
                }}
                dialogContentProps={{
                    title: !this.state.Projekt.objectId ? "Neues Projekt" : "Projekt"
                }}
                minWidth={this.state.minWidth}
                maxWidth={1200}
            >
                <MessageDialog ref={this.msgRef} />
                <PleaseWait
                    ShowSpinner={this.state.IsLoading}
                    render={() => (
                        <>
                            <ValidationMessages Messages={this.state.Projekt.GetValidationMessages()} />
                            <ValidationMessages Messages={this.showKpnExistValidationMsg()} />
                            <EasyForm
                                Object={this.state.Projekt}
                                Disabled={this.props.Model.IsReadOnly || this.props.readonly}
                            >
                                <Pivot
                                    onLinkClick={(item: PivotItem) => {
                                        this.setState({
                                            minWidth:
                                                item.props.itemKey === Knz2DokumenteEditDialogTab.Verknüpfen
                                                    ? "95%"
                                                    : "55%"
                                        });
                                    }}
                                    defaultSelectedKey={this.props.initialTab}
                                >
                                    <PivotItem headerText="Projekt">
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <Header>Projekt</Header>
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm8">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2GK2022Projekt>(
                                                            "k2F35ProjektBezeichnung"
                                                        )}
                                                        TextFieldProps={{ label: "Bezeichnung", required: true }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm4">
                                                    <EasyCombobox
                                                        PropertyName={nameof<Knz2GK2022Projekt>("k2F32StuStatus")}
                                                        Options={Utils.EnumToComboBoxOption(
                                                            EnumStuStatus,
                                                            allMetadata.enumMapping["DB.LSK.Model.Enums+EnumStuStatus"]
                                                        )}
                                                        AddEmptyOption={true}
                                                        ComboBoxProps={{ label: "Status", required: false }}
                                                        OnChange={() => {
                                                            this.setState({
                                                                tabConditions: this.props.Model.getProjectTabConditions(
                                                                    this.state.Projekt
                                                                )
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <Label>STU-Untersuchungsbereich</Label>
                                                </div>
                                            </div>
                                            <Knz2Kilometrierung
                                                PropertyName={nameof<Knz2GK2022Projekt>("k2F40WeitereStrecken")}
                                            />
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm3">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2GK2022Projekt>(
                                                            "k2F33Kurzprojektnummer"
                                                        )}
                                                        TextFieldProps={{ label: "Kurzprojektnummer", required: true }}
                                                        OnBlur={async (value: string) => {
                                                            if (
                                                                this.state.Projekt.k2F33Kurzprojektnummer !==
                                                                this.props.InitialModel.k2F33Kurzprojektnummer
                                                            ) {
                                                                const itemsFound =
                                                                    await this.props.Model.GetProjectsByKPN(value);
                                                                const result = itemsFound.length === 0;
                                                                this.setState({ IsNewKpn: result });
                                                            } else {
                                                                this.setState({ IsNewKpn: true });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm3">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2GK2022Projekt>("k2F38ProjektVorschau")}
                                                        TextFieldProps={{ label: "Vorschaujahr", required: true }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2GK2022Projekt>("k2F41Bemerkungen")}
                                                        TextFieldProps={{
                                                            label: "Bemerkungen",
                                                            multiline: true,
                                                            rows: 2
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </PivotItem>

                                    <PivotItem headerText="STU-ID Prüfprotokoll">
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <Header>STU-ID</Header>
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2GK2022Projekt>("k2F14StuId")}
                                                        TextFieldProps={{ label: "STU ID" }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm6">
                                                    <EasyDatePicker
                                                        PropertyName={nameof<Knz2GK2022Projekt>("k2F41StuIdDate")}
                                                        DatePickerProps={{ label: "Datum" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm4">
                                                    <TextField
                                                        value={
                                                            this.state.Projekt &&
                                                            Utils.FormatKilometer(this.state.Projekt.F15Summe1)
                                                        }
                                                        label="Netto km"
                                                        disabled={true}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm4">
                                                    <TextField
                                                        value={
                                                            this.state.Projekt &&
                                                            Utils.FormatKilometer(this.state.Projekt.k2F16Summe2)
                                                        }
                                                        label="Netto on Top km"
                                                        disabled={true}
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm4">
                                                    <TextField
                                                        value={
                                                            this.state.Projekt &&
                                                            Utils.FormatKilometer(this.state.Projekt.F16Summe3)
                                                        }
                                                        label="Summe Netto + Netto on Top km"
                                                        disabled={true}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <Header>Netto on Top km:</Header>
                                                </div>
                                            </div>
                                            <Knz2Kilometrierung
                                                PropertyName={nameof<Knz2GK2022Projekt>("k2F41OnTopStrecken")}
                                                OnUpdate={() => {
                                                    this.state.Projekt.UpdateSumme();
                                                }}
                                            />
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <Header>Lärmentlastete Anwohner gemäß STU:</Header>
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6">
                                                    <EasyNumberField
                                                        PropertyName={nameof<Knz2GK2022Projekt>("k2F45FFWE")}
                                                        TextFieldProps={{
                                                            label: "Förderfähige Wohneinheiten"
                                                        }}
                                                        Decimals={0}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm6">
                                                    <EasyNumberField
                                                        PropertyName={nameof<Knz2GK2022Projekt>("K2F46LA")}
                                                        TextFieldProps={{
                                                            label: "Lärmentlastete Anwohner",
                                                            disabled: true
                                                        }}
                                                        Decimals={1}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <EasyTextField
                                                        PropertyName={nameof<Knz2GK2022Projekt>("k2F17StuAnmerkungen")}
                                                        TextFieldProps={{
                                                            label: "Bemerkungen",
                                                            multiline: true,
                                                            rows: 2
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <Knz2ErstSpeichern IstGespeichert={!!this.state.Projekt.objectId}>
                                                        <FolderView
                                                            Repository={LskRepository.Knz2GK2022Projekt}
                                                            FolderName="STU"
                                                            ParentObjectId={this.state.Projekt.objectId}
                                                            Label="Hochgeladene Dateien"
                                                        />
                                                    </Knz2ErstSpeichern>
                                                </div>
                                            </div>
                                        </div>
                                    </PivotItem>

                                    {this.state.tabConditions.get("ou") && (
                                        <PivotItem
                                            headerText="Formblatt OU"
                                            itemKey={Knz2DokumenteEditDialogTab.FormblattOU}
                                        >
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <Header>Aktive Maßnahmen</Header>
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz2GK2022Projekt>("k2F38FormOUID")}
                                                            TextFieldProps={{
                                                                label: "Formblatt OU ID",
                                                                disabled: true
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyDatePicker
                                                            PropertyName={nameof<Knz2GK2022Projekt>("k2F20FormOUDate")}
                                                            DatePickerProps={{
                                                                label: "Fertigstellungsdatum",
                                                                isRequired: false
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <Knz2ErstSpeichern IstGespeichert={!!this.state.Projekt.objectId}>
                                                    <Knz2FormblattStatus
                                                        Projekt={this.state.Projekt}
                                                        Type={EnumFormblattArt.OhneUmsetzung}
                                                        FormblattDatum={this.state.Projekt.k2F20FormOUDate}
                                                        OnFreigabeStart={async (model): Promise<void> => {
                                                            let p = await this.props.Model.ProjekteCache.update(
                                                                this.state.Projekt
                                                            );

                                                            let wf = new Knz2Client();
                                                            await wf.startFormblattOUFreigabe(
                                                                model,
                                                                this.state.Projekt.objectId
                                                            );

                                                            this.setState({
                                                                Projekt: await this.props.Model.ProjekteCache.GetById(
                                                                    p.objectId
                                                                )
                                                            });
                                                        }}
                                                        Disabled={!this.state.Projekt.objectId}
                                                    />
                                                </Knz2ErstSpeichern>
                                            </div>
                                        </PivotItem>
                                    )}
                                    {this.state.tabConditions.get("a") && (
                                        <PivotItem
                                            headerText="Formblatt A"
                                            itemKey={Knz2DokumenteEditDialogTab.FormblattA}
                                        >
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <Header>Aktive Maßnahmen</Header>
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz2GK2022Projekt>("k2F38FormAID")}
                                                            TextFieldProps={{ label: "Formblatt A ID", disabled: true }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm6">
                                                        <EasyCombobox
                                                            PropertyName={nameof<Knz2GK2022Projekt>("k2F19FormAStatus")}
                                                            Options={Utils.EnumToArray(EnumFormAStatus)}
                                                            AddEmptyOption={true}
                                                            ComboBoxProps={{ label: "Status", required: false }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyDatePicker
                                                            PropertyName={nameof<Knz2GK2022Projekt>("k2F20FormADate")}
                                                            DatePickerProps={{
                                                                label: "Fertigstellungsdatum",
                                                                isRequired: false
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <Knz2ErstSpeichern IstGespeichert={!!this.state.Projekt.objectId}>
                                                    <Knz2FormblattStatus
                                                        Projekt={this.state.Projekt}
                                                        Type={EnumFormblattArt.Aktiv}
                                                        FormblattDatum={this.state.Projekt.k2F20FormADate}
                                                        OnFreigabeStart={async (model): Promise<void> => {
                                                            let p = await this.props.Model.ProjekteCache.update(
                                                                this.state.Projekt
                                                            );

                                                            let wf = new Knz2Client();
                                                            await wf.startFormblattAFreigabe(
                                                                model,
                                                                this.state.Projekt.objectId
                                                            );

                                                            this.setState({
                                                                Projekt: await this.props.Model.ProjekteCache.GetById(
                                                                    p.objectId
                                                                )
                                                            });
                                                        }}
                                                        Disabled={!this.state.Projekt.objectId}
                                                    />
                                                </Knz2ErstSpeichern>
                                            </div>
                                        </PivotItem>
                                    )}
                                    {this.state.tabConditions.get("p") && (
                                        <PivotItem
                                            headerText="Formblatt P"
                                            itemKey={Knz2DokumenteEditDialogTab.FormblattP}
                                        >
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <Header>Passive Maßnahmen</Header>
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz2GK2022Projekt>("k2F38FormPID")}
                                                            TextFieldProps={{ label: "Formblatt P ID", disabled: true }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm6">
                                                        <EasyCombobox
                                                            PropertyName={nameof<Knz2GK2022Projekt>("k2F22FormPStatus")}
                                                            Options={Utils.EnumToArray(EnumFormPStatus)}
                                                            AddEmptyOption={true}
                                                            ComboBoxProps={{ label: "Status", required: false }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyDatePicker
                                                            PropertyName={nameof<Knz2GK2022Projekt>("k2F23FormPDate")}
                                                            DatePickerProps={{
                                                                label: "Fertigstellungsdatum",
                                                                isRequired: false
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <Knz2ErstSpeichern IstGespeichert={!!this.state.Projekt.objectId}>
                                                    <Knz2FormblattStatus
                                                        Projekt={this.state.Projekt}
                                                        Type={EnumFormblattArt.Passiv}
                                                        FormblattDatum={this.state.Projekt.k2F23FormPDate}
                                                        OnFreigabeStart={async (model): Promise<void> => {
                                                            let p = await this.props.Model.ProjekteCache.update(
                                                                this.state.Projekt
                                                            );

                                                            let wf = new Knz2Client();
                                                            await wf.startFormblattPFreigabe(
                                                                model,
                                                                this.state.Projekt.objectId
                                                            );

                                                            this.setState({
                                                                Projekt: await this.props.Model.ProjekteCache.GetById(
                                                                    p.objectId
                                                                )
                                                            });
                                                        }}
                                                        Disabled={!this.state.Projekt.objectId}
                                                    />
                                                </Knz2ErstSpeichern>
                                            </div>
                                        </PivotItem>
                                    )}

                                    <PivotItem headerText="LV-Status">
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <Header>LV-Status</Header>
                                                </div>
                                            </div>

                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6">
                                                    <EasyCombobox
                                                        PropertyName={nameof<Knz2GK2022Projekt>("k2F24AbsStatus")}
                                                        Options={Utils.EnumToArray(EnumLVStatus)}
                                                        AddEmptyOption={true}
                                                        ComboBoxProps={{ label: "Status", required: false }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm3">
                                                    <EasyDatePicker
                                                        PropertyName={nameof<Knz2GK2022Projekt>("k2F31FormLVDate")}
                                                        DatePickerProps={{
                                                            label: "Fertigstellungsdatum",
                                                            isRequired: false
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm3">
                                                    <EasyDatePicker
                                                        PropertyName={nameof<Knz2GK2022Projekt>(
                                                            "k2F39FormLVAbfrageDate"
                                                        )}
                                                        DatePickerProps={{
                                                            label: "Datum der Abfrage",
                                                            isRequired: false
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm6" />
                                            </div>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12">
                                                    <Knz2ErstSpeichern IstGespeichert={!!this.state.Projekt.objectId}>
                                                        <FolderView
                                                            Repository={LskRepository.Knz2GK2022Projekt}
                                                            FolderName="LV"
                                                            ParentObjectId={this.state.Projekt.objectId}
                                                            Label="Hochgeladene Dateien"
                                                        />
                                                    </Knz2ErstSpeichern>
                                                </div>
                                            </div>
                                        </div>
                                    </PivotItem>

                                    {!!this.state.Projekt.objectId && (
                                        <PivotItem headerText="Historie">
                                            <Header>Historie</Header>
                                            <RadVersionHistoryListEntity
                                                ObjectId={this.props.InitialModel.objectId}
                                                Repository={LskRepository.Knz2GK2022Projekt}
                                            />
                                        </PivotItem>
                                    )}

                                    {!!this.state.Projekt.objectId && (
                                        <PivotItem
                                            headerText="Sanierungsbereiche"
                                            itemKey={Knz2DokumenteEditDialogTab.Verknüpfen}
                                        >
                                            <Knz2DokumenteVerknüpfen
                                                Model={this.props.Model}
                                                Projekt={this.state.Projekt}
                                                Disabled={this.props.Model.IsReadOnly || this.props.readonly}
                                            />
                                        </PivotItem>
                                    )}
                                </Pivot>
                                {this.props.readonly ? (
                                    <DialogFooter>
                                        <PrimaryButton
                                            onClick={() => {
                                                this.ExitDialog(false);
                                            }}
                                            text="Schließen"
                                        />
                                    </DialogFooter>
                                ) : (
                                    <DialogFooter>
                                        <PrimaryButton
                                            onClick={() => {
                                                this.ExitDialog(true);
                                            }}
                                            text="Speichern"
                                            disabled={
                                                this.props.Model.IsReadOnly ||
                                                !this.state.Projekt.IsValid ||
                                                !this.state.IsNewKpn
                                            }
                                        />
                                        <DefaultButton
                                            onClick={() => {
                                                this.ExitDialog(false);
                                            }}
                                            text="Abbrechen"
                                        />
                                    </DialogFooter>
                                )}
                            </EasyForm>
                        </>
                    )}
                />
            </DBDialog>
        );
    }

    private showKpnExistValidationMsg(): IValidationMessage[] {
        if (!this.state.IsNewKpn) {
            return [
                {
                    Text: "Die KPN (Kurzprojektnummer) ist schon vorhanden.",
                    Type: MessageBarType.error
                }
            ];
        }
        return [];
    }

    private async ExitDialog(shouldSave: boolean) {
        if (shouldSave) {
            this.setState({
                IsLoading: true
            });

            if (this.state.Projekt.objectId) {
                if (this.state.Projekt.k2F24AbsStatus === EnumLVStatus.LarmvorsorgeUmgesetzt) {
                    let files = await LskRepository.Knz2GK2022Projekt.listFiles(this.state.Projekt.objectId, "LV");
                    if (files.length === 0) {
                        await this.msgRef.current.Execute(
                            "Validierungsfehler",
                            <>
                                Als Beleg für eine umgesetzte Lärmvorsorge muss mindestens eine Datei hochgeladen
                                werden.
                            </>
                        );
                        this.setState({
                            IsLoading: false
                        });
                        return false;
                    }
                }
                await this.props.Model.ProjekteCache.update(this.state.Projekt);
            } else {
                this.state.Projekt.projectId = 0;
                await this.props.Model.ProjekteCache.add(this.state.Projekt);
            }
            this.setState({
                IsLoading: false
            });
        }

        this.props.closeDialog(shouldSave, this.state.Projekt);
    }
}
