import { DialogFooter, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { RadVersionHistoryListEntity, RadVersionHistoryListFile } from ".";
import { DBDialog } from "../../../tsx/Generic";
import { BaseEntity, BaseFile, IEntityRepository, IEntityRepositoryWithFile } from "../../DAL";

export interface RadVersionHistoryModalProps<T extends BaseEntity, F extends BaseFile> {
    Repository: IEntityRepository<T> | IEntityRepositoryWithFile<T, F>;
    ObjectId?: string;
    FileId?: string;
    IgnoreFields?: string[];
    OnClose: () => void;
}

export function RadVersionHistoryModal<T extends BaseEntity, F extends BaseFile>({
    Repository,
    ObjectId,
    FileId,
    IgnoreFields,
    OnClose
}: React.PropsWithChildren<RadVersionHistoryModalProps<T, F>>) {
    return (
        <DBDialog
            onDismiss={OnClose}
            dialogContentProps={{
                title: `Versionshistorie`
            }}
        >
            {ObjectId && (
                <RadVersionHistoryListEntity
                    Repository={Repository as IEntityRepository<T>}
                    ObjectId={ObjectId}
                    IgnoreFields={IgnoreFields}
                />
            )}
            {FileId && (
                <RadVersionHistoryListFile
                    Repository={Repository as IEntityRepositoryWithFile<T, F>}
                    FileId={FileId}
                    IgnoreFields={IgnoreFields}
                />
            )}
            <DialogFooter>
                <PrimaryButton onClick={OnClose} text="Ok" />
            </DialogFooter>
        </DBDialog>
    );
}
