import { RadRepository } from "../generated/RadRepository";
import { Bericht } from "../Model/Berichte";
import { DatenImport } from "../Model/DatenImport/DatenImport";
import { Knz2Altprojekt, Knz2GK2022Abschnitt, Knz2GK2022Bereich, Knz2GK2022Projekt } from "../Model/Knz2";
import { Knz2GK2018Abschnitt } from "../Model/Knz2/GK2018/Knz2Abschnitt";
import { Knz2GK2018Bereich } from "../Model/Knz2/GK2018/Knz2Bereich";
import { Knz2GK2018Projekt } from "../Model/Knz2/GK2018/Knz2Projekt";
import { Knz3DatenImport } from "../Model/Knz3/Knz3DatenImport";
import { Knz4LSObjekt } from "../Model/Knz4LS/Knz4LSObjekt";
import { Knz4LV } from "../Model/Knz4LV";
import { EntityRepository, EntityRepositoryWithFile } from "../Rad/DAL/EntityRepository";

export class LskRepository {
    static Knz4LVDatenImport = EntityRepositoryWithFile.From(RadRepository.Knz4LVDatenImportModel, DatenImport);
    static Knz4LSDatenImport = EntityRepositoryWithFile.From(RadRepository.Knz4LSDatenImportModel, DatenImport);
    static Bericht = EntityRepositoryWithFile.From(RadRepository.BerichtModel, Bericht);
    static Knz2GK2022Bereich = EntityRepository.From(RadRepository.Knz2GK2022BereichModel, Knz2GK2022Bereich);
    static Knz2GK2022Abschnitt = EntityRepository.From(RadRepository.Knz2GK2022AbschnittModel, Knz2GK2022Abschnitt);
    static Knz2GK2022Projekt = EntityRepositoryWithFile.From(RadRepository.Knz2GK2022ProjektModel, Knz2GK2022Projekt);
    static Knz2GK2018Bereich = EntityRepository.From(RadRepository.Knz2GK2018BereichModel, Knz2GK2018Bereich);
    static Knz2GK2018Abschnitt = EntityRepository.From(RadRepository.Knz2GK2018AbschnittModel, Knz2GK2018Abschnitt);
    static Knz2GK2018Projekt = EntityRepositoryWithFile.From(RadRepository.Knz2GK2018ProjektModel, Knz2GK2018Projekt);

    static Knz2Altprojekt = EntityRepositoryWithFile.From(RadRepository.Knz2AltprojektModel, Knz2Altprojekt);
    static Knz3DatenImport = EntityRepositoryWithFile.From(RadRepository.Knz3DatenImportModel, Knz3DatenImport);
    static Knz4LVModel = EntityRepository.From(RadRepository.Knz4LVModel, Knz4LV);
    static Knz4LSObjekt = EntityRepository.From(RadRepository.Knz4LSModel, Knz4LSObjekt);
}
