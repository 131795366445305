import * as mobx from "mobx";
import { MessageBarType } from "office-ui-fabric-react";

import { EnumStuStatus, Knz2GK2022ProjektModel } from "../../Rad/DAL";
import { IValidationMessage } from "../../tsx/Generic";
import { TupleMap, Utils } from "../../Utils";
import { Knz2GK2022Bereich } from "./Knz2Bereich";

export enum EnumFormAStatus {
    nurAktiv = "nur Aktiv",
    nichtförderfähig = "nicht förderfähig",
    förderfähigumgesetzt = "förderfähig, jedoch nicht gewünscht / umgesetzt",
    AktivPassiv = "Aktiv & Passiv"
}

export enum EnumFormPStatus {
    nurPassiv = "nur Passiv",
    AktivPassiv = "Aktiv & Passiv",
    förderfähigumgesetzt = "förderfähig, jedoch nicht gewünscht / umgesetzt",
    nichtFörderfähig = "nicht förderfähig"
}

export enum EnumLVStatus {
    LarmvorsorgePlanungsphase = "Lärmvorsorge in früher Planungsphase",
    KeineLarmvorsorge = "keine Lärmvorsorge geplant",
    LarmvorsorgeInBearbeitung = "Lärmvorsorge in Bearbeitung",
    LarmvorsorgeUmgesetzt = "Lärmvorsorge umgesetzt"
}

export class Knz2GK2022Projekt extends Knz2GK2022ProjektModel {
    constructor(parent?: Knz2GK2022Projekt) {
        super(parent);
    }
    /**
     * returns true if all required fields are filled
     */
    @mobx.computed
    public get IsValid(): boolean {
        if (
            this.k2F35ProjektBezeichnung &&
            this.k2F40WeitereStrecken?.length > 0 &&
            this.k2F33Kurzprojektnummer &&
            this.k2F38ProjektVorschau
        ) {
            return true;
        }
        return false;
    }

    public GetValidationMessages(): IValidationMessage[] {
        if (!this.k2F32StuStatus) {
            return [
                {
                    Text: "Bitte füllen Sie den Statuswert des STU-ID Prüfprotokolls aus.",
                    Type: MessageBarType.warning
                }
            ];
        }

        if ([EnumStuStatus.AktivPassiv, EnumStuStatus.NurAktiv].includes(this.k2F32StuStatus)) {
            if (!this.k2F19FormAStatus) {
                return [
                    {
                        Text: "Bitte füllen Sie den Statuswert von Formblatt A aus.",
                        Type: MessageBarType.warning
                    }
                ];
            }
        }

        if ([EnumStuStatus.AktivPassiv, EnumStuStatus.NurPassiv].includes(this.k2F32StuStatus)) {
            if (!this.k2F22FormPStatus) {
                return [
                    {
                        Text: "Bitte füllen Sie den Statuswert von Formblatt P aus.",
                        Type: MessageBarType.warning
                    }
                ];
            }
        }

        if (!this.IsStuStatusValid) {
            return [
                {
                    Text: "Der Status des STU-ID Prüfprotokolls passt nicht zu den Status-Werten der Formblätter.",
                    Type: MessageBarType.error
                }
            ];
        }

        if (this.k2F24AbsStatus === EnumLVStatus.LarmvorsorgeUmgesetzt) {
            if (!this.k2F31FormLVDate)
                return [
                    {
                        Text: "Das Feld 'Fertigstellungsdatum' muss für die Lärmvorsorge ausgefüllt werden",
                        Type: MessageBarType.error
                    }
                ];
        }
        return [];
    }

    @mobx.computed
    public get IsStuStatusValid(): boolean {
        if (!this.k2F32StuStatus) {
            return false;
        }

        const validStatus = new TupleMap<EnumStuStatus, [EnumFormAStatus[], EnumFormPStatus[]]>([
            [
                EnumStuStatus.AktivPassiv,
                [
                    [EnumFormAStatus.AktivPassiv, EnumFormAStatus.förderfähigumgesetzt],
                    [EnumFormPStatus.AktivPassiv, EnumFormPStatus.förderfähigumgesetzt]
                ]
            ],
            [EnumStuStatus.KeineMassnahmen, [[], []]],
            [EnumStuStatus.NurLV, [[], []]],
            [EnumStuStatus.NurPassiv, [[EnumFormAStatus.nurAktiv, EnumFormAStatus.förderfähigumgesetzt], []]],
            [EnumStuStatus.NurPassiv, [[], [EnumFormPStatus.nurPassiv, EnumFormPStatus.förderfähigumgesetzt]]]
        ]);

        const row = validStatus.get(this.k2F32StuStatus as EnumStuStatus);
        if (row[0].length > 0) {
            if (!row[0].includes(this.k2F19FormAStatus as EnumFormAStatus)) {
                return false;
            }
        }

        if (row[1].length > 0) {
            if (!row[1].includes(this.k2F22FormPStatus as EnumFormPStatus)) {
                return false;
            }
        }

        return true;
    }

    @mobx.computed
    public get getAttachmentsByStuStatus(): Map<string, boolean> {
        const stuStatusAttachmentConditions = new Map<EnumStuStatus, Map<string, boolean>>([
            [
                EnumStuStatus.AktivPassiv,
                new Map([
                    ["a", true],
                    ["p", true],
                    ["ou", false]
                ])
            ],
            [
                EnumStuStatus.NurAktiv,
                new Map([
                    ["a", true],
                    ["p", false],
                    ["ou", false]
                ])
            ],
            [
                EnumStuStatus.NurPassiv,
                new Map([
                    ["a", false],
                    ["p", true],
                    ["ou", false]
                ])
            ],
            [
                EnumStuStatus.KeineMassnahmen,
                new Map([
                    ["a", false],
                    ["p", false],
                    ["ou", true]
                ])
            ],
            [
                EnumStuStatus.NurLV,
                new Map([
                    ["a", false],
                    ["p", false],
                    ["ou", false]
                ])
            ]
        ]);

        return stuStatusAttachmentConditions.get(this.k2F32StuStatus ?? EnumStuStatus.KeineMassnahmen);
    }

    @mobx.computed
    public get getTabsByStuStatus(): Map<string, boolean> {
        const stuStatusTabsConditions = new Map<EnumStuStatus, Map<string, boolean>>([
            [
                EnumStuStatus.AktivPassiv,
                new Map([
                    ["a", true],
                    ["p", true],
                    ["ou", false]
                ])
            ],
            [
                EnumStuStatus.NurAktiv,
                new Map([
                    ["a", true],
                    ["p", false],
                    ["ou", false]
                ])
            ],
            [
                EnumStuStatus.NurPassiv,
                new Map([
                    ["a", false],
                    ["p", true],
                    ["ou", false]
                ])
            ],
            [
                EnumStuStatus.KeineMassnahmen,
                new Map([
                    ["a", false],
                    ["p", false],
                    ["ou", true]
                ])
            ],
            [
                EnumStuStatus.NurLV,
                new Map([
                    ["a", false],
                    ["p", false],
                    ["ou", false]
                ])
            ]
        ]);

        const k2F32StuStatusValue = this.k2F32StuStatus ?? EnumStuStatus.KeineMassnahmen;
        return stuStatusTabsConditions.get(k2F32StuStatusValue);
    }

    @mobx.computed
    public get F15Summe1(): number {
        // Summe aller km der zugeordneten Bereiche
        if (!this.Bereiche) {
            return null;
        }

        let summe = 0;
        for (const b of this.Bereiche) {
            summe += b.StreckenLaenge;
        }
        return summe;
    }

    @mobx.computed
    public get F16Summe3(): number {
        if (this.F15Summe1 === null || this.k2F16Summe2 === null) {
            return null;
        }
        return this.F15Summe1 + this.k2F16Summe2;
    }

    @mobx.computed
    public get K2F46LA(): number {
        if (!this.k2F45FFWE) {
            return 0;
        }
        return this.k2F45FFWE * 2.1;
    }

    @mobx.computed
    public get F18FormAId(): string {
        if (!this.k2F38ProjektVorschau) {
            return "";
        }

        return `${this.k2F38ProjektVorschau} - A - ${this.objectId.toString().padStart(4, "0")}`;
    }

    @mobx.computed
    public get F21FormPId(): string {
        if (!this.k2F38ProjektVorschau) {
            return "";
        }
        return `${this.k2F38ProjektVorschau} - P - ${this.objectId.toString().padStart(4, "0")}`;
    }

    @mobx.observable public Bereiche: Knz2GK2022Bereich[] = undefined;

    public Clone(): Knz2GK2022Projekt {
        return new Knz2GK2022Projekt(this);
    }

    public CompareTo(other: Knz2GK2022Projekt): number {
        // 1. Sortierkriterium: StuId
        if (other.k2F33Kurzprojektnummer !== this.k2F33Kurzprojektnummer) {
            return other.k2F33Kurzprojektnummer > this.k2F33Kurzprojektnummer ? -1 : 1;
        }

        return 0;
    }

    public UpdateSumme(): void {
        let summe = 0;
        if (this.k2F41OnTopStrecken) {
            for (const s of this.k2F41OnTopStrecken) {
                const dif = Utils.KilometerDifference(s.kmVon, s.kmBis);
                if (dif) {
                    summe += dif;
                }
            }
        }
        mobx.runInAction(() => {
            this.k2F16Summe2 = summe;
        });
    }
}
