import * as mobx from "mobx";
import { Knz3DatenImportModel } from "../../generated/BackendClient";


export class Knz3DatenImport extends Knz3DatenImportModel {
    constructor(parent?: Knz3DatenImport) {
        super(parent);
    }
    /**
     * returns true if all required fields are filled
     */
    @mobx.computed
    public get IsValid(): boolean {
        if (!this.k3F01PSPList || !this.k3F02PSPListLSSP) {
            return false;
        }
        return true;
    }

    public Clone(): Knz3DatenImport {
        return new Knz3DatenImport(this);
    }
}
