import { observer } from "mobx-react";
import { CommandBar } from "office-ui-fabric-react";
import * as React from "react";
import { Knz4LVRepository } from "../../Model/Knz4LV";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz4LVCommandBar extends React.Component<{
    Model: Knz4LVRepository;
    OnNewClick: () => void;
    ExportExcel: () => void;
    OnRemoveItemsClick: () => void;
    OnRestoreItemsClick: () => void;
    EnableRemoveItemsButton: boolean;
    EnableRestoreItemsButton: boolean;
}> {
    public readonly state = {};

    public render() {
        return (
            <CommandBar
                items={[
                    {
                        key: "option1",
                        name: "Neue Lärmvorsorge Maßnahme",
                        iconProps: {
                            iconName: "Add"
                        },
                        onClick: () => {
                            this.props.OnNewClick();
                        }
                    },
                    {
                        key: "option2",
                        name: "Maßn. Lärmvorsorge löschen",
                        iconProps: {
                            iconName: "RecycleBin"
                        },
                        onClick: () => {
                            this.props.OnRemoveItemsClick();
                        },
                        disabled: !this.props.EnableRemoveItemsButton
                    },
                    {
                        key: "option3",
                        name: "Maßn. Lärmvorsorge wiederherstellen",
                        iconProps: {
                            iconName: "EmptyRecycleBin"
                        },
                        onClick: () => {
                            this.props.OnRestoreItemsClick();
                        },
                        disabled: !this.props.EnableRestoreItemsButton
                    },
                    {
                        key: "option4",
                        name: "Excel Export",
                        iconProps: {
                            iconName: "ExcelDocument"
                        },
                        onClick: () => {
                            this.props.ExportExcel();
                        }
                    }
                ]}
            />
        );
    }
}
