import * as mobx from "mobx";
import { Knz3DatenImport, Knz3Repository } from ".";
import { IDetailListDatasource } from "../../tsx/Generic";

export class Knz3DetailListModel implements IDetailListDatasource {
    @mobx.computed public get IsLoading(): boolean {
        return this.RunningRequests > 0;
    }
    private Knz3DatenImportItems: Map<string, Knz3DatenImport>;

    @mobx.observable private RunningRequests = 0;
    private Model: Knz3Repository;
    private includeRecycleBinItems: boolean;

    public constructor(model: Knz3Repository, includeRecycleBinItems?: boolean) {
        this.Model = model;
        this.includeRecycleBinItems = includeRecycleBinItems;
    }

    public Sort(items: Knz3DatenImport[]): void {
        items.sort((a, b) => (a.created < b.created ? 1 : -1));
    }

    public async GetAllItems(): Promise<Map<string, Knz3DatenImport>> {
        this.Knz3DatenImportItems = await this.Model.GetAllItems(this.includeRecycleBinItems);
        return this.Knz3DatenImportItems;
    }

    public Equals(source: Knz3DetailListModel): boolean {
        return this === source;
    }
}
