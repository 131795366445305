import { observer } from "mobx-react";
import { MessageBar, MessageBarType } from "office-ui-fabric-react/lib/MessageBar";
import * as React from "react";
import { EasyFormContext } from "./EasyFormContext";

@observer
export class EasyForm extends React.Component<
    {
        Object?: object;
        Disabled?: boolean;
        ShowFooter?: boolean;
    },
    {}
> {
    public render() {
        return (
            <EasyFormContext.Provider value={{ Object: this.props.Object, Disabled: this.props.Disabled }}>
                {this.props.children}
                {this.props.ShowFooter && (
                    <>
                        <br />
                        <MessageBar messageBarType={MessageBarType.info}>
                            <span>
                                <b>* Pflichtfeld</b>
                            </span>
                        </MessageBar>
                    </>
                )}
            </EasyFormContext.Provider>
        );
    }
}
