import { observer } from "mobx-react";
import { DefaultButton, DialogFooter, PrimaryButton, TextField } from "office-ui-fabric-react";
import * as React from "react";
import { DBDialog } from "../../Generic";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class StartFreigabeDialog extends React.Component<
    {
        StartWorkflow: (message: string) => void;
        CloseDialog: () => void;
    },
    {
        message: string;
    }
> {
    public readonly state = { message: undefined };

    public render(): JSX.Element {
        return (
            <DBDialog
                onDismiss={() => {
                    this.props.CloseDialog();
                }}
                dialogContentProps={{
                    title: "Freigabe starten"
                }}
                minWidth="760px"
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            Möchten Sie den Freigabeprozess für diesen Bericht starten?
                            <br />
                            Im Textfeld haben Sie optional die Möglichkeit einen Hinweistext einzugeben, der den
                            Freigebenden angezeigt wird.
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">
                            <TextField
                                value={this.state.message || ""}
                                onChange={(e: React.FormEvent<HTMLElement>, value: string) => {
                                    this.setState({ message: value });
                                }}
                                autoComplete="off"
                                label="Hinweistext"
                                required
                                multiline={true}
                                rows={3}
                            />
                        </div>
                    </div>
                </div>
                <DialogFooter>
                    <DefaultButton
                        onClick={() => {
                            this.props.CloseDialog();
                        }}
                        text="Abbrechen"
                    />
                    <PrimaryButton
                        onClick={() => {
                            this.props.StartWorkflow(this.state.message);
                        }}
                        text="Freigabe starten"
                        disabled={!this.state.message}
                    />
                </DialogFooter>
            </DBDialog>
        );
    }
}
