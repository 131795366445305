import * as mobx from "mobx";
import { observer } from "mobx-react";
import { ChoiceGroup, IChoiceGroupOption, IChoiceGroupProps } from "office-ui-fabric-react";
import * as React from "react";
import { CatchReactErrors } from "../Error-Handler/Decorators";
import { EasyFormContext } from "./EasyFormContext";

@observer
@CatchReactErrors
export class EasyChoiceGroup extends React.Component<
    {
        Object?: object;
        PropertyName: string;
        ChoiceGroupProps?: IChoiceGroupProps; // ChoiceGroupProps,
        Options: IChoiceGroupOption[];
    },
    {}
> {
    public static contextType = EasyFormContext;
    public readonly state = {};

    private get Object(): object {
        return this.props.Object || this.context.Object;
    }

    public render() {
        return (
            <ChoiceGroup
                selectedKey={this.Object[this.props.PropertyName]}
                options={this.props.Options}
                required={true}
                onChange={(
                    event: React.FormEvent<HTMLElement>,
                    option?: IChoiceGroupOption,
                    index?: number,
                    value?: string
                ) => {
                    mobx.runInAction(() => {
                        this.Object[this.props.PropertyName] = option.key;
                    });
                }}
                disabled={this.context.Disabled}
                {...this.props.ChoiceGroupProps}
            />
        );
    }
}
