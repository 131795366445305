import {
    DefaultButton,
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IDetailsHeaderProps,
    IRenderFunction,
    Label,
    Link,
    SelectionMode
} from "office-ui-fabric-react";
import * as React from "react";
import Files from "react-butterfiles";
import { PleaseWait, ShowUser } from "../../../tsx/Generic";
import { nameof, Utils } from "../../../Utils";
import { BaseFile, IEntityRepositoryWithFile } from "../../DAL";
import { RadVersionHistoryModal } from "./RadVersionHistoryModal";

export interface RadFolderViewProps<F extends BaseFile> {
    Disabled?: boolean;
    LabelText?: string;
    Compact?: boolean;
    ObjectId: string;
    Repository: IEntityRepositoryWithFile<any, F>;
}

function GetColumns(
    compact: boolean,
    showVersionsmodal: (string) => void,
    deleteItem: (string) => void,
    disabled: boolean
): IColumn[] {
    const cols = [
        {
            key: "col1",
            name: "Name",
            fieldName: "Name",
            minWidth: 200,
            isResizable: true,
            onRender: (item: BaseFile) => <Link href={item.downloadUrl}>{item.name}</Link>
        },
        !compact && {
            key: "col2",
            name: "Version",
            fieldName: "UIVersionLabel",
            minWidth: 50,
            isResizable: true,
            onRender: (item: BaseFile) => (
                <>
                    {item.version && (
                        <a
                            href="#"
                            onClick={() => {
                                showVersionsmodal(item.objectId);
                            }}
                        >
                            {item.version}
                        </a>
                    )}
                </>
            )
        },
        !compact && {
            key: "col3",
            name: "Geändert von",
            minWidth: 120,
            isResizable: true,
            onRender: (item: BaseFile) => (
                <>
                    {(item.lastModifiedBy && (
                        <ShowUser Object={item} PropertyName={nameof<BaseFile>("lastModifiedBy")} />
                    )) ||
                        "Hochladen..."}
                </>
            )
        },
        {
            key: "col4",
            name: "Geändert am",
            fieldName: "TimeLastModified",
            minWidth: 156,
            isResizable: true,
            onRender: (item: BaseFile) => <>{item.lastModified && Utils.FormatDateTime(item.lastModified)}</>
        }
        // {
        //     key: "col5",
        //     name: "",
        //     fieldName: "",
        //     minWidth: 100,
        //     isResizable: true,
        //     onRender: (item: BaseFile) => (
        //         <PrimaryButton
        //             onClick={async () => {
        //                 deleteItem(item.id);
        //             }}
        //             iconProps={{ iconName: "Delete" }}
        //             disabled={disabled}
        //         />
        //     )
        // }
    ];

    return cols.filter((obj: any) => {
        return obj !== false;
    });
}

export function RadFolderView<F extends BaseFile>({
    Disabled,
    LabelText,
    Compact,
    ObjectId,
    Repository
}: React.PropsWithChildren<RadFolderViewProps<F>>) {
    const [isLoading, setIsLoading] = React.useState(true);
    const [files, setFiles] = React.useState([]);
    const [showVersionModel, setShowVersionModal] = React.useState();

    // load files for container objectId
    React.useEffect(() => {
        (async () => {
            setIsLoading(true);
            let files = await Repository.listFiles(ObjectId);
            setFiles(files);
            setIsLoading(false);
        })();
    }, [ObjectId]);

    if (isLoading) {
        return <PleaseWait ShowSpinner={isLoading} render={() => <></>} />;
    }

    let columns = GetColumns(
        Compact,
        (id) => {
            setShowVersionModal(id);
        },
        () => {},
        Disabled
    );
    return (
        <>
            {showVersionModel && (
                <RadVersionHistoryModal
                    Repository={Repository}
                    FileId={showVersionModel}
                    OnClose={() => {
                        setShowVersionModal(undefined);
                    }}
                />
            )}
            {LabelText && <Label disabled={Disabled}>{LabelText}</Label>}
            {Disabled ? (
                <DetailsList
                    items={files}
                    columns={columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    compact={true}
                    selectionMode={SelectionMode.none}
                />
            ) : (
                <Files
                    multiple={true}
                    maxSize="100mb"
                    multipleMaxSize="100mb"
                    multipleMaxCount={3}
                    onSuccess={(files: any) => {
                        this.onDrop(files);
                    }}
                    onError={(errors: any) => console.log(errors)}
                >
                    {({ browseFiles, getDropZoneProps }) => {
                        return (
                            <div>
                                <div
                                    {...getDropZoneProps({
                                        style: {
                                            minHeight: 200,
                                            outline: "2px lightgray dashed",
                                            paddingTop: "1px"
                                        }
                                    })}
                                >
                                    <DetailsList
                                        items={files}
                                        columns={columns}
                                        setKey="set"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        compact={true}
                                        selectionMode={SelectionMode.none}
                                        onRenderDetailsHeader={(
                                            detailsHeaderProps: IDetailsHeaderProps,
                                            defaultRender?: IRenderFunction<IDetailsHeaderProps>
                                        ): JSX.Element => {
                                            return (
                                                <DetailsHeader
                                                    {...detailsHeaderProps}
                                                    styles={{ root: { paddingTop: "1px" } }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                <div>
                                    Sie können Dateien in den gestrichelten Bereich ziehen oder den folgenden Knopf
                                    drücken:
                                    <DefaultButton text="Dateien hochladen..." onClick={browseFiles} />
                                </div>
                            </div>
                        );
                    }}
                </Files>
            )}
        </>
    );
}
