import * as deepEqual from "deep-equal";
import { observer } from "mobx-react";
import { ActionButton, ComboBox, IComboBox, IComboBoxOption, SearchBox } from "office-ui-fabric-react";
import * as React from "react";
import { Knz2BereichListModelFilter, Knz2Repository } from "../../../Model/Knz2";
import { EnumBereichStatus } from "../../../Rad/DAL";
import { CatchReactErrors } from "../../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz2FilterControl extends React.Component<
    {
        OnUpdateFilter: (filter: Knz2BereichListModelFilter) => void;
        Model: Knz2Repository;
        StatusValues: EnumBereichStatus[];
    },
    {
        filterText: string;
        filterStatus: EnumBereichStatus;
        Options: IComboBoxOption[];
    }
> {
    public readonly state = { filterStatus: null, filterText: "", Options: [] };

    public componentDidMount() {
        this.updateOptions();
    }

    public componentDidUpdate(prevProps, prevState, snapshot) {
        if (!deepEqual(prevProps.StatusValues, this.props.StatusValues)) {
            this.updateOptions();
        }
        if (this.state.Options !== prevState.Options) {
            this.setState({ filterStatus: this.state.Options[0].key.toString() });
        }
        if (prevState.filterText !== this.state.filterText || prevState.filterStatus !== this.state.filterStatus) {
            this.props.OnUpdateFilter(new Knz2BereichListModelFilter(this.state.filterText, this.state.filterStatus));
        }
    }

    public render(): JSX.Element {
        return (
            <div className="ms-Grid" dir="ltr" style={{ maxWidth: "700px", padding: "0px 0px 0px 0px" }}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm5" style={{ marginTop: "4px" }}>
                        <SearchBox
                            placeholder="Durchsuche Bezeichnung/Strecken-Nr."
                            iconProps={{ iconName: "Filter" }}
                            onChange={(event: any, value: string) => {
                                this.setState({ filterText: value });
                            }}
                            onClear={() => {
                                this.setState({ filterText: "" });
                            }}
                            value={this.state.filterText}
                            onSearch={(newValue: any) => {
                                this.setState({ filterText: newValue });
                            }}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm4" style={{ marginTop: "4px" }}>
                        <ComboBox
                            selectedKey={this.state.filterStatus}
                            allowFreeform={false}
                            autoComplete="on"
                            options={this.state.Options}
                            onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
                                this.setState({ filterStatus: option && (option.key as EnumBereichStatus) });
                            }}
                            disabled={this.state.Options.length <= 2}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm3">
                        <ActionButton
                            iconProps={{ iconName: "ClearFilter" }}
                            allowDisabledFocus={true}
                            onClick={() => {
                                this.setState({ filterStatus: null, filterText: "" });
                            }}
                        >
                            Filter zurücksetzen
                        </ActionButton>
                    </div>
                </div>
            </div>
        );
    }

    private updateOptions(): void {
        const options: IComboBoxOption[] = [];
        if (this.props.StatusValues.length > 1) {
            options.push({
                key: "",
                text: "Alle Status anzeigen"
            });
        }
        for (const option of this.props.StatusValues) {
            options.push({
                key: option,
                text: String(option)
            });
        }
        this.setState({ Options: options });
    }
}
