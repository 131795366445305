import { TaskEditModel } from "../../Model/Workflows";

export type Type<T> = new (...args: any[]) => T;

export interface ITaskEditorProps {
    OnValidate: (isValid: boolean) => void;
    Model: TaskEditModel;
}

export class TaskEditDialogStore {
    private static store = new Map<string, Type<React.Component<ITaskEditorProps, {}>>>();

    public static Register(key: string, component: Type<React.Component<ITaskEditorProps, {}>>): void {
        if (this.store.has(key)) {
            throw Error(`Key: ${key} is already used!`);
        }

        this.store.set(key, component);
    }

    public static Get(key: string): Type<React.Component<ITaskEditorProps, {}>> {
        return this.store.get(key);
    }
}
