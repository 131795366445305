import React from "react";
import { BaseEntity } from "../../DAL";
import { RadListAction, RadListState } from "./RadListState";

const RadListContext = React.createContext(null);

type RadListContextType<T extends BaseEntity> = {
    state: RadListState<T>;
    dispatch: (action: RadListAction<T>) => void;
};

export function useRadListContext<T extends BaseEntity>(): RadListContextType<T> {
    return React.useContext(RadListContext);
}

export default RadListContext;
