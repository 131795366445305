import { observer } from "mobx-react";
import { IColumn, Icon } from "office-ui-fabric-react";
import * as React from "react";
import { allMetadata } from "../../../generated/RadRepository";
import { Knz2GK2018Abschnitt } from "../../../Model/Knz2/GK2018/Knz2Abschnitt";
import { Knz2GK2018Bereich } from "../../../Model/Knz2/GK2018/Knz2Bereich";
import { Knz2Repository } from "../../../Model/Knz2/GK2018/Knz2Repository";
import { nameof, Utils } from "../../../Utils";
import { CatchReactErrors, CatchReactErrorsMethod } from "../../Generic/Error-Handler/Decorators";
import { IGroupedListDatasource, LargeGroupedList } from "../../Generic/LargeGroupedList";
import { ObserverSpan } from "../../Generic/ObserverSpan";

export enum EnumColumnType {
    Anlage1,
    Anlage3
}

@observer
@CatchReactErrors
export class Knz2BereicheList extends React.Component<
    {
        Model: Knz2Repository;
        OnEditBereich?: (bereich: Knz2GK2018Bereich) => void;
        OnSelectedItems?: (selectedItems: []) => void;
        ListModel: IGroupedListDatasource;
        ColumnType: EnumColumnType;
    },
    {}
> {
    public readonly state = {}; // makes react warning go away
    private listRef: React.RefObject<LargeGroupedList> = React.createRef();
    protected CacheAbschnitte: Map<string, Knz2GK2018Abschnitt>;
    public static ColumnsAnlage1: IColumn[] = Knz2BereicheList.GetColumns(true, null);
    protected ColumnsAnlage3: IColumn[] = Knz2BereicheList.GetColumns(false, this);

    private static GetColumns(isAnlage1: boolean, instance: Knz2BereicheList): IColumn[] {
        let output: IColumn[] = [];

        output = [
            {
                key: "Feld01",
                name: "Strecken Nr.",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                onRender: (item: Knz2GK2018Bereich) => (
                    <>
                        <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2018Bereich>("f01Streckennummer")} />
                        {item.f43GeteiltAus && (
                            <Icon
                                iconName="Split"
                                style={{ marginLeft: "10px" }}
                                ariaLabel="Entstammt geteiltem Bereich"
                            />
                        )}
                    </>
                ),
                ariaLabel: "Strecken Nr. des Sanierungsbereichs"
            },
            {
                key: "Feld02",
                name: "Weitere Strecken-Nr.",
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                onRender: (item: Knz2GK2018Bereich) => (
                    <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2018Bereich>("f27WeitereStrecken")} />
                )
            },
            {
                key: "Feld03",
                name: "Bundesland",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                onRender: (item: Knz2GK2018Bereich) => (
                    <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2018Bereich>("f26BundeslandString")} />
                ),
                ariaLabel: "Bundesland des Sanierungsbereichs"
            },
            {
                key: "Feld04",
                name: "Bezeichnung",
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                onRender: (item: Knz2GK2018Bereich) => (
                    <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2018Bereich>("f02Bezeichnung")} />
                ),
                ariaLabel: "Bezeichnung des Sanierungsbereichs"
            },
            {
                key: "Feld05",
                name: "km von",
                minWidth: 50,
                maxWidth: 50,
                isResizable: true,
                onRender: (item: Knz2GK2018Bereich) => (
                    <ObserverSpan
                        Style={{
                            display: "block",
                            textAlign: "right"
                        }}
                        Object={item}
                        PropertyName={
                            isAnlage1 ? nameof<Knz2GK2018Bereich>("f05KmVon1") : nameof<Knz2GK2018Bereich>("f07KmVon3")
                        }
                        FormatFunction={Utils.FormatKilometer}
                    />
                ),
                ariaLabel: isAnlage1 ? "km von lt. Anlage 1" : "km von lt. Anlage 3"
            },
            {
                key: "Feld06",
                name: "km bis",
                minWidth: 50,
                maxWidth: 50,
                isResizable: true,
                onRender: (item: Knz2GK2018Bereich) => (
                    <ObserverSpan
                        Style={{
                            display: "block",
                            textAlign: "right"
                        }}
                        Object={item}
                        PropertyName={
                            isAnlage1 ? nameof<Knz2GK2018Bereich>("f06KmBis1") : nameof<Knz2GK2018Bereich>("f08KmBis3")
                        }
                        FormatFunction={Utils.FormatKilometer}
                    />
                ),
                ariaLabel: isAnlage1 ? "km bis lt. Anlage 1" : "km bis lt. Anlage 3"
            },
            {
                key: "Feld07",
                name: "\u2211 km", // Sum-Symbol
                minWidth: 50,
                maxWidth: 50,
                isResizable: true,
                onRender: (item: Knz2GK2018Bereich) => (
                    <ObserverSpan
                        Style={{
                            display: "block",
                            textAlign: "right"
                        }}
                        Object={item}
                        PropertyName={
                            isAnlage1
                                ? nameof<Knz2GK2018Bereich>("f05LaengeKm1")
                                : nameof<Knz2GK2018Bereich>("f07LaengeKm3")
                        }
                        FormatFunction={Utils.FormatKilometer}
                    />
                ),
                ariaLabel: isAnlage1 ? "Summe km (Länge) lt. Anlage 1" : "Summe km (Länge) lt. Anlage 3"
            }
        ];

        if (!isAnlage1) {
            output.push({
                key: "Feld08",
                name: "PKZ",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                onRender: (item: Knz2GK2018Bereich) => {
                    const a = instance.CacheAbschnitte.get(item.knz2AbschnittRef?.objectId);
                    return <>{a && Utils.FormatNumber(a.f03PKZ)}</>;
                }
            });
        }

        output.push(
            ...[
                {
                    key: "Feld09",
                    name: "Status",
                    minWidth: 120,
                    maxWidth: 120,
                    isResizable: true,
                    onRender: (item: Knz2GK2018Bereich) => (
                        <ObserverSpan
                            Object={item}
                            PropertyName={nameof<Knz2GK2018Bereich>("f25Status")}
                            FormatFunction={(b) =>
                                Utils.ApplyMapping(b, allMetadata.enumMapping["DB.LSK.Model.Enums+EnumBereichStatus"])
                            }
                        />
                    )
                },
                {
                    key: "Feld10",
                    name: "Auslösewert (dB(A))",
                    minWidth: 70,
                    maxWidth: 70,
                    isResizable: true,
                    onRender: (item: Knz2GK2018Bereich) => (
                        <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2018Bereich>("f34Grenzwert")} />
                    )
                },
                {
                    key: "Feld11",
                    name: "Bemerkungen",
                    minWidth: 200,
                    maxWidth: 200,
                    isResizable: true,
                    onRender: (item: Knz2GK2018Bereich) => (
                        <ObserverSpan Object={item} PropertyName={nameof<Knz2GK2018Bereich>("f28Bemerkungen")} />
                    )
                }
            ]
        );

        return output;
    }

    public async componentDidMount(): Promise<void> {
        this.CacheAbschnitte = await this.props.Model.AbschnitteCache.GetAllMap();
    }

    public async componentDidUpdate(prevProps): Promise<void> {
        if (this.props.ListModel !== prevProps.ListModel) {
            this.RefreshGroups();
        }
    }

    @CatchReactErrorsMethod()
    public async RefreshGroups(): Promise<void> {
        await this.listRef.current.RefreshGroups();
    }

    public render() {
        return (
            <LargeGroupedList
                Columns={
                    this.props.ColumnType === EnumColumnType.Anlage1
                        ? Knz2BereicheList.ColumnsAnlage1
                        : this.ColumnsAnlage3
                }
                DataSource={this.props.ListModel}
                OnItemInvoked={(item) => {
                    if (this.props.OnEditBereich) {
                        this.props.OnEditBereich(item);
                    }
                }}
                OnSelectedItems={(selectedItems: []) => {
                    if (this.props.OnSelectedItems) {
                        this.props.OnSelectedItems(selectedItems);
                    }
                }}
                ref={this.listRef}
            />
        );
    }
}
