import { saveAs } from "file-saver";
import {
    ComboBox,
    DefaultButton, DialogFooter, IComboBox,
    IComboBoxOption,
    PrimaryButton
} from "office-ui-fabric-react";
import * as React from "react";
import { EnumDocumentCreationType, Knz4LSAbsenderModel } from "../../generated/BackendClient";
import { Knz4LSObjekt, Knz4Repository } from "../../Model/Knz4LS";
import { DBDialog, LoadingDialog } from "../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../Generic/Error-Handler/Decorators";

@CatchReactErrors
export class Knz4AbsenderDialog extends React.Component<
    {
        Model: Knz4Repository;
        SelectedItem: Knz4LSObjekt;
        CloseDialog: () => void;
    },
    {
        Options: IComboBoxOption[];
        SelectedAbsender: Knz4LSAbsenderModel;
        InitialText: string;
    }
> {
    public readonly state = {
        Options: [],
        SelectedAbsender: undefined,
        InitialText: undefined
    };
    private loadingRef: React.RefObject<LoadingDialog> = React.createRef();
    private localStorageAbsenderKey = "AbsenderId";

    public async componentDidMount() {
        const AbsenderItems = await this.props.Model.GetAbsender();

        const options: IComboBoxOption[] = [];
        for (const obj of AbsenderItems.values()) {
            options.push({
                key: `${obj.objectId}`,
                text: `${obj.k4F41AbsenderVorname} ${obj.k4F42AbsenderName}`,
                data: obj
            });
        }

        if (options.length > 0) {
            const absenderId: string = localStorage.getItem(this.localStorageAbsenderKey);
            let initialOption = absenderId
                ? options.find((value: any) => {
                      return value.key === absenderId;
                  })
                : options[0];

            // If the list item (Absender) is deleted but it's id is stored in Localstorage
            if (!initialOption) {
                localStorage.removeItem(this.localStorageAbsenderKey);
                initialOption = options[0];
            }

            this.setState({
                Options: options,
                InitialText: initialOption?.text,
                SelectedAbsender: AbsenderItems.get(initialOption.key as string)
            });
        }
    }

    public render():JSX.Element {
        return (
            <DBDialog
                dialogContentProps={{
                    title: "Absender auswählen"
                }}
                minWidth="760px"
            >
                <LoadingDialog ref={this.loadingRef} />
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12">Bitte wählen Sie den Absender aus.</div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6" style={{ marginTop: "20px" }}>
                            <ComboBox
                                selectedKey={2}
                                allowFreeform={false}
                                autoComplete="on"
                                options={this.state.Options}
                                onChange={(
                                    event: React.FormEvent<IComboBox>,
                                    option?: IComboBoxOption,
                                    index?: number,
                                    value?: string
                                ) => {
                                    const selectedOption = option.data as Knz4LSAbsenderModel;
                                    this.setState({
                                        SelectedAbsender: selectedOption,
                                        InitialText: `${selectedOption.k4F41AbsenderVorname} ${selectedOption.k4F42AbsenderName}`
                                    });
                                }}
                                text={this.state.InitialText}
                                required={true}
                                placeholder="Ingenieurbüro wählen"
                                label="Absender"
                            />
                        </div>
                    </div>
                </div>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            this.ExitDialog(true);
                        }}
                        text="Rücksendung"
                    />
                    <DefaultButton
                        onClick={() => {
                            this.ExitDialog(false);
                        }}
                        text="Schließen"
                    />
                </DialogFooter>
            </DBDialog>
        );
    }

    @CatchReactErrorsMethod()
    private async ExitDialog(shouldProcess: boolean) {
        if (shouldProcess) {
            localStorage.setItem(this.localStorageAbsenderKey, this.state.SelectedAbsender.objectId.toString());
            await this.loadingRef.current.Execute(async () => {
                const fileResponse = await this.props.Model.ExportDocument(
                    this.props.SelectedItem,
                    EnumDocumentCreationType.Ruecksendung,
                    this.state.SelectedAbsender
                );
                saveAs(fileResponse.data, fileResponse.fileName);
            }, "Word-Dokument wird erstellt");
        }
        this.props.CloseDialog();
    }
}
