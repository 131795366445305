import { INavLink, INavLinkGroup, INavStyles, IRenderFunction, Nav } from "office-ui-fabric-react";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { matchPath, useHistory, withRouter } from "react-router-dom";
import { UserContextModelOfEnumUserRole } from "../../generated/BackendClient";

export type IRadNavCondition = {
    requiresRole?: string[] | string;
    requiresAdmin?: boolean;
    requiresEntityAccess?: string[] | string;
    links?: IRadNavLink[];
};

export type IRadNavLinkGroup = INavLinkGroup & IRadNavCondition & {};

export type IRadNavLink = INavLink & IRadNavCondition & {};

// Your component own properties
type PropsType = RouteComponentProps & {
    Root: IRadNavLinkGroup;
    User: UserContextModelOfEnumUserRole;
    Entities: string[];
};

const navStyles: Partial<INavStyles> = {
    root: {
        width: 208,
        boxSizing: "border-box"
    },
    link: {
        height: 27,
        lineHeight: 27,
        fontSize: 13
    },
    chevronButton: {
        lineHeight: 27,
        height: 27
    },
    chevronIcon: {
        lineHeight: 27,
        height: 27
    }
};

function findKey(links: INavLink[], pathname: string): string {
    for (let l of links) {
        if (
            l.url &&
            matchPath(pathname, {
                path: l.url,
                exact: l.url.length == 1, // fix root node
                strict: false
            })
        ) {
            return l.key;
        }
        if (l.links) {
            let ll = findKey(l.links, pathname);
            if (ll) {
                return ll;
            }
        }
    }
    return null;
}

function contains(haystack: string[], needle: string | string[]): boolean {
    if (typeof needle === "string") {
        return haystack.includes(needle);
    } else if (typeof needle === "object") {
        return needle.some((r) => haystack.includes(r));
    } else throw Error(`Can not handle type ${typeof needle}`);
}

function calculateLinks(
    links: IRadNavLink[],
    user: UserContextModelOfEnumUserRole,
    entities: string[],
    keyPrefix: string = ""
): INavLink[] {
    let output: INavLink[] = [];
    let counter = 1;
    for (let link of links) {
        // admin may see everything
        if (!user.currentUser.isAdmin) {
            // ignore navigation nodes that we may not access
            if (!!link.requiresRole && !contains(user.allowedScopes, link.requiresRole)) continue;
            if (!!link.requiresEntityAccess && !contains(entities, link.requiresEntityAccess)) continue;
            if (!!link.requiresAdmin) continue;
        }

        // recurse into links
        let l: INavLink = { ...link, key: keyPrefix + counter };
        if (link.links) {
            l.links = calculateLinks(link.links, user, entities, keyPrefix + counter + ".");
        }
        if (l.links?.length > 0 || l.url) {
            output.push(l);
        }
        counter++;
    }

    return output;
}

function renderLink(props: INavLink, onRenderLink?: IRenderFunction<INavLink>) {
    if (props.badge) {
        return (
            <>
                {onRenderLink(props)}
                <div className="badgeContainer">
                    <div className="badge">{props.badge}</div>
                </div>
            </>
        );
    }
    return onRenderLink(props);
}

function RadNav({ Root, User, Entities }: React.PropsWithChildren<PropsType>) {
    let history = useHistory();
    let selectedKey: string = null;
    let groups = [{ ...Root, links: calculateLinks(Root.links, User, Entities) }];

    // determine current key
    for (let g of groups) {
        selectedKey ??= findKey(g.links, location.pathname);
    }

    return (
        <Nav
            selectedKey={selectedKey}
            styles={navStyles}
            onLinkClick={(ev, item) => {
                // use react-router to change location
                ev.preventDefault();
                history.push(item.url);
            }}
            groups={groups}
            onRenderLink={(props, defaultRender) => renderLink(props, defaultRender)}
        />
    );
}

export default withRouter(RadNav);
