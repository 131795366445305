import { ITextFieldProps, TextField } from "office-ui-fabric-react";
import * as React from "react";
import { IFieldRenderer, IFieldRendererEditProps, IFieldRendererViewProps } from "../FieldRenderer";

export interface RadTextFieldProps {
    TextFieldProps: ITextFieldProps;
    Value: string;
    OnChange?: (value: string) => void;
}

export function RadTextField({ TextFieldProps, OnChange, Value }: React.PropsWithChildren<RadTextFieldProps>) {
    return (
        <TextField
            value={Value || ""}
            onChange={(e: React.FormEvent<HTMLElement>, value: string) => {
                OnChange && OnChange(value);
            }}
            autoComplete="off"
            {...TextFieldProps}
            required={!TextFieldProps.disabled && TextFieldProps.required}
        />
    );
}

export const RadTextFieldRenderer: IFieldRenderer = {
    EditField: (props: IFieldRendererEditProps) =>
        RadTextField({
            Value: props.Value,
            TextFieldProps: { label: props.Metadata.title, disabled: props.Disabled || props.Metadata.readOnly },
            OnChange: props.OnChange
        }),
    ShowField: (props: IFieldRendererViewProps) =>
        RadTextField({ Value: props.Value, TextFieldProps: { label: props.Metadata.title, disabled: true } }),
    ListField: (props: IFieldRendererViewProps) => <>{props.Value}</>
};
